import type { FC } from 'react'

import type { AccordionProps } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from '@mui/material'
import { CaretDown, X } from '@phosphor-icons/react'

import type { MentorSessionRecapItemFragment } from '@core/graphql'
import { MentorSessionRecapType } from '@core/graphql'
import {
  DateFormatText,
  EmptyState,
  FormSubmissionContent,
} from '@core/ui/components'
import ScrollArea from '@core/ui/components/layouts/ScrollArea'

import RecapFromTo from '../RecapFromTo'

export type ViewRecapDialogProps = {
  sessionDate: Date
  recaps: MentorSessionRecapItemFragment[]
  open: boolean
  onClose?: VoidFunction
}

const ViewRecapDialog: FC<ViewRecapDialogProps> = ({
  onClose,
  open,
  sessionDate,
  recaps,
}) => {
  return (
    <Dialog
      open={open}
      scroll="paper"
      PaperProps={{
        sx: {
          minHeight: 500,
          minWidth: 640,
        },
      }}
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          position: 'sticky',
          top: 0,
          marginTop: 0,
          background: (theme) => `${theme.palette.background.paper} !important`,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          zIndex: 1,
          pb: 2,
        }}
      >
        <Box>
          Recaps of Mentor Session on{' '}
          <DateFormatText
            disableTooltip
            date={sessionDate}
            format="PP"
            variant="format"
          />
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            top: 16,
            right: 14,
          }}
          onClick={onClose}
        >
          <X />
        </IconButton>
      </DialogTitle>
      {recaps.length ? (
        <ScrollArea h={600}>
          <Stack sx={{ px: 2, py: 1 }}>
            {recaps.map((recap) => (
              <RecapAccordion
                key={recap.id}
                expanded={recaps.length === 1 ? true : undefined}
              >
                <AccordionSummary
                  expandIcon={recaps.length > 1 && <CaretDown weight="bold" />}
                  sx={{
                    cursor:
                      recaps.length === 1 ? 'default !important' : undefined,
                  }}
                >
                  {recap.type === MentorSessionRecapType.MENTEE_RECAP
                    ? recap.mentor.user?.name && (
                        <RecapFromTo
                          from={recap.learner.user?.name}
                          to={recap.mentor.user?.name}
                          type={recap.type}
                        />
                      )
                    : recap.learner.user?.name && (
                        <RecapFromTo
                          from={recap.mentor.user?.name}
                          to={recap.learner.user?.name}
                          type={recap.type}
                        />
                      )}
                </AccordionSummary>
                <AccordionDetails>
                  <FormSubmissionContent
                    content={recap.formSubmission.content}
                  />
                </AccordionDetails>
              </RecapAccordion>
            ))}
          </Stack>
        </ScrollArea>
      ) : (
        <EmptyState message="No recap has been submitted" />
      )}
      <IconButton
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
        }}
        onClick={onClose}
      >
        <X />
      </IconButton>
    </Dialog>
  )
}

const RecapAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  boxShadow: 'none !important',
  borderTop: `solid 1px ${theme.palette.divider}`,
  borderRadius: '0 !important',
  '&:first-of-type': {
    borderTop: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

export default ViewRecapDialog
