import { createContext, useContext } from 'react'

import type {
  LearnerMemberSessionsProviderContext,
  LearnerMemberSessionsState,
} from './LearnerMemberSessions.type'

export const sessionContextInitialState: LearnerMemberSessionsState = {
  dialogIsOpen: false,
}

const LearnerMemberSessionsContext =
  createContext<LearnerMemberSessionsProviderContext>({
    state: sessionContextInitialState,
    openDialog: () => {},
    closeDialog: () => {},
    refetchMentorSession: () => {},
    needsAction: () => false,
  })

export const useLearnerMemberSessionsContext = () => {
  const context = useContext(LearnerMemberSessionsContext)

  if (!context) {
    throw new Error(
      'LearnerMemberSessionsContext must be used inside LearnerMemberSessionsContextProvider',
    )
  }
  return context
}

export default LearnerMemberSessionsContext
