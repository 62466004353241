import type { ReactNode } from 'react'
import { useState } from 'react'

import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { ANY } from '@core/types'

type RHFDateTimePickerProps<FormValues extends FieldValues> = {
  control?: Control<FormValues>
  name: Path<FormValues>
  label?: string
  disabled?: boolean
  onChange?: (value: Date | null) => void
  helperText?: ReactNode
  readOnlyText?: boolean
  /** @defaults to 'dd/MM/yyyy HH:mm' */
  inputFormat?: string
  minDateTime?: Date
  maxDateTime?: Date
  defaultOpen?: boolean
}

export function RHFDateTimePicker<FormValues extends FieldValues>({
  name,
  control: $control,
  label,
  disabled,
  onChange: onChangeProp,
  helperText,
  readOnlyText = false,
  inputFormat = 'dd/MM/yyyy HH:mm',
  minDateTime,
  maxDateTime,
  defaultOpen = false,
}: RHFDateTimePickerProps<FormValues>) {
  const formContext = useFormContext()

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)

  const control: ANY = $control ?? formContext?.control

  if (!control) {
    // eslint-disable-next-line no-console
    console.warn(`[RHFDateTimePicker][${name}] FormProvider not found`)
    return null
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onChange },
        fieldState: { error },
        formState: { isSubmitting },
      }) => (
        <DateTimePicker
          disableMaskedInput
          disabled={disabled || isSubmitting}
          inputFormat={inputFormat}
          label={label}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          open={isOpen}
          value={(value ?? null) as Date | null}
          renderInput={(params) => (
            <TextField
              fullWidth
              helperText={error?.message || helperText}
              inputRef={ref}
              {...params}
              error={!!error}
              inputProps={{ ...params.inputProps, readOnly: readOnlyText }}
            />
          )}
          onClose={() => setIsOpen(false)}
          onOpen={() => setIsOpen(true)}
          onChange={(newValue) => {
            onChange({ target: { value: newValue } })
            onChangeProp?.(newValue)
          }}
        />
      )}
    />
  )
}
