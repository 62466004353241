import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import { Box, Button, Stack, Typography, Divider } from '@mui/material'
import { groupBy } from 'lodash'

import type { CourseMemberNodeFragment } from '@core/graphql'
import { useLocales } from '@core/hooks'
import {
  ListItemSkeleton,
  UrqlError,
  DateFormatText,
} from '@core/ui/components'

import { usePendingListContext } from '../PendingListContext'

import PendingMemberItem from './PendingMemberItem'

export type PendingMemberListProps = {}

const PendingMemberList: FC<PendingMemberListProps> = () => {
  const { t } = useLocales()
  const {
    pendingMemberConnection: { fetching, error, data },
    nextPage,
    fetchingNext,
  } = usePendingListContext()

  const courseMemberNodes = data?.courseMemberConnection.nodes ?? []
  const { endCursor, hasNextPage } = data?.courseMemberConnection.pageInfo ?? {}

  const enrollmentBatches = useMemo(() => {
    if (data?.courseMemberConnection.nodes) {
      return groupBy(data.courseMemberConnection.nodes, 'enrollmentDate')
    }
    return null
  }, [data?.courseMemberConnection.nodes])

  const renderNode = useCallback(
    (courseMemberNode: CourseMemberNodeFragment) => (
      <PendingMemberItem
        key={courseMemberNode.id}
        courseMember={courseMemberNode}
      />
    ),
    [],
  )

  if (!courseMemberNodes.length && fetching) {
    return <ListItemSkeleton />
  }

  if (error) {
    return <UrqlError error={error} />
  }

  return (
    <>
      {/* {courseMemberNodes.map(renderNode)} */}
      {enrollmentBatches &&
        Object.entries(enrollmentBatches)
          .sort((a, b) => +a - +b)
          .map(([enrollmentDate, pendingLearners]) => {
            return (
              <Stack key={enrollmentDate}>
                <DateFormatText
                  disableTooltip
                  date={+enrollmentDate}
                  format="PP"
                  variant="format"
                />
                <Divider sx={{ mt: 1 }} />
                {pendingLearners.map(renderNode)}
              </Stack>
            )
          })}
      <Box sx={{ display: 'grid', placeItems: 'center' }}>
        {!fetching && hasNextPage && endCursor && (
          <Button fullWidth sx={{ borderRadius: 0 }} onClick={nextPage}>
            Load more
          </Button>
        )}

        {fetchingNext && <ListItemSkeleton />}

        {!hasNextPage && (
          <Typography color="textSecondary" p={1} variant="body2">
            {t('course_member:no_more_pending_member', {
              defaultValue: 'No more pending member',
            })}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default PendingMemberList
