/*      THIS IS AN AUTO-GENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.       */
/* see packages/server/src/core/utils/locales.ts – updateClientSideNamespaces */
/* eslint-disable prettier/prettier */
export type Namespace =
  | 'activities'
  | 'activities_v2'
  | 'calendar'
  | 'chat'
  | 'common'
  | 'course'
  | 'course_detail'
  | 'course_member'
  | 'course_member_v2'
  | 'crossroads'
  | 'daskalos'
  | 'deal'
  | 'developer'
  | 'error'
  | 'learner'
  | 'learner_success'
  | 'mentor'
  | 'mentor_session'
  | 'mentor_session_v2'
  | 'notifications'
  | 'sales'
  | 'user'

const namespaces = [
  'activities',
  'activities_v2',
  'calendar',
  'chat',
  'common',
  'course',
  'course_detail',
  'course_member',
  'course_member_v2',
  'crossroads',
  'daskalos',
  'deal',
  'developer',
  'error',
  'learner',
  'learner_success',
  'mentor',
  'mentor_session',
  'mentor_session_v2',
  'notifications',
  'sales',
  'user',
]

export default namespaces
