import type { TextFieldProps } from '@mui/material'
import { TextField } from '@mui/material'
import type { Control, Path, FieldValues } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { ANY } from '@core/types'

type RHFTextFieldProps<FormValues extends FieldValues> = {
  control?: Control<FormValues>
  name: Path<FormValues>
} & Omit<TextFieldProps, 'name'>

export function RHFTextField<FormValues extends FieldValues>({
  name,
  control: $control,
  helperText,
  label,
  ...other
}: RHFTextFieldProps<FormValues>) {
  const formContext = useFormContext()

  const control: ANY = $control ?? formContext?.control

  if (!control) {
    // eslint-disable-next-line no-console
    console.warn(`[RHFTextField][${name}] FormProvider not found`)
    return null
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, ...field },
        fieldState: { error },
        formState: { isSubmitting },
      }) => (
        <TextField
          fullWidth
          required
          disabled={isSubmitting}
          error={!!error}
          helperText={error?.message || helperText}
          inputProps={{ ref }}
          label={label}
          {...field}
          value={
            typeof field.value === 'number' && field.value === 0
              ? ''
              : field.value
          }
          {...other}
        />
      )}
    />
  )
}
