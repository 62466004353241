import type { FC } from 'react'

import { Box, Stack } from '@mui/material'
import { isArray } from 'lodash'

import { useLearnerMemberSessionsContext } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessionsContext'

import type {
  SessionCalendarEvent,
  MentorSessionSecondaryAction,
} from '../LearnerMemberSessions.type'

export type SessionSecondaryActionProps = {
  action: MentorSessionSecondaryAction
  sessionEvent?: SessionCalendarEvent
}

const SessionSecondaryAction: FC<SessionSecondaryActionProps> = ({
  action: $action,
  sessionEvent,
}) => {
  const { state, openDialog, closeDialog } = useLearnerMemberSessionsContext()

  const action =
    typeof $action === 'function'
      ? $action({
          mentorSession: state.mentorSession,
          sessionEvent,
          dialogIsOpen: state.dialogIsOpen,
          onOpenDialog: openDialog,
          onCloseDialog: closeDialog,
        })
      : $action

  if (!action || !sessionEvent) {
    return null
  }

  if (isArray(action)) {
    return (
      <Stack alignItems="center" direction="row" spacing={1}>
        {action.map((item) => (
          <Box key={item.type}>
            {item.renderAction}
            {state.selectedActionType === item.type &&
              (sessionEvent
                ? state.dialogTargetId === sessionEvent.id
                : true) &&
              state.dialogIsOpen &&
              item.renderActionDialog}
          </Box>
        ))}
      </Stack>
    )
  }

  return (
    <Box>
      {action.renderAction}
      {state.selectedActionType === action.type &&
        (sessionEvent ? state.dialogTargetId === sessionEvent.id : true) &&
        state.dialogIsOpen &&
        action.renderActionDialog}
    </Box>
  )
}

export default SessionSecondaryAction
