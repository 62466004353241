import type { FC } from 'react'
import { useMemo, useState } from 'react'

import { Box, Card, Stack, styled, Typography } from '@mui/material'
import { orderBy } from 'lodash'

import ScrollArea from '@core/ui/components/layouts/ScrollArea'
import MentorSessionEventList from '@modules/mentor-session/common/MentorSessionEventList'

import type { SessionCalendarEvent } from '../LearnerMemberSessions.type'
import { useLearnerMemberSessionsContext } from '../LearnerMemberSessionsContext'

import SessionMenuActions from './SessionMenuActions'
import SessionSecondaryAction from './SessionSecondaryAction'

export type MentorSessionEventsProps = {
  events: SessionCalendarEvent[]
}

const MentorSessionEvents: FC<MentorSessionEventsProps> = ({
  events: $events,
}) => {
  const { sessionMenuActions, sessionSecondaryAction, needsAction } =
    useLearnerMemberSessionsContext()

  const [filter, setFilter] = useState<'upcoming' | 'past'>('upcoming')

  const now = useMemo(() => new Date(), [])

  const events = useMemo(
    () =>
      $events.map((event) => ({
        ...event,
        actionNeeded: needsAction
          ? needsAction({ sessionEvent: event })
          : false,
      })),
    [$events, needsAction],
  )

  const upcomingEvents = useMemo(
    () =>
      orderBy(
        events.filter(
          (event) => new Date(event.endsAt) >= now || event.actionNeeded,
        ),
        'startsAt',
        'asc',
      ),
    [events, now],
  )

  const pastEvents = useMemo(
    () =>
      orderBy(
        events
          .filter((event) => new Date(event.endsAt) < now)
          // Don't show need action section in Past tab
          .map((event) => ({ ...event, actionNeeded: false })),
        'startsAt',
        'desc',
      ),
    [events, now],
  )

  const filteredEvents = useMemo(
    () => (filter === 'past' ? pastEvents : upcomingEvents),
    [filter, pastEvents, upcomingEvents],
  )

  return (
    <Card sx={{ overflow: 'visible', pr: 1.5, py: 3 }}>
      <Stack spacing={3}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          px={3}
        >
          <Typography variant="h6">
            Mentor Sessions ({events.length})
          </Typography>

          <Stack alignItems="center" direction="row">
            {sessionSecondaryAction && (
              <SessionSecondaryAction action={sessionSecondaryAction} />
            )}
            {sessionMenuActions && (
              <SessionMenuActions actions={sessionMenuActions} />
            )}
          </Stack>
        </Stack>

        <Stack direction="row" px={3} spacing={1}>
          <FilterItem
            isActive={filter === 'upcoming'}
            onClick={() => setFilter('upcoming')}
          >
            Upcoming ({upcomingEvents.length})
          </FilterItem>
          <FilterItem
            isActive={filter === 'past'}
            onClick={() => setFilter('past')}
          >
            Past ({pastEvents.length})
          </FilterItem>
        </Stack>

        <ScrollArea h={400}>
          <MentorSessionEventList events={filteredEvents} />
        </ScrollArea>
      </Stack>
    </Card>
  )
}

const FilterItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  height: 32,
  borderRadius: 16,
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.typography.body2.fontSize,
  cursor: 'pointer',
  transition: theme.transitions.create('all'),

  // isActive
  color: isActive ? theme.palette.primary.contrastText : undefined,
  backgroundColor: isActive
    ? theme.palette.primary.main
    : theme.palette.background.neutral,

  '&:hover': {
    filter: 'brightness(0.95)',
  },
  '&:active': {
    filter: 'brightness(0.9)',
  },
}))

export default MentorSessionEvents
