import type { FC } from 'react'

import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { Link } from '@tanstack/react-location'
import { differenceInYears } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { LINKS } from '@core/config'
import type { BookMentorSessionLearnerMemberFragment } from '@core/graphql'
import {
  AvailabilitySchedulesView,
  ContentSection,
  EmptyState,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'
import { buildPath } from '@core/utils/buildPath'
import cssStyles from '@core/utils/cssStyles'
import { fDate } from '@core/utils/formatTime'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'

export type LearnerPreviewProps = {
  courseMember: BookMentorSessionLearnerMemberFragment
}

const LearnerPreview: FC<LearnerPreviewProps> = ({ courseMember }) => {
  const { setValue } = useFormContext<BookMentorSessionValues>()

  if (!(courseMember && courseMember.learner && courseMember.learner.user)) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
        <EmptyState message="Learner not found or has been removed" />
      </Box>
    )
  }

  const { learner } = courseMember
  const { user } = courseMember.learner

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={(theme) => ({
          p: 2,
          position: 'sticky',
          top: 0,
          marginTop: 0,
          ...cssStyles(theme).bgBlur(),
        })}
      >
        <UserAvatar userId={learner.userId} />
        <Box flex={1} overflow="hidden">
          <UserDisplayName
            noWrap
            component={Link}
            to={buildPath(LINKS.LEARNER_PROFILE, { learnerId: learner.id })}
            userId={learner.userId}
            variant="h6"
            {...{ target: '_blank' }}
          />
          <Typography noWrap color="textSecondary">
            {user.email}
          </Typography>
        </Box>
        <Tooltip title="Close preview">
          <IconButton
            onClick={() => setValue('previewLearnerCourseMember', null)}
          >
            <X />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack padding={2} spacing={1}>
        <ContentSection label="Learner ID">
          {learner.learnerCode}
        </ContentSection>
        <ContentSection label="Enrollment date">
          {courseMember.enrollmentDate
            ? fDate(courseMember.enrollmentDate)
            : 'unknown'}
        </ContentSection>
        {learner.dateOfBirth && (
          <ContentSection label="Date of birth">
            {fDate(learner.dateOfBirth)} (
            {differenceInYears(Date.now(), learner.dateOfBirth)} yo)
          </ContentSection>
        )}
        <ContentSection label="Gender">
          {learner.gender ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Discord">
          {learner.discordId ?? 'N/A'}
        </ContentSection>

        <Divider />

        <ContentSection label="Coding skill">
          {learner.codingSkill ?? 'N/A'}
        </ContentSection>
        <ContentSection label="English level">
          {learner.englishLevel?.replace('– Speaking', '\nSpeaking') ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Course expectation" maxContentHeight={200}>
          {courseMember.courseExpectation ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Job expectation" maxContentHeight={200}>
          {courseMember.jobExpectation ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Notes" maxContentHeight={200}>
          {learner.notes ?? 'N/A'}
        </ContentSection>

        <Divider />

        <ContentSection label="Availability">
          <AvailabilitySchedulesView targetId={learner.id} />
        </ContentSection>
      </Stack>
    </Box>
  )
}

export default LearnerPreview
