import { useMemo } from 'react'

import { format } from 'date-fns'

import { useEnrollmentBatchesQuery } from '@core/graphql'

const useEnrollmentDatesFilter = () => {
  const [{ error, fetching, data }] = useEnrollmentBatchesQuery()

  const enrollmentDates = useMemo(
    () => data?.getAllEnrollmentBatches ?? [],
    [data?.getAllEnrollmentBatches],
  )

  const enrollmentDateValues = useMemo(
    () =>
      enrollmentDates.map((date) => ({
        value: date.id,
        label: format(new Date(date.id), 'MMM dd, yyyy'),
      })),
    [enrollmentDates],
  )

  return { enrollmentDates, enrollmentDateValues, error, fetching, data }
}

export default useEnrollmentDatesFilter
