import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import type { SelectChangeEvent } from '@mui/material'
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { addDays, format, getDay, getHours, getMinutes } from 'date-fns'
import type { FieldError } from 'react-hook-form'
import { Frequency, RRule, rrulestr, Weekday } from 'rrule'

import type { ANY } from '@core/types'

const getRule = ({
  freq,
  dtstart,
  until: untilDate,
}: {
  freq: Frequency
  dtstart: Date
  until: Date | null
}) => {
  const until = untilDate
  switch (freq) {
    case Frequency.DAILY:
      return new RRule({
        freq,
        dtstart,
        until,
        byhour: getHours(dtstart),
        byminute: getMinutes(dtstart),
      })

    case Frequency.WEEKLY:
      return new RRule({
        freq,
        dtstart,
        until,
        byhour: getHours(dtstart),
        byminute: getMinutes(dtstart),
        byweekday: new Weekday(getDay(addDays(dtstart, -1))),
      })

    case Frequency.MONTHLY:
      return new RRule({
        freq,
        dtstart,
        until,
        byhour: getHours(dtstart),
        byminute: getMinutes(dtstart),
        bymonthday: +format(dtstart, 'd'),
      })

    default:
      return null
  }
}

export type RecurrenceInputProps = {
  value: string | null
  start: Date
  onChange: (value: string | null) => void
  error?: FieldError
}

const RecurrenceInput: FC<RecurrenceInputProps> = ({
  value,
  start: $start,
  onChange,
  error,
}) => {
  const start = useMemo(() => new Date($start), [$start])
  const selectedFreq = useMemo(() => {
    if (!value) {
      return 0
    }
    return rrulestr(value).options.freq
  }, [value])
  const until = useMemo(() => {
    if (!value) {
      return null
    }
    return rrulestr(value).options.until
  }, [value])

  const handleChangeFreq = useCallback(
    (event: SelectChangeEvent<Frequency | 0>) => {
      const freq = event.target.value as Frequency | 0

      if (!freq) {
        onChange(null)
        return
      }

      const rule = getRule({
        ...(value ? (rrulestr(value).options as ANY) : {}),
        freq,
        dtstart: start,
      })

      onChange(rule?.toString() ?? null)
    },
    [onChange, start, value],
  )

  const handleUntilChange = useCallback(
    (untilValue: null | Date) => {
      const rule = getRule({
        ...(value ? (rrulestr(value).options as ANY) : {}),
        until: untilValue,
        dtstart: start,
      })

      onChange(rule?.toString() ?? null)
    },
    [onChange, start, value],
  )

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs>
          <FormControl fullWidth>
            <Select value={selectedFreq} onChange={handleChangeFreq}>
              <MenuItem value={0}>Does not repeat</MenuItem>
              <MenuItem value={RRule.DAILY}>Daily</MenuItem>
              <MenuItem value={RRule.WEEKLY}>
                Weekly on {format(start, 'EEEE')}
              </MenuItem>
              <MenuItem value={RRule.MONTHLY}>
                Monthly on day {format(start, 'd')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs>
          <DatePicker
            disabled={!selectedFreq}
            label="Repeats until"
            value={until ?? null}
            views={['year', 'month', 'day']}
            renderInput={(params) => (
              <TextField fullWidth {...params} placeholder="Repeats until" />
            )}
            onChange={handleUntilChange}
          />
        </Grid>
      </Grid>
      {error && (
        <Typography color="error" px={1.75} py={1} variant="caption">
          {error.message}
        </Typography>
      )}
    </Box>
  )
}

export default RecurrenceInput
