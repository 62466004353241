import type { ElementType, FC, ReactNode } from 'react'
import { useMemo } from 'react'

import type { BoxProps } from '@mui/material'
import { Box, useTheme } from '@mui/material'
import clsx from 'clsx'

export type SelectiveBoxProps = {
  selected?: boolean
  children?: ReactNode
  to?: string
  component?: ElementType
} & BoxProps

const SelectiveBox: FC<SelectiveBoxProps> = ({
  selected,
  children,
  sx,
  ...props
}) => {
  const theme = useTheme()

  const overrideSx = useMemo(() => {
    if (typeof sx === 'function') {
      return sx(theme)
    }

    return sx
  }, [sx, theme])

  return (
    <Box
      {...props}
      className={clsx({ selected }, props.className)}
      sx={[
        {
          outline: `solid 2px transparent`,
          borderRadius: 1,
          padding: 2,
          cursor: 'pointer',
          height: '100%',
          transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest,
          }),
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.neutral',

          '&:hover': {
            outline: `solid 2px ${theme.palette.divider}`,
          },

          '&.selected': {
            outline: `solid 3px ${theme.palette.primary.main}`,
            bgcolor: 'background.paper',
          },
        },
        ...(Array.isArray(overrideSx) ? overrideSx : [overrideSx]),
      ]}
    >
      {children}
    </Box>
  )
}

export default SelectiveBox
