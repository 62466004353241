import { type FC, useEffect } from 'react'

import { useLwSSO } from '@core/hooks'
import { SplashScreen } from '@core/ui/components'
import ErrorScreen from '@core/ui/components/feedback/ErrorScreen'

export type LWGoToProps = {}

const LWGoTo: FC<LWGoToProps> = () => {
  const { sso, error } = useLwSSO()

  useEffect(() => {
    const fetchLwSSO = async () => {
      const url = await sso()

      if (url) {
        window.location.replace(url)
      }
    }

    fetchLwSSO()
  }, [sso])

  if (error) {
    return <ErrorScreen error={error} />
  }

  return <SplashScreen />
}

export default LWGoTo
