import type { BaseSyntheticEvent, ChangeEvent, FC } from 'react'
import { useCallback, useRef, useState } from 'react'

import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material'
import { uniq } from 'lodash'

import type { FieldInputProps } from '../types'

export type CheckboxesProps = {
  options: string[]
  withOther?: boolean
} & FieldInputProps<string[]>

const CheckBoxes: FC<CheckboxesProps> = ({
  onChange,
  options,
  value,
  withOther = false,
  disabled,
}) => {
  const [otherValue, setOtherValue] = useState('')
  const [otherChecked, setOtherChecked] = useState(false)
  const otherInputRef = useRef<HTMLInputElement>(null)

  const handleOtherValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange([
        ...value.filter((opt) => opt !== otherValue),
        event.target.value,
      ])
      setOtherValue(event.target.value || '')
    },
    [onChange, otherValue, value],
  )

  const handleCheck = useCallback(
    (option: string, checked: boolean) => {
      if (!checked) {
        return onChange(uniq(value.filter((opt) => opt !== option)))
      }

      return onChange(uniq([...value.filter((opt) => opt !== option), option]))
    },
    [onChange, value],
  )

  const handleOtherCheck = useCallback(
    (event: BaseSyntheticEvent) => {
      setOtherChecked(event.target.checked)
      if (event.target.checked) {
        onChange([...value, otherValue])
        setTimeout(() => otherInputRef.current?.focus(), 200)
      } else {
        onChange(value.filter((opt) => opt !== otherValue))
      }
    },
    [onChange, otherValue, value],
  )

  return (
    <Box>
      {options.map((option) => (
        <Box key={option}>
          <FormControlLabel
            label={option}
            control={
              <Checkbox
                checked={value.includes(option)}
                disabled={disabled}
                onChange={(e, checked) => handleCheck(option, checked)}
              />
            }
          />
        </Box>
      ))}
      {withOther && (
        <Box>
          <FormControlLabel
            control={<Checkbox checked={otherChecked} disabled={disabled} />}
            value={otherChecked}
            label={
              <Stack alignItems="center" direction="row" spacing={1}>
                <span>Other:</span>
                <TextField
                  fullWidth
                  disabled={disabled || !otherChecked}
                  inputRef={otherInputRef}
                  size="small"
                  value={otherValue}
                  onChange={handleOtherValueChange}
                />
              </Stack>
            }
            sx={{
              width: 'calc(100% + 11px)',
              '.MuiFormControlLabel-label': { width: '100%' },
            }}
            onChange={handleOtherCheck}
          />
        </Box>
      )}
    </Box>
  )
}

export default CheckBoxes
