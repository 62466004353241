import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import { Alert, Box, Rating, Stack, Typography } from '@mui/material'

import type { ANY } from '@core/types'

import type { CustomFormValues } from '../CustomFormInput'
import { FormFieldType } from '../CustomFormInput/types'

import MultiChoiceGridAnswer from './field-types/MultiChoiceGridAnswer'

export type FormSubmissionContentProps = {
  content: string
}

const FormSubmissionContent: FC<FormSubmissionContentProps> = ({ content }) => {
  const questions = useMemo(() => {
    try {
      return JSON.parse(content) as CustomFormValues
    } catch (error) {
      return null
    }
  }, [content])

  const answerContent = useCallback((question: ANY) => {
    const questionType = question.type as FormFieldType

    if (questionType === FormFieldType.RATING) {
      return <Rating readOnly value={question.answered} />
    }

    if (questionType === FormFieldType.CHECKBOXES) {
      return (
        <Box component="ul" pl={2}>
          {question.answered?.map((item: string) => (
            <Typography
              key={item}
              color="textPrimary"
              component="li"
              fontWeight={500}
              whiteSpace="break-spaces"
            >
              {item || 'N/A'}
            </Typography>
          ))}
        </Box>
      )
    }

    if (questionType === FormFieldType.MULTI_CHOICE_GRID) {
      return (
        <MultiChoiceGridAnswer
          answered={question.answered}
          columns={question.config.columns}
          rows={question.config.rows}
        />
      )
    }

    return (
      <Typography
        color="textPrimary"
        fontWeight={500}
        whiteSpace="break-spaces"
      >
        {question.answered || 'N/A'}
      </Typography>
    )
  }, [])

  if (!questions) {
    return <Alert severity="error">Could not display this form contents.</Alert>
  }

  return (
    <Stack spacing={2}>
      {questions.map((question) => (
        <Box key={question.question}>
          <Typography color="textSecondary" variant="body2">
            {question.question}
          </Typography>
          {question.description && (
            <Typography color="textSecondary" variant="caption">
              {question.description}
            </Typography>
          )}

          {answerContent(question)}
          {/* <Typography
            color="textPrimary"
            fontWeight={500}
            whiteSpace="break-spaces"
          >
            {JSON.stringify(question.answered) || 'N/A'}
          </Typography> */}
        </Box>
      ))}
    </Stack>
  )
}

export default FormSubmissionContent
