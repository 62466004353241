import type { FC } from 'react'
import { useCallback, useState } from 'react'

import type { MenuProps } from '@mui/material'
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import { keys, pickBy } from 'lodash'

import { fDayOfWeek } from '@core/utils/formatTime'
import { remToPx } from '@core/utils/getFontValue'

import type { DayIndex } from './AvailabilitySchedulesForm'

export type CopyAvailabilitySchedulesMenuProps = {
  open: boolean
  onSubmit?: (
    copiedFromDayIndex: DayIndex,
    copiedToDayIndices: DayIndex[],
  ) => void
  anchorEl: MenuProps['anchorEl'] | null
  onClose?: VoidFunction
  copiedFromDayIndex: DayIndex
}

const defaultState = {
  '0': false,
  '1': false,
  '2': false,
  '3': false,
  '4': false,
  '5': false,
  '6': false,
}

const CopyAvailabilitySchedulesMenu: FC<CopyAvailabilitySchedulesMenuProps> = ({
  open,
  onSubmit,
  anchorEl,
  onClose,
  copiedFromDayIndex,
}) => {
  const [selectedWeekDays, setSelectedWeekDays] = useState(defaultState)

  const handleSelect = useCallback(
    (dayIndex: DayIndex) => {
      setSelectedWeekDays({
        ...selectedWeekDays,
        [dayIndex]: !selectedWeekDays[dayIndex],
      })
    },
    [selectedWeekDays],
  )

  const handleSubmit = useCallback(() => {
    const copiedToDayIndices = keys(pickBy(selectedWeekDays, (value) => value))
    onSubmit?.(copiedFromDayIndex, copiedToDayIndices as DayIndex[])
    setSelectedWeekDays(defaultState)
    onClose?.()
  }, [selectedWeekDays, onSubmit, copiedFromDayIndex, onClose])

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
      <Typography color="textSecondary" sx={{ pl: 1.5 }} variant="body2">
        Copy item to:
      </Typography>
      {Object.keys(selectedWeekDays).map((dayIndex) => (
        <MenuItem
          key={dayIndex}
          dense
          disableRipple
          disabled={dayIndex === copiedFromDayIndex}
          sx={{ py: 0.3, pl: 3, width: 180 }}
          onClick={() => handleSelect(dayIndex as DayIndex)}
        >
          <Stack alignItems="center" direction="row">
            <Checkbox
              disableRipple
              disabled={dayIndex === copiedFromDayIndex}
              edge="start"
              size="small"
              sx={{ ml: -2.5 }}
              tabIndex={-1}
              checked={
                dayIndex === copiedFromDayIndex ||
                selectedWeekDays[dayIndex as DayIndex]
              }
            />
            <Typography variant="body2">{fDayOfWeek(+dayIndex)}</Typography>
          </Stack>
        </MenuItem>
      ))}
      <Button
        variant="contained"
        sx={{
          width: `calc(100% - ${remToPx('1.5')}px)`,
          mx: 1.5,
          mt: 1,
          height: 30,
        }}
        onClick={handleSubmit}
      >
        Apply
      </Button>
    </Menu>
  )
}

export default CopyAvailabilitySchedulesMenu
