import type { SchemaOf } from '@core/lib/yup'
import { object, string } from '@core/lib/yup'

import type { ConfirmFormValues } from './components/components/ConfirmForm'

export const useConFirmFormValidationSchema =
  (): SchemaOf<ConfirmFormValues> => {
    return object({
      reason: string().nullable().required(),
    })
  }
