import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'

import { useYupForm } from '@core/helpers'
import { FormProvider, RHFTextField } from '@core/ui/components/hook-form'

import { useConFirmFormValidationSchema } from '../../BookingRequestView.util'

export type ConfirmFormValues = {
  reason?: string | null
}

export type ConfirmFormProps = {
  children: (props: { fields: JSX.Element }) => ReactNode
  initialValues?: Partial<ConfirmFormValues>
  onSubmit: (values: ConfirmFormValues) => void | Promise<void>
}

const ConfirmForm: FC<ConfirmFormProps> = ({
  children,
  initialValues,
  onSubmit,
}) => {
  const validationSchema = useConFirmFormValidationSchema()

  const { getFieldProps, resolver } = useYupForm(validationSchema)

  const methods = useForm<ConfirmFormValues>({
    resolver,
    defaultValues: initialValues,
  })

  const fields = useMemo(() => {
    return (
      <Stack spacing={2}>
        <RHFTextField
          multiline
          rows={3}
          {...getFieldProps('reason')}
          focused
          label="Notes"
        />
      </Stack>
    )
  }, [getFieldProps])
  return (
    <FormProvider methods={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children({
          fields,
        })}
      </form>
    </FormProvider>
  )
}

export default ConfirmForm
