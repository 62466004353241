import type { ProviderProps, ReactNode } from 'react'

import type { MentorSessionViewFragment } from '@core/graphql'
import type { ArrayElement, Icon } from '@core/types'

export type LearnerMember = ArrayElement<
  MentorSessionViewFragment['courseMembers']
>

export type MemberSessionViewCourse = MentorSessionViewFragment['course']

export type SessionCalendarEvent = ArrayElement<
  MentorSessionViewFragment['calendarEvents']
>

export type SidebarMenuItem = {
  icon: Icon
  name: string
  label: string
}

export enum MentorSessionMenuActionType {
  CREATE_SESSION = 'CREATE_SESSION',
  CANCEL_SESSION = 'CANCEL_SESSION',
  UPDATE_SESSION = 'UPDATE_SESSION',
  ADD_TO_CALENDAR = 'ADD_TO_CALENDAR',
  EXTEND_SESSION = 'EXTEND_SESSION',
}

export enum SessionEventMenuActionType {
  CANCEL_EVENT = 'CANCEL_EVENT',
  RESCHEDULE_EVENT = 'RESCHEDULE_EVENT',
  MARK_AS_FINISHED = 'MARK_AS_FINISHED',
}

export enum MentorSessionSecondaryActionType {
  PENDING_RESCHEDULE = 'PENDING_RESCHEDULE',
}

export enum SessionEventSecondaryActionType {
  JOIN_EVENT = 'JOIN_EVENT',
  WRITE_RECAP = 'WRITE_RECAP',
  VIEW_RECAP = 'VIEW_RECAP',
  DISPLAY_STATUS = 'DISPLAY_STATUS',
  RESCHEDULE_STATUS = 'RESCHEDULE_STATUS',
  RESCHEDULE_EVENT = 'RESCHEDULE_EVENT',
}

export type CombinedMentorSessionActionType =
  | MentorSessionMenuActionType
  | SessionEventMenuActionType
  | MentorSessionSecondaryActionType
  | SessionEventSecondaryActionType

interface MentorSessionMenuActionData {
  disabled?: boolean
  label: string
  icon: ReactNode
  type: MentorSessionMenuActionType | SessionEventMenuActionType
  renderActionDialog: ReactNode
}

export interface MentorSessionSecondaryActionData {
  disabled?: boolean
  type: MentorSessionSecondaryActionType | SessionEventSecondaryActionType
  renderAction: ReactNode
  renderActionDialog?: ReactNode
}

export interface RenderMentorSessionActionProps {
  mentorSession?: MentorSessionViewFragment | null
  sessionEvent?: SessionCalendarEvent
  dialogIsOpen: boolean
  onOpenDialog?: (args: {
    actionType: CombinedMentorSessionActionType
    dialogTargetId?: string
  }) => void
  onCloseDialog?: VoidFunction
}

export type MentorSessionMenuAction =
  | MentorSessionMenuActionData[]
  | null
  | ((
      props: RenderMentorSessionActionProps,
    ) => MentorSessionMenuActionData[] | null)

export type MentorSessionSecondaryAction =
  | MentorSessionSecondaryActionData
  | MentorSessionSecondaryActionData[]
  | null
  | ((
      props: RenderMentorSessionActionProps,
    ) =>
      | MentorSessionSecondaryActionData
      | MentorSessionSecondaryActionData[]
      | null)

export type LearnerMemberSessionsState = {
  dialogIsOpen: boolean
  selectedActionType?: CombinedMentorSessionActionType
  mentorSession?: MentorSessionViewFragment | null
  dialogTargetId?: string
}

export interface LearnerMemberSessionsProviderContext {
  state: LearnerMemberSessionsState
  sessionMenuActions?: MentorSessionMenuAction
  eventMenuActions?: MentorSessionMenuAction
  sessionSecondaryAction?: MentorSessionSecondaryAction
  eventSecondaryAction?: MentorSessionSecondaryAction
  /** Callback to determine if event needs action */
  needsAction?: (props: {
    sessionEvent: SessionCalendarEvent
    mentorSession?: MentorSessionViewFragment | null
  }) => boolean
  openDialog: (args: {
    actionType: CombinedMentorSessionActionType
    dialogTargetId?: string
  }) => void
  closeDialog: VoidFunction
  refetchMentorSession: VoidFunction
}

export type LearnerMemberSessionsContextValue =
  ProviderProps<LearnerMemberSessionsProviderContext>['value']

export interface LearnerMemberSessionProviderProps
  extends Pick<
    LearnerMemberSessionsProviderContext,
    | 'sessionMenuActions'
    | 'eventMenuActions'
    | 'needsAction'
    | 'sessionSecondaryAction'
    | 'eventSecondaryAction'
    | 'refetchMentorSession'
  > {
  children: ReactNode
  mentorSession?: MentorSessionViewFragment | null
}

export enum LearnerMemberSessionsReducerActionType {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
}
