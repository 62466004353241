import type { FieldError } from 'react-hook-form'

import type { MENTEE_RECAP_FORM_CONFIG } from '@core/config/forms/mentee-recap'
import type { MENTOR_RECAP_FORM_CONFIG } from '@core/config/forms/mentor-recap'
import type { ANY, ArrayElement } from '@core/types'

export enum FormFieldType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  LINEAR_SCALE = 'LINEAR_SCALE',
  CHECKBOXES = 'CHECKBOXES',
  MULTI_CHOICE_GRID = 'MULTI_CHOICE_GRID',
  RATING = 'RATING',
}

export type FormConfig =
  | typeof MENTOR_RECAP_FORM_CONFIG
  | typeof MENTEE_RECAP_FORM_CONFIG

export type FormField = ArrayElement<FormConfig['fields']>

export type FieldInputProps<Value = ANY> = {
  value: Value
  onChange: (value: Value) => void
  disabled?: boolean
  error?: FieldError
}
