export const UI = {
  LOGO_FILTER:
    import.meta.env.VITE_DEPLOY_ENV === 'production'
      ? 'brightness(1.15)'
      : 'hue-rotate(180deg)',
  DASHBOARD_SIDEBAR_WIDTH: 230,
  DEFAULT_RIGHT_DRAWER_WIDTH: 350,
  DEAL_VIEW_DRAWER_WIDTH: 350,
  MENTOR_VIEW_DRAWER_WIDTH: 350,
  LEARNER_VIEW_DRAWER_WIDTH: 350,
  USER_VIEW_DRAWER_WIDTH: 350,
  APP_BAR_TOGGLE_BTN_NAV_HEIGHT: 44,
}

export const FAVICON: Record<ImportMetaEnv['VITE_DEPLOY_ENV'], string> = {
  development: 'https://fav.farm/🤦‍♂️',
  sit: 'https://fav.farm/💩',
  production: '/static/img/favicon-32x32.png',
}
