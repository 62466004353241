import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import { Check } from '@phosphor-icons/react'
import type { MakeGenerics } from '@tanstack/react-location'
import { useNavigate, useSearch } from '@tanstack/react-location'
import { addHours } from 'date-fns'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet-async'

import { LINKS } from '@core/config'
import { useCreateEventMutation } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { Page, UrqlError } from '@core/ui/components'

import type { EventFormValues } from '../components/EventForm'
import EventForm from '../components/EventForm'

export type CreateEventProps = {}

type CreateEventGenerics = MakeGenerics<{
  Search: Record<string, string>
}>

const CreateEvent: FC<CreateEventProps> = () => {
  const { t } = useLocales('calendar')
  const [{ fetching, error }, mutateCreateEvent] = useCreateEventMutation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { calendarId, startsAt, endsAt } = useSearch<CreateEventGenerics>()

  const initialValues: Partial<EventFormValues> = useMemo(
    () => ({
      calendarId,
      startsAt: new Date(startsAt ?? Date.now()),
      endsAt: addHours(new Date(endsAt ?? Date.now()), 1),
    }),
    [calendarId, endsAt, startsAt],
  )

  const handleCreateEvent = useCallback(
    async (values: EventFormValues) => {
      const { data } = await mutateCreateEvent({
        input: {
          ...values,
          // Placeholder value
          recurringCount: 26,
        },
      })

      if (data?.createCalendarEvent) {
        enqueueSnackbar(
          t('created_event_success', {
            defaultValue: 'Created event {{name}} successfully',
            name: data.createCalendarEvent.name,
          }),
        )
        navigate({ to: LINKS.CALENDAR_VIEW })
      }
    },
    [enqueueSnackbar, mutateCreateEvent, navigate, t],
  )

  return (
    <>
      <Helmet title={t('create_event_page_title', 'Create an Event')} />
      <EventForm initialValues={initialValues} onSubmit={handleCreateEvent}>
        {({ fields }) => (
          <Page
            maxWidth="sm"
            pageTitle={t('create_event_page_title', 'Create an Event')}
            actions={
              <LoadingButton
                loading={fetching}
                startIcon={<Check />}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            }
            goBack={{
              label: t('calendar'),
              to: '/workplace/calendar',
            }}
          >
            {error && <UrqlError error={error} sx={{ mb: 2 }} />}
            {fields}
          </Page>
        )}
      </EventForm>
    </>
  )
}

export default CreateEvent
