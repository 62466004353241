import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import {
  Divider,
  Grid,
  Stack,
  Typography,
  IconButton,
  Link as LinkNode,
} from '@mui/material'
import { Eye, EyeClosed } from '@phosphor-icons/react'
import { useSnackbar } from 'notistack'

import { useAuth } from '@core/auth'
import {
  Gender,
  Permission,
  UserRole,
  useLearnerProfileQuery,
  useUpdateLearnerProfileMutation,
} from '@core/graphql'
import { useIsDesktop, useLocales, useLearner } from '@core/hooks'
import yup, { ValidationError } from '@core/lib/yup'
import {
  ContentSectionGroup,
  ContentSkeleton,
  DateFormatText,
  EmptyState,
  InlineEditableDatePicker,
  InlineEditableSelect,
  InlineEditableText,
  UrqlError,
} from '@core/ui/components'
import {
  LearnerExpYears,
  LearnerIndustry,
  LearnerWorkingStatus,
  useLearnerFormValidationSchema,
} from 'apps/workplace/modules/learners/components/LearnerForm/LearnerForm.util'
import UpdateAvatar from 'apps/workplace/modules/user-account/user-settings/components/update-avatar/UpdateAvatar'

export type LearnerProfileSettingsProps = {}

const FIELD_SPACING = 2
const ITEM_SM = 6
const ITEM_MD = 4

const NA = <Typography color="textSecondary">N/A</Typography>

const LearnerProfileSettings: FC<LearnerProfileSettingsProps> = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useLocales('learner')

  const [maskVisibility, setMaskVisibility] = useState(true)
  const isDesktop = useIsDesktop()
  const { $user: user, hasPermission } = useAuth()

  const learnerValidationSchema = useLearnerFormValidationSchema()

  const {
    $learner: { id: learnerId },
  } = useLearner()
  const [{ fetching, error, data }] = useLearnerProfileQuery({
    variables: { id: learnerId },
  })
  const [{ fetching: isUpdating }, mutateUpdateLearner] =
    useUpdateLearnerProfileMutation()

  const canUpdate = hasPermission(Permission.UPDATE_LEARNER)
  const learner = useMemo(() => data?.learner, [data?.learner])

  const handleUpdateLearner = useCallback(
    (field: string) => async (value: string | number | Date | null) => {
      let validatedValue

      try {
        validatedValue = yup
          .reach(learnerValidationSchema, field)
          .validateSync(value)
      } catch (validationError: unknown) {
        if (validationError instanceof ValidationError) {
          enqueueSnackbar(validationError.message, {
            variant: 'error',
          })
        }
        return
      }

      const { error: updateError } = await mutateUpdateLearner({
        id: learnerId,
        input: {
          [field]: validatedValue,
        },
      })

      if (updateError) {
        enqueueSnackbar(t(`error.${updateError.message}`), {
          variant: 'error',
        })
      } else {
        enqueueSnackbar(t('updated_learner'), { variant: 'success' })
      }
    },
    [
      enqueueSnackbar,
      learnerId,
      mutateUpdateLearner,
      t,
      learnerValidationSchema,
    ],
  )

  const handleToggleMaskVisibility = () => {
    setMaskVisibility((currentState) => !currentState)
  }

  if (error) {
    return <UrqlError error={error} />
  }

  if (fetching && !data) {
    return <ContentSkeleton />
  }

  if (!learner) {
    return <EmptyState message="You don't have learner profile" />
  }

  return (
    <Stack py={4} spacing={isDesktop ? 4 : 2}>
      <ContentSectionGroup
        direction="vertical"
        headerDivider={false}
        subtitle="First we need to know a little bit about you"
        title="Personal Information"
        actions={
          <IconButton
            aria-label="toggle mask visibility"
            edge="end"
            onClick={handleToggleMaskVisibility}
          >
            {maskVisibility ? <EyeClosed /> : <Eye />}
          </IconButton>
        }
      >
        <Grid container spacing={FIELD_SPACING}>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <UpdateAvatar hasAvatar={!!user.avatarFileId} userId={user.id} />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <Stack spacing={FIELD_SPACING}>
              <InlineEditableText
                editable={false}
                label={t('user:user_name')}
                readView={learner.user?.name || NA}
                value={user.name ?? ''}
                onSubmit={() => {}}
              />
              <InlineEditableSelect
                isUpdating={isUpdating}
                label="Gender"
                size="small"
                value={learner.gender || null}
                options={Object.values(Gender).map((option) => ({
                  label: t(`gender_options.${option}`),
                  value: option,
                }))}
                readView={
                  <Typography>
                    {t(`gender_options.${learner.gender}`)}
                  </Typography>
                }
                onSubmit={handleUpdateLearner('gender')}
              />

              <InlineEditableText
                masked
                allowedRoles={[UserRole.LEARNER]}
                editable={canUpdate}
                isUpdating={isUpdating}
                label={t('nationalIdIssuedPlace')}
                maskVisibility={maskVisibility}
                readView={learner.nationalIdIssuedPlace || NA}
                value={learner.nationalIdIssuedPlace ?? ''}
                onSubmit={handleUpdateLearner('nationalIdIssuedPlace')}
              />
            </Stack>
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <Stack spacing={FIELD_SPACING}>
              <InlineEditableDatePicker
                editable={canUpdate}
                isUpdating={isUpdating}
                label="Date of birth"
                value={learner.dateOfBirth}
                readView={
                  learner.dateOfBirth ? (
                    <DateFormatText
                      date={learner.dateOfBirth}
                      format="MMM dd, yyyy"
                      variant="format"
                    />
                  ) : (
                    NA
                  )
                }
                onSubmit={handleUpdateLearner('dateOfBirth')}
              />
              <InlineEditableText
                masked
                allowedRoles={[UserRole.LEARNER]}
                editable={canUpdate}
                isUpdating={isUpdating}
                label={t('nationalIdNumber')}
                maskVisibility={maskVisibility}
                readView={learner.nationalIdNumber || NA}
                value={learner.nationalIdNumber ?? ''}
                onSubmit={handleUpdateLearner('nationalIdNumber')}
              />

              <InlineEditableDatePicker
                masked
                allowedRoles={[UserRole.LEARNER]}
                editable={canUpdate}
                isUpdating={isUpdating}
                label={t('nationalIdIssuedDate')}
                maskVisibility={maskVisibility}
                value={learner.nationalIdIssuedDate}
                readView={
                  learner.nationalIdIssuedDate ? (
                    <DateFormatText
                      disableTooltip
                      date={learner.nationalIdIssuedDate}
                      format="PP"
                      variant="format"
                    />
                  ) : (
                    NA
                  )
                }
                onSubmit={handleUpdateLearner('nationalIdIssuedDate')}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </ContentSectionGroup>
      <ContentSectionGroup
        divider
        direction="vertical"
        subtitle="Some ways to communicate with you"
        title={t('contact_information')}
      >
        <Grid container spacing={FIELD_SPACING}>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              masked
              allowedRoles={[UserRole.LEARNER]}
              editable={false}
              label={t('user:email')}
              maskVisibility={maskVisibility}
              readView={user.email || NA}
              value={user.email ?? ''}
              onSubmit={() => {}}
            />
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('discord_id')}
              readView={learner.discordId || NA}
              value={learner.discordId ?? ''}
              onSubmit={handleUpdateLearner('discordId')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              masked
              allowedRoles={[UserRole.LEARNER]}
              editable={false}
              label={t('user:phone')}
              maskVisibility={maskVisibility}
              readView={learner.user?.phone || NA}
              value={learner.user?.phone ?? ''}
              onSubmit={() => {}}
            />
            <InlineEditableText
              masked
              allowedRoles={[UserRole.LEARNER]}
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('secondary_email')}
              maskVisibility={maskVisibility}
              readView={learner.secondaryEmail || NA}
              value={learner.secondaryEmail ?? ''}
              onSubmit={handleUpdateLearner('secondaryEmail')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              masked
              allowedRoles={[UserRole.LEARNER]}
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('address')}
              maskVisibility={maskVisibility}
              readView={learner.address || NA}
              value={learner.address ?? ''}
              onSubmit={handleUpdateLearner('address')}
            />
            <InlineEditableText
              masked
              allowedRoles={[UserRole.LEARNER]}
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('secondary_phone_number')}
              maskVisibility={maskVisibility}
              readView={learner.secondaryPhoneNumber || NA}
              value={learner.secondaryPhoneNumber ?? ''}
              onSubmit={handleUpdateLearner('secondaryPhoneNumber')}
            />
          </Grid>
        </Grid>
      </ContentSectionGroup>
      <ContentSectionGroup
        divider
        direction="vertical"
        subtitle="Tell us more about your professional profile"
        title={t('professional_info')}
      >
        <Grid container spacing={FIELD_SPACING}>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableSelect
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('working_status')}
              readView={learner.workingStatus || NA}
              size="small"
              value={learner.workingStatus || ''}
              options={LearnerWorkingStatus.map((option) => ({
                label: option,
                value: option,
              }))}
              onSubmit={handleUpdateLearner('workingStatus')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableSelect
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('industry')}
              readView={learner.industry || NA}
              size="small"
              value={learner.industry || ''}
              options={LearnerIndustry.map((option) => ({
                label: option,
                value: option,
              }))}
              onSubmit={handleUpdateLearner('industry')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableSelect
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('exp_years')}
              readView={learner.expYears || NA}
              size="small"
              value={learner.expYears || ''}
              options={LearnerExpYears.map((option) => ({
                label: option,
                value: option,
              }))}
              onSubmit={handleUpdateLearner('expYears')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('organization')}
              readView={learner.organization || NA}
              value={learner.organization ?? ''}
              onSubmit={handleUpdateLearner('organization')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('jobTitle')}
              readView={learner.jobTitle || NA}
              value={learner.jobTitle ?? ''}
              onSubmit={handleUpdateLearner('jobTitle')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('prior_coding_courses')}
              readView={learner.priorCodingCourses || NA}
              value={learner.priorCodingCourses ?? ''}
              onSubmit={handleUpdateLearner('priorCodingCourses')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('english_level')}
              readView={learner.englishLevel || NA}
              value={learner.englishLevel ?? ''}
              onSubmit={handleUpdateLearner('englishLevel')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('linkedin_url')}
              value={learner.linkedinURL ?? ''}
              readView={
                learner.linkedinURL ? (
                  <LinkNode
                    fontWeight="bold"
                    href={learner.linkedinURL}
                    rel="noopener"
                    target="_blank"
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    {t('open_linkedin')}
                  </LinkNode>
                ) : (
                  NA
                )
              }
              onSubmit={handleUpdateLearner('linkedinURL')}
            />
          </Grid>
          <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
            <InlineEditableText
              editable={canUpdate}
              isUpdating={isUpdating}
              label={t('resume_url')}
              value={learner.resumeURL ?? ''}
              readView={
                learner.resumeURL ? (
                  <LinkNode
                    fontWeight="bold"
                    href={learner.resumeURL}
                    rel="noopener"
                    target="_blank"
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    {t('open resume')}
                  </LinkNode>
                ) : (
                  NA
                )
              }
              onSubmit={handleUpdateLearner('resumeURL')}
            />
          </Grid>
        </Grid>
      </ContentSectionGroup>
    </Stack>
  )
}

export default LearnerProfileSettings
