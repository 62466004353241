import { Navigate } from '@tanstack/react-location'

import Feedback from 'apps/feedback/Feedback'
import ResetPassword from 'apps/workplace/modules/auth/reset-password'
import InitializePassword from 'apps/workplace/modules/auth/set-password'
import SignIn from 'apps/workplace/modules/auth/sign-in'
import SignOut from 'apps/workplace/modules/auth/sign-out/SignOut'
import Sso from 'apps/workplace/modules/auth/sso'
import UniversalGoTo from 'containers/common/go-to'
import LWGoTo from 'containers/common/go-to/LWGoTo'
import MeetingGoTo from 'containers/common/go-to/MeetingGoTo'
import MeetingContainer from 'containers/MeetingContainer'
import RootContainer from 'containers/RootContainer'
import VersionWatcher from 'containers/VersionWatcher'

import LearnerRoute from './learner/LearnerRoute'
import MentorRoute from './mentor/MentorRoute'
import type { GenericsRoutes } from './routes.type'
import WorkplaceRoute from './workplace/WorkplaceRoute'

const routes: GenericsRoutes = [
  {
    path: '/',
    element: <RootContainer />,
  },
  {
    path: 'auth',
    children: [
      {
        path: '/',
        element: <Navigate replace to="sign-in" />,
      },
      {
        path: 'sign-in',
        element: <SignIn />,
        meta: { helmet: { title: 'Sign in' } },
      },
      {
        path: 'reset-password',
        element: <Navigate replace search />,
      },
      {
        path: 'reset-password/:step',
        element: <ResetPassword />,
      },
      {
        path: 'activate-account/:step',
        element: <InitializePassword />,
      },
      {
        path: 'sso',
        element: <Sso />,
      },
      {
        path: 'sign-out',
        element: <SignOut />,
      },
    ],
  },
  {
    path: 'meeting/:meetingId',
    element: <MeetingContainer />,
  },
  {
    path: 'feedback',
    element: <Feedback />,
  },
  {
    path: 'go/learning-platform',
    element: <LWGoTo />,
  },
  {
    path: 'go/meeting/:eventId',
    element: <MeetingGoTo />,
  },
  {
    path: 'go/:linkPath',
    element: <UniversalGoTo />,
  },
  {
    path: 'v2/*',
    element: <VersionWatcher />,
  },

  WorkplaceRoute,
  MentorRoute,
  LearnerRoute,
]

export default routes
