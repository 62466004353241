import { addMinutes } from 'date-fns'

const DEFAULT_TIMEZONE_OFFSET = -420 // GMT +7
export const convertToDefaultTimeZone = (date: Date | number) => {
  // get local timezone offset and difference with default timezone
  const localTimeZoneOffset = new Date(date).getTimezoneOffset()
  const diffTimeZoneOffset = Math.abs(
    localTimeZoneOffset - DEFAULT_TIMEZONE_OFFSET,
  )
  if (diffTimeZoneOffset === 0) {
    return new Date(date)
  }
  // adjust date to default timezone by adding the difference of timezone offset
  const newDate =
    DEFAULT_TIMEZONE_OFFSET < localTimeZoneOffset
      ? addMinutes(date, diffTimeZoneOffset)
      : addMinutes(date, -diffTimeZoneOffset)

  return newDate
}

export const revertToLocalTimeZone = (date: Date | number) => {
  // get local timezone offset and difference with default timezone
  const localTimeZoneOffset = new Date(date).getTimezoneOffset()
  const diffTimeZoneOffset = Math.abs(
    localTimeZoneOffset - DEFAULT_TIMEZONE_OFFSET,
  )
  if (diffTimeZoneOffset === 0) {
    return new Date(date)
  }
  // adjust date to default timezone by adding the difference of timezone offset
  const newDate =
    DEFAULT_TIMEZONE_OFFSET < localTimeZoneOffset
      ? addMinutes(date, -diffTimeZoneOffset)
      : addMinutes(date, diffTimeZoneOffset)
  return newDate
}
