/** API path for video sdk recording */
export const VIDEOSDK_RECORDING_API_PATH = '/api/meeting/recording/video-sdk/'

/** Number of milliseconds per second */
export const MS_PER_SECOND = 1_000

/** Video player's width */
export const PLAYER_WIDTH = '70vw'

/** Media controller's fade out delay */
export const CONTROLLER_FADE_OUT_DELAY_SECONDS = 5

/** Default player volume. Range: 0-1 */
export const DEFAULT_VOLUME = 0.3

/** Seconds to move player forward/backward */
export const FORWARD_SECONDS = 10

/** Icon size on Media Controller */
export const ICON_BUTTON_SIZE = 26

/** Size ratio between icon and its inner text.
 * Currently used in Forward/Backward buttons
 */
export const ICON_TO_INNER_TEXT_RATIO = 1 / 3
