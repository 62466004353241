import type { FC, ReactNode } from 'react'

import { Fade, IconButton, Stack } from '@mui/material'
import { X } from '@phosphor-icons/react'

import { pxToRem } from '@core/utils/getFontValue'

import { useVideoPlayerStore } from '../../VideoPlayerStore'

export type TopContainerProps = {
  title?: string | ReactNode
  onClose?: VoidFunction
}

const TopContainer: FC<TopContainerProps> = ({ title, onClose }) => {
  const { playerState } = useVideoPlayerStore()

  const { showController } = playerState

  return (
    <Fade in={showController}>
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        px={2}
        py={2}
        sx={{
          background:
            'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)',
        }}
      >
        {title}
        <Stack alignItems="center" flexDirection="row" spacing={1}>
          <IconButton
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={onClose}
          >
            <X color="white" size={pxToRem(26)} />
          </IconButton>
        </Stack>
      </Stack>
    </Fade>
  )
}

export default TopContainer
