import { type FC } from 'react'

import { useAuth } from '@core/auth'
import MentorSessionSchedulesUpdate from '@core/ui/components/common/MentorSessionSchedulesUpdate'

export type UserAvailabilitySettingsProps = {}

const UserAvailabilitySettings: FC<UserAvailabilitySettingsProps> = () => {
  const { user } = useAuth()

  if (!user) {
    return null
  }

  return <MentorSessionSchedulesUpdate asPage={false} targetId={user.id} />
}

export default UserAvailabilitySettings
