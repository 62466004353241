import type { FC } from 'react'
import { useCallback, useState } from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import type { CombinedError } from 'urql'

import { useEnrollToLwMutation } from '@core/graphql'
import { useLocales } from '@core/hooks'
import CircularProgressWithLabel from '@core/ui/components/widgets/CircularProgressWithLabel'

import { usePendingListContext } from '../PendingListContext'

import EnrollMemberTable from './EnrollMemberTable'

export type EnrollMembersDialogProps = {
  open: boolean
  onClose?: VoidFunction
}

export type EnrollMemberError = CombinedError | undefined | 'init'

const EnrollMembersDialog: FC<EnrollMembersDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useLocales()
  const {
    pendingMemberConnection: { data },
  } = usePendingListContext()
  const [{ fetching }, enrollMemberMutation] = useEnrollToLwMutation()
  const [progress, setProgress] = useState(0)
  const courseMembers = data?.courseMemberConnection.nodes ?? []
  const numMembers = courseMembers.length
  const [errors, setErrors] = useState<EnrollMemberError[]>(() =>
    new Array(numMembers).fill('init'),
  )

  const handleCloseDialog = useCallback(() => {
    if (fetching) {
      return
    }
    setProgress(0)
    setErrors(new Array(numMembers).fill('init'))
    onClose?.()
  }, [fetching, onClose, numMembers])

  const handleSubmit = async () => {
    setProgress(0)
    setErrors(new Array(numMembers).fill('init'))
    setTimeout(async () => {
      const promises = courseMembers.map((courseMember, index) => {
        return enrollMemberMutation({ courseMemberId: courseMember.id }).then(
          (result) => {
            setProgress((prev) => prev + 1)
            setErrors((prevErrors) => [
              ...prevErrors.slice(0, index),
              result.error,
              ...prevErrors.slice(index + 1),
            ])
            return result
          },
        )
      })

      await Promise.allSettled(promises)
    }, 200)
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: 640,
          maxHeight: 500,
          maxWidth: '90vw',
          position: 'relative',
        },
      }}
      onClose={handleCloseDialog}
    >
      {numMembers === 0 ? (
        <Typography variant="subtitle1">
          {t('courseMember:no_pending_member_to_add')}
        </Typography>
      ) : (
        <>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle1">
              Enrolling {numMembers} members to LW
            </Typography>
            <CircularProgressWithLabel
              color="success"
              value={(progress * 100) / numMembers}
            />
          </DialogTitle>

          <DialogContent sx={{ mt: 2 }}>
            <Stack direction="column" spacing={2}>
              <EnrollMemberTable
                courseMembers={courseMembers}
                errors={errors}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button color="inherit" onClick={handleCloseDialog}>
              {t('cancel')}
            </Button>
            <Button
              disabled={fetching}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              {t('confirm')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default EnrollMembersDialog
