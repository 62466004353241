import type { FC } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, Grid, Stack, Typography } from '@mui/material'
import fuzzysort from 'fuzzysort'
import { some, take } from 'lodash'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionLearnerMemberFragment } from '@core/graphql'
import { useLearnersAvailableForBookingRequestQuery } from '@core/graphql'
import { ListItemSkeleton, SearchBox, UrqlError } from '@core/ui/components'
import { removeVietnameseTones } from '@core/utils/normalizeSearchText'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import {
  LEARNER_LIST_HEIGHT,
  LEARNER_LIST_LIMIT,
} from '../../BookMentorSession.util'

import SelectiveLearnerItem from './SelectiveLearnerItem'

export type LearnerWithNoSessionsListProps = {
  courseId: string
  onChange: (courseMember: BookMentorSessionLearnerMemberFragment) => void
  learnerMemberId?: string
}

const LearnerWithNoSessionsList: FC<LearnerWithNoSessionsListProps> = ({
  courseId,
  onChange,
  learnerMemberId,
}) => {
  const [{ data, fetching, error }] =
    useLearnersAvailableForBookingRequestQuery({
      variables: { courseId },
      requestPolicy: 'network-only',
    })

  const { watch, setValue } = useFormContext<BookMentorSessionValues>()
  const [searchText, setSearchText] = useState('')

  const value = watch('selectedLearnerCourseMember')

  const handleChange = useCallback(
    (courseMember: BookMentorSessionLearnerMemberFragment) => {
      onChange(courseMember)
    },
    [onChange],
  )

  const allMembers = useMemo(
    () =>
      (data?.learnerMembersAvailableForBookingRequest ?? []).filter(
        (cm) =>
          !!cm.learner && cm.learner.mentorSessionAvailabilitySchedules.length,
      ),
    [data?.learnerMembersAvailableForBookingRequest],
  )

  const courseMembers = useMemo(() => {
    if (!searchText) {
      return allMembers
    }

    const decoratedMembers = allMembers.map((member) => ({
      ...member,
      search: [removeVietnameseTones(member.learner?.user?.name || '')].join(
        ' ',
      ),
    }))

    const filtered = fuzzysort.go(
      removeVietnameseTones(searchText),
      decoratedMembers,
      {
        keys: [
          'learner.user.name',
          'learner.user.email',
          'learner.discordId',
          'learner.learnerCode',
          'search',
        ],
        all: true,
      },
    )

    return filtered.map((item) => item.obj)
  }, [allMembers, searchText])

  const content = useMemo(() => {
    if (error) {
      return <UrqlError error={error} />
    }

    if (fetching) {
      return <ListItemSkeleton />
    }

    return courseMembers.length ? (
      <Grid container spacing={1}>
        {take(courseMembers, LEARNER_LIST_LIMIT).map((courseMember) => {
          return (
            <Grid
              key={courseMember.id}
              item
              lg={6}
              md={12}
              sm={6}
              xl={3}
              xs={6}
            >
              <SelectiveLearnerItem
                courseMember={courseMember}
                selected={
                  courseMember.id === value?.id ||
                  courseMember.id === learnerMemberId
                }
                onSelect={handleChange}
              />
            </Grid>
          )
        })}
      </Grid>
    ) : (
      <Typography p={1}>No learners found.</Typography>
    )
  }, [courseMembers, error, fetching, handleChange, learnerMemberId, value?.id])

  useEffect(() => {
    // Set first option if a default value is provided
    if (
      some(courseMembers, (courseMember) => courseMember.id === learnerMemberId)
    ) {
      const defaultLearnerMember = courseMembers.find(
        (cm) => cm.id === learnerMemberId,
      )

      if (defaultLearnerMember) {
        setValue('selectedLearnerCourseMember', defaultLearnerMember)
      }
    }
  }, [courseMembers, learnerMemberId, setValue])

  return (
    <Stack spacing={2}>
      <SearchBox
        placeholder={`Search in ${allMembers.length} learners...`}
        onSubmit={setSearchText}
      />
      <Box
        sx={{
          maxHeight: `min(${LEARNER_LIST_HEIGHT}px, 70vh)`,
          overflow: 'auto',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          borderRadius: 1,
          padding: 1,
        }}
      >
        {content}
      </Box>
    </Stack>
  )
}

export default LearnerWithNoSessionsList
