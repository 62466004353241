import { useMyLearnerQuery } from '@core/graphql'

const useLearnerHook = () => {
  const [{ fetching, error, data }, refetch] = useMyLearnerQuery({
    requestPolicy: 'cache-first',
  })

  const learner = data?.myLearner ?? null

  return { learner, $learner: learner!, fetching, error, refetch }
}

export default useLearnerHook
