import type { FC } from 'react'
import { useEffect } from 'react'

import { Card, Container } from '@mui/material'
import { useNavigate } from '@tanstack/react-location'

import { LEARNER_ACTIVE_MENTOR_SESSION_ID, LEARNER_LINKS } from '@core/config'
import { useLatestLearnerMentorSessionsQuery } from '@core/graphql'
import { useLearner, useLocalStorage } from '@core/hooks'
import { PageSkeleton, SuspenseOutlet } from '@core/ui/components'
import { buildPath } from '@core/utils/buildPath'

import WelcomeLearner from '../LearnerAlert'

export type LearnerDashboardProps = {}

const LearnerDashboard: FC<LearnerDashboardProps> = () => {
  const { learner } = useLearner()

  const [mentorSessionId, setMentorSessionId] = useLocalStorage(
    `${LEARNER_ACTIVE_MENTOR_SESSION_ID}/${learner?.id}`,
    '',
  )

  const navigate = useNavigate()

  const [{ data, fetching }] = useLatestLearnerMentorSessionsQuery({
    variables: {
      learnerId: learner?.id || '',
    },
  })

  const mentorSessions = data?.latestLearnerMentorSessions

  useEffect(() => {
    if (mentorSessions?.length && mentorSessions.length > 0) {
      // check if local storage mentorSessionId is in the latest mentorSessions
      const isMentorSessionIdInLatestMentorSessions = mentorSessions.some(
        (mentorSession) => mentorSession.id === mentorSessionId,
      )
      // if not, set the first mentorSessionId in the latest mentorSessions to local storage
      if (!isMentorSessionIdInLatestMentorSessions) {
        setMentorSessionId(mentorSessions[0].id)
      }
      navigate({
        to: buildPath(LEARNER_LINKS.MENTOR_SESSION_VIEW, {
          mentorSessionId: isMentorSessionIdInLatestMentorSessions
            ? mentorSessionId
            : mentorSessions[0].id,
        }),
        replace: true,
      })
    }
  }, [mentorSessionId, mentorSessions, navigate, setMentorSessionId])

  if (fetching) {
    return <PageSkeleton />
  }

  if (!learner || mentorSessions?.length) {
    return null
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          width: 500,
          boxShadow: 'none',
          borderWidth: 2,
          padding: 2,
        }}
      >
        <WelcomeLearner learner={learner} />
      </Card>
      <SuspenseOutlet />
    </Container>
  )
}

export default LearnerDashboard
