import type { FC } from 'react'

import { Card, Stack, styled, Typography } from '@mui/material'
import { Link } from 'react-scroll'

import { useApp } from '@core/hooks'
import {
  ActivityLogIcon,
  LearnerIcon,
  MentorIcon,
  MentorSessionIcon,
} from '@core/icons'

import type { SidebarMenuItem } from '../LearnerMemberSessions.type'

export type SessionSidebarProps = {}

const MENU_ITEMS: SidebarMenuItem[] = [
  {
    icon: MentorSessionIcon,
    name: 'session-events',
    label: 'Mentor Sessions',
  },
  {
    icon: MentorIcon,
    name: 'mentor-info',
    label: 'Mentor Info',
  },
  {
    icon: LearnerIcon,
    name: 'learner-info',
    label: 'Learner Info',
  },
  {
    icon: ActivityLogIcon,
    name: 'activities',
    label: 'Activity Log',
  },
]

const SessionSidebar: FC<SessionSidebarProps> = () => {
  const { isWorkplace, isLearner, isMentor } = useApp()

  return (
    <Card sx={{ py: 2 }}>
      <Stack spacing={0}>
        {MENU_ITEMS.map((item) => {
          if (isLearner && item.name === 'learner-info') {
            return null
          }

          if (isMentor && item.name === 'mentor-info') {
            return null
          }

          if (
            isWorkplace &&
            (item.name === 'learner-info' || item.name === 'mentor-info')
          ) {
            return null
          }

          return (
            <MenuItem
              key={item.name}
              saveHashHistory
              smooth
              spy
              activeClass="active"
              className={item.name}
              duration={500}
              hashSpy={false}
              offset={-80}
              to={item.name}
            >
              <item.icon size={20} />
              <Typography variant="subtitle2">{item.label}</Typography>
            </MenuItem>
          )
        })}
      </Stack>
    </Card>
  )
}

const MenuItem = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  height: 48,
  color: theme.palette.text.secondary,
  cursor: 'pointer',
  borderRight: 'solid 2px transparent',
  transition: theme.transitions.create(['color', 'border-color']),

  '&:hover': {
    color: theme.palette.text.primary,
    textDecoration: 'unset',
  },
  '&.active': {
    color: theme.palette.primary.main,
    borderRightColor: theme.palette.primary.main,
  },
}))

export default SessionSidebar
