import type {
  BookMentorSessionLearnerMemberFragment,
  BookMentorSessionMentorMemberFragment,
} from '@core/graphql'

export const LEARNER_LIST_HEIGHT = 640
export const LEARNER_LIST_LIMIT = 200
export const DRAWER_WIDTH = 380

export type BookMentorSessionValues = {
  course: {
    id: string
    title: string
  } | null
  selectedLearnerCourseMember: BookMentorSessionLearnerMemberFragment | null
  selectedMentorCourseMember: BookMentorSessionMentorMemberFragment | null

  time: {
    startsAt: Date
    duration: number
    endsAfterMonths: number
  }

  options: {
    autoSelect?: boolean
    stayForNextSession?: boolean
  }

  previewLearnerCourseMember: BookMentorSessionLearnerMemberFragment | null
  previewMentorCourseMember: BookMentorSessionMentorMemberFragment | null
}

export const DAYS_OF_WEEK = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' },
]
