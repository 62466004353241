import type { FC } from 'react'

import { Breadcrumbs, Grid, Typography, styled } from '@mui/material'

import type { LearnerProfileCourseMemberFragment } from '@core/graphql'
import { LearningVelocity } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { EmptyState } from '@core/ui/components'

import InfoItem from '../../InfoItem'

export type LearnerProgressProps = {
  progressData?: LearnerProfileCourseMemberFragment['latestProgressReport']
  configs: {
    ITEM_MD: number
    ITEM_SM: number
    FIELD_SPACING: number
  }
  showOverallProgress?: boolean
}
const SEC_IN_HOURS = 60 * 60
const SEC_IN_MINUTES = 60

const LearnerProgress: FC<LearnerProgressProps> = ({
  progressData,
  configs,
  showOverallProgress = false,
}) => {
  const { translate: t } = useLocales('course_member')

  const { ITEM_MD, ITEM_SM, FIELD_SPACING } = configs

  if (!progressData) {
    return <EmptyState message={t('empty_progress')} />
  }

  const {
    overallProgress,
    averageScore,
    studyTimeDuringPeriod,
    learningVelocity,
    weeksOfftrack,
    currentUnitPath,
  } = progressData

  return (
    <Grid container spacing={FIELD_SPACING}>
      {showOverallProgress && (
        <InfoItem
          configs={{ ITEM_MD, ITEM_SM }}
          content={`${Math.round(overallProgress * 1000) / 10}%`}
          label="Overall Progress"
        />
      )}
      <InfoItem
        configs={{ ITEM_MD, ITEM_SM }}
        content={averageScore && Math.round(averageScore * 100) / 100}
        label="Average score"
      />
      <InfoItem
        configs={{ ITEM_MD, ITEM_SM }}
        label="Study time"
        content={
          studyTimeDuringPeriod / SEC_IN_HOURS > 1
            ? `${Math.round(studyTimeDuringPeriod / SEC_IN_HOURS)} hour(s)`
            : `${Math.round(studyTimeDuringPeriod / SEC_IN_MINUTES)} minute(s)`
        }
      />
      <InfoItem
        configs={{ ITEM_MD, ITEM_SM }}
        label="Learning velocity"
        content={
          learningVelocity && (
            <Typography
              color={
                learningVelocity === LearningVelocity.ON_TRACK
                  ? 'success.main'
                  : 'warning.main'
              }
            >
              {t(learningVelocity)}
              {weeksOfftrack &&
                ` by ${t('week_with_count', {
                  count: weeksOfftrack,
                })}`}
            </Typography>
          )
        }
      />
      <InfoItem
        configs={{ ITEM_MD: ITEM_MD * 2, ITEM_SM: ITEM_SM * 2 }}
        label="Current unit"
        content={
          currentUnitPath && (
            <Breadcrumbs separator="›">
              <BreadcrumbItem
                fontWeight={500}
                title={currentUnitPath?.section.title}
              >
                {currentUnitPath?.section.title}
              </BreadcrumbItem>
              {currentUnitPath?.path.map((unit) => (
                <BreadcrumbItem key={unit.id}>{unit.title}</BreadcrumbItem>
              ))}
            </Breadcrumbs>
          )
        }
      />
    </Grid>
  )
}

const BreadcrumbItem = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
}))

export default LearnerProgress
