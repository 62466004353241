import type { Theme } from '@mui/material/styles'

import { LinkBehavior } from '@core/ui/components'

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          boxShadow: 'none !important',
          fontWeight: 600,
        },
        sizeLarge: {
          height: 48,
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
          '&:active': {
            backgroundColor: theme.palette.grey[600],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
          '&:active': {
            backgroundColor: theme.palette.primary.darker,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          '&:active': {
            backgroundColor: theme.palette.secondary.darker,
          },
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
          '&:active': {
            backgroundColor: theme.palette.info.darker,
          },
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
          '&:active': {
            backgroundColor: theme.palette.success.darker,
          },
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
          '&:active': {
            backgroundColor: theme.palette.warning.darker,
          },
        },
        containedError: {
          boxShadow: theme.customShadows.error,
          '&:active': {
            backgroundColor: theme.palette.error.darker,
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:active': {
            backgroundColor: theme.palette.action.selected,
          },
        },
        outlinedPrimary: {
          '&:active': {
            backgroundColor: theme.palette.primary.lighter,
          },
        },
        outlinedSecondary: {
          '&:active': {
            backgroundColor: theme.palette.secondary.lighter,
          },
        },
        // text
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&:active': {
            backgroundColor: theme.palette.action.selected,
          },
        },
      },
    },
  }
}
