import type { FC } from 'react'
import { useMemo } from 'react'

import type { SxProps, Theme } from '@mui/material'
import { Avatar, Box, Skeleton } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import { Robot } from '@phosphor-icons/react'
import ReactAvatar from 'react-avatar'

import { UserRole, useUserAvatarQuery } from '@core/graphql'

import Image from '../Image'

export type UserAvatarProps = {
  userId?: string | null
  size?: number
  sx?: SxProps<Theme>
}

const UserAvatar: FC<UserAvatarProps> = ({ userId, sx, size = 40 }) => {
  const [{ fetching, data }] = useUserAvatarQuery({
    variables: { id: userId || 'unknown' },
    requestPolicy: 'network-only',
    pause: !userId,
  })

  const user = useMemo(() => data?.user, [data?.user])

  const isBotOnly = useMemo(
    () => user?.roles.length === 1 && user.roles[0] === UserRole.OAUTH_APP,
    [user?.roles],
  )

  const avatar = useMemo(() => {
    if (isBotOnly) {
      return (
        <Avatar
          sx={{
            height: size,
            width: size,
            bgcolor: deepPurple[50],
            transition: (theme) =>
              theme.transitions.create(['width', 'height']),
          }}
        >
          <Robot
            color={deepPurple[500]}
            height="60%"
            weight="fill"
            width="60%"
          />
        </Avatar>
      )
    }

    if (user?.avatar?.signedUrl) {
      return (
        <Avatar
          sx={{
            height: size,
            width: size,
            bgcolor: 'transparent !important',
          }}
        >
          <Image
            blurhash={user.avatar.blurhash}
            src={user.avatar.signedUrl}
            wrapperSx={{ display: 'block !important' }}
            sx={{
              height: size,
              width: size,
              borderRadius: size,
            }}
          />
        </Avatar>
      )
    }

    return (
      <ReactAvatar
        round
        alt={user?.name}
        email={user?.email}
        maxInitials={4}
        name={user?.name.replace(/[^a-zA-Z\s]/g, '') ?? 'Coder School'}
        size={`${size}px`}
        title={user?.name}
      />
    )
  }, [
    isBotOnly,
    size,
    user?.avatar?.blurhash,
    user?.avatar?.signedUrl,
    user?.email,
    user?.name,
  ])

  if (fetching) {
    return <Skeleton height={size} variant="circular" width={size} />
  }
  return <Box sx={sx}>{avatar}</Box>
}

export default UserAvatar
