import type { FC, ReactNode } from 'react'

import type { StackProps } from '@mui/material'
import { Box, Stack } from '@mui/material'

export type ContentIconSectionProps = {
  icon: ReactNode
  children: ReactNode
  /** @default "center" */
  alignItems?: StackProps['alignItems']
  /** @default 1 */
  spacing?: StackProps['spacing']
}

const ContentIconSection: FC<ContentIconSectionProps> = ({
  icon,
  children,
  alignItems = 'center',
  spacing = 1,
}) => {
  return (
    <Stack alignItems={alignItems} direction="row" spacing={spacing}>
      {icon}
      <Box>{children}</Box>
    </Stack>
  )
}

export default ContentIconSection
