import type { FC, ReactNode } from 'react'

import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ArrowElbowDownRight, Calendar, Clock } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { rrulestr } from 'rrule'

import type { ANY, Icon } from '@core/types'

export type BatchRescheduleActivityProps = {
  data: ANY
  size?: 'small' | 'medium'
}

const BatchRescheduleActivity: FC<BatchRescheduleActivityProps> = ({
  data,
  size = 'medium',
}) => {
  const theme = useTheme()

  const isSmall = size === 'small'

  const {
    prevRecurrence,
    newRecurrence,
    prevStartsAt,
    prevEndsAt,
    newStartsAt,
    newEndsAt,
  } = data

  return (
    <Stack maxWidth={isSmall ? 300 : 400} mb={1} width="100%">
      <RecurrenceBox
        endsAt={prevEndsAt}
        isSmall={isSmall}
        label="Current sessions"
        recurrence={prevRecurrence}
        startsAt={prevStartsAt}
      />

      <Stack direction="row" spacing={isSmall ? 0.5 : 1} width="100%">
        <ArrowElbowDownRight
          color={theme.palette.text.secondary}
          fontSize={isSmall ? '1.6rem' : '1.8rem'}
        />
        <RecurrenceBox
          endsAt={newEndsAt}
          isSmall={isSmall}
          label="Rescheduled to"
          recurrence={newRecurrence}
          startsAt={newStartsAt}
        />
      </Stack>
    </Stack>
  )
}

const RecurrenceBox: FC<{
  label: string
  recurrence: string
  startsAt: Date | string
  endsAt: Date
  isSmall: boolean
}> = ({ label, recurrence, startsAt, endsAt, isSmall }) => {
  const recurrenceOptions = rrulestr(recurrence).options

  if (!recurrence) {
    return null
  }

  return (
    <Stack
      spacing={0.5}
      sx={{
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 1,
        width: '100%',
        backgroundColor: (theme) => theme.palette.background.neutral,
      }}
    >
      <Typography color="textSecondary" fontSize={isSmall ? '0.8rem' : '1rem'}>
        {label}
      </Typography>
      <InfoItem icon={Clock} isSmall={isSmall}>
        Every {format(recurrenceOptions.dtstart, 'EEE')},{' '}
        {format(new Date(startsAt), 'HH:mm')}
        {' - '}
        {format(new Date(endsAt), 'HH:mm a')}
      </InfoItem>
      <InfoItem icon={Calendar} isSmall={isSmall}>
        {format(new Date(startsAt), 'MMM dd, yyyy')}
        {' - '}
        {format(
          recurrenceOptions.until || recurrenceOptions.dtstart,
          'MMM dd, yyyy',
        )}
      </InfoItem>
    </Stack>
  )
}

const InfoItem: FC<{ icon: Icon; children: ReactNode; isSmall: boolean }> = ({
  icon: IconComponent,
  children,
  isSmall,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconComponent size={isSmall ? '0.8rem' : '1rem'} />
      <Typography fontSize={isSmall ? '0.8rem' : '1rem'} ml={1}>
        {children}
      </Typography>
    </Box>
  )
}

export default BatchRescheduleActivity
