import type { FC } from 'react'

import { Card, Grid, Stack, Typography } from '@mui/material'

import { useLocales } from '@core/hooks'
import type { ANY } from '@core/types'
import {
  ContentSectionGroup,
  InlineEditableText,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'

const FIELD_SPACING = 2
const ITEM_SM = 6
const ITEM_MD = 4

const NA = <Typography color="textSecondary">N/A</Typography>

export type MentorMemberInfoProps = {
  mentorMember: ANY
}

const MentorMemberInfo: FC<MentorMemberInfoProps> = ({ mentorMember }) => {
  const { t } = useLocales('mentor')

  return (
    <Card sx={{ overflow: 'visible', pr: 1.5, py: 3 }}>
      <Stack px={3} spacing={3}>
        <ContentSectionGroup
          headerDivider
          direction="vertical"
          subtitle="Some ways to communicate with your mentor"
          title="About your mentor"
        >
          <Grid container spacing={FIELD_SPACING}>
            <Grid item md={8} sm={8} xs={12}>
              <Stack alignItems="center" direction="row" spacing={2}>
                <UserAvatar size={45} userId={mentorMember.userId} />
                <UserDisplayName
                  color="currentcolor"
                  userId={mentorMember.userId}
                  whiteSpace="break-spaces"
                  sx={{
                    overflow: 'auto',
                  }}
                />
              </Stack>
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('user:email')}
                readView={mentorMember.user?.email || NA}
                value={mentorMember.user?.email || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('discord_id')}
                readView={mentorMember.mentor?.discordId || NA}
                value={mentorMember.mentor?.discordId || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('job_title')}
                readView={mentorMember.mentor?.jobTitle || NA}
                value={mentorMember.mentor?.jobTitle || ''}
                onSubmit={() => {}}
              />
            </Grid>
          </Grid>
        </ContentSectionGroup>
      </Stack>
    </Card>
  )
}

export default MentorMemberInfo
