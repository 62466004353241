import type { FC } from 'react'
import { useCallback } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useSnackbar } from 'notistack'

import { useCancelMentorSessionEventRescheduleRequestMutation } from '@core/graphql'
import { UrqlError } from '@core/ui/components'

export type CancelMentorSessionEventRescheduleRequestDialogProps = {
  open: boolean
  onClose: VoidFunction
  userRequestId: string
}
const CancelMentorSessionEventRescheduleRequestDialog: FC<
  CancelMentorSessionEventRescheduleRequestDialogProps
> = ({ onClose, open, userRequestId }) => {
  const [{ fetching, error }, mutationCancelRescheduleRequest] =
    useCancelMentorSessionEventRescheduleRequestMutation()

  const { enqueueSnackbar } = useSnackbar()

  const handleCancel = useCallback(async () => {
    const { data } = await mutationCancelRescheduleRequest({
      userRequestId,
      input: {},
    })
    if (data) {
      enqueueSnackbar('The request to reschedule has been canceled', {
        variant: 'success',
      })
      onClose()
    }
  }, [enqueueSnackbar, mutationCancelRescheduleRequest, onClose, userRequestId])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel rescheduling?</DialogTitle>
      <DialogContent>
        Are you sure you want to cancel rescheduling? <br />
        {error && <UrqlError error={error} sx={{ mt: 2 }} />}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" variant="outlined" onClick={onClose}>
          Go back
        </Button>
        <LoadingButton
          color="primary"
          loading={fetching}
          variant="contained"
          onClick={handleCancel}
        >
          Cancel rescheduling
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default CancelMentorSessionEventRescheduleRequestDialog
