import type { FC, SyntheticEvent } from 'react'
import { useMemo, useState } from 'react'

import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'

import type {
  MentorSessionEventViewQuery,
  MentorSessionViewFragment,
} from '@core/graphql'
import {
  MentorSessionRecapType,
  useLearnerMentorSessionRecapsQuery,
} from '@core/graphql'
import { useMentor } from '@core/hooks'
import type { ArrayElement } from '@core/types'
import {
  DeterminateCircularProgress,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'

import MentorSessionRecapList from '../MentorSessionRecapList'
import RecapActions from '../RecapActions'

import LearnerInfo from './components/LearnerInfo'
import LearnerProgress from './components/LearnerProgress'

export type LearnerMember = ArrayElement<
  MentorSessionViewFragment['courseMembers']
>

export type LearnerItemProps = {
  learnerMember: LearnerMember
  mentorSessionEvent?: MentorSessionEventViewQuery['mentorSessionEvent']
}

type TabValue = 'info' | 'progress' | 'recaps'

const LearnerItem: FC<LearnerItemProps> = ({
  learnerMember,
  mentorSessionEvent,
}) => {
  const { mentor } = useMentor()

  const [{ data: recapsData }] = useLearnerMentorSessionRecapsQuery({
    variables: {
      learnerId: learnerMember.learner?.id ?? '',
      mentorSessionIds: [mentorSessionEvent?.mentorSessionId || ''],
    },
  })

  const [tabValue, setTabValue] = useState<TabValue>('progress')

  const handleChangeTab = (event: SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue)
  }

  const tabContent = useMemo(() => {
    switch (tabValue) {
      case 'info':
        return <LearnerInfo learnerMember={learnerMember} />
      case 'progress':
        return (
          <LearnerProgress
            configs={{ ITEM_SM: 4, ITEM_MD: 4, FIELD_SPACING: 2 }}
            progressData={learnerMember.latestProgressReport}
          />
        )
      case 'recaps':
        return (
          learnerMember.learner?.id &&
          mentorSessionEvent?.mentorSessionId && (
            <MentorSessionRecapList
              learnerId={learnerMember.learner.id}
              mentorSessionId={mentorSessionEvent.mentorSessionId}
              type={MentorSessionRecapType.MENTOR_RECAP}
            />
          )
        )
      default:
        return null
    }
  }, [learnerMember, mentorSessionEvent?.mentorSessionId, tabValue])

  const existingRecap = mentorSessionEvent?.mentorSessionRecaps.find(
    (recap) =>
      recap.learnerId === learnerMember.learner?.id &&
      recap.type === MentorSessionRecapType.MENTOR_RECAP,
  )

  return (
    <Box
      sx={{
        pb: 4,
        borderBottom: (theme) => `solid 3px ${theme.palette.divider}`,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        {learnerMember.userId && (
          <UserAvatar size={44} userId={learnerMember.userId} />
        )}
        <Box>
          {learnerMember.userId && (
            <UserDisplayName userId={learnerMember.userId} variant="h6" />
          )}
          <Typography color="textSecondary">
            {learnerMember.user?.email}
          </Typography>
        </Box>

        <Box flex={1} />

        {mentorSessionEvent && learnerMember.learner?.id && mentor && (
          <RecapActions
            calendarEventId={mentorSessionEvent.calendarEventId}
            existingRecap={existingRecap}
            learnerId={learnerMember.learner.id}
            mentorId={mentor.id}
            mentorSessionId={mentorSessionEvent.mentorSessionId}
            recapType={MentorSessionRecapType.MENTOR_RECAP}
            mentorSessionEventStartsAt={
              mentorSessionEvent.calendarEvent.startsAt
            }
          />
        )}

        <DeterminateCircularProgress
          value={
            (learnerMember.latestProgressReport?.overallProgress || 0) * 100
          }
        >
          <Typography variant="caption">{`${
            Math.round(
              (learnerMember.latestProgressReport?.overallProgress || 0) * 1000,
            ) / 10
          }%`}</Typography>
        </DeterminateCircularProgress>
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Progress" value="progress" />
          <Tab label="Info" value="info" />
          <Tab
            value="recaps"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Recaps{' '}
                <Box
                  sx={{
                    ml: 1,
                    bgcolor: 'background.neutral',
                    borderRadius: 1,
                    px: 0.75,
                  }}
                >
                  <Typography
                    color="inherit"
                    fontWeight="bold"
                    variant="caption"
                  >
                    {recapsData?.mentorSessionRecaps.length}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Tabs>
      </Box>

      <Box mt={2}>{tabContent}</Box>
    </Box>
  )
}

export default LearnerItem
