import type { ReactNode, Ref } from 'react'
import { forwardRef } from 'react'

import type { SxProps } from '@mui/material'
import { Autocomplete, Checkbox, TextField } from '@mui/material'

import { Permission } from '@core/graphql'

export type PermissionsAutocompleteProps = {
  value?: Permission[]
  onChange?: (value: Permission[]) => void
  label?: string
  helperText?: ReactNode
  name?: string
  error?: boolean
  sx?: SxProps
  disabled?: boolean
}

const PermissionsAutocomplete = forwardRef<
  Ref<unknown>,
  PermissionsAutocompleteProps
>(({ onChange, value, label, helperText, disabled, error, name, sx }, ref) => {
  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      disabled={disabled}
      options={Object.values(Permission)}
      sx={sx}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          inputRef={ref}
          label={label}
          name={name}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            // icon={icon}
            // checkedIcon={checkedIcon}
            checked={selected}
            style={{ marginRight: 8 }}
          />
          {option}
        </li>
      )}
      onChange={(event, selected) => onChange?.(selected)}
    />
  )
})

export default PermissionsAutocomplete
