/* eslint-disable react/no-unstable-nested-components */
import type { FC, ReactNode } from 'react'

import type { Theme } from '@mui/material'

import type { Permission, UserRole } from '@core/graphql'

import InlineEditable from './InlineEditable'
import { InlineInput } from './InlineInput'

export type InlineEditableTextProps = {
  value: string | number | null
  isUpdating?: boolean
  onSubmit: (value: string | number | null) => void | Promise<void>
  label?: string
  readView: ReactNode
  textVariant?: keyof Theme['typography']
  editable?: boolean
  inputPlaceholder?: string
  masked?: boolean
  allowedRoles?: UserRole[]
  allowedPermissions?: Permission[]
  maskVisibility?: boolean
}

export const InlineEditableText: FC<InlineEditableTextProps> = ({
  value,
  isUpdating,
  onSubmit,
  label,
  readView,
  textVariant,
  editable,
  inputPlaceholder,
  masked,
  allowedRoles,
  allowedPermissions,
  maskVisibility,
}) => {
  return (
    <InlineEditable
      allowedPermissions={allowedPermissions}
      allowedRoles={allowedRoles}
      editable={editable}
      isUpdating={isUpdating}
      label={label}
      maskVisibility={maskVisibility}
      masked={masked}
      readView={readView}
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      editView={({ onChange, onDismiss, onSubmit }) => (
        <InlineInput
          defaultValue={value}
          disabled={isUpdating}
          placeholder={inputPlaceholder}
          textVariant={textVariant}
          onChange={(event) => onChange(event.target.value)}
          onDismiss={onDismiss}
          onSubmit={onSubmit}
        />
      )}
      onSubmit={onSubmit}
    />
  )
}

export default InlineEditableText
