import {
  format,
  getTime,
  formatDistanceToNow,
  addDays,
  startOfWeek,
} from 'date-fns'
import numeral from 'numeral'

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p')
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })
}

export function fDayOfWeek(dayIndex: number, f = 'EEEE') {
  return format(addDays(startOfWeek(new Date()), dayIndex), f)
}

export const fCountDown = (number = 0) => numeral(number).format('00')
