import type { FC } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  Slider,
  Stack,
} from '@mui/material'
import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
  X,
} from '@phosphor-icons/react'
import AvatarEditor from 'react-avatar-editor'

import type { ANY } from '@core/types'

const IMAGE_SIZE = 500

export type AvatarEditorDialogProps = {
  file: File | null
  open: boolean
  onClose: VoidFunction
  onSubmit: (blob: Blob) => void
}

const AvatarEditorDialog: FC<AvatarEditorDialogProps> = ({
  file,
  open,
  onClose,
  onSubmit,
}) => {
  const [scale, setScale] = useState(1.2)
  const editorRef = useRef<AvatarEditor>()

  useEffect(() => {
    if (open) {
      setScale(1.2)
    }
  }, [open])

  const handleSubmit = useCallback(() => {
    editorRef.current?.getImageScaledToCanvas().toBlob((blob) => {
      if (blob) {
        onSubmit(blob)
        onClose()
      }
    })
  }, [onClose, onSubmit])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          py: 2,
          pr: 3,
          pl: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton sx={{ mr: 1 }} onClick={onClose}>
            <X weight="bold" />
          </IconButton>
          Update profile picture
        </Box>
        <LoadingButton
          color="primary"
          sx={{ boxShadow: 'none' }}
          variant="contained"
          onClick={handleSubmit}
        >
          Apply
        </LoadingButton>
      </DialogTitle>

      {file ? (
        <AvatarEditor
          borderRadius={IMAGE_SIZE / 2}
          height={IMAGE_SIZE}
          image={file}
          ref={editorRef as ANY}
          scale={scale}
          width={IMAGE_SIZE}
        />
      ) : (
        <Box height={IMAGE_SIZE} width={IMAGE_SIZE} />
      )}
      <Stack
        direction="row"
        spacing={4}
        sx={{
          py: 2,
          px: '10%',
          alignItems: 'center',
          justifyContent: 'center',
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <MagnifyingGlassMinus size={32} />
        <Slider
          max={3}
          min={1}
          size="medium"
          step={0.01}
          value={scale}
          onChange={(e, value) => setScale(value as number)}
        />
        <MagnifyingGlassPlus size={32} />
      </Stack>
    </Dialog>
  )
}

export default AvatarEditorDialog
