import { Fade } from '@mui/material'
import type { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenu: {
      defaultProps: {
        TransitionComponent: Fade,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
        },
      },
    },
  }
}
