import type { FC } from 'react'
import { memo } from 'react'

import { format } from 'date-fns'

import type { ANY } from '@core/types'
import { UserDisplayName } from '@core/ui/components'

type ExtendedMentorSessionActivityProps = {
  data: ANY
  userId?: string | null
}

const ExtendedMentorSessionActivity: FC<ExtendedMentorSessionActivityProps> = ({
  data,
  userId,
}) => {
  return (
    <>
      <UserDisplayName display="inline" userId={userId} /> created{' '}
      {`${data.eventIds.length}`} mentor sessions starting from
      {data.firstEventStartsAt && data.lastEventStartsAt && (
        <>
          <b>
            {` `}
            {format(new Date(data.firstEventStartsAt), ' MMM dd, yyy')}
          </b>
          {` to `}
          <b>{`${format(new Date(data.lastEventStartsAt), ' MMM dd, yyy')}`}</b>
        </>
      )}
    </>
  )
}

export default memo(ExtendedMentorSessionActivity)
