import { useState, useEffect, useCallback } from 'react'

export default function useLocalStorage<ValueType>(
  key: string,
  defaultValue: ValueType,
) {
  const [value, setValue] = useState<ValueType>(() => {
    const storedValue = localStorage.getItem(key)

    return storedValue === null ? defaultValue : JSON.parse(storedValue)
  })

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue)
      }
    }
    window.addEventListener('storage', listener)

    return () => {
      window.removeEventListener('storage', listener)
    }
  }, [key, defaultValue])

  const setValueInLocalStorage = useCallback(
    (newValue: ValueType) => {
      setValue((currentValue: ValueType) => {
        const result =
          typeof newValue === 'function' ? newValue(currentValue) : newValue

        localStorage.setItem(key, JSON.stringify(result))

        return result
      })
    },
    [key],
  )

  return [value, setValueInLocalStorage] as [
    ValueType,
    (newValue: ValueType) => void,
  ]
}
