import type { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function CircularProgress(_theme: Theme) {
  return {
    MuiCircularProgress: {
      defaultProps: {
        disableShrink: true,
      },
    },
  }
}
