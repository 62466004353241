import { useEffect, type FC } from 'react'

import { useMatch } from '@tanstack/react-location'

import { useGetEventMeetingUrlMutation } from '@core/graphql'
import { SplashScreen } from '@core/ui/components'
import ErrorScreen from '@core/ui/components/feedback/ErrorScreen'

export type MeetingGoToProps = {}

const MeetingGoTo: FC<MeetingGoToProps> = () => {
  const {
    params: { eventId },
  } = useMatch()

  const [{ error }, getEventMeetingUrlMutation] =
    useGetEventMeetingUrlMutation()

  useEffect(() => {
    const getMeetingUrl = async () => {
      const { data } = await getEventMeetingUrlMutation({ eventId })

      if (data?.getEventMeetingUrl) {
        window.location.replace(data?.getEventMeetingUrl)
      }
    }

    getMeetingUrl()
  }, [eventId, getEventMeetingUrlMutation])

  useEffect(() => {
    if (!eventId) {
      window.location.replace('/')
    }
  })

  if (error) {
    return <ErrorScreen error={error} />
  }

  return <SplashScreen />
}

export default MeetingGoTo
