import type { FC } from 'react'
import { useMemo } from 'react'

import { Button, Grid, Stack } from '@mui/material'
import { ArrowLeft } from '@phosphor-icons/react'
import { useMatch } from '@tanstack/react-location'
import { orderBy } from 'lodash'
import { Helmet } from 'react-helmet-async'

import { LINKS } from '@core/config'
import { useBookingRequestViewQuery, UserRequestStatus } from '@core/graphql'
import { useDialogState, useLocales } from '@core/hooks'
import { Page, PageSkeleton, UrqlError, EmptyState } from '@core/ui/components'
import ActivityLog from '@modules/mentor-session/common/MentorSessionActivityLog'

import { CancelConfirmDialog } from './components/CancelConfirmDialog'
import GeneralInfo from './components/GeneralInfo'

export type BookingRequestViewProps = {}

const BookingRequestView: FC<BookingRequestViewProps> = () => {
  const { isOpen, openDialog, closeDialog } = useDialogState()
  const { translate } = useLocales('mentor_session')
  const {
    params: { requestId },
  } = useMatch()

  const [{ fetching, data, error }, refetchBookingRequest] =
    useBookingRequestViewQuery({
      variables: { userRequestId: requestId },
      requestPolicy: 'cache-and-network',
    })

  const bookingRequest = useMemo(
    () => data?.mentorSessionBookingRequest,
    [data?.mentorSessionBookingRequest],
  )

  const bookingRequestActivityLog = useMemo(
    () => orderBy(bookingRequest?.activityLogs ?? [], 'createdAt', 'desc'),
    [bookingRequest?.activityLogs],
  )

  if (error) {
    return <UrqlError isPage error={error} />
  }

  if (!bookingRequest && fetching) {
    return <PageSkeleton />
  }

  if (!bookingRequest) {
    return (
      <EmptyState
        actions={
          <Button href=".." startIcon={<ArrowLeft />} variant="contained">
            {translate('go_back')}
          </Button>
        }
        message={translate('mentor_session:booking_request_not_found', {
          capitalize: false,
        })}
      />
    )
  }

  const actionGroup = (
    <Stack direction="row" spacing={1}>
      <Button disabled color="secondary" variant="outlined">
        {translate('booking_request_action_options.update')}
      </Button>
      <Button
        color="primary"
        disabled={bookingRequest.status !== UserRequestStatus.PENDING}
        variant="outlined"
        onClick={openDialog}
      >
        {translate('booking_request_action_options.cancel')}
      </Button>
    </Stack>
  )

  return (
    <>
      <Helmet title="Request Details" />

      <Page
        actions={actionGroup}
        pageTitle="Request Details"
        goBack={{
          label: 'Requests',
          to: LINKS.MENTOR_SESSION_BOOKING_REQUEST_LIST,
        }}
      >
        <CancelConfirmDialog
          closeDialog={closeDialog}
          open={isOpen}
          refetchBookingRequest={refetchBookingRequest}
        />
        <Grid container spacing={2}>
          <Grid item lg={5} md={6} xs={12}>
            <GeneralInfo request={bookingRequest} />
          </Grid>
          <Grid item lg={7} md={6} xs={12}>
            <ActivityLog activityLogs={bookingRequestActivityLog} />
          </Grid>
        </Grid>
      </Page>
    </>
  )
}

export default BookingRequestView
