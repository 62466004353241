import type { FC } from 'react'
import { useMemo } from 'react'

import { useMatch } from '@tanstack/react-location'

import { useAuth } from '@core/auth'
import { Permission } from '@core/graphql'
import { AvailabilitySchedulesView } from '@core/ui/components'
import MentorSessionSchedulesUpdate from '@core/ui/components/common/MentorSessionSchedulesUpdate'

export type MentorAvailabilityProps = {}

const MentorAvailability: FC<MentorAvailabilityProps> = () => {
  const {
    params: { mentorId },
  } = useMatch()
  const { hasPermission } = useAuth()

  const hasUpdatePermission = useMemo(
    () => hasPermission(Permission.UPDATE_MENTOR_AVAILABILITY_SCHEDULES),
    [hasPermission],
  )

  if (hasUpdatePermission) {
    return <MentorSessionSchedulesUpdate asPage={false} targetId={mentorId} />
  }

  return <AvailabilitySchedulesView targetId={mentorId} />
}

export default MentorAvailability
