import type { FC, ReactNode } from 'react'
import { useEffect, useMemo } from 'react'

import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
import { addHours } from 'date-fns'
import type { FormState } from 'react-hook-form'
import { Controller, useForm } from 'react-hook-form'

import { useYupForm } from '@core/helpers'
import { useLocales } from '@core/hooks'
import type { SchemaOf } from '@core/lib/yup'
import { array, boolean, date, object, ref, string } from '@core/lib/yup'
import {
  FormProvider,
  RHFDateTimePicker,
  RHFTextField,
} from '@core/ui/components/hook-form'

import CalendarSelect from '../CalendarSelect'
import RecurrenceInput from '../RecurrenceInput'

import EventAttendeesField from './EventAttendeesField'

export type EventFormValues = {
  name: string
  description?: string
  calendarId: string
  startsAt: Date
  endsAt: Date
  recurrence?: string | null
  attendees: { userId: string; isHost: boolean; isOptional: boolean }[]
}

export type EventFormProps = {
  initialValues?: Partial<EventFormValues>
  children: (props: {
    fields: JSX.Element
    formState: FormState<EventFormValues>
    values: EventFormValues
  }) => ReactNode
  onSubmit: (values: EventFormValues) => void | Promise<void>
}

const defaultValues: EventFormValues = {
  name: '',
  description: '',
  calendarId: '',
  startsAt: new Date(),
  endsAt: addHours(new Date(), 1),
  recurrence: null,
  attendees: [],
}

const EventForm: FC<EventFormProps> = ({
  children,
  onSubmit,
  initialValues,
}) => {
  const { t } = useLocales('calendar')

  const validationSchema = useMemo(
    (): SchemaOf<EventFormValues> =>
      object({
        name: string().label(t('event_fields.name')).trim().required(),
        description: string()
          .label(t('event_fields.description'))
          .trim()
          .notRequired(),
        calendarId: string().label('calendar').trim().required(),
        startsAt: date().label(t('event_fields.startsAt')).required(),
        endsAt: date()
          .label(t('event_fields.endsAt'))
          .min(ref('startsAt'), 'End time must be later than start time')
          .required(),
        recurrence: string().trim().nullable().notRequired(),
        attendees: array()
          .label(t('event_fields.attendeeUsers', 'Meeting guests'))
          .of(
            object({
              userId: string().required(),
              isHost: boolean().default(false).required(),
              isOptional: boolean().default(false).required(),
              key_id: string().notRequired().strip(),
            }).required(),
          )
          .min(1)
          .required(),
      }),
    [t],
  )
  const { getFieldProps, resolver } = useYupForm(validationSchema)

  const methods = useForm<EventFormValues>({
    resolver,
    defaultValues: {
      ...defaultValues,
      ...initialValues,
    },
  })

  useEffect(() => {
    methods.reset({
      ...defaultValues,
      ...initialValues,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const startsAt = methods.watch('startsAt')

  const fields = useMemo(() => {
    return (
      <Stack spacing={2}>
        <RHFTextField autoFocus {...getFieldProps('name')} />

        <RHFTextField
          {...getFieldProps('description')}
          multiline
          maxRows={10}
          minRows={3}
        />

        <Box>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <RHFDateTimePicker {...getFieldProps('startsAt')} label="" />
            </Grid>
            <Grid item>
              <Typography color="textSecondary">to</Typography>
            </Grid>
            <Grid item xs>
              <RHFDateTimePicker {...getFieldProps('endsAt')} label="" />
            </Grid>
          </Grid>
        </Box>

        <Controller
          control={methods.control}
          name="recurrence"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <RecurrenceInput
              error={error}
              start={startsAt}
              value={value ?? null}
              onChange={(newValue) => onChange({ target: { value: newValue } })}
            />
          )}
        />

        <Controller
          control={methods.control}
          name="calendarId"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <CalendarSelect
              fullWidth
              calendarId={value}
              error={!!error}
              helperText={error?.message}
              label={t('calendar')}
              size="medium"
              sx={{ width: 'unset' }}
              onChange={(id) => onChange({ target: { value: id } })}
            />
          )}
        />

        <Divider />

        {/* <Typography color="textSecondary" variant="button">
          Add guests
        </Typography> */}

        <EventAttendeesField />
      </Stack>
    )
  }, [getFieldProps, methods.control, startsAt, t])

  return (
    <FormProvider methods={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children({
          fields,
          formState: methods.formState,
          values: methods.getValues(),
        })}
      </form>
    </FormProvider>
  )
}

export default EventForm
