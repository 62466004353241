import type { FC, MouseEvent } from 'react'

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import numeral from 'numeral'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionMentorMemberFragment } from '@core/graphql'
import { SelectiveBox, UserAvatar, UserDisplayName } from '@core/ui/components'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'

export type SelectiveMentorItemProps = {
  courseMember: BookMentorSessionMentorMemberFragment
  selected?: boolean
  onSelect: (courseMember: BookMentorSessionMentorMemberFragment) => void
}

const SelectiveMentorItem: FC<SelectiveMentorItemProps> = ({
  courseMember,
  onSelect,
  selected,
}) => {
  const mentor = courseMember.mentor!

  const occupiedCapacity = courseMember.bookedSessionsAndPendingRequests

  const { setValue } = useFormContext<BookMentorSessionValues>()

  const handleSelectMember = () => {
    onSelect(courseMember)
  }

  const handleDisplayNameClick = (event: MouseEvent) => {
    event.stopPropagation()
    setValue('previewMentorCourseMember', courseMember)
  }

  return (
    <SelectiveBox selected={selected} onClick={handleSelectMember}>
      <Tooltip
        title={`${occupiedCapacity} / ${courseMember.mentor?.capacity} booked sessions or pending requests`}
      >
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 8,
            left: 8,
            borderRadius: 1,
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.primary.contrastText,
            px: 0.8,
          })}
        >
          <Typography fontSize="0.75rem" fontWeight={500} variant="body2">
            {occupiedCapacity} / {courseMember.mentor?.capacity}
          </Typography>
        </Box>
      </Tooltip>
      <Stack alignItems="center" spacing={2}>
        <Box position="relative">
          <UserAvatar size={80} userId={mentor.userId} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Tooltip title="Click to preview">
            <Box>
              <UserDisplayName
                maxWidth={280}
                userId={mentor.userId}
                variant="h6"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline !important',
                  },
                }}
                onClick={handleDisplayNameClick}
              />
            </Box>
          </Tooltip>
          <Typography noWrap color="textSecondary" variant="body2">
            {mentor.user?.email}
            <br />
            {mentor.discordId}
          </Typography>
        </Box>
      </Stack>
      <Tooltip
        title={`Match ${numeral(courseMember.score ?? 0).format('0.000%')}`}
      >
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: 8,
            right: 8,
            borderRadius: 1,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            px: 0.8,
          })}
        >
          <Typography fontSize="0.75rem" fontWeight={500} variant="body2">
            {numeral(courseMember.score ?? 0).format('0%')}
          </Typography>
        </Box>
      </Tooltip>
    </SelectiveBox>
  )
}

export default SelectiveMentorItem
