import type { FC } from 'react'
import { useCallback } from 'react'

import { X } from '@phosphor-icons/react'
import type { SnackbarKey } from 'notistack'
import { useSnackbar } from 'notistack'

import IconButtonAnimate from '@core/ui/animates/IconButtonAnimate'

const CloseSnackbarAction: FC<{ snackKey: SnackbarKey }> = ({
  snackKey: key,
}) => {
  const { closeSnackbar } = useSnackbar()

  const handleClose = useCallback(
    () => closeSnackbar(key),
    [closeSnackbar, key],
  )

  return (
    <IconButtonAnimate size="small" sx={{ p: 0.5 }} onClick={handleClose}>
      <X />
    </IconButtonAnimate>
  )
}

export default CloseSnackbarAction
