import type { FC, SyntheticEvent } from 'react'
import { useMemo, useState } from 'react'

import { Box, Stack, Tab, Tabs, Typography } from '@mui/material'

import type {
  MentorSessionEventViewQuery,
  MentorSessionViewFragment,
} from '@core/graphql'
import {
  MentorSessionRecapType,
  useLearnerMentorSessionRecapsQuery,
} from '@core/graphql'
import { useLearner } from '@core/hooks'
import type { ArrayElement } from '@core/types'
import { UserAvatar, UserDisplayName } from '@core/ui/components'

import MentorSessionRecapList from '../MentorSessionRecapList'
import RecapActions from '../RecapActions'

import MentorInfo from './components/MentorInfo'

export type MentorMember = ArrayElement<
  MentorSessionViewFragment['courseMembers']
>

export type MentorItemProps = {
  mentorMember: MentorMember
  mentorSessionEvent?: MentorSessionEventViewQuery['mentorSessionEvent']
}

type TabValue = 'info' | 'recaps'

const MentorItem: FC<MentorItemProps> = ({
  mentorMember,
  mentorSessionEvent,
}) => {
  const { learner } = useLearner()
  const [{ data: recapsData }] = useLearnerMentorSessionRecapsQuery({
    variables: {
      learnerId: mentorMember.mentor?.id ?? '',
      mentorSessionIds: [mentorSessionEvent?.mentorSessionId || ''],
    },
  })
  const [tabValue, setTabValue] = useState<TabValue>('info')

  const handleChangeTab = (event: SyntheticEvent, newValue: TabValue) => {
    setTabValue(newValue)
  }

  const tabContent = useMemo(() => {
    switch (tabValue) {
      case 'info':
        return <MentorInfo mentorMember={mentorMember} />
      case 'recaps':
        return (
          mentorMember.mentor?.id &&
          mentorSessionEvent?.mentorSessionId && (
            <MentorSessionRecapList
              mentorId={mentorMember.mentor.id}
              mentorSessionId={mentorSessionEvent.mentorSessionId}
              type={MentorSessionRecapType.MENTEE_RECAP}
            />
          )
        )
      default:
        return null
    }
  }, [mentorMember, mentorSessionEvent?.mentorSessionId, tabValue])

  const existingRecap = mentorSessionEvent?.mentorSessionRecaps.find(
    (recap) =>
      recap.mentorId === mentorMember.mentor?.id &&
      recap.type === MentorSessionRecapType.MENTEE_RECAP,
  )

  return (
    <Box
      sx={{
        pb: 4,
        borderBottom: (theme) => `solid 3px ${theme.palette.divider}`,
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2}>
        {mentorMember.userId && (
          <UserAvatar size={44} userId={mentorMember.userId} />
        )}
        <Box>
          {mentorMember.userId && (
            <UserDisplayName userId={mentorMember.userId} variant="h6" />
          )}
          <Typography color="textSecondary">
            {mentorMember.user?.email}
          </Typography>
        </Box>

        <Box flex={1} />

        {mentorSessionEvent && mentorMember.mentor?.id && learner && (
          <RecapActions
            calendarEventId={mentorSessionEvent.calendarEventId}
            existingRecap={existingRecap}
            learnerId={learner.id}
            mentorId={mentorMember.mentor.id}
            mentorSessionId={mentorSessionEvent.mentorSessionId}
            recapType={MentorSessionRecapType.MENTEE_RECAP}
            mentorSessionEventStartsAt={
              mentorSessionEvent.calendarEvent.startsAt
            }
          />
        )}
      </Stack>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChangeTab}>
          <Tab label="Info" value="info" />
          <Tab
            value="recaps"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Recaps{' '}
                <Box
                  sx={{
                    ml: 1,
                    bgcolor: 'background.neutral',
                    borderRadius: 1,
                    px: 0.75,
                  }}
                >
                  <Typography
                    color="inherit"
                    fontWeight="bold"
                    variant="caption"
                  >
                    {recapsData?.mentorSessionRecaps.length}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Tabs>
      </Box>

      <Box mt={2}>{tabContent}</Box>
    </Box>
  )
}

export default MentorItem
