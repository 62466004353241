import type { ReactNode } from 'react'
import { forwardRef, useRef } from 'react'

import type { Theme } from '@mui/material'
import { Box, alpha, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import type { IconWeight } from '@phosphor-icons/react'
import { Check, Info, Warning, WarningCircle } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { CustomContentProps } from 'notistack'
import { SnackbarContent, SnackbarProvider } from 'notistack'

import type { ANY, Icon } from '@core/types'

import type { ColorSchema } from '../../theme/palette'

import CloseSnackbarAction from './CloseSnackbarAction'

const useStyles: ANY = makeStyles((theme: Theme) => {
  return {
    root: {
      width: '100%',
      flexGrow: 1,
      padding: theme.spacing(1),
      margin: theme.spacing(0.25, 0),
      boxShadow: theme.customShadows.z8,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.up('md')]: {
        minWidth: 240,
      },
    },
    default: {
      paddingLeft: theme.spacing(2),
    },
    success: {},
    error: {},
    warning: {},
    info: {},
    message: {
      padding: '0 !important',
      flexGrow: 1,
    },
  }
})

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

const StyledSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, forwardedRef) => {
    const classes = useStyles()
    const { message, variant, iconVariant, action, className, id } = props
    const icon = iconVariant[variant]

    const actionEl = typeof action === 'function' ? action(id) : action

    return (
      <SnackbarContent
        className={clsx(className, classes.root, classes[variant])}
        ref={forwardedRef}
      >
        <Stack alignItems="center" direction="row" flexGrow={1}>
          {icon}
          <Typography className={classes.message}>{message}</Typography>
          {actionEl}
        </Stack>
      </SnackbarContent>
    )
  },
)

export default function NotistackProvider({ children }: Props) {
  const classes = useStyles()
  const notistackRef = useRef<ANY>(null)

  return (
    <SnackbarProvider
      dense // Set default variant
      preventDuplicate
      // eslint-disable-next-line react/no-unstable-nested-components
      action={(key) => <CloseSnackbarAction snackKey={key} />}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      className={clsx(classes)}
      maxSnack={5}
      ref={notistackRef}
      variant="success"
      Components={{
        default: StyledSnackbar,
        info: StyledSnackbar,
        success: StyledSnackbar,
        warning: StyledSnackbar,
        error: StyledSnackbar,
      }}
      iconVariant={{
        info: <SnackbarIcon color="info" icon={Info} iconWeight="fill" />,
        success: <SnackbarIcon color="success" icon={Check} />,
        warning: <SnackbarIcon color="warning" icon={WarningCircle} />,
        error: <SnackbarIcon color="error" icon={Warning} />,
      }}
    >
      {children}
    </SnackbarProvider>
  )
}

// ----------------------------------------------------------------------

type SnackbarIconProps = {
  icon: Icon
  color: ColorSchema
  iconWeight?: IconWeight
}

function SnackbarIcon({
  icon: IconComponent,
  color,
  iconWeight,
}: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <IconComponent size={24} weight={iconWeight} />
    </Box>
  )
}
