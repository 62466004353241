import type { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Backdrop(_theme: Theme) {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0,0,30,0.4)',
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
            backdropFilter: 'none',
          },
        },
      },
    },
  }
}
