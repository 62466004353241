import { forwardRef } from 'react'

import type { LinkProps as LocationLinkProps } from '@tanstack/react-location'
import { Link as LocationLink } from '@tanstack/react-location'

import type { ANY } from '@core/types'

const LinkBehavior = forwardRef<
  ANY,
  Omit<LocationLinkProps, 'to'> & { href: string; replace?: boolean }
>(({ href, replace, ...props }, ref) => (
  <LocationLink
    _ref={ref}
    replace={replace}
    to={href}
    {...props}
    role={undefined}
  />
))

export default LinkBehavior
