import type { FC } from 'react'
import { useMemo } from 'react'

import { Button, Container } from '@mui/material'
import { Kanban } from '@phosphor-icons/react'
import { useMatch } from '@tanstack/react-location'
import { Helmet } from 'react-helmet-async'

import { LINKS } from '@core/config'
import { useLearnerProfileQuery } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { CoursesIcon, ProfileTabIcon } from '@core/icons'
import {
  EmptyState,
  NavTabs,
  Page,
  PageSkeleton,
  SuspenseOutlet,
  UrqlError,
  UserDisplayName,
} from '@core/ui/components'
import { buildPath } from '@core/utils/buildPath'

export type LearnerProfileProps = {}

const LearnerProfile: FC<LearnerProfileProps> = () => {
  const { t } = useLocales('learner')
  const {
    params: { learnerId },
  } = useMatch()

  const [{ fetching, data, error }] = useLearnerProfileQuery({
    variables: { id: learnerId },
  })

  const learner = useMemo(() => data?.learner, [data?.learner])

  if (error) {
    return (
      <Container>
        <UrqlError error={error} />
      </Container>
    )
  }

  if (fetching && !learner) {
    return <PageSkeleton />
  }

  if (!learner) {
    return (
      <EmptyState message={t('learner_not_found_message')} minHeight="100vh" />
    )
  }

  return (
    <>
      <Helmet title={`Learner: ${learner.user?.name}`} />
      <Page
        pageTitle={<UserDisplayName userId={learner.userId} variant="h3" />}
        actions={
          <Button
            color="inherit"
            href={buildPath(LINKS.USER_VIEW, { userId: learner.userId })}
            size="small"
            variant="outlined"
          >
            {t('user:view_user')}
          </Button>
        }
        goBack={{
          label: t('learners'),
          to: LINKS.LEARNER_LIST,
        }}
        tabs={
          <NavTabs
            items={[
              {
                label: t('profile'),
                href: './',
                icon: ProfileTabIcon,
                IconProps: { weight: 'fill' },
              },
              {
                label: t('courses'),
                href: './courses',
                icon: CoursesIcon,
                IconProps: { weight: 'fill' },
              },
              {
                label: t('common:availability_schedules'),
                href: './availability',
                icon: Kanban,
              },
            ]}
          />
        }
      >
        <SuspenseOutlet />
      </Page>
    </>
  )
}

export default LearnerProfile
