import type { FC } from 'react'

import { Box, FormHelperText, Typography } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

import type { ArrayElement } from '@core/types'

import FieldInput from './field-inputs/FieldInput'
import type { FormConfig } from './types'
import { FormFieldType } from './types'

export type FieldItemProps = {
  field: ArrayElement<FormConfig['fields']>
  name: number
  disabled?: boolean
}

const FieldItem: FC<FieldItemProps> = ({ field, name, disabled }) => {
  const methods = useFormContext()

  return (
    <Box>
      <Typography fontWeight={400} variant="h6">
        {field.question}
        {field.isRequired && (
          <Box
            component="span"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              ml: 1,
            }}
          >
            *
          </Box>
        )}
      </Typography>
      <Typography color="textSecondary">{field.description}</Typography>
      <Box
        sx={{
          width: (theme) => theme.breakpoints.values.sm,
          maxWidth: '95%',
          overflow: 'auto',
          mt: 2,
          px: 2,
        }}
      >
        <Controller
          control={methods.control}
          name={name.toString()}
          render={({ field: formField, fieldState: { error } }) => (
            <Box>
              <FieldInput
                config={field.config}
                disabled={disabled}
                error={error}
                fieldType={field.type}
                value={formField.value}
                onChange={(value) => formField.onChange({ target: { value } })}
              />
              {error && field.type !== FormFieldType.MULTI_CHOICE_GRID && (
                <FormHelperText error>{error.message}</FormHelperText>
              )}
            </Box>
          )}
        />
      </Box>
    </Box>
  )
}

export default FieldItem
