import type { FC } from 'react'
import { useCallback } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { useSnackbar } from 'notistack'

import { MENTEE_RECAP_FORM_CONFIG } from '@core/config/forms/mentee-recap'
import { MENTOR_RECAP_FORM_CONFIG } from '@core/config/forms/mentor-recap'
import {
  MentorSessionRecapType,
  useCreateMentorSessionRecapForMentorMutation,
} from '@core/graphql'
import type { CustomFormValues } from '@core/ui/components'
import { CustomFormInput, UrqlError } from '@core/ui/components'

export type CreateRecapDialogProps = {
  open: boolean
  onClose: VoidFunction
  calendarEventId: string
  learnerId: string
  mentorId: string
  mentorSessionId: string
  type: MentorSessionRecapType
}

const CreateRecapDialog: FC<CreateRecapDialogProps> = ({
  open,
  onClose,
  calendarEventId,
  learnerId,
  mentorId,
  mentorSessionId,
  type,
}) => {
  const [{ fetching, error }, mutateCreate] =
    useCreateMentorSessionRecapForMentorMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleCreate = useCallback(
    async (formValues: CustomFormValues) => {
      const { data } = await mutateCreate({
        input: {
          calendarEventId,
          learnerId,
          mentorId,
          mentorSessionId,
          type,
          content: JSON.stringify(formValues),
        },
      })

      if (data?.createMentorSessionRecap) {
        enqueueSnackbar('Mentor session recap submitted', {
          variant: 'success',
        })
        onClose()
      }
    },
    [
      calendarEventId,
      enqueueSnackbar,
      learnerId,
      mentorId,
      mentorSessionId,
      mutateCreate,
      onClose,
      type,
    ],
  )

  const config =
    type === MentorSessionRecapType.MENTEE_RECAP
      ? MENTEE_RECAP_FORM_CONFIG
      : MENTOR_RECAP_FORM_CONFIG

  return (
    <Dialog
      // fullScreen
      PaperProps={{ sx: { position: 'relative' } }}
      open={open}
      scroll="paper"
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          position: 'sticky',
          top: 0,
          marginTop: 0,
          background: (theme) => `${theme.palette.background.paper} !important`,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          zIndex: 1,
          pb: 2,
        }}
      >
        Mentor session recap
        <IconButton
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
          }}
          onClick={onClose}
        >
          <X />
        </IconButton>
      </DialogTitle>

      <CustomFormInput config={config} onSubmit={handleCreate}>
        {({ fields }) => (
          <>
            <DialogContent dividers>
              {fields} {error && <UrqlError error={error} sx={{ mt: 2 }} />}
            </DialogContent>
            <DialogActions
              sx={{
                position: 'sticky',
                bottom: 0,
                marginBottom: 0,
                background: (theme) => theme.palette.background.paper,
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                zIndex: 1,
              }}
            >
              <LoadingButton
                loading={fetching}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </CustomFormInput>
    </Dialog>
  )
}

export default CreateRecapDialog
