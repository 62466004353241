import { lazy } from 'react'

import { Navigate } from '@tanstack/react-location'

import { AuthGuard } from '@core/auth'
import { Permission, UserRole } from '@core/graphql'
import Dashboard from 'apps/workplace/dashboard'
import DeveloperModule from 'apps/workplace/modules/developer'
import ActivityLogs from 'apps/workplace/modules/developer/ActivityLogs'
import AppTokensPage from 'apps/workplace/modules/developer/app-tokens'
import DeveloperUtility from 'apps/workplace/modules/developer/DeveloperUtility'
import PermissionPlayground from 'apps/workplace/modules/developer/PermissionPlayground'
import UserSettings from 'apps/workplace/modules/user-account/user-settings'
import AccountSettings from 'apps/workplace/modules/user-account/user-settings/components/account-settings/AccountSettings'
import UserAvailabilitySettings from 'apps/workplace/modules/user-account/user-settings/components/availability/UserAvailabilitySettings'
import UserProfileSettings from 'apps/workplace/modules/user-account/user-settings/components/profile-settings/UserProfileSettings'
import UserList from 'apps/workplace/modules/users/UserList'
import UserView from 'apps/workplace/modules/users/UserView'
import WorkplaceContainer from 'containers/WorkplaceContainer'

import type { GenericsRoute } from '../routes.type'
import { authRoute } from '../routes.util'

import AcademicsRoute from './AcademicsRoute'
import CalendarRoute from './CalendarRoute'
import MentorSessionRoute from './MentorSessionRoute'
import SalesRoute from './SalesRoute'

const NotificationsPage = lazy(
  () => import('@core/ui/components/notification/NotificationsPage'),
)

const WorkplaceRoute: GenericsRoute = {
  path: 'workplace',
  element: authRoute(<WorkplaceContainer />, Permission.ACCESS_WORKPLACE_APP),
  children: [
    {
      path: 'dashboard',
      element: <Dashboard />,
    },
    {
      path: 'users',
      children: [
        {
          path: '/',
          element: authRoute(<UserList />, Permission.LIST_USERS),
        },
        {
          path: ':userId',
          element: authRoute(<UserView />, Permission.VIEW_USER),
        },
      ],
    },

    CalendarRoute,
    AcademicsRoute,
    MentorSessionRoute,
    SalesRoute,
    {
      path: 'developer',
      element: (
        <AuthGuard isPage role={UserRole.DEVELOPER}>
          <DeveloperModule />
        </AuthGuard>
      ),
      children: [
        {
          path: 'permission-playground',
          element: <PermissionPlayground />,
        },
        {
          path: 'activities',
          element: <ActivityLogs />,
        },
        {
          path: 'utility',
          element: <DeveloperUtility />,
        },
        {
          path: 'app-tokens',
          element: <AppTokensPage />,
        },
        {
          path: '/',
          element: <Navigate replace to="activities" />,
        },
      ],
    },
    {
      path: 'settings',
      element: <UserSettings />,
      children: [
        { path: '/', element: <AccountSettings /> },
        { path: 'profile', element: <UserProfileSettings /> },
        { path: 'availability', element: <UserAvailabilitySettings /> },
      ],
    },
    {
      path: 'notifications',
      element: <NotificationsPage />,
    },

    { element: <Navigate replace to="dashboard" /> },
  ],
}

export default WorkplaceRoute
