import type { FC, ReactNode } from 'react'

import type { ListItemButtonProps, ListItemTextProps } from '@mui/material'
import {
  alpha,
  Box,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  styled,
  Typography,
} from '@mui/material'
import { useUnseenCount } from '@novu/notification-center'
import { Heartbeat } from '@phosphor-icons/react'
import { Link } from '@tanstack/react-location'

import { useAuth } from '@core/auth'
import type { NavItem } from '@core/config'
import { NAVBAR, WP_NAV } from '@core/config'
import { useLocales } from '@core/hooks'
import { NotificationsIcon, NotificationsUnseenIcon } from '@core/icons'
import cssStyles from '@core/utils/cssStyles'

import AppVersionInfo from '../../data-display/AppVersionInfo'
import Label from '../../data-display/Label'

export type SidebarMenuProps = {}

const SidebarMenu: FC<SidebarMenuProps> = () => {
  const { translate } = useLocales()
  const { hasRole } = useAuth()
  const { data } = useUnseenCount()

  const isCollapse = false
  const unseenCount = data?.count ?? 0

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {WP_NAV.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 1 }}>
          <StyledListSubheader
            sx={
              isCollapse
                ? {
                    opacity: 0,
                  }
                : {}
            }
          >
            {group.subheader}
          </StyledListSubheader>

          {group.items
            .filter((item) =>
              item.roles?.length ? hasRole(...item.roles) : true,
            )
            .map((list) => (
              <ListItemNavigator key={list.title} listItem={list}>
                {(isActive) => (
                  <StyledListItem disableRipple depth={1} isActive={isActive}>
                    {list.icon && (
                      <Box
                        component={list.icon}
                        fontSize="1rem"
                        mr={1}
                        weight="bold"
                      />
                    )}
                    <StyledListItemText
                      isCollapse={isCollapse}
                      primary={translate(list.title)}
                      primaryTypographyProps={{
                        noWrap: true,
                        variant: 'body2',
                        fontWeight: 500,
                      }}
                      secondaryTypographyProps={{
                        noWrap: true,
                        variant: 'caption',
                      }}
                    />
                    {list.isBeta && <Label color="primary">Beta</Label>}
                  </StyledListItem>
                )}
              </ListItemNavigator>
            ))}
        </List>
      ))}

      <Box
        sx={(theme) => ({
          mx: 1,
          position: 'sticky',
          bottom: 0,
          mb: 0,
          py: 0.5,
          borderTop: `solid 1px ${theme.palette.divider}`,
          zIndex: 10,
          ...cssStyles(theme).bgBlur(),
        })}
      >
        <Link style={{ textDecoration: 'none' }} to="./notifications">
          {({ isActive }) => (
            <StyledListItem
              disableRipple
              depth={1}
              isActive={isActive}
              sx={{ mb: 0 }}
            >
              <Box
                sx={{
                  mr: 1,
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                {unseenCount ? (
                  <NotificationsUnseenIcon weight="bold" />
                ) : (
                  <NotificationsIcon weight="bold" />
                )}
              </Box>
              <StyledListItemText
                isCollapse={isCollapse}
                primary={translate('notifications')}
                primaryTypographyProps={{
                  noWrap: true,
                  variant: 'body2',
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{
                  noWrap: true,
                  variant: 'caption',
                  textTransform: 'none',
                }}
              />
              {!unseenCount ? (
                <Box />
              ) : (
                <ListItemSecondaryAction onClick={(e) => e.stopPropagation()}>
                  <Typography color="primary" variant="button">
                    {unseenCount}
                  </Typography>
                </ListItemSecondaryAction>
              )}
            </StyledListItem>
          )}
        </Link>
      </Box>
      <Box sx={{ paddingX: 1 }}>
        <Link style={{ textDecoration: 'none' }} to="/feedback">
          <StyledListItem
            disableRipple
            depth={1}
            sx={{ backgroundColor: '#4169e121' }}
          >
            <Box component={Heartbeat} mr={1} weight="bold" />
            <StyledListItemText
              primary="Give us a feedback"
              primaryTypographyProps={{
                noWrap: true,
                variant: 'subtitle2',
              }}
            />
          </StyledListItem>
        </Link>
      </Box>
      <Box sx={{ px: 2, py: 1, marginTop: 'auto', textAlign: 'center' }}>
        <AppVersionInfo />
      </Box>
    </Box>
  )
}

export interface ListItemStyleProps extends ListItemButtonProps {
  isActive?: boolean
  depth: number
  to?: string
  getActiveProps?: () => Partial<ListItemStyleProps>
}

export const StyledListItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ListItemStyleProps>(({ isActive, depth, theme }) => ({
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  '&:active': {
    backgroundColor: theme.palette.action.selected,
  },
  // Active item
  ...(isActive && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }),
  // Active item
  ...(isActive &&
    depth !== 1 && {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    }),
  // Sub item
  ...(depth && {
    ...(depth > 1 && {
      height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
    }),
    ...(depth > 2 && {
      paddingLeft: theme.spacing(depth),
    }),
  }),
}))

export const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  ...theme.typography.overline,
  textTransform: 'none',
  borderRadius: theme.shape.borderRadius,
  paddingTop: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
  ...cssStyles(theme).bgBlur(),
}))

export const StyledListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1rem',
  '& svg': { width: '100%', height: '100%' },
})

interface ListItemTextStyleProps extends ListItemTextProps {
  isCollapse?: boolean
}

export const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isCollapse',
})<ListItemTextStyleProps>(({ isCollapse, theme }) => ({
  transition: theme.transitions.create(['width', 'opacity'], {
    duration: theme.transitions.duration.shorter,
  }),
  ...(isCollapse && {
    width: 0,
    opacity: 0,
  }),
}))

const ListItemNavigator: FC<{
  listItem: NavItem
  children: (isActive: boolean) => ReactNode
}> = ({ listItem, children }) => {
  if (!listItem.path.startsWith('http')) {
    return (
      <Link style={{ textDecoration: 'none' }} to={listItem.path}>
        {({ isActive }) => children(isActive)}
      </Link>
    )
  }

  return (
    <Box
      component={MuiLink}
      href={listItem.path}
      sx={{ textDecoration: 'none', ':hover': { textDecoration: 'none' } }}
      target="_blank"
    >
      {children(false)}
    </Box>
  )
}

export default SidebarMenu
