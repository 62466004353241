import type { FC } from 'react'
import { useCallback } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import { MIN_PASSWORD_LENGTH } from '@core/config/validation'
import { useUpdateUserPasswordMutation } from '@core/graphql'
import { useYupForm } from '@core/helpers'
import type { SchemaOf } from '@core/lib/yup'
import { object, ref, string } from '@core/lib/yup'
import { UrqlError } from '@core/ui/components'
import { FormProvider, RHFTextField } from '@core/ui/components/hook-form'

type ChangePasswordValues = {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

export type ChangePasswordProps = {}

const validationSchema: SchemaOf<ChangePasswordValues> = object({
  currentPassword: string().label('Current password').required(),
  newPassword: string()
    .label('New password')
    .min(MIN_PASSWORD_LENGTH)
    .required(),
  confirmPassword: string()
    .label('Confirm new password')
    .min(MIN_PASSWORD_LENGTH)
    .oneOf([ref('newPassword')], 'Passwords must match')
    .required(),
})

const ChangePassword: FC<ChangePasswordProps> = () => {
  const [{ fetching, error }, mutateUpdatePassword] =
    useUpdateUserPasswordMutation()
  const { enqueueSnackbar } = useSnackbar()
  const methods = useForm<ChangePasswordValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: yupResolver(validationSchema),
  })

  const { getFieldProps } = useYupForm(validationSchema)

  const handleUpdate = useCallback(
    async ({ currentPassword, newPassword }: ChangePasswordValues) => {
      const { data } = await mutateUpdatePassword({
        currentPassword,
        newPassword,
      })

      if (!data?.updateUserPassword.id) {
        return
      }

      enqueueSnackbar('Password changed successfully', { variant: 'success' })
      methods.reset()
    },
    [enqueueSnackbar, methods, mutateUpdatePassword],
  )

  return (
    <FormProvider methods={methods}>
      <form onSubmit={methods.handleSubmit(handleUpdate)}>
        <Stack spacing={2}>
          <RHFTextField {...getFieldProps('currentPassword')} type="password" />
          <RHFTextField {...getFieldProps('newPassword')} type="password" />
          <RHFTextField {...getFieldProps('confirmPassword')} type="password" />

          {error && <UrqlError error={error} />}

          <Box pt={1}>
            <LoadingButton
              loading={fetching}
              loadingPosition="center"
              type="submit"
              variant="contained"
              disabled={
                methods.formState.submitCount > 0 && !methods.formState.isValid
              }
            >
              Change password
            </LoadingButton>
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default ChangePassword
