import { useMemo, useState } from 'react'

import type { Duration } from 'date-fns'
import { addSeconds, differenceInSeconds, intervalToDuration } from 'date-fns'

import { useInterval } from '@core/hooks/useInterval'

export const useCountdown = ({
  countSeconds,
  countTo,
  onCountdownEnd,
}: {
  countTo?: Date
  countSeconds?: number
  onCountdownEnd?: VoidFunction
}) => {
  const endsAt = countTo || addSeconds(new Date(), countSeconds ?? 0)
  const [secondsLeft, setSecondsLeft] = useState(
    differenceInSeconds(endsAt, new Date()),
  )

  const { intervalId } = useInterval(() => {
    if (secondsLeft > 0) {
      setSecondsLeft(secondsLeft - 1)
    } else if (intervalId) {
      onCountdownEnd?.()
      clearInterval(intervalId)
    }
  }, 1000)

  const duration: Duration = useMemo(() => {
    const now = new Date()
    return intervalToDuration({ start: now, end: addSeconds(now, secondsLeft) })
  }, [secondsLeft])

  return { secondsLeft, duration }
}
