import type { FC } from 'react'
import { useMemo } from 'react'

import { Timeline, timelineItemClasses } from '@mui/lab'
import { Box } from '@mui/material'

import { EmptyState } from '@core/ui/components'
import MentorSessionEventItem from '@modules/mentor-session/common/MentorSessionEventList/components/MentorSessionEventListItem'
import type { SessionCalendarEvent } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'

export type MentorSessionEventListProps = {
  events: (SessionCalendarEvent & { actionNeeded: boolean })[]
}

const MentorSessionEventList: FC<MentorSessionEventListProps> = ({
  events,
}) => {
  const actionNeededEvents = useMemo(
    () => events.filter((event) => event.actionNeeded),

    [events],
  )

  const nonActionNeededEvents = useMemo(
    () => events.filter((event) => !event.actionNeeded),
    [events],
  )

  if (!events.length) {
    return <EmptyState message="No events found" />
  }

  return (
    <Timeline
      sx={{
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 1.5,
        },
      }}
    >
      <Box
        sx={{
          visibility: actionNeededEvents.length ? 'visible' : 'hidden',
          [`& .${timelineItemClasses.root}:before`]: {
            padding: 0,
          },
          pl: '11px', // = 1.5 unit - 1px border
          ml: 1.5,
          backgroundColor: 'background.neutral',
          borderRadius: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        {actionNeededEvents.map((event, idx) => {
          return (
            <MentorSessionEventItem
              key={event.id}
              event={event}
              isLast={idx === actionNeededEvents.length - 1}
            />
          )
        })}
      </Box>

      {nonActionNeededEvents.map((event, idx) => {
        return (
          <MentorSessionEventItem
            key={event.id}
            event={event}
            isLast={idx === nonActionNeededEvents.length - 1}
          />
        )
      })}
    </Timeline>
  )
}

export default MentorSessionEventList
