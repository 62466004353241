import type { ReactNode } from 'react'
import {
  type FC,
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { findDOMNode } from 'react-dom'

import { Box } from '@mui/material'
import { isIOS } from 'react-device-detect'
import Player from 'react-player'
import type { OnProgressProps } from 'react-player/base'
import screenfull from 'screenfull'

import { useKeyDown } from '@core/hooks'

import Spinner from '../feedback/Spinner'

import MediaController from './components/MediaController/MediaController'
import {
  DEFAULT_VOLUME,
  FORWARD_SECONDS,
  MS_PER_SECOND,
  CONTROLLER_FADE_OUT_DELAY_SECONDS,
  PLAYER_WIDTH,
} from './VideoPlayer.const'
import { useVideoPlayerStore } from './VideoPlayerStore'

export type VideoPlayerProps = {
  id: string
  title?: string | ReactNode
  url: string | string[]
  containerIsOpen?: boolean
  onClose?: VoidFunction
  onPlay?: (times: number) => void
  onPause?: VoidFunction
  onFullScreen?: VoidFunction
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  id,
  title,
  url,
  containerIsOpen,
  onClose,
  onPlay,
  onPause,
  onFullScreen,
}) => {
  const { playerState, updatePlayerState, resetPlayerState } =
    useVideoPlayerStore()

  // Whether all videos' durations are available
  const [isReady, setIsReady] = useState(false)
  // Count how many times the video has been played within the same mount session
  const [playCount, setPlayCount] = useState(0)

  const playerRefs = useRef<Player[]>([])
  const containerRef = useRef<HTMLDivElement | null>(null)
  const documentRef = useRef<Document>(document)

  const {
    targetId,
    playing,
    muted,
    totalDuration,
    totalDurationBeforeCurrentVideo,
    totalPlayedSeconds,
    currentVideoIndex,
    durationPerVideo,
    volume,
    playbackRate,
    fullscreen,
    showController,
    subMenuActive,
  } = playerState

  const urls = useMemo(() => (Array.isArray(url) ? url : [url]), [url])

  /** Add all video players to playerRefs on first render */
  const addToPlayerRefs = useCallback((el: Player) => {
    if (el && !playerRefs.current.includes(el)) {
      playerRefs.current.push(el)
    }
  }, [])

  /** Update total video duration and duration of each video to state */
  const handleDuration = useCallback(() => {
    const $durationPerVideo = playerRefs.current.map((player, index) => ({
      url: urls[index],
      duration: player.getDuration(),
    }))

    updatePlayerState({
      durationPerVideo: $durationPerVideo,
      totalDuration: $durationPerVideo
        .map((video) => video.duration)
        .reduce((acc, current) => acc + current, 0),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  /** Progress video and update state */
  const handleProgress = useCallback(
    (videoIndex: number, progress: OnProgressProps) => {
      if (!isReady) {
        return
      }

      if (videoIndex === currentVideoIndex && playing) {
        updatePlayerState({
          totalPlayedSeconds:
            totalDurationBeforeCurrentVideo + progress.playedSeconds,
        })
      }

      const currentVideo = durationPerVideo[currentVideoIndex]

      // Move to next video if current video is finished
      if (currentVideo.duration === progress.playedSeconds) {
        // And if there's a next video
        if (currentVideoIndex < durationPerVideo.length - 1) {
          const nextVideoIndex = currentVideoIndex + 1

          // Recalculate totalDurationBeforeCurrentVideo
          const videosBeforeNextVideo = durationPerVideo.slice(
            0,
            nextVideoIndex,
          )

          // Seek to start of next video
          playerRefs.current[nextVideoIndex].seekTo(0)

          updatePlayerState({
            currentVideoIndex: nextVideoIndex,
            totalDurationBeforeCurrentVideo: videosBeforeNextVideo
              .map((video) => video.duration)
              .reduce((acc, current) => acc + current, 0),
          })
          return
        }

        // If it is the last video, pause player
        updatePlayerState({
          playing: false,
        })
      }
    },
    [
      currentVideoIndex,
      durationPerVideo,
      isReady,
      playing,
      totalDurationBeforeCurrentVideo,
      updatePlayerState,
    ],
  )

  /** Seek to a specific time (in seconds) */
  const handleSeek = useCallback(
    (accumulatedSeekTo: number) => {
      const target = { videoIndex: 0, seekTo: 0 }

      let accumulatedDuration = 0

      // Find which video index and where in it to seek to
      for (let i = 0; i < durationPerVideo.length; i++) {
        const video = durationPerVideo[i]

        accumulatedDuration += video.duration

        if (accumulatedDuration >= accumulatedSeekTo) {
          target.videoIndex = i
          target.seekTo =
            accumulatedSeekTo - (accumulatedDuration - video.duration)
          break
        }
      }

      // Recalculate totalDurationBeforeCurrentVideo
      const videosBeforeCurrentVideo = durationPerVideo.slice(
        0,
        target.videoIndex,
      )

      updatePlayerState({
        currentVideoIndex: target.videoIndex,
        totalDurationBeforeCurrentVideo: videosBeforeCurrentVideo
          .map((video) => video.duration)
          .reduce((acc, current) => acc + current, 0),
        totalPlayedSeconds: accumulatedSeekTo,
      })

      // Seek to target if target is not the end of the last video
      if (accumulatedSeekTo < totalDuration) {
        playerRefs.current[target.videoIndex].seekTo(target.seekTo)
        return
      }

      // If target is greater than the end of the last video,
      // seek to the end of the last video and pause player
      playerRefs.current[target.videoIndex].seekTo(
        durationPerVideo[target.videoIndex].duration,
      )
      updatePlayerState({
        playing: false,
      })
    },
    [durationPerVideo, totalDuration, updatePlayerState],
  )

  /** Toggle Play/pause video */
  const handlePlayPause = useCallback(() => {
    if (!isReady) {
      return
    }

    // If the last video is finished, seek to start
    if (totalPlayedSeconds === totalDuration) {
      handleSeek(0)
    }

    updatePlayerState({
      playing: !playing,
    })

    setPlayCount((prev) => prev + 1)

    if (playing) {
      onPause?.()
    } else {
      onPlay?.(playCount)
    }
  }, [
    handleSeek,
    isReady,
    onPause,
    onPlay,
    playCount,
    playing,
    totalDuration,
    totalPlayedSeconds,
    updatePlayerState,
  ])

  /** Mute/unmute video
   * If volume is 0 when un-muting, also set volume to DEFAULT_VOLUME
   */
  const handleMuteUnmute = useCallback(() => {
    if (!isReady) {
      return
    }
    let newVolume = volume
    if (muted && volume === 0) {
      newVolume = DEFAULT_VOLUME
    }

    updatePlayerState({ muted: !muted, volume: newVolume })
  }, [isReady, muted, updatePlayerState, volume])

  /** Current video is being buffered */
  const handleBuffer = useCallback(() => {
    updatePlayerState({ buffering: true })
  }, [updatePlayerState])

  /** Current video has finished buffering */
  const handleBufferEnd = useCallback(() => {
    updatePlayerState({ buffering: false })
  }, [updatePlayerState])

  /** Change volume. Mute if volume is 0 */
  const handleVolumeChange = useCallback(
    (value: number) => {
      if (!isReady) {
        return
      }
      updatePlayerState({ volume: value, muted: value === 0 })
    },
    [isReady, updatePlayerState],
  )

  /** Go forward by FORWARD_SECONDS seconds */
  const handleForward = useCallback(() => {
    if (!isReady) {
      return
    }
    handleSeek(Math.min(totalPlayedSeconds + FORWARD_SECONDS, totalDuration))
  }, [handleSeek, isReady, totalDuration, totalPlayedSeconds])

  /** Go back by FORWARD_SECONDS seconds  */
  const handleBackward = useCallback(() => {
    if (!isReady) {
      return
    }
    handleSeek(Math.max(totalPlayedSeconds - FORWARD_SECONDS, 0))
  }, [handleSeek, isReady, totalPlayedSeconds])

  /** Toggle fullscreen mode */
  const handleFullScreen = useCallback(() => {
    if (!isReady) {
      return
    }

    if (screenfull.isEnabled) {
      if (containerRef) {
        if (!screenfull.isFullscreen) {
          onFullScreen?.()
          // eslint-disable-next-line react/no-find-dom-node
          const element = findDOMNode(containerRef.current) as HTMLElement
          screenfull.request(element)
        } else {
          screenfull.exit()
        }
      }
    }
  }, [isReady, onFullScreen])

  const handlePlaybackRateChange = useCallback(
    (value: number) => {
      updatePlayerState({
        playbackRate: value,
      })
    },
    [updatePlayerState],
  )

  /** Show media controller on mouse move */
  const handleMouseMove = useCallback(() => {
    updatePlayerState({
      showController: true,
    })
  }, [updatePlayerState])

  // Hide controller after CONTROLLER_FADE_OUT_DELAY_SECONDS seconds if playing
  // and there is no active sub-menu
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (playing && !subMenuActive) {
      const timeout = setTimeout(() => {
        updatePlayerState({
          showController: false,
        })
      }, CONTROLLER_FADE_OUT_DELAY_SECONDS * MS_PER_SECOND)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [playing, showController, subMenuActive, updatePlayerState])

  // Init video state
  useEffect(() => {
    if (!isReady) {
      return
    }

    // If opening the same target, seek to the last played time
    if (targetId === id) {
      updatePlayerState({ playing: false })
      handleSeek(totalPlayedSeconds)
      return
    }

    // Otherwise, reset video state and update targetId
    resetPlayerState()
    updatePlayerState({ targetId: id })

    // Re-calculate durationPerVideo and totalDuration
    handleDuration()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady])

  // Update ready state depending on whether all videos' durations are available
  useEffect(() => {
    if (
      durationPerVideo.filter((video) => !!video.duration).length ===
      urls.length
    ) {
      setIsReady(true)
    } else {
      setIsReady(false)
    }

    // Prevent infinite loading
    return () => setIsReady(false)
  }, [durationPerVideo, isReady, updatePlayerState, urls.length])

  // Pause player when container is closed
  useEffect(() => {
    if (!containerIsOpen) {
      updatePlayerState({ playing: false })
    }
  }, [containerIsOpen, updatePlayerState])

  // Update fullscreen state
  useEffect(() => {
    if (isIOS) {
      return
    }

    screenfull.onchange(() =>
      updatePlayerState({
        fullscreen: screenfull.isFullscreen,
      }),
    )
  }, [updatePlayerState])

  // Keyboard shortcuts
  useKeyDown([' ', 'Enter', 'k'], handlePlayPause, documentRef)
  useKeyDown('m', handleMuteUnmute, documentRef)
  useKeyDown('ArrowRight', handleForward, documentRef)
  useKeyDown('ArrowLeft', handleBackward, documentRef)
  useKeyDown('f', handleFullScreen, documentRef)

  return (
    <Box
      ref={containerRef}
      sx={{
        // Place video player in the center in fullscreen mode
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: showController ? 'default' : 'none',
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={() =>
        playing &&
        updatePlayerState({
          showController: false,
        })
      }
    >
      {!isReady && <Spinner variant="overlay" />}
      <Box>
        {urls.map((videoUrl, index) => {
          const isCurrent = index === currentVideoIndex

          return (
            <Box
              key={videoUrl}
              sx={{
                display: isCurrent ? 'block' : 'none',
                margin: '0 !important',
                width: fullscreen ? '100vw' : PLAYER_WIDTH,
              }}
            >
              <Player
                controls={false}
                height="100%"
                muted={isCurrent && muted}
                playbackRate={playbackRate}
                playing={isCurrent && playing}
                ref={addToPlayerRefs}
                url={videoUrl}
                volume={isCurrent ? volume : 0}
                width="100%"
                wrapper={VideoPlayerWrapper}
                onBuffer={handleBuffer}
                onBufferEnd={handleBufferEnd}
                onDuration={handleDuration}
                onProgress={(progress) => handleProgress(index, progress)}
              />
            </Box>
          )
        })}

        <MediaController
          title={title}
          onBackward={handleBackward}
          onClose={onClose}
          onForward={handleForward}
          onFullScreen={handleFullScreen}
          onMute={handleMuteUnmute}
          onPlayPause={handlePlayPause}
          onPlaybackRateChange={handlePlaybackRateChange}
          onSeek={handleSeek}
          onVolumeChange={handleVolumeChange}
        />
      </Box>
    </Box>
  )
}

const VideoPlayerWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return <Box display="flex">{children}</Box>
}

export default VideoPlayer
