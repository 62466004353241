import type { FC, ReactNode } from 'react'

import type { CircularProgressProps as MuiCircularProgressProps } from '@mui/material'
import { Box, CircularProgress as MuiCircularProgress } from '@mui/material'

export type DeterminateCircularProgressProps = {
  value: number
  size?: number
  children?: ReactNode
  color?: MuiCircularProgressProps['color']
  borderThickness?: number
}

const DeterminateCircularProgress: FC<DeterminateCircularProgressProps> = ({
  size = 56,
  value,
  children,
  color,
  borderThickness = 5,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: size,
        width: size,
        border: (theme) =>
          `solid ${borderThickness}px ${theme.palette.background.neutral}`,
        borderRadius: '100%',
      }}
    >
      <MuiCircularProgress
        color={color}
        size={size}
        value={value}
        variant="determinate"
        sx={{
          position: 'absolute',
          top: -borderThickness,
          left: -borderThickness,
          right: 0,
          bottom: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'grid',
          placeItems: 'center',
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

export default DeterminateCircularProgress
