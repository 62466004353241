import type { FC } from 'react'

import type { ContainerProps } from '@mui/material'
import { Box, Container, Fade, Grid, Skeleton, Typography } from '@mui/material'

import { useIsDesktop } from '@core/hooks'

export type PageSkeletonProps = {
  maxWidth?: ContainerProps['maxWidth']
}

export const PageSkeleton: FC<PageSkeletonProps> = ({ maxWidth = 'md' }) => {
  const isDesktop = useIsDesktop()

  return (
    <Fade
      in
      unmountOnExit
      style={{
        transitionDelay: '800ms',
      }}
    >
      <Container maxWidth={maxWidth} sx={{ py: isDesktop ? 6 : 4 }}>
        <Box pb={4}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <Typography variant="h3">
                <Skeleton width="60%" />
              </Typography>
              <Typography color="textSecondary" variant="body1">
                <Skeleton width="30%" />
              </Typography>
              <Typography color="textSecondary" variant="body1">
                <Skeleton width="40%" />
              </Typography>
            </Grid>

            <Grid item>
              <Skeleton height={40} variant="rectangular" width={180} />
            </Grid>
          </Grid>
        </Box>

        <Typography variant="h3">
          <Skeleton width="60%" />
        </Typography>
        <Skeleton width="40%" />
        <Skeleton width="70%" />
      </Container>
    </Fade>
  )
}

export default PageSkeleton
