import 'animate.css'
import type { FC, ReactNode } from 'react'
import { Suspense, useMemo } from 'react'

import { AnalyticsProvider } from '@cs-platform/analytics'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ReactLocation, Router } from '@tanstack/react-location'
import { rankRoutes } from '@tanstack/react-location-rank-routes'
import { ConfigProvider as ReactAvatarConfigProvider } from 'react-avatar'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Provider as UrqlProvider } from 'urql'

import { FAVICON } from '@core/config'
import urqlClient from '@core/graphql/urql-client'
import routes from '@core/routes'
import type { ANY } from '@core/types'
import { NotistackProvider, PageSkeleton } from '@core/ui/components'
import ThemeProvider from '@core/ui/theme'
import GlobalStyles from '@core/ui/theme/global-styles'

import './@core/locales/i18n'

const App: FC = () => {
  const router = useMemo(() => {
    const location = new ReactLocation()
    return (
      <Router filterRoutes={rankRoutes} location={location} routes={routes} />
    )
  }, [])

  return (
    <UrqlProvider value={urqlClient}>
      <GoogleOAuthProvider
        clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID}
      >
        <AnalyticsProvider>
          <ThemeProvider>
            <GlobalStyles />
            <AvatarProvider>
              <HelmetProvider>
                <Helmet
                  defaultTitle="CoderSchool Platform"
                  titleTemplate="%s - CoderSchool"
                >
                  <link
                    href={FAVICON[import.meta.env.VITE_DEPLOY_ENV]}
                    rel="icon"
                    type="image/png"
                  />
                </Helmet>

                <NotistackProvider>
                  <Suspense fallback={<PageSkeleton />}>{router}</Suspense>
                </NotistackProvider>
              </HelmetProvider>
            </AvatarProvider>
          </ThemeProvider>
        </AnalyticsProvider>
      </GoogleOAuthProvider>
    </UrqlProvider>
  )
}

const AvatarProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const Provider = ReactAvatarConfigProvider as ANY

  return (
    <Provider
      colors={[
        '#316B83',
        '#FF7878',
        '#554994',
        '#7D9D9C',
        '#68A7AD',
        '#655D8A',
      ]}
    >
      {children as ANY}
    </Provider>
  )
}

export default App
