import type { FC } from 'react'
import { useRef } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  styled,
  Typography,
} from '@mui/material'

import type { ActivityLogItem } from './MentorSessionActivityLogTimeline'
import MentorSessionActivityLogTimeline from './MentorSessionActivityLogTimeline'

export type MentorSessionActivityLogProps = {
  activityLogs: ActivityLogItem[]
}

const MentorSessionActivityLog: FC<MentorSessionActivityLogProps> = ({
  activityLogs,
}) => {
  const cardRef = useRef<HTMLDivElement>(null)

  return (
    <Card ref={cardRef}>
      <CardHeader
        title={activityLogs.length ? 'Activity' : 'No activity yet'}
      />
      <StyledCardContent>
        {!activityLogs.length ? (
          <Typography color="textSecondary">
            Future interactions between learner and mentor will appear.
          </Typography>
        ) : null}
        <MentorSessionActivityLogTimeline activityLogs={activityLogs} />
      </StyledCardContent>
    </Card>
  )
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(1),
}))

export default MentorSessionActivityLog
