import type { FC } from 'react'

import { Badge, Box, Button, Dialog, Stack, dialogClasses } from '@mui/material'
import { Video } from '@phosphor-icons/react'
import { format } from 'date-fns'

import { ZOOM_RECORDING_API_PATH } from '@core/config'
import type { MentorSessionViewFragment } from '@core/graphql'
import { useDisclosure } from '@core/hooks'
import type { ArrayElement } from '@core/types'
import { DateFormatText } from '@core/ui/components'
import VideoPlayer from '@core/ui/components/video-player/VideoPlayer'
import { VIDEOSDK_RECORDING_API_PATH } from '@core/ui/components/video-player/VideoPlayer.const'
import { pxToRem } from '@core/utils/getFontValue'

export const ZOOM_MEETING_URL = 'https://us06web.zoom.us/j/:meetingId'

export type ViewRecordingButtonProps = {
  event: ArrayElement<MentorSessionViewFragment['calendarEvents']>
}

const ViewRecordingButton: FC<ViewRecordingButtonProps> = ({ event }) => {
  const [opened, { open, close }] = useDisclosure()

  const recordings = event.mentorSessionEvent?.meetingRecordings
  const zoomMeetingId = event.mentorSessionEvent?.zoomMeetingId
  const zoomRecordings = event.mentorSessionEvent?.zoomMeetingRecordings

  if (!recordings?.length && !zoomRecordings?.length) {
    return null
  }

  return (
    <>
      <Button
        color="secondary"
        size="small"
        startIcon={<Video />}
        variant="outlined"
        onClick={open}
      >
        View recording
      </Button>
      <Dialog
        maxWidth={false}
        open={opened}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            overflow: 'hidden',
          },
        }}
        onClose={close}
      >
        <VideoPlayer
          containerIsOpen={opened}
          id={event.id}
          title={
            <Stack
              alignItems="center"
              color="white"
              direction="row"
              spacing={1}
              sx={{
                fontSize: pxToRem(18),
                fontWeight: 600,
              }}
            >
              <Badge
                color="primary"
                variant="standard"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                badgeContent={
                  recordings?.length || 0 + (zoomRecordings?.length || 0)
                }
              >
                <Video size={pxToRem(20)} />
              </Badge>
              <Box>
                Recording of mentor session on{' '}
                <DateFormatText
                  disableTooltip
                  date={event.startsAt}
                  format="PP"
                  variant="format"
                />{' '}
                {format(event.startsAt, 'hh:mm')}-
                {format(event.endsAt, 'hh:mm aaa')}
              </Box>
            </Stack>
          }
          url={
            zoomMeetingId
              ? zoomRecordings?.map(
                  (recording) => `${ZOOM_RECORDING_API_PATH}${recording.id}`,
                ) || []
              : recordings?.map(
                  (recording) =>
                    `${VIDEOSDK_RECORDING_API_PATH}${recording.id}.mp4`,
                ) || []
          }
          onClose={close}
        />
      </Dialog>
    </>
  )
}

export default ViewRecordingButton
