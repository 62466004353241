import type { FC } from 'react'

import { ShieldStar, UserSquare } from '@phosphor-icons/react'
import { Helmet } from 'react-helmet-async'

import { useAuth } from '@core/auth'
import { CalendarIcon } from '@core/icons'
import { NavTabs, Page, SuspenseOutlet } from '@core/ui/components'

export type ProfileSettingsProps = {}

const ProfileSettings: FC<ProfileSettingsProps> = () => {
  const { $user: user } = useAuth()
  return (
    <>
      <Helmet title="Settings" />
      <Page
        pageTitle={user.name}
        subtitle={user.email}
        tabs={
          <NavTabs
            items={[
              { href: '.', label: 'Profile', icon: UserSquare },
              {
                href: './availability',
                label: 'Availability',
                icon: CalendarIcon,
              },
              {
                href: './security',
                label: 'Security',
                icon: ShieldStar,
              },
            ]}
          />
        }
      >
        <SuspenseOutlet />
      </Page>
    </>
  )
}

export default ProfileSettings
