import type { FC, ReactNode } from 'react'
import { useCallback, useMemo, useRef, useState } from 'react'

import type { PopoverProps } from '@mui/material'
import { Box, Popover } from '@mui/material'
import { useSnackbar } from 'notistack'

import {
  UserResponseAction,
  useMentorSessionEventRescheduleRequestQuery,
  useResponseMentorSessionEventRescheduleRequestMutation,
} from '@core/graphql'
import { useDisclosure } from '@core/hooks'
import { ContentSkeleton } from '@core/ui/components'
import CancelMentorSessionEventRescheduleRequestDialog from '@modules/mentor-session/common/MentorSessionEventReschedule/components/CancelMentorSessionEventRescheduleRequestDialog'
import MentorSessionEventRescheduleRequestAction from '@modules/mentor-session/common/MentorSessionEventReschedule/components/MentorSessionEventRescheduleRequestAction'
import RejectMentorSessionEventRescheduleRequestDialog from '@modules/mentor-session/common/MentorSessionEventReschedule/components/RejectMentorSessionEventRescheduleRequestDialog'

export type MentorSessionEventRescheduleRequestPopoverProps = {
  userRequestId: string
  children: ReactNode
  variant?: 'hover' | 'click'
  anchorOrigin?: PopoverProps['anchorOrigin']
  transformOrigin?: PopoverProps['transformOrigin']
  refetchMentorSession?: () => void
}

const MentorSessionEventRescheduleRequestPopover: FC<
  MentorSessionEventRescheduleRequestPopoverProps
> = ({
  userRequestId,
  children,
  variant,
  anchorOrigin,
  transformOrigin,
  refetchMentorSession,
}) => {
  const [
    {
      fetching: isFetchingMentorSessionEventRescheduleRequest,
      error: errorMentorSessionEventRescheduleRequest,
    },
    mutateMentorSessionEventRescheduleRequest,
  ] = useResponseMentorSessionEventRescheduleRequestMutation()

  const [
    {
      fetching: isFetchingRescheduleRequest,
      error: errorRescheduleRequest,
      data: dataRescheduleRequest,
    },
  ] = useMentorSessionEventRescheduleRequestQuery({
    variables: {
      userRequestId,
    },
  })

  const [opened, { open, close }] = useDisclosure()
  const anchorEl = useRef<HTMLDivElement>(null)

  const { enqueueSnackbar } = useSnackbar()

  const [openDialogCancel, setDialogCancel] = useState(false)
  const [openDialogReject, setDialogReject] = useState(false)

  const handleAcceptRequest = useCallback(async () => {
    if (
      dataRescheduleRequest?.mentorSessionEventRescheduleRequest
        ?.mentorSessionEventId
    ) {
      const { data } = await mutateMentorSessionEventRescheduleRequest({
        input: {
          userRequestId:
            dataRescheduleRequest?.mentorSessionEventRescheduleRequest
              .userRequestId,
          action: UserResponseAction.ACCEPT,
          reason: '',
        },
      })
      if (data) {
        enqueueSnackbar('The request to reschedule has been accepted', {
          variant: 'success',
        })
        refetchMentorSession?.()
      }
    }
  }, [
    dataRescheduleRequest?.mentorSessionEventRescheduleRequest
      ?.mentorSessionEventId,
    dataRescheduleRequest?.mentorSessionEventRescheduleRequest?.userRequestId,
    enqueueSnackbar,
    mutateMentorSessionEventRescheduleRequest,
    refetchMentorSession,
  ])

  const popover = useMemo(
    () => (
      <Popover
        disableAutoFocus
        disableRestoreFocus
        anchorEl={anchorEl.current}
        anchorOrigin={anchorOrigin}
        open={opened}
        transformOrigin={transformOrigin}
        onClose={close}
      >
        <Box
          component="div"
          onMouseLeave={() => (variant === 'hover' ? close() : undefined)}
        >
          <MentorSessionEventRescheduleRequestAction
            rescheduleRequest={
              dataRescheduleRequest?.mentorSessionEventRescheduleRequest
            }
            onAccept={handleAcceptRequest}
            onCancel={() => setDialogCancel(true)}
            onReject={() => setDialogReject(true)}
          />
          <CancelMentorSessionEventRescheduleRequestDialog
            open={openDialogCancel}
            userRequestId={userRequestId}
            onClose={() => {
              setDialogCancel(false)
              refetchMentorSession?.()
            }}
          />
          <RejectMentorSessionEventRescheduleRequestDialog
            open={openDialogReject}
            userRequestId={userRequestId}
            rescheduleRequest={
              dataRescheduleRequest?.mentorSessionEventRescheduleRequest
            }
            onClose={() => {
              setDialogReject(false)
              refetchMentorSession?.()
            }}
          />
        </Box>
      </Popover>
    ),
    [
      anchorOrigin,
      close,
      opened,
      transformOrigin,
      handleAcceptRequest,
      dataRescheduleRequest?.mentorSessionEventRescheduleRequest,
      openDialogCancel,
      userRequestId,
      openDialogReject,
      variant,
      refetchMentorSession,
    ],
  )

  if (
    isFetchingRescheduleRequest ||
    isFetchingMentorSessionEventRescheduleRequest
  ) {
    return <ContentSkeleton />
  }

  if (errorRescheduleRequest || errorMentorSessionEventRescheduleRequest) {
    return null
  }

  const triggerProps =
    variant === 'click'
      ? { onClick: open }
      : { onMouseEnter: open, onMouseLeave: close }

  return (
    <>
      <Box component="div" {...triggerProps} ref={anchorEl}>
        {children}
        {variant === 'hover' && popover}
      </Box>
      {variant === 'click' && popover}
    </>
  )
}

export default MentorSessionEventRescheduleRequestPopover
