import type { FC } from 'react'

import { alpha, Box, Grid, Typography } from '@mui/material'

import type { CourseMemberListItemFragment } from '@core/graphql'
import { MentorIcon } from '@core/icons'
import { DateFormatText, UserDisplayName } from '@core/ui/components'

export type MentorItemProps = {
  courseMember: CourseMemberListItemFragment
}

export const mentorIcon = (
  <Box
    sx={{
      borderRadius: 100,
      p: 1,
      display: 'grid',
      placeItems: 'center',
      background: (theme) => alpha(theme.palette.mentor.main, 0.1),
      color: (theme) => theme.palette.mentor.dark,
    }}
  >
    <MentorIcon size={22} weight="fill" />
  </Box>
)

const MentorItem: FC<MentorItemProps> = ({ courseMember }) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>{mentorIcon}</Grid>
      <Grid item>
        {courseMember.mentor?.userId ? (
          <UserDisplayName
            userId={courseMember.mentor?.userId}
            variant="subtitle1"
          />
        ) : (
          'deleted'
        )}
        <Typography color="textSecondary">
          Joined{' '}
          <DateFormatText
            addSuffix
            date={courseMember.createdAt}
            variant="distanceToNowStrict"
          />
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MentorItem
