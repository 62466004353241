import type { FC, ReactNode } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'

import type { ANY } from '@core/types'

import { FormProvider } from '../../hook-form'

import FieldItem from './FieldItem'
import type { FormConfig, FormField } from './types'
import { getDefaultValues, getValidationSchema } from './utils'

export type CustomFormValues = (FormField & {
  answered: ANY
})[]

export type CustomFormInputProps = {
  readOnly?: boolean
  config: FormConfig
  onSubmit: (values: CustomFormValues) => void | Promise<void>
  children: (props: { fields: ReactNode }) => ReactNode
}

const CustomFormInput: FC<CustomFormInputProps> = ({
  config,
  readOnly,
  onSubmit,
  children,
}) => {
  const methods = useForm({
    defaultValues: getDefaultValues(config),
    resolver: yupResolver(getValidationSchema(config)),
  })

  const handleSubmit = (values: ANY) => {
    onSubmit(
      Object.values(values).map((answered, idx) => ({
        ...config.fields[idx],
        answered,
      })),
    )
  }

  const fields = (
    <Stack py={2} spacing={4}>
      {config.fields.map((field, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <FieldItem key={idx} disabled={readOnly} field={field} name={idx} />
      ))}
    </Stack>
  )

  return (
    <FormProvider methods={methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        {children({ fields })}
      </form>
    </FormProvider>
  )
}

export default CustomFormInput
