import { FormFieldType } from '@core/ui/components/forms/CustomFormInput/types'

export const MENTOR_RECAP_FORM_CONFIG = {
  name: 'MENTOR_RECAP_FORM',
  fields: [
    {
      question:
        "Rate your learner's Knowledge Retention prior to the session 1-7",
      description: '',
      isRequired: true,
      type: FormFieldType.MULTIPLE_CHOICE,
      config: {
        options: [
          '1: Insufficient - Did not study or unable to recall or apply past topic ',
          '2: Low - Unable to recall or apply past topics, needed a lot of teaching effort to review lesson.',
          '3: Medium - Able to recall or apply past topics, needed some teaching effort to review lesson.',
          '4: High - Able to recall or apply past topics, needed little teaching effort to review lesson.',
          '5: Very High - Able to recall or apply past topics, needed no teaching effort to review lesson.',
          '6: Exceptional - Able to articulate the topic learnt, made minor mistakes.',
          '7: Outstanding - Perfectly demonstrated the topic learnt, made no mistakes.',
        ],
        withOther: false,
      },
    },
    {
      question:
        'Please rate how well your mentee understands the content delivered in the session.',
      isRequired: true,
      type: FormFieldType.LINEAR_SCALE,
      config: {
        from: 1,
        to: 7,
        labelMin: 'Very Poor',
        labelMax: 'Excellent',
      },
    },
    {
      question: 'Please rate your overall satisfaction with the session',
      description: '',
      isRequired: true,
      type: FormFieldType.LINEAR_SCALE,
      config: {
        from: 1,
        to: 7,
        labelMin: 'Very Poor',
        labelMax: 'Excellent',
      },
    },
    {
      question:
        'What are the questions/activities that you hosted in evaluating this rate?',
      description: '',
      isRequired: true,
      type: FormFieldType.TEXT,
      config: { placeholder: 'Your answer', multiline: true },
    },
    {
      question:
        'Did your mentee meet the goals of last week? If not, explain in "Other"',
      isRequired: true,
      type: FormFieldType.MULTIPLE_CHOICE,
      config: {
        options: ['Yes'],
        withOther: true,
      },
    },
    {
      question:
        'List down in bullet points topics you discuss today (English or Vietnamese)',
      isRequired: true,
      type: FormFieldType.TEXT,
      config: { placeholder: 'Your answer', multiline: true },
    },
    {
      question:
        'Is your mentee on track basis on the lesson plan tracker? If no, describe in "Other"',
      isRequired: true,
      type: FormFieldType.MULTIPLE_CHOICE,
      config: {
        options: ['Yes'],
        withOther: true,
      },
    },
    {
      question:
        'Please detail the plan of action to send as reminder for learner before the next session',
      isRequired: true,
      type: FormFieldType.TEXT,
      config: { placeholder: 'Your answer', multiline: true },
    },
    {
      question:
        'Please choose any of the following support that you recommend for your mentee',
      isRequired: true,
      type: FormFieldType.CHECKBOXES,
      config: {
        options: [
          'TA 1n1',
          'Emotional Consultation',
          'Career Orientation',
          'Workshop',
        ],
        withOther: true,
      },
    },
    {
      question:
        "If there's one thing you want to change about platform, what will it be?",
      isRequired: false,
      type: FormFieldType.TEXT,
      config: { placeholder: 'Your answer', multiline: true },
    },
  ],
}
