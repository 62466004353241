import type { FC } from 'react'

import type { ViewMentorSessionRecapQuery } from '@core/graphql'
import { EmptyState, FormSubmissionContent } from '@core/ui/components'

export type ViewRecapProps = {
  mentorSessionRecap: ViewMentorSessionRecapQuery['mentorSessionRecap']
}

const ViewRecap: FC<ViewRecapProps> = ({ mentorSessionRecap }) => {
  if (!mentorSessionRecap?.formSubmission) {
    return (
      <EmptyState message="This recap cannot be found or has been removed." />
    )
  }
  return (
    <FormSubmissionContent
      content={mentorSessionRecap.formSubmission.content}
    />
  )
}

export default ViewRecap
