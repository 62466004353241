import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'

import { Box, Stack, Typography, Grid } from '@mui/material'
import fuzzysort from 'fuzzysort'
import { first, some } from 'lodash'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionMentorMemberFragment } from '@core/graphql'
import { useSuitableMentorMembersQuery } from '@core/graphql'
import { ListItemSkeleton, SearchBox, UrqlError } from '@core/ui/components'
import { removeVietnameseTones } from '@core/utils/normalizeSearchText'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import { LEARNER_LIST_HEIGHT } from '../../BookMentorSession.util'

import SelectiveMentorItem from './SelectiveMentorItem'

export type SuitableMentorListProps = {
  courseId: string
  learnerCourseMemberId: string
  mentorMemberId?: string
  onChange: (courseMember: BookMentorSessionMentorMemberFragment) => void
}

const SuitableMentorList: FC<SuitableMentorListProps> = ({
  courseId,
  learnerCourseMemberId,
  mentorMemberId,
  onChange,
}) => {
  const [{ data, fetching, error }] = useSuitableMentorMembersQuery({
    variables: { courseId, learnerCourseMemberId },
    requestPolicy: 'network-only',
  })

  const { watch, setValue } = useFormContext<BookMentorSessionValues>()
  const [searchText, setSearchText] = useState('')

  const value = watch('selectedMentorCourseMember')
  const autoSelect = watch('options.autoSelect')

  const allMembers = useMemo(
    () =>
      (data?.suitableMentorsForMentorSession ?? []).filter((cm) => !!cm.mentor),
    [data?.suitableMentorsForMentorSession],
  )

  const courseMembers = useMemo(() => {
    if (!searchText) {
      return allMembers
    }

    const decoratedMembers = allMembers.map((member) => ({
      ...member,
      search: removeVietnameseTones(member.mentor?.user?.name || ''),
    }))

    const filtered = fuzzysort.go(
      removeVietnameseTones(searchText),
      decoratedMembers,
      {
        keys: [
          'mentor.user.name',
          'mentor.user.email',
          'mentor.discordId',
          'search',
        ],
        all: true,
      },
    )

    return filtered.map((item) => item.obj)
  }, [allMembers, searchText])

  // Auto select
  useEffect(() => {
    let firstOption = first(courseMembers)

    // Set first option if a default value is provided
    if (
      some(courseMembers, (courseMember) => courseMember.id === mentorMemberId)
    ) {
      const defaultCourseMember = courseMembers.find(
        (cm) => cm.id === mentorMemberId,
      )

      if (defaultCourseMember) {
        firstOption = defaultCourseMember
      }
    }

    if (firstOption && autoSelect) {
      setValue('selectedMentorCourseMember', firstOption)
    }
  }, [autoSelect, courseMembers, mentorMemberId, setValue])

  const content = useMemo(() => {
    if (error) {
      return <UrqlError error={error} />
    }

    if (fetching) {
      return <ListItemSkeleton />
    }

    return courseMembers.length ? (
      <Grid container spacing={1}>
        {courseMembers.map((courseMember) => (
          <Grid key={courseMember.id} item lg={6} md={12} sm={6} xl={3} xs={6}>
            <SelectiveMentorItem
              courseMember={courseMember}
              selected={courseMember.id === value?.id}
              onSelect={() => onChange(courseMember)}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography p={1}>No suitable mentors found.</Typography>
    )
  }, [courseMembers, error, fetching, onChange, value?.id])

  return (
    <Stack spacing={2}>
      <SearchBox
        placeholder={`Search in ${allMembers.length} mentor...`}
        onSubmit={setSearchText}
      />
      <Box
        sx={{
          maxHeight: `min(${LEARNER_LIST_HEIGHT}px, 70vh)`,
          overflow: 'auto',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
          borderRadius: 1,
          padding: 1,
        }}
      >
        {content}
      </Box>
    </Stack>
  )
}

export default SuitableMentorList
