import type { FC } from 'react'

import type { SxProps } from '@mui/material'
import { Alert, Box, Container } from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import type { CombinedError } from 'urql'

import { useIsDesktop, useLocales } from '@core/hooks'

export type UrqlErrorProps = {
  error: CombinedError
  sx?: SxProps
  isPage?: boolean
}

const UrqlError: FC<UrqlErrorProps> = ({ error, sx, isPage = false }) => {
  const { t } = useLocales('error')
  const isDesktop = useIsDesktop()

  const content = (
    <Box id="urql-error" sx={sx}>
      <Alert color="error" icon={<Warning />}>
        {t(
          error.message,
          import.meta.env.PROD &&
            import.meta.env.VITE_DEPLOY_ENV === 'production'
            ? 'Something went wrong. Please try again or contact us for support.'
            : error.message,
        )}
      </Alert>
    </Box>
  )

  if (isPage) {
    return <Container sx={{ py: isDesktop ? 6 : 4 }}>{content}</Container>
  }

  return content
}

export default UrqlError
