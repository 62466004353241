import type { FC } from 'react'
import { Suspense } from 'react'

import { Outlet } from '@tanstack/react-location'

import { RouteSkeleton } from '../skeletons'

export type SuspenseOutletProps = {
  disableSkeleton?: boolean
}

const SuspenseOutlet: FC<SuspenseOutletProps> = ({ disableSkeleton }) => {
  return (
    <Suspense fallback={disableSkeleton ? null : <RouteSkeleton />}>
      <Outlet />
    </Suspense>
  )
}

export default SuspenseOutlet
