import { Permission } from '@core/graphql'
import { SuspenseOutlet } from '@core/ui/components'
import MentorSessionEventView from '@modules/mentor-session/MentorSessionEventView'
import MentorSessionEventGeneral from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventGeneral'
import MentorSessionEventLearners from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventLearners'
import MentorSessionEventMentors from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventMentors'
import MentorTimesheetPage from 'apps/mentor/mentor-session/MentorTimesheet'
import BookingRequestView from 'apps/workplace/modules/mentor-session/pages/BookingRequestView/BookingRequestView'
import BookMentorSession from 'apps/workplace/modules/mentor-session/pages/BookMentorSession'
import CreateMentorSession from 'apps/workplace/modules/mentor-session/pages/CreateMentorSession'
import CreateMentorSessionBookingRequest from 'apps/workplace/modules/mentor-session/pages/CreateMentorSessionBookingRequest'
import LearnerMemberSessionsForStaff from 'apps/workplace/modules/mentor-session/pages/LearnerMemberSessionsForStaff'
import MentorSessionBookingRequestListPage from 'apps/workplace/modules/mentor-session/pages/MentorSessionBookingList'
import MentorSessionListPage from 'apps/workplace/modules/mentor-session/pages/MentorSessionList'

import type { GenericsRoute } from '../routes.type'
import { authRoute } from '../routes.util'

const MentorSessionRoute: GenericsRoute = {
  path: 'mentor-sessions',
  element: <SuspenseOutlet />,
  children: [
    {
      path: '/sessions/:mentorSessionId/events/:eventId',
      element: authRoute(
        <MentorSessionEventView viewBy="staff" />,
        Permission.VIEW_MENTOR_SESSION,
      ),
      children: [
        { path: '/', element: <MentorSessionEventGeneral /> },
        { path: '/learners', element: <MentorSessionEventLearners /> },
        { path: '/mentors', element: <MentorSessionEventMentors /> },
      ],
    },
    {
      path: '/sessions/:mentorSessionId',
      element: authRoute(
        <LearnerMemberSessionsForStaff />,
        Permission.VIEW_MENTOR_SESSION,
      ),
    },
    {
      path: '/sessions/create',
      element: authRoute(
        <CreateMentorSession />,
        Permission.CREATE_MENTOR_SESSION,
      ),
    },
    {
      path: '/sessions/booking',
      element: authRoute(
        <BookMentorSession />,
        Permission.CREATE_MENTOR_SESSION,
      ),
    },
    {
      path: '/sessions',
      element: authRoute(
        <MentorSessionListPage />,
        Permission.LIST_MENTOR_SESSION,
      ),
    },
    {
      path: '/booking-requests/:requestId',
      element: authRoute(
        <BookingRequestView />,
        Permission.LIST_ALL_MENTOR_SESSION_BOOKING_REQUESTS,
      ),
    },
    {
      path: '/booking-requests/create',
      element: authRoute(
        <CreateMentorSessionBookingRequest />,
        Permission.CREATE_MENTOR_SESSION_BOOKING_REQUEST,
      ),
    },
    {
      path: '/booking-requests',
      element: authRoute(
        <MentorSessionBookingRequestListPage />,
        Permission.LIST_ALL_MENTOR_SESSION_BOOKING_REQUESTS,
      ),
    },
    {
      path: '/timesheet',
      element: authRoute(
        <MentorTimesheetPage />,
        Permission.VIEW_MENTOR_TIMESHEET,
      ),
    },
  ],
}

export default MentorSessionRoute
