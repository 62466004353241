import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import type { GridSpacing } from '@mui/material'
import { Box, Divider, Grid, Stack, Typography } from '@mui/material'
// eslint-disable-next-line import/no-extraneous-dependencies
import type { ResponsiveStyleValue } from '@mui/system'

export type ContentSectionGroupProps = {
  title: string
  subtitle?: string | ReactNode
  children?: ReactNode
  headerDivider?: boolean
  divider?: boolean
  /**
   * The orientation of the section group
   * @defaults "horizontal" */
  direction?: 'horizontal' | 'vertical'
  /** @defaults 2 */
  spacing?: ResponsiveStyleValue<GridSpacing>
  /**
   * Add a divider between title and content on HORIZONTAL direction.
   * @defaults true
   */
  horizontalDivider?: boolean
  /** Only renders on HORIZONTAL direction. */
  actions?: ReactNode
}

const ContentSectionGroup: FC<ContentSectionGroupProps> = ({
  title,
  children,
  subtitle,
  headerDivider = true,
  divider = false,
  spacing = 2,
  direction = 'horizontal',
  horizontalDivider: vertical = true,
  actions,
}) => {
  const isHorizontal = useMemo(() => direction === 'horizontal', [direction])

  return (
    <Box>
      <Grid container spacing={spacing}>
        <Grid item md={isHorizontal ? 4 : 12} xs={12}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs>
              <Stack mt={isHorizontal ? -0.5 : 0} spacing={0}>
                <Typography
                  fontWeight={isHorizontal ? 'normal' : 'bold'}
                  variant="h6"
                >
                  {title}
                </Typography>
                {subtitle && typeof subtitle === 'string' ? (
                  <Typography color="textSecondary" variant="body2">
                    {subtitle}
                  </Typography>
                ) : (
                  subtitle
                )}
              </Stack>
            </Grid>

            {!!actions && (
              <Grid item>
                <Stack direction="row" my={-1} spacing={1}>
                  {actions}
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isHorizontal && vertical && headerDivider && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        <Grid item md={isHorizontal ? 8 : 12} xs={12}>
          {children}
        </Grid>
        {divider && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default ContentSectionGroup
