import type { FC } from 'react'

import { GlobalStyles as MuiGlobalStyles, useTheme } from '@mui/material'

export type GlobalStylesProps = {}

const GlobalStyles: FC<GlobalStylesProps> = () => {
  const theme = useTheme()

  return (
    <MuiGlobalStyles
      styles={{
        a: {
          color: theme.palette.primary.main,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    />
  )
}

export default GlobalStyles
