import type { FC } from 'react'
import { useMemo } from 'react'

import { Stack, useTheme } from '@mui/material'

import { RescheduleActivityIcon } from '@core/icons'
import type { SessionCalendarEvent } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'
import { useLearnerMemberSessionsContext } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessionsContext'

import MentorSessionEventRescheduleRequestPopover from './components/MentorSessionEventRescheduleRequestPopover'

export type MentorSessionEventRescheduleProps = {
  event: SessionCalendarEvent
}

const MentorSessionEventReschedule: FC<MentorSessionEventRescheduleProps> = ({
  event,
}) => {
  const { refetchMentorSession } = useLearnerMemberSessionsContext()
  const theme = useTheme()
  const userRequestId = useMemo(() => {
    return (
      event.mentorSessionEvent?.rescheduleRequests?.[0]?.userRequestId || ''
    )
  }, [event.mentorSessionEvent?.rescheduleRequests])

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={1}
      justifyContent="flex-end"
    >
      <MentorSessionEventRescheduleRequestPopover
        refetchMentorSession={refetchMentorSession}
        userRequestId={userRequestId}
        variant="hover"
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 0,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{
            fontSize: '1.5rem',
            color: theme.palette.warning.main,
          }}
        >
          <RescheduleActivityIcon />
        </Stack>
      </MentorSessionEventRescheduleRequestPopover>
    </Stack>
  )
}

export default MentorSessionEventReschedule
