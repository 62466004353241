/* eslint-disable react/no-unstable-nested-components */
import type { FC, ReactNode } from 'react'

import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

import type { Permission, UserRole } from '@core/graphql'

import InlineEditable from './InlineEditable'

export type InlineEditableDatePickerProps = {
  value: Date | null
  isUpdating?: boolean
  onSubmit: (value: Date | null) => void | Promise<void>
  label?: string
  readView: ReactNode
  editable?: boolean
  masked?: boolean
  allowedRoles?: UserRole[]
  allowedPermissions?: Permission[]
  maskVisibility?: boolean
}

export const InlineEditableDatePicker: FC<InlineEditableDatePickerProps> = ({
  label,
  isUpdating,
  onSubmit,
  value,
  readView,
  editable,
  masked,
  allowedRoles,
  allowedPermissions,
  maskVisibility,
}) => {
  return (
    <InlineEditable
      allowedPermissions={allowedPermissions}
      allowedRoles={allowedRoles}
      editable={editable}
      isUpdating={isUpdating}
      label={label}
      maskVisibility={maskVisibility}
      masked={masked}
      readView={readView}
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      editView={({ onChange, value, isUpdating }) => (
        <DatePicker
          disabled={isUpdating}
          openTo="year"
          renderInput={(params) => <TextField fullWidth {...params} />}
          value={value ?? null}
          views={['year', 'month', 'day']}
          onChange={onChange}
        />
      )}
      onSubmit={onSubmit}
    />
  )
}

export default InlineEditableDatePicker
