import { Outlet } from '@tanstack/react-location'

import { Permission } from '@core/graphql'
import DealList from 'apps/workplace/modules/sales/deals/DealList'
import DealView from 'apps/workplace/modules/sales/deals/DealView'

import type { GenericsRoute } from '../routes.type'
import { authRoute } from '../routes.util'

const SalesRoute: GenericsRoute = {
  path: 'sales',
  element: authRoute(<Outlet />, Permission.EXPERIMENTAL),
  children: [
    { path: 'deals', element: <DealList /> },
    { path: 'deals/:dealId', element: <DealView /> },
  ],
}

export default SalesRoute
