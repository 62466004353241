import type { FC } from 'react'
import { useCallback, useMemo, useState } from 'react'

import { alpha, Box, Stack, Typography } from '@mui/material'
import { Camera } from '@phosphor-icons/react'
import { get } from 'lodash'
import numeral from 'numeral'
import { useDropzone } from 'react-dropzone'

import { useUpdateUserAvatarMutation } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { Spinner, UrqlError, UserAvatar } from '@core/ui/components'

import AvatarEditorDialog from './AvatarEditorDialog'

// avatar editor will resize & crop the image so no worries
const MAX_BYTES = 50 * 1024 * 1024 // 50 MB

export type UpdateAvatarProps = {
  userId: string
  hasAvatar?: boolean
}

const UpdateAvatar: FC<UpdateAvatarProps> = ({ userId }) => {
  const { t } = useLocales()
  const [{ fetching: uploading, error }, mutateUpdate] =
    useUpdateUserAvatarMutation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const { fileRejections, getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: false,
    noClick: true,
    maxSize: MAX_BYTES,
    onDropAccepted: (files) => {
      setSelectedFile(files[0] ?? null)
    },
  })

  const fileError = useMemo(() => {
    const code = get(fileRejections, '0.errors.0.code')

    return code
  }, [fileRejections])

  const handleUpdate = useCallback(
    async (fileBlob: Blob | null) => {
      await mutateUpdate({ file: fileBlob })
    },
    [mutateUpdate],
  )

  return (
    <Box
      {...getRootProps()}
      sx={{
        position: 'relative',
        borderRadius: 3,
        overflow: 'hidden',
        width: 260,
        maxWidth: '100%',
      }}
    >
      <input {...getInputProps()} />

      <Stack
        alignItems="center"
        spacing={4}
        sx={{
          backgroundColor: 'background.neutral',
          padding: 4,
        }}
      >
        <Box
          sx={{
            borderRadius: '50%',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          {uploading && <Spinner variant="overlay" />}
          <Box
            sx={{
              borderRadius: '50%',
              cursor: 'pointer',

              outline: 'dashed 1px transparent',
              overflow: 'hidden',
              transition: (theme) =>
                theme.transitions.create(['outline-offset', 'outline-color']),

              '&:hover': {
                outlineOffset: (theme) => theme.spacing(1),
                outlineColor: (theme) => theme.palette.divider,
                '& >.overlay': {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.grey[900], 0.4),
                  opacity: 0.8,
                },
              },
            }}
            onClick={open}
          >
            <Box
              className="overlay"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1,
                backgroundColor: 'transparent',
                opacity: 0,
                transition: (theme) =>
                  theme.transitions.create(['background-color', 'opacity']),
                display: 'grid',
                placeItems: 'center',
                color: (theme) => theme.palette.common.white,
              }}
            >
              <Box textAlign="center">
                <Camera size={32} weight="fill" />
                <Typography display="block" mt={-0.5} variant="caption">
                  Update photo
                </Typography>
              </Box>
            </Box>
            <UserAvatar size={120} userId={userId} />
          </Box>
        </Box>

        {fileError ? (
          <Typography color="error.main" textAlign="center" variant="caption">
            Oops. {t(`error:${fileError}`)}.
          </Typography>
        ) : (
          <Typography
            color="textSecondary"
            textAlign="center"
            variant="caption"
          >
            Allowed *.jpeg, *.jpg, *.png.
            <br />
            Max size of {numeral(MAX_BYTES).format('0 b')}
          </Typography>
        )}
        {error && <UrqlError error={error} sx={{ mt: 2 }} />}
      </Stack>

      <AvatarEditorDialog
        file={selectedFile}
        open={!!selectedFile}
        onClose={() => setSelectedFile(null)}
        onSubmit={handleUpdate}
      />
    </Box>
  )
}

export default UpdateAvatar
