import type { FC } from 'react'

import { Box, Grid } from '@mui/material'

import type { MentorSessionViewFragment } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { ArrayElement } from '@core/types'

import InfoItem from '../../InfoItem'

export type MentorMember = ArrayElement<
  MentorSessionViewFragment['courseMembers']
>

export type MentorInfoProps = {
  mentorMember: MentorMember
}

const MentorInfo: FC<MentorInfoProps> = ({ mentorMember }) => {
  const { t } = useLocales()

  return (
    <Box>
      <Grid container spacing={2}>
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={mentorMember.user?.phone}
          label={t('user:phone')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={mentorMember.mentor?.jobTitle}
          label={t('mentor:job_title')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={mentorMember.mentor?.organization}
          label={t('mentor:company')}
        />
      </Grid>
    </Box>
  )
}

export default MentorInfo
