import type { FocusEventHandler, ReactNode, Ref } from 'react'
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'

import type { SxProps } from '@mui/material'
import {
  Autocomplete,
  Box,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { orderBy } from 'lodash'
import { matchSorter } from 'match-sorter'

import type { CourseMemberSelectFragment } from '@core/graphql'
import { CourseMemberType, useCourseMemberSelectQuery } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { Spinner, UrqlError } from '@core/ui/components'
import { learnerIcon } from 'apps/workplace/modules/courses/CourseView/CourseMembers/components/LearnerItem'
import { mentorIcon } from 'apps/workplace/modules/courses/CourseView/CourseMembers/components/MentorItem'

export type CourseMemberItem = CourseMemberSelectFragment

export type CourseMemberSelectProps = {
  courseId?: string | null
  label?: string
  placeholder?: string
  name?: string
  onBlur?: FocusEventHandler<HTMLDivElement>
  onChange?: (courseMembers: CourseMemberItem[]) => void
  value?: CourseMemberItem[]
  error?: boolean
  helperText?: ReactNode
  sx?: SxProps
  disabled?: boolean
  filterCourseMembers?: (
    courseMembers: CourseMemberItem[],
  ) => CourseMemberItem[]
  getValue?: (courseMembers: CourseMemberItem[]) => CourseMemberItem[]
}

const DEFAULT_LIMIT = 1000

const filterOptions = (
  options: CourseMemberItem[],
  { inputValue }: { inputValue: string },
) =>
  orderBy(
    matchSorter(options, inputValue, {
      keys: ['id', 'user.name', 'user.email'],
    }),
    'type',
    'desc',
  )

const CourseMemberSelect = forwardRef<Ref<unknown>, CourseMemberSelectProps>(
  (
    {
      courseId,
      label,
      placeholder,
      onChange,
      error,
      helperText,
      value,
      getValue,
      filterCourseMembers,
      ...rest
    },
    ref,
  ) => {
    const { t } = useLocales('course')
    const [limit, setLimit] = useState(DEFAULT_LIMIT)
    const [{ data, error: queryError, fetching, operation }] =
      useCourseMemberSelectQuery({
        variables: { courseId, first: limit },
        pause: !courseId,
      })

    const courseMembers: CourseMemberItem[] = useMemo(() => {
      if (!courseId || courseId !== operation?.variables?.courseId) {
        return []
      }
      const nodes = data?.courseMemberConnection.nodes ?? []
      if (!filterCourseMembers) {
        return nodes
      }
      return filterCourseMembers(nodes)
    }, [
      courseId,
      data?.courseMemberConnection.nodes,
      filterCourseMembers,
      operation?.variables?.courseId,
    ])

    const selectedValue = useMemo(() => {
      if (typeof getValue === 'function') {
        return getValue(courseMembers) ?? null
      }

      if (typeof value !== 'undefined') {
        return value
      }

      return null
    }, [courseMembers, getValue, value])

    const renderOption = useCallback((option: CourseMemberItem) => {
      const icon =
        option.type === CourseMemberType.MENTOR ? mentorIcon : learnerIcon

      return (
        <Box ml={-2} mr={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>{icon}</Grid>
            <Grid item xs>
              <Typography fontWeight={500} variant="body1">
                {option.user?.name}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {option.user?.email}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )
    }, [])

    useEffect(() => {
      if (
        data?.courseMemberConnection.totalCount &&
        data.courseMemberConnection.totalCount > DEFAULT_LIMIT
      ) {
        setLimit(data.courseMemberConnection.totalCount)
      }
    }, [data?.courseMemberConnection.totalCount])

    if (queryError) {
      return <UrqlError error={queryError} />
    }

    if (fetching && !courseMembers.length) {
      return <Skeleton height={56} variant="rectangular" />
    }

    return (
      <Autocomplete
        disableCloseOnSelect
        multiple
        disabled={fetching}
        filterOptions={filterOptions}
        groupBy={(item) => `${item.type}S`}
        id="course-member-select"
        isOptionEqualToValue={(option, theValue) => option.id === theValue.id}
        loading={fetching}
        options={courseMembers}
        value={selectedValue ?? []}
        getOptionLabel={({ id }) =>
          courseMembers.find((courseMember) => courseMember.id === id)?.user
            ?.name || '(unknown)'
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            inputRef={ref}
            label={label ?? t('select_course_member', { capitalize: false })}
            InputProps={{
              ...params.InputProps,
              startAdornment: null,
              endAdornment: (
                <>
                  {fetching ? <Spinner size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            placeholder={
              placeholder || t('search_course_members', { capitalize: false })
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ display: 'block' }}>
            {renderOption(option)}
          </li>
        )}
        onChange={(event, selected) => onChange?.(selected)}
        {...rest}
      />
    )
  },
)

export default CourseMemberSelect
