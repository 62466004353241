import type { FC } from 'react'
import { useMemo, useRef, useState } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Button,
  MenuItem,
  Popover,
  MenuList,
  Stack,
  ListItemIcon,
  Chip,
} from '@mui/material'
import { CaretUp, Check } from '@phosphor-icons/react'
import { useFormContext } from 'react-hook-form'

import type { ANY } from '@core/types'

import type { BookMentorSessionValues } from '../BookMentorSession.util'

export type ActionsButtonGroupProps = {
  onSubmit: VoidFunction
  submitButtonLabel: string
}

const ActionsButtonGroup: FC<ActionsButtonGroupProps> = ({
  onSubmit,
  submitButtonLabel,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<BookMentorSessionValues>()

  const {
    course,
    selectedLearnerCourseMember,
    selectedMentorCourseMember,
    time,
    options = {},
  } = watch()
  const { autoSelect, stayForNextSession } = options

  const isFormValid = useMemo(() => {
    return (
      course?.id &&
      selectedLearnerCourseMember?.id &&
      selectedMentorCourseMember?.id &&
      time.startsAt &&
      time.duration &&
      time.endsAfterMonths
    )
  }, [
    course?.id,
    selectedLearnerCourseMember?.id,
    selectedMentorCourseMember?.id,
    time.duration,
    time.endsAfterMonths,
    time.startsAt,
  ])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <LoadingButton
          fullWidth
          disabled={!isFormValid}
          loading={isSubmitting}
          variant="contained"
          onClick={onSubmit}
        >
          {submitButtonLabel}
        </LoadingButton>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          aria-label="select merge strategy"
          color="inherit"
          endIcon={<CaretUp />}
          ref={anchorRef as ANY}
          size="small"
          sx={{ flexShrink: 0 }}
          variant="outlined"
          onClick={handleToggle}
        >
          Options
        </Button>
      </Stack>
      <Popover
        anchorEl={anchorRef.current}
        open={open}
        role={undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuList autoFocusItem dense id="split-button-menu">
          <MenuItem onClick={() => setValue('options.autoSelect', !autoSelect)}>
            <ListItemIcon sx={{ minWidth: '24px !important' }}>
              {autoSelect && <Check weight="bold" />}
            </ListItemIcon>
            Sumi auto-pilot{' '}
            <Chip
              color="secondary"
              label="beta"
              size="small"
              sx={{ ml: 1, fontSize: '0.7rem', fontWeight: 500 }}
              variant="outlined"
            />
          </MenuItem>
          <MenuItem
            onClick={() =>
              setValue('options.stayForNextSession', !stayForNextSession)
            }
          >
            <ListItemIcon sx={{ minWidth: '24px !important' }}>
              {stayForNextSession && <Check weight="bold" />}
            </ListItemIcon>
            Create another booking
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

export default ActionsButtonGroup
