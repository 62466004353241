import type { FC } from 'react'
import { useCallback } from 'react'

import {
  Table,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
} from '@mui/material'

import type { CourseMemberNodeFragment } from '@core/graphql'
import { CourseMemberType } from '@core/graphql'
import {
  CheckCircleIcon,
  MinusCircleIcon,
  WarningCircleIcon,
} from '@core/icons'

import type { EnrollMemberError } from './EnrollMembersDialog'

export type EnrollMemberTableProps = {
  courseMembers: CourseMemberNodeFragment[]
  errors: EnrollMemberError[]
}

const EnrollMemberTable: FC<EnrollMemberTableProps> = ({
  courseMembers,
  errors,
}) => {
  const renderRow = useCallback(
    (courseMember: CourseMemberNodeFragment, index: number) => {
      const memberInfo =
        courseMember.type === CourseMemberType.LEARNER
          ? courseMember.learner
          : courseMember.mentor
      const courseTitle = courseMember.course?.title

      const renderIcon = (error: EnrollMemberError) => {
        if (!error) {
          return (
            <Typography color="success.main">
              <CheckCircleIcon size={24} />
            </Typography>
          )
        }
        if (error === 'init') {
          return (
            <Typography color="warning.main">
              <MinusCircleIcon size={24} />
            </Typography>
          )
        }
        return (
          <Tooltip title={error.message || 'Something wrong happens'}>
            <Typography color="error">
              <WarningCircleIcon size={24} />
            </Typography>
          </Tooltip>
        )
      }

      return (
        <TableRow hover>
          <TableCell>
            <Typography noWrap variant="subtitle2">
              {memberInfo?.user?.name}
            </Typography>
          </TableCell>

          <TableCell align="left">{courseTitle}</TableCell>

          <TableCell align="center">{renderIcon(errors[index])}</TableCell>
        </TableRow>
      )
    },
    [errors],
  )

  return (
    <TableContainer>
      <Table>
        <TableBody>{courseMembers.map(renderRow)}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default EnrollMemberTable
