import type { FC } from 'react'

import { Button, Grid, Stack, Typography } from '@mui/material'
import { DiscordLogo } from '@phosphor-icons/react'
import { useSnackbar } from 'notistack'

import { useAuth } from '@core/auth'
import { MIN_PASSWORD_LENGTH } from '@core/config/validation'
import { useDisconnectDiscordMutation } from '@core/graphql'
import { ContentSectionGroup } from '@core/ui/components'

import ChangePassword from '../change-password/ChangePassword'

export type AccountSettingsProps = {}

const AccountSettings: FC<AccountSettingsProps> = () => {
  const { $user: user, refetch } = useAuth()
  const [{ fetching: disconnecting }, mutateDisconnectDiscord] =
    useDisconnectDiscordMutation()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <Stack spacing={4}>
      <ContentSectionGroup title="Email address">
        <Typography>{user.email}</Typography>
      </ContentSectionGroup>

      <ContentSectionGroup title="Connections">
        {user.discordId ? (
          <Grid container gap={2}>
            <Grid item>
              <Button disabled variant="outlined">
                <DiscordLogo size={20} style={{ marginRight: 8 }} />
                Connected
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={disconnecting}
                variant="text"
                onClick={async () => {
                  await mutateDisconnectDiscord({})
                  refetch({
                    requestPolicy: 'network-only',
                  })
                  enqueueSnackbar('Discord account disconnected', {
                    variant: 'success',
                  })
                }}
              >
                Disconnect
              </Button>
            </Grid>
          </Grid>
        ) : (
          <a href="/v2/auth/connect-discord">
            <Button variant="outlined">
              <DiscordLogo size={20} style={{ marginRight: 8 }} />
              Connect my Discord
            </Button>
          </a>
        )}
      </ContentSectionGroup>

      <ContentSectionGroup
        subtitle={`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`}
        title="Change password"
      >
        <ChangePassword />
      </ContentSectionGroup>
    </Stack>
  )
}

export default AccountSettings
