import type { FC, ReactNode } from 'react'
import { useCallback } from 'react'

import { LoadingButton } from '@mui/lab'
import { Box, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useMatch } from '@tanstack/react-location'
import { useSnackbar } from 'notistack'

import { useCancelBookingRequestMutation } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { Icon } from '@core/icons'
import { MentorSessionBookingRequestCancelConfirmDialogIcon } from '@core/icons'

import type { ConfirmFormValues } from './components/ConfirmForm'
import ConfirmForm from './components/ConfirmForm'

export type CancelConfirmDialogProps = {
  closeDialog: () => void
  refetchBookingRequest: () => void
  open: boolean
}

export const CancelConfirmDialog: FC<CancelConfirmDialogProps> = ({
  closeDialog,
  refetchBookingRequest,
  open,
}) => {
  const { t } = useLocales()
  const [
    { fetching: cancelingRequest },
    mutateCancelPendingMentorSessionBookingRequest,
  ] = useCancelBookingRequestMutation()
  const {
    params: { requestId },
  } = useMatch()

  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = useCallback(
    async (values: ConfirmFormValues) => {
      const { error } = await mutateCancelPendingMentorSessionBookingRequest({
        userRequestId: requestId,
        reason: values.reason,
      })

      if (error) {
        enqueueSnackbar(t(`error:${error.message}`), {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Update booking request successfully!', {
          variant: 'success',
        })
      }
      closeDialog()
      refetchBookingRequest()
    },
    [
      mutateCancelPendingMentorSessionBookingRequest,
      requestId,
      closeDialog,
      refetchBookingRequest,
      enqueueSnackbar,
      t,
    ],
  )

  return (
    <Dialog fullWidth open={open} onClose={closeDialog}>
      <ConfirmForm onSubmit={handleSubmit}>
        {({ fields }) => (
          <>
            <DialogTitle>
              <InfoItem
                icon={MentorSessionBookingRequestCancelConfirmDialogIcon}
              >
                Cancel Request
              </InfoItem>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <DialogContentText>
                  Are you sure you want to cancel this request?
                </DialogContentText>
                {fields}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                disabled={cancelingRequest}
                variant="text"
                onClick={closeDialog}
              >
                Go Back
              </Button>
              <LoadingButton
                loading={cancelingRequest}
                loadingPosition="center"
                type="submit"
                variant="contained"
              >
                Cancel Request
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </ConfirmForm>
    </Dialog>
  )
}

const InfoItem: FC<{ icon: Icon; children: ReactNode }> = ({
  icon: IconComponent,
  children,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconComponent size={22} />
      <Box ml={2}>{children}</Box>
    </Box>
  )
}
