import type { ReactNode } from 'react'
import { useMemo } from 'react'

import type { TimelineDotProps, TimelineItemProps } from '@mui/lab'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab'
import { Box, Typography } from '@mui/material'

import type { ActivityLog } from '@core/graphql'

import DateFormatText from '../../data-display/DateFormatText'

export type PartialActivityLog = Pick<
  ActivityLog,
  'id' | 'createdAt' | 'data' | 'activity'
>

type CommonProps<
  ActivityLogItem extends PartialActivityLog = PartialActivityLog,
> = {
  getTimelineItemProps?: (activityLog: ActivityLogItem) => TimelineItemProps
  getTimelineDotProps?: (activityLog: ActivityLogItem) => TimelineDotProps
  getContent: (activityLog: ActivityLogItem, data?: unknown) => JSX.Element
  getDescription?: (
    activityLog: ActivityLogItem,
    data?: unknown,
  ) => ActivityLogDescriptionProps | null
}

export type ActivityLogTimelineProps<
  ActivityLogItem extends PartialActivityLog = PartialActivityLog,
> = {
  activityLogs: ActivityLogItem[]
} & CommonProps<ActivityLogItem>

function ActivityLogTimeline<
  T extends PartialActivityLog = PartialActivityLog,
>({ activityLogs, ...commonProps }: ActivityLogTimelineProps<T>) {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        pb: 0,
        pl: 0,
        overflow: 'auto',
      }}
    >
      {activityLogs
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((activityLog, idx) => {
          return (
            <ActivityLogItem
              key={activityLog.id}
              activityLog={activityLog}
              isLast={idx === activityLogs.length - 1}
              {...commonProps}
            />
          )
        })}
    </Timeline>
  )
}

export type ActivityLogItemProps<
  ActivityLogItem extends PartialActivityLog = PartialActivityLog,
> = {
  activityLog: ActivityLogItem
  isLast?: boolean
} & CommonProps<ActivityLogItem>

export function ActivityLogItem<
  T extends PartialActivityLog = PartialActivityLog,
>({
  activityLog,
  isLast = false,
  getTimelineDotProps,
  getTimelineItemProps,
  getContent,
  getDescription,
}: ActivityLogItemProps<T>) {
  const logData = useMemo(() => {
    try {
      return JSON.parse(activityLog.data)
    } catch (error) {
      return null
    }
  }, [activityLog.data])

  const timelineDotProps = useMemo(() => {
    return getTimelineDotProps?.(activityLog)
  }, [activityLog, getTimelineDotProps])

  const timelineItemProps = useMemo(() => {
    return getTimelineItemProps?.(activityLog)
  }, [activityLog, getTimelineItemProps])

  const content = useMemo(() => {
    return getContent(activityLog, logData)
  }, [activityLog, getContent, logData])

  const description = useMemo(() => {
    return getDescription?.(activityLog, logData)
  }, [activityLog, getDescription, logData])

  return (
    <TimelineItem
      {...timelineItemProps}
      sx={{ pr: 0, minHeight: 'unset', ...timelineItemProps?.sx }}
    >
      <TimelineSeparator>
        <TimelineDot {...timelineDotProps} />
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        {content}
        {description && <ActivityLogDescription {...description} />}
        <Typography color="textSecondary" variant="body2">
          <DateFormatText
            addSuffix
            date={activityLog.createdAt}
            variant="distanceToNowStrict"
          />
        </Typography>
      </TimelineContent>
    </TimelineItem>
  )
}

type ActivityLogDescriptionProps = {
  title?: ReactNode
  content?: ReactNode
}

function ActivityLogDescription({
  title,
  content,
}: ActivityLogDescriptionProps) {
  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 1,
        backgroundColor: (theme) => theme.palette.background.neutral,
      }}
    >
      {title && <Typography fontWeight={500}>{title}</Typography>}
      {content && <Typography color="textSecondary">{content}</Typography>}
    </Box>
  )
}

export default ActivityLogTimeline
