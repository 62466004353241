import { withAuthGuard } from '@core/auth'
import { LOCAL_STORAGE_TOKEN } from '@core/config'
import type { Permission } from '@core/graphql'

export const authRoute = (
  element: JSX.Element,
  ...permissions: Permission[]
) => {
  // Extract token from search params
  const query = new URLSearchParams(window.location.search)
  const tokenName = query.get('token_name') || 'token'
  const token = query.get(tokenName)

  // If token is present, set it in localStorage
  if (token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token)
    // Remove token from search params
    query.delete(tokenName)
    query.delete('token_type')
    query.delete('token_name')
    // Append signed_in=true to search params
    query.set('signed_in', 'true')
    window.history.replaceState({}, '', `${window.location.pathname}?${query}`)
  }

  return withAuthGuard({ permissions, isPage: true }, element)
}
