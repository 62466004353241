import React from 'react'
import ReactDOM from 'react-dom/client'

import { initSentry } from '@core/lib/sentry'

import App from './App'

if (import.meta.env.PROD) {
  initSentry()
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
