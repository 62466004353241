import { lazy } from 'react'

import { Navigate } from '@tanstack/react-location'

import MentorSessionEventView from '@modules/mentor-session/MentorSessionEventView'
import MentorSessionEventGeneral from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventGeneral'
import MentorSessionEventMentors from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventMentors'
import LearnerProfileSettings from 'apps/learner/learner-settings/LearnerProfileSettings'
import LearnerDashboard from 'apps/learner/LearnerDashboard'
import LearnerSchedules from 'apps/learner/LearnerSchedules'
import LearnerMemberSessionsForLearner from 'apps/learner/mentor-session/LearnerMemberSessionsForLearner/LearnerMemberSessionsForLearner'
import AccountSettings from 'apps/workplace/modules/user-account/user-settings/components/account-settings/AccountSettings'
import ProfileSettings from 'containers/common/ProfileSettings.container'

import type { GenericsRoute } from '../routes.type'

const NotificationsPage = lazy(
  () => import('@core/ui/components/notification/NotificationsPage'),
)

const LearnerRoute: GenericsRoute = {
  path: 'learner',
  element: <Navigate replace to="/v2/daskalos" />,
  children: [
    {
      path: '/',
      element: <LearnerDashboard />,
    },
    {
      path: 'settings',
      element: <ProfileSettings />,
      children: [
        { path: '/', element: <LearnerProfileSettings /> },
        { path: '/availability', element: <LearnerSchedules /> },
        { path: '/security', element: <AccountSettings /> },
      ],
    },
    { path: '/notifications', element: <NotificationsPage /> },
    {
      path: '/mentor-sessions/:mentorSessionId/events/:eventId',
      element: <MentorSessionEventView viewBy="mentee" />,
      children: [
        { path: '/', element: <MentorSessionEventGeneral /> },
        { path: '/mentors', element: <MentorSessionEventMentors /> },
      ],
    },
    {
      path: '/mentor-sessions/:mentorSessionId',
      element: <LearnerMemberSessionsForLearner />,
    },
    { element: <Navigate replace to="mentor-sessions" /> },
  ],
}

export default LearnerRoute
