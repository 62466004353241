import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type { FormControlProps, SelectChangeEvent } from '@mui/material'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
} from '@mui/material'

import { useUserCalendarSelectQuery } from '@core/graphql'
import { createButtonIcon } from '@core/icons'

export type CalendarSelectProps = {
  calendarId?: string
  onChange: (calendarId: string) => void
  enableCreate?: boolean
  size?: FormControlProps['size']
  sx?: FormControlProps['sx']
  label?: string
  fullWidth?: boolean
  error?: boolean
  helperText?: ReactNode
}

const WIDTH = 240

const CalendarSelect: FC<CalendarSelectProps> = ({
  calendarId = '',
  onChange,
  enableCreate,
  size,
  sx,
  label,
  fullWidth,
  error,
  helperText,
}) => {
  const [{ fetching, data }] = useUserCalendarSelectQuery()
  const loadedRef = useRef(false)
  const [loaded, setLoaded] = useState(false)

  const userCalendars = useMemo(
    () => data?.userCalendars ?? [],
    [data?.userCalendars],
  )

  const handleChangeCalendar = useCallback(
    (event: SelectChangeEvent) => {
      const selectedValue = event.target.value
      if (selectedValue === 'create') {
        return
      }
      onChange(selectedValue)
    },
    [onChange],
  )

  useEffect(() => {
    if (loadedRef.current) {
      return
    }
    if (!fetching && userCalendars[0]) {
      onChange(userCalendars[0].calendarId)
      loadedRef.current = true
      setLoaded(true)
    }
  }, [userCalendars, fetching, onChange])

  if (fetching && !data) {
    return <Skeleton sx={{ width: WIDTH, height: 40 }} variant="rectangular" />
  }

  if (!loaded) {
    return null
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      size={size ?? 'small'}
      sx={{ width: WIDTH, ...sx }}
    >
      {label && <InputLabel id="calendar-select-label">{label}</InputLabel>}
      <Select
        label={label}
        labelId="calendar-select-label"
        value={calendarId}
        onChange={handleChangeCalendar}
      >
        {userCalendars.map((userCalendar) => (
          <MenuItem
            key={userCalendar.calendarId}
            value={userCalendar.calendarId}
          >
            {userCalendar.calendar?.name}
          </MenuItem>
        ))}
        {enableCreate && (
          <MenuItem value="create">
            <ListItemIcon sx={{ minWidth: 'unset !important', marginRight: 1 }}>
              {createButtonIcon}
            </ListItemIcon>
            <ListItemText>
              <b>Create new calendar</b>
            </ListItemText>
          </MenuItem>
        )}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default CalendarSelect
