import type { FC, ReactNode } from 'react'

import { Link } from '@tanstack/react-location'

import { LEARNER_LINKS, LINKS, MENTOR_LINKS } from '@core/config'
import { useApp } from '@core/hooks'
import type { ANY } from '@core/types'
import { buildPath } from '@core/utils/buildPath'

export type GoToLinkProps = (
  | {
      link: 'MENTOR_SESSION_VIEW'
      mentorSessionId: string
    }
  | { link: 'LEARNER_COURSE_LIST'; learnerId: string }
  | {
      link: 'MENTOR_SESSION_BOOKING_REQUEST_VIEW'
      requestId: string
    }
  | {
      link: 'JOIN_INSTANT_MEETING'
      meetingId: string
    }
) & { search?: Record<string, string> }

const UNIVERSAL_LINKS = {
  workplace: LINKS,
  mentor: MENTOR_LINKS,
  learner: LEARNER_LINKS,
}

export const useGoToLink = ({
  link,
  search,
  ...rest
}: GoToLinkProps): string | null => {
  const { app } = useApp()

  const appLink: string | undefined | null =
    app && (UNIVERSAL_LINKS as ANY)[app]?.[link]

  if (!appLink) {
    return null
  }

  return buildPath(appLink, rest, search)
}

const GoToLink: FC<GoToLinkProps & { children: ReactNode }> = ({
  children,
  ...props
}) => {
  const href = useGoToLink(props)

  if (!href) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return <Link to={href}>{children}</Link>
}

export default GoToLink
