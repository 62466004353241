import type { FC } from 'react'

import {
  alpha,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import { Link } from '@tanstack/react-location'

import type { CourseMemberListItemFragment } from '@core/graphql'
import { LearnerIcon } from '@core/icons'
import { DateFormatText, UserDisplayName } from '@core/ui/components'

export type LearnerItemProps = {
  courseMember: CourseMemberListItemFragment
  learner: NonNullable<CourseMemberListItemFragment['learner']>
}

export const learnerIcon = (
  <Box
    sx={{
      borderRadius: 100,
      p: 1,
      display: 'grid',
      placeItems: 'center',
      background: (theme) => alpha(theme.palette.learner.main, 0.1),
      color: (theme) => theme.palette.learner.dark,
    }}
  >
    <LearnerIcon size={22} weight="fill" />
  </Box>
)

const LearnerItem: FC<LearnerItemProps> = ({ courseMember, learner }) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>{learnerIcon}</Grid>
      <Grid item xs>
        <UserDisplayName
          color="learner.main"
          component={Link}
          to={`./${courseMember.id}`}
          userId={learner.userId}
          variant="subtitle1"
        />
        <Typography color="textSecondary">
          Joined{' '}
          <DateFormatText
            addSuffix
            date={courseMember.createdAt}
            variant="distanceToNowStrict"
          />
        </Typography>
      </Grid>
      <Grid item>
        {!learner.lwUser && (
          <Tooltip title="Not enrolled on LearnWorlds">
            <IconButton color="warning">
              <Warning />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  )
}

export default LearnerItem
