import type { FC } from 'react'
import { memo } from 'react'

import { Stack, Typography, useTheme } from '@mui/material'
import { ArrowRight } from '@phosphor-icons/react'
import { format } from 'date-fns'

import { useLocales } from '@core/hooks'
import type { ANY } from '@core/types'
import { UserDisplayName } from '@core/ui/components'

type MarkedEventAsFinishedActivityProps = {
  data: ANY
  userId?: string | null
}

const MarkedEventAsFinishedActivity: FC<MarkedEventAsFinishedActivityProps> = ({
  data,
  userId,
}) => {
  const theme = useTheme()
  return (
    <>
      <UserDisplayName display="inline" userId={userId} /> updated the status of
      the mentor session on{' '}
      <b>
        {data.startsAt && format(new Date(data.startsAt), 'ccc, LLL dd, yyyy')}{' '}
        {data.startsAt && format(new Date(data.startsAt), 'HH:mm')}
        {` - `}
        {data.endsAt && format(new Date(data.endsAt), 'HH:mm a')}
      </b>
      .
      <Stack alignItems="center" direction="row" maxWidth="100%" spacing={1.2}>
        <StatusBox status={data.prevStatus} title="Before" />
        <ArrowRight color={theme.palette.text.secondary} fontSize="1.6rem" />
        <StatusBox status={data.newStatus} title="After" />
      </Stack>
    </>
  )
}

const StatusBox: FC<{ status: ANY; title: string }> = ({ status, title }) => {
  const { t } = useLocales('mentor_session')
  return (
    <Stack
      sx={{
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 1,
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.neutral,
      }}
    >
      <Typography fontSize="0.875rem" fontWeight={600}>
        {title}
      </Typography>

      <Typography color="textSecondary" fontSize="0.875rem" fontWeight={400}>
        {t(status)}
      </Typography>
    </Stack>
  )
}

export default memo(MarkedEventAsFinishedActivity)
