import { omit } from 'lodash'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import createSelectors from '@core/stores/createSelectors'

import { DEFAULT_VOLUME } from './VideoPlayer.const'

const VIDEO_PLAYER_STORAGE_NAME = 'video-player'

export type VideoPlayerState = {
  /** Id of the target entity which the video belongs to
   * (e.g. Mentor session event,...)
   */
  targetId: string | null

  /** Index of the current video  */
  currentVideoIndex: number

  /** Whether the player is being played */
  playing: boolean

  /** Whether the current video in the player is being buffered */
  buffering: boolean

  /** Whether the player is muted */
  muted: boolean

  /** Volume of the player */
  volume: number

  /** Playback rate */
  playbackRate: number

  /** Whether player is in fullscreen mode */
  fullscreen: boolean

  /** Total played seconds of all videos */
  totalPlayedSeconds: number

  /** Total duration of all videos */
  totalDuration: number

  /** Total duration of all videos before the current video */
  totalDurationBeforeCurrentVideo: number

  /** Duration of each video in order */
  durationPerVideo: { url: string; duration: number }[]

  /** Whether to show media controller */
  showController: boolean

  /** Whether to any sub-menu is active */
  subMenuActive: boolean
}

const DEFAULT_VIDEO_STATE: VideoPlayerState = {
  targetId: null,
  currentVideoIndex: 0,
  playing: false,
  buffering: false,
  muted: false,
  playbackRate: 1,
  fullscreen: false,
  volume: DEFAULT_VOLUME,
  totalPlayedSeconds: 0,
  totalDuration: 0,
  totalDurationBeforeCurrentVideo: 0,
  durationPerVideo: [],
  showController: true,
  subMenuActive: false,
}

export type VideoPlayerStore = {
  playerState: VideoPlayerState
  updatePlayerState: (state: Partial<VideoPlayerState>) => void
  resetPlayerState: VoidFunction
}

export const useVideoPlayerStoreBase = create<VideoPlayerStore>()(
  persist(
    (set) => ({
      playerState: DEFAULT_VIDEO_STATE,
      /** Update video state */
      updatePlayerState: (state) =>
        set((prevState) => ({
          playerState: { ...prevState.playerState, ...state },
        })),
      /** Reset video state */
      resetPlayerState: () =>
        set((prevState) => ({
          playerState: {
            ...prevState.playerState,
            ...omit(DEFAULT_VIDEO_STATE, [
              // Preserve user choice of muted and volume
              'muted',
              'volume',
            ]),
          },
        })),
    }),
    { name: VIDEO_PLAYER_STORAGE_NAME },
  ),
)

export const useVideoPlayerStore = createSelectors(useVideoPlayerStoreBase)
