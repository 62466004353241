import { Gender } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { SchemaOf } from '@core/lib/yup'
import { date, mixed, number, object, string } from '@core/lib/yup'

import type { LearnerFormValues } from './LearnerForm'

export const useLearnerFormValidationSchema =
  (): SchemaOf<LearnerFormValues> => {
    const { t } = useLocales('learner')

    return object({
      address: string().label(t('address')).trim().notRequired(),
      discordId: string().label(t('discord_id')).trim().notRequired(),
      dateOfBirth: date().label(t('date_of_birth')).nullable().notRequired(),
      englishLevel: string().label(t('english_level')).trim().notRequired(),
      expYears: mixed()
        .label(t('exp_years'))
        .oneOf(['', ...LearnerExpYears], t('invalid_exp_years_value'))
        .notRequired(),
      gender: mixed()
        .label(t('gender'))
        .oneOf(Object.keys(Gender), t('invalid_gender_value'))
        .transform((value) => value || undefined)
        .required(),
      industry: mixed()
        .label(t('industry'))
        .oneOf(['', ...LearnerIndustry], t('invalid_industry_value'))
        .notRequired(),
      jobTitle: string().label(t('job_title')).trim().notRequired(),
      learnerCode: number()
        .integer()
        .label(t('learner_code'))
        .positive()
        .notRequired(),
      linkedinURL: string().label(t('linkedin_url')).url().trim().notRequired(),
      organization: string().label(t('organization')).trim().notRequired(),
      priorCodingCourses: string()
        .label(t('prior_coding_courses'))
        .trim()
        .notRequired(),
      nationalIdIssuedDate: date()
        .label(t('national_id_issued_date'))
        .notRequired(),
      nationalIdIssuedPlace: string()
        .label(t('national_id_issued_place'))
        .trim()
        .notRequired(),
      nationalIdNumber: string()
        .label(t('national_id_number'))
        .trim()
        .notRequired(),
      notes: string().label(t('notes')).trim().notRequired(),
      resumeURL: string().label(t('resume_url')).url().trim().notRequired(),
      secondaryEmail: string()
        .label(t('secondary_email'))
        .email()
        .lowercase()
        .trim()
        .notRequired(),
      secondaryPhoneNumber: string()
        .label(t('secondary_phone_number'))
        .trim()
        .notRequired(),
      workingStatus: mixed()
        .label(t('working_status'))
        .oneOf(['', ...LearnerWorkingStatus], t('invalid_working_status_value'))
        .notRequired(),
    })
  }

export const LearnerIndustry = [
  'Accounting',
  'Finance, banking',
  'Marketing',
  'Tourism & Hospitality',
  'Engineering',
  'Data Science',
  'HR',
  'Biotech',
  'Insurance',
  'Hospital & Health Care',
  'Education',
  'Business Administration',
  'Stock',
  'Agriculture, forestry, and fishing',
  'Communication & Advertising',
  'IT & Computer Software',
  'Mining',
  'Food & Beverage',
  'Manufacturing',
  'Retail trade',
  'Wholesale trade',
  'Logistics & Supply chain',
  'Construction & Real Estate',
  'Public administration',
  'Transportation and public utilities',
  'Professional Services',
  'Other',
]

export const LearnerWorkingStatus = [
  'Full-time',
  'Part-time',
  'Freelancer',
  'Unemployed',
  'High-school student',
  'University/College student (1st to 3rd year)',
  'University/College student (Final year)',
  'Post graduate',
  'Other',
]

export const LearnerExpYears = [
  'No experience',
  'Under 3 years',
  'Under 5 years',
  'Under 10 years',
  'Over 10 years',
]
