import type { FC } from 'react'

import type { BoxProps, TypographyProps } from '@mui/material'
import { Box, Stack, Typography } from '@mui/material'
import { Clock } from '@phosphor-icons/react'
import { format } from 'date-fns'

import { CoursesIcon, LearnerIcon, MentorIcon } from '@core/icons'
import { ContentIconSection, UserDisplayName } from '@core/ui/components'

export type MentorSessionEventOverviewProps = {
  courseTitle: string
  learnerUserIds: string[]
  mentorUserIds: string[]
  startsAt: Date
  endsAt: Date
  sx?: BoxProps['sx']
  typographyVariant?: TypographyProps['variant']
}

const MentorSessionEventOverview: FC<MentorSessionEventOverviewProps> = ({
  courseTitle,
  endsAt,
  learnerUserIds,
  mentorUserIds,
  startsAt,
  sx,
  typographyVariant = 'body2',
}) => {
  return (
    <Box sx={sx}>
      <Stack spacing={1.5}>
        <ContentIconSection icon={<CoursesIcon />}>
          <Typography variant={typographyVariant}>{courseTitle}</Typography>
        </ContentIconSection>
        <ContentIconSection
          alignItems="flex-start"
          icon={<LearnerIcon style={{ marginTop: '3px' }} />}
        >
          {learnerUserIds.length === 0 ? (
            'No learners'
          ) : (
            <Box>
              {learnerUserIds.map((userId) => (
                <UserDisplayName
                  key={userId}
                  color="textPrimary"
                  fontWeight="normal"
                  userId={userId}
                  variant={typographyVariant}
                />
              ))}
            </Box>
          )}
        </ContentIconSection>
        <ContentIconSection
          alignItems="flex-start"
          icon={<MentorIcon style={{ marginTop: '3px' }} />}
        >
          {mentorUserIds.length === 0 ? (
            'No mentors'
          ) : (
            <Box>
              {mentorUserIds.map((userId) => (
                <UserDisplayName
                  key={userId}
                  color="textPrimary"
                  fontWeight="normal"
                  userId={userId}
                  variant={typographyVariant}
                />
              ))}
            </Box>
          )}
        </ContentIconSection>
        <ContentIconSection icon={<Clock />}>
          <Typography variant={typographyVariant}>
            {format(startsAt, 'EEEE, MMM dd, yyyy HH:mm')} –{' '}
            {format(endsAt, 'HH:mm a')}
          </Typography>
        </ContentIconSection>
      </Stack>
    </Box>
  )
}

export default MentorSessionEventOverview
