import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import type { ChipProps } from '@mui/material'
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Chip,
  Box,
  alpha,
} from '@mui/material'
import { CircleHalf, CalendarBlank, Timer } from '@phosphor-icons/react'
import { Link } from '@tanstack/react-location'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { rrulestr } from 'rrule'

import { LINKS } from '@core/config'
import { DANGER_TIME_BREAKPOINT } from '@core/config/mentor-session'
import type { BookingRequestViewFragment } from '@core/graphql'
import { UserRequestStatus } from '@core/graphql'
import type { Icon } from '@core/icons'
import { CoursesIcon, LearnerIcon, MentorIcon } from '@core/icons'
import { Countdown, UserDisplayName } from '@core/ui/components'
import { buildPath } from '@core/utils/buildPath'
import { fCountDown } from '@core/utils/formatTime'

const statusColorMap: Record<UserRequestStatus, ChipProps['color']> = {
  PENDING: 'info',
  ACCEPTED: 'success',
  REJECTED: 'error',
  CANCELED: 'error',
  EXPIRED: 'warning',
}

export type GeneralInfoProps = {
  request: BookingRequestViewFragment
}

const GeneralInfo: FC<GeneralInfoProps> = ({ request }) => {
  const sessionEndDate =
    request.recurrence && rrulestr(request.recurrence).options.until

  const statusColor = useMemo(
    () => statusColorMap[request.status],
    [request.status],
  )

  return (
    <Card>
      <CardHeader title="General" />
      <CardContent>
        <Stack spacing={2}>
          <InfoItem icon={CircleHalf}>
            <Stack direction="row" spacing={1}>
              <Chip
                color={statusColor}
                label={capitalize(request.status)}
                variant="filled"
                sx={{
                  fontWeight: 500,
                  fontSize: (theme) => theme.typography.body2.fontSize,
                  borderRadius: 1,
                  color: (theme) => theme.palette.common.white,
                }}
              />
              {request.status === UserRequestStatus.PENDING && (
                <Countdown
                  countTo={new Date(request.expiresOn)}
                  render={({
                    secondsLeft,
                    duration: { days, hours, minutes, seconds },
                  }) => (
                    <Chip
                      variant="outlined"
                      label={
                        <>
                          {!!days && `${days}d`} {fCountDown(hours)}:
                          {fCountDown(minutes)}:{fCountDown(seconds)}
                        </>
                      }
                      sx={{
                        fontWeight: 500,
                        fontSize: (theme) => theme.typography.body2.fontSize,
                        borderRadius: 1,
                        borderColor: 'currentcolor',
                        minWidth: 105,

                        ...(secondsLeft <= DANGER_TIME_BREAKPOINT
                          ? {
                              color: 'error.main',
                              backgroundColor: (theme) =>
                                alpha(theme.palette.error.main, 0.05),
                            }
                          : {}),
                      }}
                    />
                  )}
                />
              )}
            </Stack>
          </InfoItem>

          <InfoItem icon={CoursesIcon}>
            <Typography
              component={Link}
              fontWeight={500}
              to={buildPath(LINKS.VIEW_COURSE, { courseId: request.course.id })}
            >
              {request.course.title}
            </Typography>
          </InfoItem>

          <InfoItem icon={MentorIcon}>
            <UserDisplayName
              component={Link}
              userId={request.mentors[0].user?.id}
              to={buildPath(LINKS.MENTOR_PROFILE, {
                mentorId: request.mentors[0].id,
              })}
            />
          </InfoItem>

          <InfoItem icon={LearnerIcon}>
            <UserDisplayName
              component={Link}
              userId={request.learners[0].user?.id}
              to={buildPath(LINKS.LEARNER_PROFILE, {
                learnerId: request.learners[0].id,
              })}
            />
          </InfoItem>

          <InfoItem icon={CalendarBlank}>
            <Typography>
              {format(request.startsAt, 'EEE')},{' '}
              {format(request.startsAt, 'h:mm')} –{' '}
              {format(request.endsAt, 'h:mm a')}
            </Typography>
          </InfoItem>

          {sessionEndDate && (
            <InfoItem icon={Timer}>
              <Typography>Ends on {format(sessionEndDate, 'PPP')}</Typography>
            </InfoItem>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

const InfoItem: FC<{ icon: Icon; children: ReactNode }> = ({
  icon: IconComponent,
  children,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconComponent size={22} style={{ flexShrink: '0' }} />
      <Box ml={2} overflow="hidden" sx={{ flexGrow: '0' }}>
        {children}
      </Box>
    </Box>
  )
}

export default GeneralInfo
