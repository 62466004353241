import type { Theme } from '@mui/material/styles'

// ----------------------------------------------------------------------

export default function Breadcrumbs(theme: Theme) {
  return {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
        },
      },
    },
  }
}
