/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type { ConnectionPageInfoFragment, Maybe } from '@core/graphql'

import { useDeepCompareMemoize } from './useDeepCompareMemoize'

type Cursor = Maybe<string>
type PaginationArgs = {
  first?: number
  last?: number
  before?: Cursor
  after?: Cursor
}

const usePagination = <Variables extends object = {}>(options?: {
  limit?: number
  variables?: Variables
}) => {
  const { limit = 10, variables } = options ?? {}

  const startCursor = useRef<Cursor | undefined>(undefined)
  const endCursor = useRef<Cursor | undefined>(undefined)

  const [paginationArgs, setPaginationArgs] = useState<PaginationArgs>({
    first: limit,
  })
  const [pageInfo, setPageInfo] = useState<ConnectionPageInfoFragment>()

  const [fetchingNext, setFetchingNext] = useState(false)
  const [fetchingPrevious, setFetchingPrevious] = useState(false)

  const onResponse = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (pageInfo?: ConnectionPageInfoFragment) => {
      setFetchingNext(false)
      setFetchingPrevious(false)

      if (!pageInfo) {
        return
      }

      startCursor.current = pageInfo.startCursor
      endCursor.current = pageInfo.endCursor

      setPageInfo(pageInfo)
    },
    [],
  )

  const nextPage = useCallback(() => {
    if (paginationArgs.after === endCursor.current) {
      return
    }
    setPaginationArgs({ after: endCursor.current, first: limit })
    setFetchingNext(true)
  }, [limit, paginationArgs.after])

  const previousPage = useCallback(() => {
    if (paginationArgs.before === startCursor.current) {
      return
    }

    setPaginationArgs({ before: startCursor.current, last: limit })
    setFetchingPrevious(true)
  }, [limit, paginationArgs.before])

  const paginationVariables: PaginationArgs & (Variables | {}) = useMemo(
    () => ({
      ...paginationArgs,
      ...variables,
    }),
    [useDeepCompareMemoize(paginationArgs), useDeepCompareMemoize(variables)],
  )

  // Reset pagination on variables change
  useEffect(() => {
    setPaginationArgs({ first: limit })
  }, [limit, useDeepCompareMemoize(variables)])

  return {
    /** @deprecated Use variables to prevent weird caching */
    paginationArgs,
    onResponse,
    nextPage,
    previousPage,
    fetchingNext,
    fetchingPrevious,
    variables: paginationVariables as PaginationArgs & (Variables | {}),
    ...pageInfo,
  }
}

export default usePagination
