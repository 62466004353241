import type { FC } from 'react'

import type { SxProps } from '@mui/material'
import { Box, CircularProgress, Fade } from '@mui/material'

import type { ANY } from '@core/types'

export type SpinnerProps = {
  mt?: number
  mb?: number
  m?: number
  sx?: SxProps
  size?: string | number
  variant?: 'centered' | 'overlay' | 'fullscreen'
}

const sizeMap = { small: 24, medium: 40, large: 64 }

const Spinner: FC<SpinnerProps> = ({
  sx,
  m,
  mt,
  mb,
  size: sizeProp,
  variant,
}) => {
  const sxProp: SxProps = { m, mb, mt, ...sx }
  const size = sizeProp ? (sizeMap as ANY)[sizeProp] ?? sizeProp : sizeProp
  const spinnerEl = <CircularProgress size={size} />

  if (variant === 'centered') {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', ...sxProp }}>
        {spinnerEl}
      </Box>
    )
  }

  if (variant === 'overlay') {
    return (
      <Box
        sx={(theme) => ({
          display: 'grid',
          placeItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: theme.zIndex.modal + 1,
          backgroundColor:
            theme.palette.mode === 'light'
              ? 'rgba(255,255,255,0.7)'
              : 'rgba(0,0,0,0.7)',
        })}
      >
        {spinnerEl}
      </Box>
    )
  }

  if (variant === 'fullscreen') {
    return (
      <Box
        sx={(theme) => ({
          display: 'grid',
          placeItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: theme.zIndex.modal + 1,
          backgroundColor:
            theme.palette.mode === 'light'
              ? 'rgba(255,255,255,0.7)'
              : 'rgba(0,0,0,0.7)',
        })}
      >
        {spinnerEl}
      </Box>
    )
  }

  return (
    <Fade
      in
      unmountOnExit
      style={{
        transitionDelay: '800ms',
      }}
    >
      {spinnerEl}
    </Fade>
  )
}

export default Spinner
