/* eslint-disable no-unused-vars */
import type { ReactNode } from 'react'
import { useMemo } from 'react'

import { CssBaseline } from '@mui/material'
import type { ThemeOptions } from '@mui/material/styles'
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import breakpoints from './breakpoints'
import componentsOverride from './overrides'
import palette from './palette'
import shadows, { customShadows } from './shadows'
import typography from './typography'

type Props = {
  children: ReactNode
  dark?: boolean
}

export default function ThemeProvider({ children, dark }: Props) {
  // const [darkMode, setDarkMode] = useState(
  //   dark ?? window.matchMedia('(prefers-color-scheme:dark)').matches,
  // )

  const darkMode = false

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: darkMode ? palette.dark : palette.light,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: 'ltr',
      shadows: darkMode ? shadows.light : shadows.dark,
      customShadows: darkMode ? customShadows.light : customShadows.dark,
    }),
    [darkMode],
  )

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  // useEffect(() => {
  //   window
  //     .matchMedia('(prefers-color-scheme: dark)')
  //     .addEventListener('change', (event) => {
  //       setDarkMode(event.matches)
  //     })
  // }, [])

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {children}
        </LocalizationProvider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  )
}
