import type { FC } from 'react'

import { Alert, TextField } from '@mui/material'
import type { FieldError } from 'react-hook-form'

import type { ANY } from '@core/types'

import { FormFieldType } from '../types'

import Checkboxes from './Checkboxes'
import LinearScale from './LinearScale'
import MultiChoice from './MultiChoice'
import MultiChoiceGrid from './MultiChoiceGrid'

export type FieldInputProps = {
  fieldType: FormFieldType
  value: ANY
  config?: ANY
  onChange: (value: ANY) => void
  disabled?: boolean
  error?: FieldError
}

const FieldInput: FC<FieldInputProps> = ({
  fieldType,
  value,
  onChange,
  config,
  disabled,
  error,
}) => {
  const fieldProps = {
    disabled,
    value,
    onChange,
    error,
  }

  if (fieldType === FormFieldType.LINEAR_SCALE) {
    return (
      <LinearScale
        from={config.from}
        labelMax={config.labelMax}
        labelMin={config.labelMin}
        to={config.to}
        {...fieldProps}
      />
    )
  }

  if (fieldType === FormFieldType.TEXT) {
    const { multiline, placeholder } = config
    const tfProps = {
      multiline,
      minRows: multiline ? 3 : undefined,
      maxRows: multiline ? 6 : undefined,
    }
    return (
      <TextField
        fullWidth
        placeholder={placeholder}
        {...tfProps}
        disabled={disabled}
        error={!!error}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    )
  }

  if (fieldType === FormFieldType.MULTIPLE_CHOICE) {
    return (
      <MultiChoice
        options={config.options}
        withOther={config.withOther}
        {...fieldProps}
      />
    )
  }

  if (fieldType === FormFieldType.CHECKBOXES) {
    return (
      <Checkboxes
        options={config.options}
        withOther={config.withOther}
        {...fieldProps}
      />
    )
  }

  if (fieldType === FormFieldType.MULTI_CHOICE_GRID) {
    return (
      <MultiChoiceGrid
        columns={config.columns}
        rows={config.rows}
        {...fieldProps}
      />
    )
  }

  return <Alert severity="error">{fieldType} unsupported</Alert>
}

export default FieldInput
