/* eslint-disable react/no-unstable-nested-components */
import type { ReactNode } from 'react'

import type { SelectProps } from '@mui/material'
import { MenuItem, Select } from '@mui/material'

import type { ANY } from '@core/types'

import InlineEditable from './InlineEditable'

export type InlineEditableSelectProps<Value extends unknown> = {
  options: { label: string; value: Value }[]
  value: Value
  isUpdating?: boolean
  onSubmit: (value: Value) => void | Promise<void>
  label?: string
  readView: ReactNode
  size?: SelectProps['size']
  editable?: boolean
}

export function InlineEditableSelect<Value extends unknown>({
  value,
  options,
  isUpdating,
  onSubmit,
  label,
  readView,
  size,
  editable,
}: InlineEditableSelectProps<Value>) {
  return (
    <InlineEditable
      editable={editable}
      isUpdating={isUpdating}
      label={label}
      readView={readView}
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      editView={({ onChange, value, isUpdating }) => (
        <Select
          fullWidth
          MenuProps={{ sx: { maxHeight: 300 } }}
          disabled={isUpdating}
          id="select-type"
          label=""
          labelId="select-type-label"
          size={size}
          sx={{ mt: 1 }}
          value={value}
          onChange={(event) => {
            onChange(event.target.value as Value)
          }}
        >
          {!!value &&
            !options
              .map((option) => {
                return option.value
              })
              .includes(value) && (
              <MenuItem
                key={value as ANY}
                disabled
                value={value as ANY}
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                {value as ANY}
              </MenuItem>
            )}
          {options.map((option) => (
            <MenuItem key={option.label} value={option.value as ANY}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      onSubmit={onSubmit}
    />
  )
}
