import type { ReactNode } from 'react'

import type { FieldValues, UseFormReturn } from 'react-hook-form'
import { FormProvider as Form } from 'react-hook-form'

type Props<FormValues extends FieldValues> = {
  children: ReactNode
  methods: UseFormReturn<FormValues>
}

export function FormProvider<FormValues extends FieldValues>({
  children,
  methods,
}: Props<FormValues>) {
  return <Form {...methods}>{children}</Form>
}
