import type { FC } from 'react'

import { MenuItem, Select, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'

export type DurationSelectProps = {}

const DurationSelect: FC<DurationSelectProps> = () => {
  const { watch, setValue } = useFormContext<BookMentorSessionValues>()

  const { duration } = watch('time')

  return (
    <Select
      value={duration}
      MenuProps={{
        disableScrollLock: true,
      }}
      renderValue={(v) => (
        <>
          Duration:{' '}
          <Box
            sx={{
              display: 'inline-block',
              fontWeight: 600,
            }}
          >
            {v} mins
          </Box>
        </>
      )}
      sx={{
        fontSize: 14,
        height: 32,
        minWidth: 110,
      }}
    >
      {[60, 90, 120].map((d) => (
        <MenuItem
          key={d}
          value={d}
          sx={{
            fontSize: 14,
          }}
          onClick={() => setValue('time.duration', d)}
        >
          {d} mins
        </MenuItem>
      ))}
    </Select>
  )
}

export default DurationSelect
