import type { ANY } from '@core/types'

import type { LearnerMemberSessionsState } from './LearnerMemberSessions.type'
import { LearnerMemberSessionsReducerActionType } from './LearnerMemberSessions.type'

const { OPEN_DIALOG, CLOSE_DIALOG } = LearnerMemberSessionsReducerActionType

export const learnerMemberSessionsReducer = (
  state: LearnerMemberSessionsState,
  action: {
    type: LearnerMemberSessionsReducerActionType
    payload?: ANY
  },
): LearnerMemberSessionsState => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        dialogIsOpen: true,
        selectedActionType: action.payload.actionType,
        dialogTargetId: action.payload.dialogTargetId,
      }
    case CLOSE_DIALOG:
      return {
        ...state,
        dialogIsOpen: false,
        selectedActionType: undefined,
      }
    default:
      return state
  }
}
