import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import { CalendarX } from '@phosphor-icons/react'
import { compact } from 'lodash'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import type { SchemaOf } from 'yup/lib'
import { object, string } from 'yup/lib'

import type { MentorSessionViewFragment } from '@core/graphql'
import {
  CourseMemberType,
  useCancelSessionEventForLearnerMutation,
} from '@core/graphql'
import { useYupForm } from '@core/helpers'
import { useLocales } from '@core/hooks'
import type { ANY } from '@core/types'
import { UrqlError } from '@core/ui/components'
import { RHFTextField } from '@core/ui/components/hook-form'
import type { SessionCalendarEvent } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'
import MentorSessionEventOverview from 'apps/workplace/modules/mentor-session/pages/LearnerMemberSessionsForStaff/components/MentorSessionEventOverview'

export type CancelSessionEventForLearnerDialogProps = {
  mentorSession?: MentorSessionViewFragment
  sessionEvent?: SessionCalendarEvent
  isOpen: boolean
  hasPendingRescheduleRequest?: boolean
  onClose?: VoidFunction
  onRedirect?: VoidFunction
}

type CancelSessionValues = {
  cancelReason?: string
}

const validationSchema: SchemaOf<CancelSessionValues> = object({
  cancelReason: string().label('Notes').required(),
})

const CancelSessionEventForLearnerDialog: FC<
  CancelSessionEventForLearnerDialogProps
> = ({
  isOpen,
  hasPendingRescheduleRequest,
  onClose,
  mentorSession,
  sessionEvent,
  onRedirect,
}) => {
  const { t } = useLocales('error')
  const { getFieldProps, resolver } = useYupForm(validationSchema)
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<CancelSessionValues>({
    resolver,
    defaultValues: {
      cancelReason: '',
    },
  })
  const [{ fetching }, mutateCancel] = useCancelSessionEventForLearnerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleCancel = useCallback(
    async (values: CancelSessionValues) => {
      if (!sessionEvent) {
        return
      }

      const { error } = await mutateCancel({
        id: sessionEvent.id,
        cancelReason: values.cancelReason,
      })

      if (error) {
        setError('root', {
          message: t(
            error.message,
            import.meta.env.PROD
              ? 'Something went wrong. Please try again or contact us for support.'
              : error.message,
          ),
        })
        return
      }

      onClose?.()
      enqueueSnackbar('Session canceled successfully', { variant: 'success' })
    },
    [enqueueSnackbar, mutateCancel, onClose, sessionEvent, setError, t],
  )

  const learnerUserIds = useMemo(() => {
    return compact(
      (
        mentorSession?.courseMembers.filter(
          (member) => member.type === CourseMemberType.LEARNER,
        ) ?? []
      ).map((member) => member.userId),
    )
  }, [mentorSession?.courseMembers])

  const mentorUserIds = useMemo(() => {
    return compact(
      (
        mentorSession?.courseMembers.filter(
          (member) => member.type === CourseMemberType.MENTOR,
        ) ?? []
      ).map((member) => member.userId),
    )
  }, [mentorSession?.courseMembers])

  if (!(sessionEvent && mentorSession)) {
    return null
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Stack alignItems="center" direction="row" spacing={1}>
          <CalendarX size="1.5rem" />
          <Box>Cancel Session?</Box>
        </Stack>
      </DialogTitle>

      <form onSubmit={handleSubmit(handleCancel)}>
        <DialogContent>
          {onRedirect && (
            <Alert
              severity="warning"
              variant="outlined"
              sx={{
                mb: 2,
                backgroundColor: 'background.paper',
                '& .MuiAlert-message': {
                  overflow: 'visible',
                },
              }}
            >
              <Typography sx={{ fontSize: 'inherit' }}>
                If you and your mentor have decided on another time to meet,
                please reschedule the session instead.
              </Typography>

              <Button
                color="primary"
                size="small"
                variant="text"
                sx={{
                  mt: 0.5,
                  ml: -0.6,
                }}
                onClick={onRedirect}
              >
                Reschedule
              </Button>
            </Alert>
          )}
          <Stack spacing={2}>
            <Typography>
              Are you sure you want to cancel this mentor session?
            </Typography>

            <MentorSessionEventOverview
              courseTitle={mentorSession.course?.title ?? 'N/A'}
              endsAt={sessionEvent.endsAt}
              learnerUserIds={learnerUserIds}
              mentorUserIds={mentorUserIds}
              startsAt={sessionEvent.startsAt}
              sx={{
                bgcolor: 'background.neutral',
                borderRadius: 1,
                px: 2,
                py: 1,
              }}
            />

            <RHFTextField
              multiline
              control={control}
              disabled={fetching}
              maxRows={4}
              minRows={2}
              {...getFieldProps('cancelReason')}
            />

            {hasPendingRescheduleRequest && (
              <Alert
                severity="warning"
                sx={{ backgroundColor: 'background.paper' }}
                variant="outlined"
              >
                This session has a pending reschedule request. By canceling this
                session, the reschedule request will also be canceled.
              </Alert>
            )}

            {errors.root && <UrqlError error={errors.root as ANY} />}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" disabled={fetching} onClick={onClose}>
            Nevermind
          </Button>
          <LoadingButton
            loading={fetching}
            loadingPosition="center"
            type="submit"
            variant="contained"
          >
            Cancel session
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default CancelSessionEventForLearnerDialog
