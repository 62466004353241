import type { FC } from 'react'
import { useEffect } from 'react'

import { useAuth } from '@core/auth'
import { LOCAL_STORAGE_TOKEN, LOCAL_STORAGE_USER_ID } from '@core/config'
import { SplashScreen } from '@core/ui/components'

export type SignOutProps = {}

const SignOut: FC<SignOutProps> = () => {
  const { signOut } = useAuth()
  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_USER_ID)
    localStorage.removeItem(LOCAL_STORAGE_TOKEN)
  }, [])
  signOut()
  return <SplashScreen />
}
export default SignOut
