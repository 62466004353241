import type { FC } from 'react'
import { useMemo, useReducer } from 'react'

import type {
  CombinedMentorSessionActionType,
  LearnerMemberSessionProviderProps,
  LearnerMemberSessionsContextValue,
} from './LearnerMemberSessions.type'
import { LearnerMemberSessionsReducerActionType } from './LearnerMemberSessions.type'
import LearnerMemberSessionsContext, {
  sessionContextInitialState,
} from './LearnerMemberSessionsContext'
import { learnerMemberSessionsReducer } from './LearnerMemberSessionsReducer'

const LearnerMemberSessionsProvider: FC<LearnerMemberSessionProviderProps> = ({
  mentorSession,
  sessionSecondaryAction,
  eventMenuActions,
  sessionMenuActions,
  eventSecondaryAction,
  needsAction,
  children,
  refetchMentorSession,
}) => {
  const [state, dispatch] = useReducer(
    learnerMemberSessionsReducer,
    sessionContextInitialState,
  )

  const openDialog = (args: {
    actionType: CombinedMentorSessionActionType
    dialogTargetId?: string
  }) => {
    dispatch({
      type: LearnerMemberSessionsReducerActionType.OPEN_DIALOG,
      payload: args,
    })
  }

  const closeDialog = () => {
    dispatch({ type: LearnerMemberSessionsReducerActionType.CLOSE_DIALOG })
  }

  const learnerMemberSessionsContextValue: LearnerMemberSessionsContextValue =
    useMemo(
      () => ({
        state: { ...state, mentorSession },
        openDialog,
        closeDialog,
        refetchMentorSession,
        sessionSecondaryAction,
        eventMenuActions,
        sessionMenuActions,
        eventSecondaryAction,
        needsAction,
      }),
      [
        state,
        mentorSession,
        refetchMentorSession,
        sessionSecondaryAction,
        eventMenuActions,
        sessionMenuActions,
        eventSecondaryAction,
        needsAction,
      ],
    )

  return (
    <LearnerMemberSessionsContext.Provider
      value={learnerMemberSessionsContextValue}
    >
      {children}
    </LearnerMemberSessionsContext.Provider>
  )
}

export default LearnerMemberSessionsProvider
