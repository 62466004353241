import type { FC } from 'react'

import { Stack, Typography, useTheme } from '@mui/material'
import { ArrowRight, X } from '@phosphor-icons/react'
import { format } from 'date-fns'

import type { ANY } from '@core/types'

export type RescheduleActivityProps = {
  data: ANY
  size?: 'small' | 'medium'
  isAccepted?: boolean
}

const RescheduleActivity: FC<RescheduleActivityProps> = ({
  data,
  size = 'medium',
  isAccepted = true,
}) => {
  const theme = useTheme()

  const isSmall = size === 'small'

  const { prevEndsAt, prevStartsAt, newEndsAt, newStartsAt } = data

  if (!prevEndsAt || !prevStartsAt || !newEndsAt || !newStartsAt) {
    return null
  }

  return (
    <Stack
      alignItems="center"
      direction="row"
      maxWidth="100%"
      spacing={isSmall ? 1 : 1.2}
    >
      <DateTimeBox
        endDate={prevEndsAt}
        isSmall={isSmall}
        startDate={prevStartsAt}
      />
      {isAccepted ? (
        <ArrowRight
          color={theme.palette.text.secondary}
          fontSize={isSmall ? '1.4rem' : '1.6rem'}
        />
      ) : (
        <X
          color={theme.palette.text.secondary}
          fontSize={isSmall ? '1.4rem' : '1.6rem'}
        />
      )}
      <DateTimeBox
        endDate={newEndsAt}
        isSmall={isSmall}
        startDate={newStartsAt}
      />
    </Stack>
  )
}

const DateTimeBox: FC<{
  startDate: ANY
  endDate: ANY
  isSmall: boolean
}> = ({ startDate, endDate, isSmall }) => {
  return (
    <Stack
      sx={{
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 1,
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.neutral,
      }}
    >
      <Typography
        color="textSecondary"
        fontSize={isSmall ? '0.875rem' : '1rem'}
        fontWeight={600}
      >
        {format(new Date(startDate), 'ccc, LLL dd, yyyy')}
      </Typography>
      <Typography
        color="textSecondary"
        fontSize={isSmall ? '0.875rem' : '1rem'}
        fontWeight={400}
      >
        {format(new Date(startDate), 'HH:mm a')}
        {' - '}
        {format(new Date(endDate), 'HH:mm a')}
      </Typography>
    </Stack>
  )
}

export default RescheduleActivity
