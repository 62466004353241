import type { ReactNode } from 'react'
import { type FC } from 'react'

import { Stack } from '@mui/material'

import BotContainer from './BotContainer'
import MidContainer from './MidContainer'
import TopContainer from './TopContainer'

export type MediaControllerProps = {
  title?: string | ReactNode
  onPlayPause?: VoidFunction
  onSeek?: (seconds: number) => void
  onVolumeChange?: (value: number) => void
  onPlaybackRateChange?: (value: number) => void
  onFullScreen?: VoidFunction
  onForward?: VoidFunction
  onBackward?: VoidFunction
  onMute?: VoidFunction
  onClose?: VoidFunction
}

const MediaController: FC<MediaControllerProps> = ({
  title,
  onPlayPause,
  onSeek,
  onFullScreen,
  onForward,
  onBackward,
  onVolumeChange,
  onMute,
  onClose,
  onPlaybackRateChange,
}) => {
  return (
    <Stack
      maxWidth="100%"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1,
        justifyContent: 'space-between',
      }}
    >
      <TopContainer title={title} onClose={onClose} />

      <MidContainer onPlayPause={onPlayPause} />

      <BotContainer
        onBackward={onBackward}
        onForward={onForward}
        onFullScreen={onFullScreen}
        onMute={onMute}
        onPlayPause={onPlayPause}
        onPlaybackRateChange={onPlaybackRateChange}
        onSeek={onSeek}
        onVolumeChange={onVolumeChange}
      />
    </Stack>
  )
}

export default MediaController
