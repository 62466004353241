import type { FC } from 'react'
import { useMemo } from 'react'

import { Container, Grid, Stack, Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Element } from 'react-scroll'

import { CourseMemberType } from '@core/graphql'
import { useApp, useLocales } from '@core/hooks'
import { EmptyState } from '@core/ui/components'
import MentorSessionActivityLog from '@modules/mentor-session/common/MentorSessionActivityLog'

import LearnerMemberHeader from './components/LearnerMemberHeader'
import LearnerMemberInfo from './components/LearnerMemberInfo'
import MentorMemberInfo from './components/MentorMemberInfo'
import MentorSessionEvents from './components/MentorSessionEvents'
import SessionSidebar from './components/SessionSidebar'
import { useLearnerMemberSessionsContext } from './LearnerMemberSessionsContext'

export type LearnerMemberSessionsProps = {}

const LearnerMemberSessions: FC<LearnerMemberSessionsProps> = () => {
  const { isWorkplace, isLearner, isMentor } = useApp()
  const { t } = useLocales('mentor_session')

  const { state } = useLearnerMemberSessionsContext()

  const { mentorSession } = state

  const learnerCourseMember = useMemo(() => {
    return mentorSession?.courseMembers.find(
      (member) => member.type === CourseMemberType.LEARNER,
    )
  }, [mentorSession?.courseMembers])

  const mentorCourseMember = useMemo(() => {
    return mentorSession?.courseMembers.find(
      (member) => member.type === CourseMemberType.MENTOR,
    )
  }, [mentorSession?.courseMembers])

  const events = useMemo(() => {
    return mentorSession?.calendarEvents ?? []
  }, [mentorSession?.calendarEvents])

  if (!mentorSession) {
    return (
      <Container>
        <EmptyState message={t('mentor_session_not_found')} />
      </Container>
    )
  }

  return (
    <>
      <Helmet title="Mentor Session" />
      <Container
        sx={(theme) => ({
          py: 4,
          [theme.breakpoints.up('sm')]: {
            py: 6,
          },
        })}
      >
        <Stack spacing={4}>
          {learnerCourseMember && (
            <LearnerMemberHeader
              course={mentorSession.course}
              learnerCourseMember={learnerCourseMember}
            />
          )}
          <Box maxHeight="contentHeight">
            <Grid container spacing={3}>
              <Grid item md={4} sm={4} xs={3}>
                <Box
                  sx={(theme) => ({
                    position: 'sticky',
                    top: isWorkplace ? theme.spacing(3) : theme.spacing(10),
                    marginTop: 0,
                    [theme.breakpoints.down('md')]: {
                      top: 80,
                    },
                  })}
                >
                  <SessionSidebar />
                </Box>
              </Grid>
              <Grid item md={8} sm={8} xs={9}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Element name="session-events">
                      <MentorSessionEvents events={events} />
                    </Element>
                  </Grid>

                  {isMentor && (
                    <Grid item xs={12}>
                      <Element name="learner-info">
                        <LearnerMemberInfo
                          learnerMember={learnerCourseMember}
                        />
                      </Element>
                    </Grid>
                  )}

                  {isLearner && (
                    <Grid item xs={12}>
                      <Element name="mentor-info">
                        <MentorMemberInfo mentorMember={mentorCourseMember} />
                      </Element>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Element name="activities">
                      <MentorSessionActivityLog
                        activityLogs={mentorSession.activityLogs}
                      />
                    </Element>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Container>
    </>
  )
}

export default LearnerMemberSessions
