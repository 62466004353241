import type { FC, MouseEvent } from 'react'

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionLearnerMemberFragment } from '@core/graphql'
import { SelectiveBox, UserAvatar, UserDisplayName } from '@core/ui/components'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'

export type SelectiveLearnerItemProps = {
  courseMember: BookMentorSessionLearnerMemberFragment
  selected?: boolean
  onSelect: (courseMember: BookMentorSessionLearnerMemberFragment) => void
}

const SelectiveLearnerItem: FC<SelectiveLearnerItemProps> = ({
  courseMember,
  selected,
  onSelect,
}) => {
  const learner = courseMember.learner!
  const { setValue } = useFormContext<BookMentorSessionValues>()

  const handleDisplayNameClick = (event: MouseEvent) => {
    event.stopPropagation()
    setValue('previewLearnerCourseMember', courseMember)
  }

  const handleSelectMember = () => {
    onSelect(courseMember)
  }

  return (
    <SelectiveBox selected={selected} onClick={handleSelectMember}>
      <Stack alignItems="center" spacing={2}>
        <UserAvatar size={80} userId={learner.userId} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Tooltip title="Click to preview">
            <Box overflow="hidden">
              <UserDisplayName
                maxWidth={280}
                userId={learner.userId}
                variant="h6"
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline !important',
                  },
                }}
                onClick={handleDisplayNameClick}
              />
            </Box>
          </Tooltip>
          <Typography noWrap color="textSecondary" variant="body2">
            {learner.user?.email}
            <br />
            {[learner.discordId, learner.learnerCode]
              .filter((i) => !!i)
              .join(' • ')}
          </Typography>
        </Box>
      </Stack>
    </SelectiveBox>
  )
}

export default SelectiveLearnerItem
