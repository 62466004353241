import type { FC } from 'react'

import { Typography } from '@mui/material'

export type AppVersionInfoProps = {}

const AppVersionInfo: FC<AppVersionInfoProps> = () => {
  const { VITE_APP_VERSION, VITE_APP_BUILD_NUMBER } = import.meta.env

  return (
    <Typography color="textSecondary" variant="caption">
      CoderSchool &copy; {new Date().getFullYear()}
      <br />
      {VITE_APP_VERSION}
      {VITE_APP_BUILD_NUMBER ? ` (#${VITE_APP_BUILD_NUMBER || '?'})` : ''}
    </Typography>
  )
}

export default AppVersionInfo
