import type { FC } from 'react'

import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { Link } from '@tanstack/react-location'
import { differenceInYears } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import { LINKS } from '@core/config'
import type { BookMentorSessionMentorMemberFragment } from '@core/graphql'
import {
  AvailabilitySchedulesView,
  ContentSection,
  EmptyState,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'
import { buildPath } from '@core/utils/buildPath'
import cssStyles from '@core/utils/cssStyles'
import { fDate } from '@core/utils/formatTime'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'

export type MentorPreviewProps = {
  courseMember: BookMentorSessionMentorMemberFragment
}

const MentorPreview: FC<MentorPreviewProps> = ({ courseMember }) => {
  const { setValue } = useFormContext<BookMentorSessionValues>()

  if (!(courseMember && courseMember.mentor && courseMember.mentor.user)) {
    return (
      <Box sx={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
        <EmptyState message="Learner not found or has been removed" />
      </Box>
    )
  }

  const { mentor } = courseMember
  const { user } = courseMember.mentor

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={(theme) => ({
          p: 2,
          position: 'sticky',
          top: 0,
          marginTop: 0,
          ...cssStyles(theme).bgBlur(),
        })}
      >
        <UserAvatar userId={mentor.userId} />
        <Box flex={1} overflow="hidden">
          <UserDisplayName
            noWrap
            component={Link}
            to={buildPath(LINKS.MENTOR_PROFILE, { mentorId: mentor.id })}
            userId={mentor.userId}
            variant="h6"
            {...{ target: '_blank' }}
          />
          <Typography noWrap color="textSecondary">
            {user.email}
          </Typography>
        </Box>
        <Tooltip title="Close preview">
          <IconButton
            onClick={() => setValue('previewMentorCourseMember', null)}
          >
            <X />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack padding={2} spacing={1}>
        {mentor.dateOfBirth && (
          <ContentSection label="Date of birth">
            {fDate(mentor.dateOfBirth)} (
            {differenceInYears(Date.now(), mentor.dateOfBirth)} yo)
          </ContentSection>
        )}
        <ContentSection label="Gender">{mentor.gender ?? 'N/A'}</ContentSection>
        <ContentSection label="Discord">
          {mentor.discordId ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Job title">
          {mentor.jobTitle ?? 'N/A'}
        </ContentSection>
        <ContentSection label="Organization">
          {mentor.organization ?? 'N/A'}
        </ContentSection>

        <Divider />

        <ContentSection label="Availability">
          <AvailabilitySchedulesView targetId={mentor.id} />
        </ContentSection>
      </Stack>
    </Box>
  )
}

export default MentorPreview
