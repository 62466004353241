import type { ChangeEvent, FC, KeyboardEvent } from 'react'
import { useCallback, useRef, useState } from 'react'

import type { SxProps, Theme } from '@mui/material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { X } from '@phosphor-icons/react'

import { SearchIcon } from '@core/icons'
import type { ANY } from '@core/types'
import normalizeSearchText from '@core/utils/normalizeSearchText'

export type SearchBoxProps = {
  sx?: SxProps<Theme>
  placeholder?: string
  defaultValue?: string
  onSubmit?: (value: string) => void
  size?: 'medium' | 'small'
  normalizeSearchValue?: boolean
}

const HEIGHT = {
  medium: 56,
  small: 32,
}

const SearchBox: FC<SearchBoxProps> = ({
  defaultValue = '',
  onSubmit,
  sx,
  size = 'medium',
  normalizeSearchValue = true,
  ...rest
}) => {
  const inputRef = useRef<ANY>()
  const [value, setValue] = useState(defaultValue)

  const handleSubmit = useCallback(() => {
    const nextValue = normalizeSearchValue ? normalizeSearchText(value) : value
    onSubmit?.(nextValue)
  }, [normalizeSearchValue, onSubmit, value])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(event.target.value)
    },
    [],
  )

  const handleClear = useCallback(() => {
    setValue('')
    onSubmit?.('')
    inputRef?.current?.focus?.()
  }, [onSubmit])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSubmit()
      }
    },
    [handleSubmit],
  )

  return (
    <TextField
      size={size}
      sx={{ height: HEIGHT[size], ...sx }}
      value={value}
      InputProps={{
        sx: (theme) => ({
          height: HEIGHT[size],
          pl: size === 'small' ? 1 : undefined,
          fontSize:
            theme.typography[size === 'medium' ? 'body1' : 'body2'].fontSize,
          flexShrink: 0,
        }),
        inputRef,
        onKeyDown: handleKeyDown,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon size={size === 'medium' ? 24 : '1em'} />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton size="small" onClick={handleClear}>
              <X />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        sx: {
          height: 'calc(100% - 8px)',
          padding: 0,
        },
      }}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default SearchBox
