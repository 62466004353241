import type { FC } from 'react'

import { Stack } from '@mui/material'
import { Helmet } from 'react-helmet-async'

import { useLocales } from '@core/hooks'

import PendingMemberList from './components/PendingMemberList'
import PendingMemberToolbar from './components/PendingMemberToolbar'
import { PendingListContextProvider } from './PendingListContext'

export type PendingListProps = {}

const PendingList: FC<PendingListProps> = () => {
  const { t } = useLocales()

  return (
    <>
      <Helmet title={t('courseMember:pending')} />
      <PendingListContextProvider>
        <Stack spacing={4}>
          <PendingMemberToolbar />
          <PendingMemberList />
        </Stack>
      </PendingListContextProvider>
    </>
  )
}

export default PendingList
