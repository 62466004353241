import type { FC } from 'react'
import { useMemo } from 'react'

import { Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { groupBy, isEmpty } from 'lodash'

import { useAvailabilitySchedulesViewQuery } from '@core/graphql'
import { convertToDefaultTimeZone } from '@core/utils/date'
import { fDayOfWeek } from '@core/utils/formatTime'

import UrqlError from '../../feedback/UrqlError'
import { ListItemSkeleton } from '../../states/skeletons'

export type AvailabilitySchedulesViewProps = {
  targetId?: string
  targetIds?: string[]
}

const AvailabilitySchedulesView: FC<AvailabilitySchedulesViewProps> = ({
  targetId,
  targetIds,
}) => {
  const [{ fetching, data, error }] = useAvailabilitySchedulesViewQuery({
    variables: { targetId, targetIds },
  })

  const schedules = useMemo(() => {
    const mentorSchedules = data?.mentorSessionAvailabilitySchedules ?? []

    const groupedByDayOfWeek = groupBy(
      mentorSchedules.map((schedule) => ({
        dayOfWeek: schedule.dayOfWeek,
        start: new Date(schedule.start),
        end: new Date(schedule.end),
      })),
      'dayOfWeek',
    )

    return groupedByDayOfWeek
  }, [data?.mentorSessionAvailabilitySchedules])

  if (error) {
    return <UrqlError error={error} />
  }

  if (fetching && !data) {
    return <ListItemSkeleton />
  }

  if (isEmpty(schedules)) {
    return <Typography>Unavailable</Typography>
  }

  return (
    <Stack spacing={2}>
      <Typography color="text.secondary" sx={{ mb: 2 }} variant="caption">
        Please be aware that all time slots are displayed in GMT+7 (Indochina
        Time)
      </Typography>
      {Object.entries(schedules).map(([index, daySchedules]) => (
        <DayItem key={index} dayIndex={+index} daySchedules={daySchedules} />
      ))}
    </Stack>
  )
}

const DayItem: FC<{
  dayIndex: number
  daySchedules: {
    dayOfWeek: number
    start: Date
    end: Date
  }[]
}> = ({ dayIndex, daySchedules }) => {
  return (
    <Stack spacing={0}>
      <Typography color="textSecondary" variant="button">
        {fDayOfWeek(dayIndex)}
      </Typography>
      {daySchedules.map((daySchedule, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Typography key={index}>
          {format(convertToDefaultTimeZone(daySchedule.start), 'hh:mm a')} –{' '}
          {format(convertToDefaultTimeZone(daySchedule.end), 'hh:mm a')}
        </Typography>
      ))}
    </Stack>
  )
}

export default AvailabilitySchedulesView
