import type { FC } from 'react'

import { Stack } from '@mui/material'
import { useMatch } from '@tanstack/react-location'

import {
  CourseMemberType,
  useMentorSessionEventViewQuery,
  useMentorSessionViewQuery,
} from '@core/graphql'
import { EmptyState, ListItemSkeleton, UrqlError } from '@core/ui/components'

import LearnerItem from '../../components/LearnerItem'

export type MentorSessionEventLearnersProps = {}

const MentorSessionEventLearners: FC<MentorSessionEventLearnersProps> = () => {
  const {
    params: { mentorSessionId, eventId },
  } = useMatch()
  const [
    {
      data: mentorSessionData,
      fetching: fetchingMentorSession,
      error: mentorSessionError,
    },
  ] = useMentorSessionViewQuery({
    variables: { id: mentorSessionId },
  })
  const [{ data: eventData, fetching: fetchingEvent, error: eventError }] =
    useMentorSessionEventViewQuery({
      variables: { eventId },
    })

  const learnerMembers = mentorSessionData?.mentorSession?.courseMembers.filter(
    (member) => member.type === CourseMemberType.LEARNER,
  )
  const mentorSessionEvent = eventData?.mentorSessionEvent

  const error = mentorSessionError || eventError

  if (error) {
    return <UrqlError error={error} />
  }

  if (
    (fetchingMentorSession && !mentorSessionData) ||
    (fetchingEvent && !eventData)
  ) {
    return <ListItemSkeleton />
  }

  if (!(learnerMembers && learnerMembers?.length)) {
    return <EmptyState message="This event does not have any learners" />
  }

  return (
    <Stack spacing={4}>
      {learnerMembers.map((learnerMember) => (
        <LearnerItem
          key={learnerMember.id}
          learnerMember={learnerMember}
          mentorSessionEvent={mentorSessionEvent}
        />
      ))}
    </Stack>
  )
}

export default MentorSessionEventLearners
