import type { FC } from 'react'

import { Tabs } from '@mui/material'
import { useMatchRoute } from '@tanstack/react-location'

import type { NavTabItemProps } from './NavTabItem'
import NavTabItem from './NavTabItem'

export type NavTabsProps = {
  items: (NavTabItemProps | boolean | null | undefined)[]
}

const NavTabs: FC<NavTabsProps> = ({ items }) => {
  const matchRoute = useMatchRoute()

  const tabItems = items.filter((item) => !!item) as NavTabItemProps[]

  const matchedItem =
    tabItems.find((item) =>
      [...(item.matchRoutes ?? []), item.href].some((href) =>
        matchRoute({ to: href }),
      ),
    ) ?? tabItems[0]

  return (
    <Tabs
      sx={{ '& a': { textDecoration: 'none !important' } }}
      value={matchedItem?.href}
    >
      {tabItems.map((tabItem) => (
        <NavTabItem key={tabItem.href} value={tabItem.href} {...tabItem} />
      ))}
    </Tabs>
  )
}

export default NavTabs
