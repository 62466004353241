import type { FC } from 'react'
import { useMemo } from 'react'

import type { TooltipProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import type { Locale } from 'date-fns'
import {
  format,
  formatDistanceToNowStrict,
  formatDistanceToNow,
} from 'date-fns'

import type { ANY } from '@core/types'

import ErrorBoundary from '../feedback/ErrorBoundary'

type DateFormatProps =
  | {
      variant: 'format'
      format: string
      locale?: Locale
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: number
      useAdditionalWeekYearTokens?: boolean
      useAdditionalDayOfYearTokens?: boolean
    }
  | {
      variant: 'distanceToNow'
      includeSeconds?: boolean
      addSuffix?: boolean
      locale?: Locale
    }
  | {
      variant: 'distanceToNowStrict'
      addSuffix?: boolean
      unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
      roundingMethod?: 'floor' | 'ceil' | 'round'
      locale?: Locale
    }

export type DateFormatTextProps = {
  date: Date | number | string
  placement?: TooltipProps['placement']
  disableTooltip?: boolean
} & DateFormatProps

const DateFormatTextComponent: FC<DateFormatTextProps> = ({
  date: $date,
  variant,
  placement,
  disableTooltip = false,
  ...opts
}) => {
  const { format: formatStr, ...options }: ANY = opts ?? {}

  const date = useMemo(() => {
    if (typeof $date === 'string') {
      if (!Number.isNaN(+date)) {
        return new Date(+$date)
      }
      return new Date($date)
    }
    return $date
  }, [$date])

  const detailedDate = useMemo(() => format(date, 'PPppp'), [date])
  const formattedDate = useMemo(() => {
    switch (variant) {
      case 'distanceToNowStrict':
        return formatDistanceToNowStrict(date, options)

      case 'distanceToNow':
        return formatDistanceToNow(date, options)

      case 'format':
      default:
        return format(date, formatStr, options)
    }
  }, [date, formatStr, options, variant])

  const content = useMemo(
    () => (
      <Box
        component="span"
        sx={{
          display: 'inline',
          '&:hover': disableTooltip
            ? {}
            : {
                borderBottom: (theme) =>
                  `dashed 1px ${theme.palette.action.disabled}`,
              },
        }}
      >
        {formattedDate}
      </Box>
    ),
    [disableTooltip, formattedDate],
  )

  if (disableTooltip) {
    return content
  }

  return (
    <Tooltip placement={placement} title={detailedDate}>
      {content}
    </Tooltip>
  )
}

const DateFormatText: FC<DateFormatTextProps> = (props) => (
  <ErrorBoundary showOnProd renderError={() => 'Invalid date'}>
    <DateFormatTextComponent {...props} />
  </ErrorBoundary>
)

export default DateFormatText
