import type { FC } from 'react'

import { Button, Container, Stack, Typography } from '@mui/material'
import { ArrowLeft } from '@phosphor-icons/react'

import { LINKS } from '@core/config'
import { useLocales } from '@core/hooks'

import Image from '../data-display/Image'

export type ForbiddenProps = {
  title?: string
  message?: string
}

const Forbidden: FC<ForbiddenProps> = ({ title, message }) => {
  const { translate } = useLocales()

  return (
    <Container>
      <Stack alignItems="center" spacing={2}>
        <Image
          alt="403_forbidden"
          src="/static/img/403.png"
          sx={{ maxWidth: 280, width: '100%' }}
        />

        <Typography align="center" variant="h3" whiteSpace="break-spaces">
          {title ?? translate('403_title', { capitalize: false })}
        </Typography>
        <Typography align="center" variant="body1" whiteSpace="break-spaces">
          {message ?? translate('403_message', { capitalize: false })}
        </Typography>

        <Stack direction="row" spacing={2}>
          <Button href=".." startIcon={<ArrowLeft />} variant="contained">
            {translate('go_back')}
          </Button>
          <Button color="inherit" href={LINKS.SIGN_IN} variant="text">
            {translate('try_another_account')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Forbidden
