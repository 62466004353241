import type { FC } from 'react'

import { Button } from '@mui/material'

import { useLocales, useDialogState } from '@core/hooks'
import { createButtonIcon } from '@core/icons'
import { FILTERS_HEIGHT } from '@core/ui/components/feedback/InlineFilters/InlineFilters.const'

import { usePendingListContext } from '../PendingListContext'

import EnrollMembersDialog from './EnrollMembersDialog'

export type EnrollToLWButtonProps = {}

const EnrollToLWButton: FC<EnrollToLWButtonProps> = () => {
  const { t } = useLocales()
  const { isOpen, openDialog, closeDialog } = useDialogState()
  const {
    pendingMemberConnection: { data },
  } = usePendingListContext()
  const courseMemberNodes = data?.courseMemberConnection.nodes ?? []

  return (
    <>
      <Button
        disabled={courseMemberNodes.length === 0}
        size="small"
        startIcon={createButtonIcon}
        sx={{ height: FILTERS_HEIGHT }}
        variant="contained"
        onClick={openDialog}
      >
        {t('course_member:enroll_all')}
      </Button>
      <EnrollMembersDialog open={isOpen} onClose={closeDialog} />
    </>
  )
}

export default EnrollToLWButton
