import type { FC } from 'react'
import { useCallback, useEffect } from 'react'

import { Box, Divider, Grid } from '@mui/material'
import { useNavigate } from '@tanstack/react-location'
import {
  addDays,
  addMinutes,
  addMonths,
  getDay,
  getHours,
  getMinutes,
  startOfMinute,
} from 'date-fns'
import { useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { Frequency, RRule, Weekday } from 'rrule'

import { LINKS, MS_BOOKING_OPTS } from '@core/config'
import type {
  BookMentorSessionLearnerMemberFragment,
  BookMentorSessionMentorMemberFragment,
  CourseSelectItemFragment,
} from '@core/graphql'
import {
  useCreateMentorSessionMutation,
  useUpdateMentorSessionsStatusBatchMutation,
} from '@core/graphql'
import { useLocalStorage } from '@core/hooks'
import type { ANY } from '@core/types'
import { Page } from '@core/ui/components'
import { FormProvider } from '@core/ui/components/hook-form'
import { buildPath } from '@core/utils/buildPath'
import { revertToLocalTimeZone } from '@core/utils/date'
import CourseSelect from 'apps/workplace/modules/courses/components/CourseSelect'

import type { BookMentorSessionValues } from './BookMentorSession.util'
import RightDrawer from './components/drawer/RightDrawer'
import LearnerWithNoSessionsList from './components/learners/LearnerWithNoSessionsList'
import SuitableMentorList from './components/mentors/SuitableMentorList'
import BookingTime from './components/time/BookingTime'

export type BookMentorSessionPageProps = {}

const defaultValues: Partial<BookMentorSessionValues> = {
  time: {
    duration: 60,
    endsAfterMonths: 6,
    startsAt: undefined as ANY,
  },
  options: {
    autoSelect: true,
    stayForNextSession: true,
  },
}

const BookMentorSessionPage: FC<BookMentorSessionPageProps> = () => {
  const [, mutateCreate] = useCreateMentorSessionMutation()
  const [, mutateUpdateMentorSessionsStatus] =
    useUpdateMentorSessionsStatusBatchMutation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [cachedOptions, updateCachedOptions] = useLocalStorage(
    MS_BOOKING_OPTS,
    defaultValues.options,
  )

  const methods = useForm<BookMentorSessionValues>({
    defaultValues: {
      ...defaultValues,
      options: cachedOptions,
    },
  })

  const values = methods.watch()

  const {
    course,
    selectedLearnerCourseMember,
    selectedMentorCourseMember,
    options,
  } = values

  const handleSubmit = useCallback(
    async (formValues: BookMentorSessionValues) => {
      const startsAt = revertToLocalTimeZone(
        startOfMinute(formValues.time.startsAt),
      )
      const { error, data } = await mutateCreate({
        input: {
          courseId: formValues.course?.id!,
          courseMemberIds: [
            formValues.selectedMentorCourseMember?.id!,
            formValues.selectedLearnerCourseMember?.id!,
          ],
          startsAt,
          endsAt: addMinutes(startsAt, formValues.time.duration),
          recurrence: new RRule({
            freq: Frequency.WEEKLY,
            dtstart: startsAt,
            until: startOfMinute(
              addMonths(startsAt, formValues.time.endsAfterMonths),
            ),
            byhour: getHours(startsAt),
            byminute: getMinutes(startsAt),
            byweekday: new Weekday(getDay(addDays(startsAt, -1))),
          }).toString(),
        },
      })

      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' })
      }

      if (data?.createMentorSession) {
        enqueueSnackbar('Created mentor session', {
          variant: 'success',
        })

        if (formValues.options.stayForNextSession) {
          methods.reset({ ...defaultValues, options: formValues.options })

          if (formValues.options.autoSelect) {
            setTimeout(() => {
              methods.setValue('course', formValues.course)
            }, 1000)
          }
        } else {
          navigate({
            to: buildPath(LINKS.MENTOR_SESSION_VIEW, {
              mentorSessionId: data.createMentorSession.id,
            }),
          })
        }
      }
    },
    [enqueueSnackbar, methods, mutateCreate, navigate],
  )

  const clearPreviews = useCallback(() => {
    methods.setValue('previewLearnerCourseMember', null)
    methods.setValue('previewMentorCourseMember', null)
  }, [methods])

  const handleCourseChange = useCallback(
    (selectedCourse: CourseSelectItemFragment | null) => {
      // Set form value
      methods.setValue(
        'course',
        selectedCourse
          ? { id: selectedCourse.id, title: selectedCourse.title }
          : null,
      )

      // Reset dependencies
      methods.setValue('selectedLearnerCourseMember', null)
      methods.setValue('selectedMentorCourseMember', null)
      methods.setValue('time.startsAt', undefined as ANY)

      clearPreviews()
    },
    [clearPreviews, methods],
  )

  const handleLearnerMemberChange = useCallback(
    (courseMember: BookMentorSessionLearnerMemberFragment) => {
      methods.setValue('selectedLearnerCourseMember', courseMember)

      methods.setValue('selectedMentorCourseMember', null)
      methods.setValue('time.startsAt', undefined as ANY)
      clearPreviews()
    },
    [clearPreviews, methods],
  )

  const handleMentorMemberChange = useCallback(
    (courseMember: BookMentorSessionMentorMemberFragment) => {
      methods.setValue('selectedMentorCourseMember', courseMember)

      methods.setValue('time.startsAt', undefined as ANY)
      clearPreviews()
    },
    [clearPreviews, methods],
  )

  // Update cached options to local storage
  useEffect(() => {
    updateCachedOptions(options)
  }, [options, updateCachedOptions])

  useEffect(() => {
    // Call update status to make sure that the mentor session status
    mutateUpdateMentorSessionsStatus({})
  }, [mutateUpdateMentorSessionsStatus])

  return (
    <Box>
      <Helmet title="Book new mentor session" />
      <FormProvider methods={methods}>
        <Box sx={{ display: 'flex' }}>
          <Page
            goBack={{ label: 'Sessions', to: LINKS.MENTOR_SESSION_LIST }}
            maxWidth="lg"
            pageTitle="Book new mentor session"
          >
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <CourseSelect
                  getValue={(courses) =>
                    courses.find((c) => c.id === course?.id)
                  }
                  onChange={handleCourseChange}
                />
              </Grid>

              {!!course?.id && (
                <Grid item xs={12}>
                  <LearnerWithNoSessionsList
                    courseId={course.id}
                    onChange={handleLearnerMemberChange}
                  />
                </Grid>
              )}
              {!!course?.id && !!selectedLearnerCourseMember?.id && (
                <Grid item xs={12}>
                  <SuitableMentorList
                    courseId={course.id}
                    learnerCourseMemberId={selectedLearnerCourseMember.id}
                    onChange={handleMentorMemberChange}
                  />
                </Grid>
              )}
              {!!course?.id &&
                !!selectedLearnerCourseMember?.id &&
                !!selectedMentorCourseMember?.id && (
                  <Grid item xs={12}>
                    <Divider />
                    <Box mb={3} />
                    <BookingTime />
                  </Grid>
                )}
            </Grid>
          </Page>

          <RightDrawer
            submitButtonLabel="Create session series"
            onSubmit={methods.handleSubmit(handleSubmit)}
          />
        </Box>
      </FormProvider>
    </Box>
  )
}

export default BookMentorSessionPage
