import type { FC } from 'react'
import { useCallback } from 'react'

import { Alert, Box, Stack, Typography, useTheme } from '@mui/material'
import { addMinutes, format } from 'date-fns'
import { useFormContext } from 'react-hook-form'

import {
  ContentSection,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'
import cssStyles from '@core/utils/cssStyles'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import ActionsButtonGroup from '../ActionsButtonGroup'

export type SessionPreviewProps = {
  onSubmit: VoidFunction
  submitButtonLabel: string
}

const SessionPreview: FC<SessionPreviewProps> = ({
  onSubmit,
  submitButtonLabel,
}) => {
  const theme = useTheme()
  const { watch, setValue } = useFormContext<BookMentorSessionValues>()

  const {
    course,
    selectedLearnerCourseMember: learnerMember,
    selectedMentorCourseMember: mentorMember,
    time,
  } = watch()

  const handlePreviewLearner = useCallback(() => {
    setValue('previewLearnerCourseMember', learnerMember)
  }, [learnerMember, setValue])

  const handlePreviewMentor = useCallback(() => {
    setValue('previewMentorCourseMember', mentorMember)
  }, [mentorMember, setValue])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{
          // backgroundColor: theme.palette.background.neutral,
          p: 2,
          position: 'sticky',
          top: 0,
          marginTop: 0,
          ...cssStyles(theme).bgBlur(),
        }}
      >
        <Typography variant="h6">Mentor Session Preview</Typography>
      </Stack>
      <Stack flex={1} padding={2} spacing={2}>
        <ContentSection label="Course">
          {course?.title || 'Please select course'}
        </ContentSection>
        <Box>
          <ContentSection
            label="Learner"
            sx={{
              padding: 1,
              margin: -1,
              cursor: 'pointer',
              transition: theme.transitions.create('all'),
              borderRadius: 1,

              '&:hover': {
                backgroundColor: 'background.neutral',
              },
            }}
            onClick={handlePreviewLearner}
          >
            {learnerMember?.learner?.user?.id ? (
              <Stack spacing={1}>
                <Stack alignItems="center" direction="row" mt={1} spacing={1.5}>
                  <UserAvatar userId={learnerMember.learner.user.id} />
                  <Box flex={1}>
                    <UserDisplayName userId={learnerMember.learner.user.id} />
                    <Typography color="textSecondary" variant="body2">
                      {learnerMember.learner.user.email}
                    </Typography>
                  </Box>
                </Stack>
                {!!learnerMember.redeemedMentorSessionEvents && (
                  <Alert severity="warning">{`This learner has ${learnerMember.redeemedMentorSessionEvents} past non-platform mentor session(s)`}</Alert>
                )}
              </Stack>
            ) : (
              'Not selected'
            )}
          </ContentSection>
        </Box>

        <Box>
          <ContentSection
            label="Mentor"
            sx={{
              padding: 1,
              margin: -1,
              cursor: 'pointer',
              transition: theme.transitions.create('all'),
              borderRadius: 1,

              '&:hover': {
                backgroundColor: 'background.neutral',
              },
            }}
            onClick={handlePreviewMentor}
          >
            {mentorMember?.mentor?.user?.id ? (
              <Stack alignItems="center" direction="row" mt={1} spacing={1.5}>
                <UserAvatar userId={mentorMember.mentor.user.id} />
                <Box flex={1}>
                  <UserDisplayName userId={mentorMember.mentor.user.id} />
                  <Typography color="textSecondary" variant="body2">
                    {mentorMember.mentor.user.email}
                  </Typography>
                </Box>
              </Stack>
            ) : (
              'Not selected'
            )}
          </ContentSection>
        </Box>

        {time.startsAt && (
          <>
            <ContentSection label="Starting by">
              <b>
                {format(time.startsAt, 'PPPP')}
                <br />
                {format(time.startsAt, 'HH:mm')} –{' '}
                {format(addMinutes(time.startsAt, time.duration), 'HH:mm a')}
              </b>
            </ContentSection>
            <ContentSection label="Repeats">
              Weekly on {format(time.startsAt, 'EEEE')}
            </ContentSection>
            <Typography color="text.secondary" sx={{ mb: 2 }} variant="caption">
              Please be aware that the time displayed on this page is in GMT+7
              (Indochina Time)
            </Typography>
          </>
        )}
      </Stack>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          marginBottom: 0,
          p: 2,
          ...cssStyles(theme).bgBlur(),
        }}
      >
        <ActionsButtonGroup
          submitButtonLabel={submitButtonLabel}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  )
}

export default SessionPreview
