import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import {
  Alert,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { useAuth } from '@core/auth'
import { useLocales } from '@core/hooks'
import { AttendeeOptionalIcon } from '@core/icons'
import type { ArrayElement } from '@core/types'
import { UserAvatar, UserDisplayName } from '@core/ui/components'
import UserAutocomplete from '@core/ui/components/inputs/UserAutocomplete'

import { getAttendeeDescription } from '../../calendar.util'

import type { EventFormValues } from './EventForm'

export type EventAttendeesFieldProps = {}

const EventAttendeesField: FC<EventAttendeesFieldProps> = () => {
  const { t } = useLocales('calendar')
  const { $user: currentUser } = useAuth()
  const {
    control,
    formState: { errors },
  } = useFormContext<EventFormValues>()
  const {
    fields: attendees,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'attendees',
    keyName: 'key_id',
  })

  const handleAdd = useCallback(
    (user: { id: string }) => {
      let appendItems = [
        {
          userId: user.id,
          isHost: !attendees.length || user.id === currentUser.id,
          isOptional: false,
        },
      ]

      if (attendees.find((attendee) => attendee.userId === user.id)) {
        return
      }

      if (attendees.length === 0 && user.id !== currentUser.id) {
        appendItems = [
          {
            userId: currentUser.id,
            isHost: !attendees.length || user.id === currentUser.id,
            isOptional: false,
          },
          ...appendItems,
        ]
      }

      append(appendItems)
    },
    [append, attendees, currentUser.id],
  )

  const renderAttendeeItem = useCallback(
    (attendee: ArrayElement<typeof attendees>, index: number) => (
      <ListItem key={attendee.key_id} disableGutters>
        <ListItemAvatar>
          <UserAvatar size={32} userId={attendee.userId} />
        </ListItemAvatar>
        <ListItemText
          primary={<UserDisplayName userId={attendee.userId} />}
          secondary={getAttendeeDescription(attendee)}
        />
        <ListItemSecondaryAction>
          <Tooltip
            title={t(
              attendee.isOptional
                ? 'mark_attendee_required'
                : 'mark_attendee_optional',
            )}
          >
            <IconButton
              size="small"
              onClick={() =>
                update(index, { ...attendee, isOptional: !attendee.isOptional })
              }
            >
              <AttendeeOptionalIcon
                weight={attendee.isOptional ? 'regular' : 'fill'}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('common:remove')}>
            <IconButton size="small" onClick={() => remove(index)}>
              <X />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    ),
    [remove, t, update],
  )

  const renderAttendees = useMemo(
    () => attendees.map(renderAttendeeItem),
    [attendees, renderAttendeeItem],
  )

  return (
    <>
      <UserAutocomplete
        excludedUserIds={attendees.map(({ userId }) => userId)}
        placeholder="Add guests"
        onSelectUser={handleAdd}
      />

      {errors.attendees && (
        <Alert severity="error">{errors.attendees.message}</Alert>
      )}

      <List>{renderAttendees}</List>
    </>
  )
}

export default EventAttendeesField
