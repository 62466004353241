import { useEffect, useRef } from 'react'

export function useInterval(callback: VoidFunction, delay: number) {
  const savedCallback = useRef<typeof callback>()
  const intervalId = useRef<NodeJS.Timeout>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      savedCallback.current?.()
    }
    if (delay !== null) {
      intervalId.current = setInterval(tick, delay)
      return () => clearInterval(intervalId.current)
    }
  }, [delay])

  return { intervalId: intervalId.current }
}
