import { useDisclosure } from './useDisclosure'

/** @deprecated Use `useDisclosure` instead */
const useDialogState = (defaultOpen = false) => {
  const [isOpen, { open, close, toggle }] = useDisclosure(defaultOpen)

  return { isOpen, openDialog: open, closeDialog: close, toggle }
}

export default useDialogState
