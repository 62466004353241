import type { FC } from 'react'

import { Box, Skeleton, Stack } from '@mui/material'

export type ContentSkeletonProps = {}

export const ContentSkeleton: FC<ContentSkeletonProps> = () => {
  return (
    <Box>
      <Skeleton width="40%" />
      <Skeleton width="80%" />
      <Stack direction="row" spacing={1}>
        <Skeleton width="10%" />
        <Skeleton width="15%" />
        <Skeleton width="10%" />
      </Stack>

      <Skeleton width="40%" />
      <Skeleton width="55%" />
      <Stack direction="row" spacing={1}>
        <Skeleton width="10%" />
        <Skeleton width="15%" />
        <Skeleton width="10%" />
      </Stack>
    </Box>
  )
}
