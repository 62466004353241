import type { FC, ReactNode } from 'react'

import { Box } from '@mui/material'

export type ScrollAreaProps = {
  h?: number
  children: ReactNode
}

const ScrollArea: FC<ScrollAreaProps> = ({ children, h }) => {
  return (
    <Box
      height={h || 'auto'}
      sx={{
        overflow: 'auto',
      }}
    >
      {children}
    </Box>
  )
}

export default ScrollArea
