import { set, startOfDay } from 'date-fns'

export type IntervalDate = { end: Date; start: Date }

export const setDateTo2000 = (date: Date): Date => {
  return set(date, {
    year: 2000,
    month: 0,
    date: 1,
  })
}

/** Validate if a date is between 00:00:00, 01 Jan 2000 and 00:00:00, 02 Jan 2000. */
export const isDateWithinStartOf2000 = (date: Date): boolean => {
  const start = startOfDay(new Date(Date.UTC(2000, 0, 1, 0, 0, 0)))
  const end = startOfDay(new Date(Date.UTC(2000, 0, 2, 0, 0, 0)))

  return start <= date && end >= date
}

export const findOverlapIntervals =
  (minimum: number) => ($intervals: IntervalDate[]) => {
    if ($intervals.length < minimum) {
      return []
    }

    const intervals = $intervals.sort(
      // By start, ascending
      (a, b) => a.start.getTime() - b.start.getTime(),
    )

    const result = intervals.reduce((value, current) => {
      // Skip the first interval
      if (value.length === 0) {
        return [current]
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const previous = value.pop()!

      if (current.end <= previous.end) {
        // Current range is completely inside previous
        return [...value, current]
      }

      // Merges overlapping (<) and contiguous (==) ranges
      if (current.start <= previous.end) {
        // Current range overlaps previous
        return [...value, { start: current.start, end: previous.end }]
      }

      // Ranges do not overlap
      return [...value, previous]
    }, [] as IntervalDate[])

    return result
  }
