import { useCallback } from 'react'

import type { TOptions } from 'i18next'
import { snakeCase } from 'lodash'
import cap from 'lodash/capitalize'
import start from 'lodash/startCase'
import upFirst from 'lodash/upperFirst'
import type { UseTranslationOptions } from 'react-i18next'
import { useTranslation } from 'react-i18next'

import type { Namespace } from '@core/locales/namespaces'
import type { ANY } from '@core/types'

const useLocales = (ns?: Namespace, opt?: UseTranslationOptions<ANY>) => {
  const { t } = useTranslation(ns, opt)

  const translate = useCallback(
    (
      $key: string | string[],
      defaultValueOrOptions:
        | (TOptions<Record<string, ANY>> & {
            capitalize?: boolean
            upperFirst?: boolean
            startCase?: boolean
          })
        | string = {},
    ) => {
      const {
        upperFirst = true,
        capitalize = false,
        startCase = false,
        ...options
      } = typeof defaultValueOrOptions === 'string'
        ? {
            defaultValue: defaultValueOrOptions,
          }
        : defaultValueOrOptions

      let key = $key

      if (typeof key === 'string') {
        key = key
          .split(':')
          .map((p) =>
            p
              .split('.')
              .map((part) => snakeCase(part))
              .join('.'),
          )
          .join(':')
      }

      if (!options.defaultValue && typeof key === 'string') {
        options.defaultValue = start(key.split('.').reverse()[0]).toLowerCase()
      }

      let text = t(key, options)

      if (upperFirst) {
        text = upFirst(text)
      }

      if (capitalize) {
        text = cap(text)
      }

      if (startCase) {
        text = start(text)
      }

      return text
    },
    [t],
  )

  return { translate, t: translate }
}

export default useLocales
