import type { FC, ReactNode } from 'react'

import type { BoxProps, SxProps } from '@mui/material'
import { Box, Stack, Typography } from '@mui/material'

export type ContentSectionProps = {
  label: string | ReactNode
  children: ReactNode
  maxContentHeight?: number
  sx?: SxProps
  sxContent?: SxProps
  onClick?: BoxProps['onClick']
}

const ContentSection: FC<ContentSectionProps> = ({
  label,
  children,
  maxContentHeight,
  sx,
  sxContent,
  onClick,
}) => {
  return (
    <Box sx={sx} onClick={onClick}>
      <Stack spacing={0}>
        <Typography color="textSecondary" display="block" variant="subtitle2">
          {label}
        </Typography>
        <Box
          whiteSpace="break-spaces"
          sx={{
            overflow: 'auto',
            maxHeight: maxContentHeight,
            ...sxContent,
          }}
        >
          {children}
        </Box>
      </Stack>
    </Box>
  )
}

export default ContentSection
