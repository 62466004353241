import type { FC } from 'react'

import { Box, Chip, Fade, Grid, Stack, Typography } from '@mui/material'
import { CheckCircle } from '@phosphor-icons/react'

import type { CourseMemberNodeFragment } from '@core/graphql'
import { CourseMemberType } from '@core/graphql'
import { DateFormatText } from '@core/ui/components'

export type PendingMemberItemProps = {
  courseMember: CourseMemberNodeFragment
}

const PendingMemberItem: FC<PendingMemberItemProps> = ({ courseMember }) => {
  const memberType = courseMember.type
  const memberInfo =
    memberType === CourseMemberType.LEARNER
      ? courseMember.learner
      : courseMember.mentor
  const courseTitle = courseMember.course?.title

  return (
    <Fade in>
      <Box
        sx={{
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          pt: 2,
          pb: 2,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs sm={6}>
            <Box>
              <Typography
                noWrap
                color="textPrimary"
                fontWeight="bold"
                title={memberInfo?.user?.name ?? ''}
              >
                {memberInfo?.user?.name}
              </Typography>
              <Typography color="textSecondary">
                {memberInfo?.user?.email}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Box>
              <Typography color="textPrimary" fontWeight={500}>
                {courseTitle}
              </Typography>
              {courseMember.createdAt && (
                <Typography color="textSecondary">
                  <DateFormatText
                    addSuffix
                    date={courseMember.createdAt}
                    variant="distanceToNowStrict"
                  />
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Stack direction="column" spacing={0.5}>
              <Chip
                label={courseMember.type}
                size="small"
                variant="outlined"
                sx={{
                  textTransform: 'capitalize',
                  color:
                    courseMember.type === CourseMemberType.LEARNER
                      ? 'learner.main'
                      : 'mentor.main',
                }}
              />
              {memberInfo?.lwUserId && (
                <Typography
                  color="success.main"
                  variant="body2"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckCircle
                    size={18}
                    style={{ marginRight: 8 }}
                    weight="fill"
                  />{' '}
                  LW Account
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  )
}

export default PendingMemberItem
