import type { ReactNode } from 'react'
import { createContext, useContext, useEffect } from 'react'

import type { MakeGenerics } from '@tanstack/react-location'
import { useSearch } from '@tanstack/react-location'
import type { UseQueryState } from 'urql'

import type {
  CourseMemberType,
  PendingCourseMemberConnectionQuery,
  PendingMemberStatisticsQuery,
} from '@core/graphql'
import {
  usePendingCourseMemberConnectionQuery,
  usePendingMemberStatisticsQuery,
} from '@core/graphql'
import { usePagination } from '@core/hooks'

/**
 * Create Context
 */

type PendingListContextType = {
  pendingMemberStats: UseQueryState<PendingMemberStatisticsQuery>
  pendingMemberConnection: UseQueryState<PendingCourseMemberConnectionQuery>
  nextPage: VoidFunction
  fetchingNext: boolean
}
export const PendingListContext = createContext<PendingListContextType | null>(
  null,
)

/**
 * Context Provider
 */

export type PendingListGenerics = MakeGenerics<{
  Search: {
    type?: CourseMemberType
    courseId?: string
    enrollmentDate?: Date
  }
}>

const LIMIT = 100

export type PendingListContextProviderProps = {
  children: ReactNode
}

export function PendingListContextProvider({
  children,
}: PendingListContextProviderProps) {
  const [pendingMemberStats] = usePendingMemberStatisticsQuery()

  const search = useSearch<PendingListGenerics>()
  const { variables, nextPage, onResponse, fetchingNext } = usePagination({
    limit: LIMIT,
    variables: {
      type: search.type,
      enrollmentDate: search.enrollmentDate,
      courseId: search.courseId,
    },
  })
  const [pendingMemberConnection] = usePendingCourseMemberConnectionQuery({
    variables: { ...variables, limit: LIMIT },
    requestPolicy: 'cache-and-network',
  })

  useEffect(() => {
    onResponse(pendingMemberConnection.data?.courseMemberConnection.pageInfo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingMemberConnection.data?.courseMemberConnection.pageInfo])

  return (
    <PendingListContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        pendingMemberStats,
        pendingMemberConnection,
        nextPage,
        fetchingNext,
      }}
    >
      {children}
    </PendingListContext.Provider>
  )
}

/**
 * useContext hook
 */

export const usePendingListContext = () => {
  const context = useContext(PendingListContext)

  if (!context) {
    throw new Error(
      'PendingListContext must be use inside PendingListContextProvider',
    )
  }

  return context
}
