import type { FC, ReactNode } from 'react'

import type { DialogProps } from '@mui/material'
import { Dialog } from '@mui/material'

export type MenuItemDialogProps = DialogProps & {
  children: ReactNode
}

const MenuItemDialog: FC<MenuItemDialogProps> = ({ children, ...props }) => {
  return (
    <Dialog
      onKeyDown={(event) => {
        event.stopPropagation()
      }}
      {...props}
    >
      {children}
    </Dialog>
  )
}

export default MenuItemDialog
