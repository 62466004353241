import { useRef } from 'react'

import { isEqual } from 'lodash'

export function useDeepCompareMemoize<Value>(value: Value) {
  const ref = useRef<Value>()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
