import type { ReactNode } from 'react'
import { useCallback, useMemo } from 'react'

import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
import {
  addDays,
  closestTo,
  eachMinuteOfInterval,
  format,
  startOfDay,
} from 'date-fns'
import type { Control, FieldValues, Path } from 'react-hook-form'
import { Controller, useFormContext } from 'react-hook-form'

import type { ANY } from '@core/types'
import { setDateTo2000 } from 'apps/workplace/modules/mentor-session/pages/CreateMentorSession/components/CombinedAvailabilitySchedules/CombinedAvailabilitySchedules.util'

const filterOptions = createFilterOptions({
  // Allow searching using default label or without 'space' and ':'
  stringify: (option: Date) =>
    `${format(option, 'hh:mm a')} ${format(option, 'hh:mm a').replace(
      /\s|:/g,
      '',
    )} ${option}`,
})

type RHFTimeSlotPickerProps<FormValues extends FieldValues> = {
  control?: Control<FormValues>
  name: Path<FormValues>
  label?: string
  disabled?: boolean
  minTimeSlot?: Date
  maxTimeSlot?: Date
  helperText?: ReactNode
  onChange?: VoidFunction
}

export function RHFTimeSlotPicker<FormValues extends FieldValues>({
  name,
  control: $control,
  label,
  disabled,
  minTimeSlot,
  maxTimeSlot,
  helperText,
  onChange,
}: RHFTimeSlotPickerProps<FormValues>) {
  const formContext = useFormContext()

  const control: ANY = $control ?? formContext?.control

  const startOf2000 = useMemo(() => setDateTo2000(new Date()), [])
  const firstTimeSlot = useMemo(() => startOfDay(startOf2000), [startOf2000])
  const lastTimeSlot = useMemo(
    () => addDays(startOfDay(startOf2000), 1),
    [startOf2000],
  )

  // Generate time slots of the current date with 15 minute interval
  const timeSlots = useMemo(
    () =>
      eachMinuteOfInterval(
        {
          start: firstTimeSlot,
          end: lastTimeSlot,
        },
        { step: 15 },
        // Filter by maxTimeSlot and minTimeSlot
      ).filter((timeSlot) => {
        return (
          (maxTimeSlot ? timeSlot < maxTimeSlot : true) &&
          (minTimeSlot ? timeSlot > minTimeSlot : true)
        )
      }),

    [firstTimeSlot, lastTimeSlot, maxTimeSlot, minTimeSlot],
  )

  // Get nearest time slot in the current day of the input date
  const getNearestTimeSlot = useCallback(
    (value: Date | undefined) => {
      if (!value) {
        return value
      }

      const nearestTimeSlotValue = closestTo(value, timeSlots)

      return nearestTimeSlotValue
    },

    [timeSlots],
  )

  if (!control) {
    // eslint-disable-next-line no-console
    console.warn(`[RHFTimeSlotPicker][${name}] FormProvider not found`)
    return null
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onChange: onChangeRHF },
        fieldState: { error },
        formState: { isSubmitting },
      }) => {
        return (
          <Autocomplete
            clearOnEscape
            disableClearable
            disabled={disabled || isSubmitting}
            filterOptions={filterOptions}
            getOptionLabel={(option) => format(option, 'hh:mm a')}
            id={`${name}-time-slot`}
            options={timeSlots}
            sx={{ width: '100%' }}
            value={getNearestTimeSlot(value as Date)}
            isOptionEqualToValue={(option, $value) =>
              option.getTime() === $value.getTime()
            }
            renderInput={(params) => (
              <TextField
                helperText={error?.message || helperText}
                inputRef={ref}
                {...params}
                InputLabelProps={{ shrink: true }}
                error={!!error}
                label={label}
              />
            )}
            onChange={(_, selected) => {
              onChangeRHF(selected)
              onChange?.()
            }}
          />
        )
      }}
    />
  )
}
