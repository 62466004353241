export const getAttendeeDescription = (attendee: {
  userId: string
  isHost: boolean
  isOptional: boolean
}) => {
  const status = []

  if (attendee.isHost) {
    status.push('Host')
  }

  if (attendee.isOptional) {
    status.push('Optional')
  }

  return status.join(' · ')
}
