import type { BaseSyntheticEvent, ChangeEvent, FC } from 'react'
import { useCallback, useRef, useState } from 'react'

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'

import type { FieldInputProps } from '../types'

export type MultiChoiceProps = {
  options: string[]
  withOther?: boolean
} & FieldInputProps<string>

const MultiChoice: FC<MultiChoiceProps> = ({
  onChange,
  options,
  value,
  withOther = false,
  disabled,
}) => {
  const [otherValue, setOtherValue] = useState('')
  const [otherChecked, setOtherChecked] = useState(false)
  const otherInputRef = useRef<HTMLInputElement>(null)

  const handleOtherValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOtherValue(event.target.value || '-')
    onChange(event.target.value || '-')
  }

  const handleOtherCheck = useCallback(
    (event: BaseSyntheticEvent) => {
      onChange(otherValue)
      setOtherChecked(event.target.checked)
      if (event.target.checked) {
        setTimeout(() => otherInputRef.current?.focus(), 200)
      }
    },
    [onChange, otherValue],
  )

  return (
    <Box>
      <RadioGroup
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
          setOtherChecked(false)
        }}
      >
        {options.map((option: string) => (
          <FormControlLabel
            key={option}
            control={<Radio />}
            disabled={disabled}
            label={option}
            value={option}
          />
        ))}
      </RadioGroup>
      {withOther && (
        <FormControlLabel
          checked={otherChecked}
          control={<Radio disabled={disabled} />}
          label={
            <Stack alignItems="center" direction="row" spacing={1}>
              <span>Other:</span>
              <TextField
                fullWidth
                disabled={disabled || !otherChecked}
                inputRef={otherInputRef}
                size="small"
                onChange={handleOtherValueChange}
              />
            </Stack>
          }
          sx={{
            width: 'calc(100% + 11px)',
            '.MuiFormControlLabel-label': { width: '100%' },
          }}
          onChange={handleOtherCheck}
        />
      )}
    </Box>
  )
}

export default MultiChoice
