import type { FocusEventHandler, ReactNode, Ref } from 'react'
import { forwardRef, useEffect, useMemo } from 'react'

import type { SxProps } from '@mui/material'
import {
  Autocomplete,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { matchSorter } from 'match-sorter'

import type { CourseSelectItemFragment } from '@core/graphql'
import { useCourseSelectQuery } from '@core/graphql'
import { useLocales } from '@core/hooks'
import { Spinner, UrqlError } from '@core/ui/components'

export type CourseItem = CourseSelectItemFragment

export type CourseSelectProps = {
  label?: string
  placeholder?: string
  name?: string
  onBlur?: FocusEventHandler<HTMLDivElement>
  onChange?: (courseItem: CourseItem | null) => void
  value?: CourseItem | null
  defaultValue?:
    | CourseItem
    | ((courses: CourseItem[]) => CourseItem | null | undefined)
    | null
  error?: boolean
  helperText?: ReactNode
  getValue?: (courses: CourseItem[]) => CourseItem | null | undefined
  sx?: SxProps
  disabled?: boolean
  filterCourses?: (courses: CourseItem[]) => CourseItem[]
  disabledCourses?: string[]
}

const filterOptions = (
  options: CourseItem[],
  { inputValue }: { inputValue: string },
) => matchSorter(options, inputValue, { keys: ['id', 'title', 'description'] })

const CourseSelect = forwardRef<Ref<unknown>, CourseSelectProps>(
  (
    {
      label,
      placeholder,
      onChange,
      error,
      helperText,
      value,
      defaultValue,
      getValue,
      filterCourses,
      disabledCourses,
      ...rest
    },
    ref,
  ) => {
    const { translate } = useLocales()
    const [{ data, error: queryError, fetching }] = useCourseSelectQuery()

    const courses = useMemo(() => {
      const nodes = data?.courseConnection.nodes ?? []
      if (!filterCourses) {
        return nodes
      }
      return filterCourses(nodes)
    }, [data?.courseConnection.nodes, filterCourses])

    const defaultCourse = useMemo(() => {
      if (typeof defaultValue === 'function') {
        return defaultValue(courses) ?? null
      }

      if (typeof defaultValue !== 'undefined') {
        return defaultValue
      }

      return null
    }, [courses, defaultValue])

    const selectedValue = useMemo(() => {
      if (typeof getValue === 'function') {
        return getValue(courses) ?? defaultCourse ?? null
      }

      if (typeof value !== 'undefined') {
        return value
      }

      return null
    }, [courses, defaultCourse, getValue, value])

    useEffect(() => {
      if (defaultCourse) {
        onChange?.(defaultCourse)
      }
    }, [defaultCourse, onChange])

    if (queryError) {
      return <UrqlError error={queryError} />
    }

    if (fetching && !courses) {
      return <Skeleton height={56} variant="rectangular" />
    }

    return (
      <Autocomplete
        disabled={fetching}
        filterOptions={filterOptions}
        id="course-select"
        isOptionEqualToValue={(option, theValue) => option.id === theValue.id}
        loading={fetching}
        options={courses}
        value={selectedValue}
        getOptionDisabled={({ id }) => {
          return disabledCourses?.includes(id) || false
        }}
        getOptionLabel={({ id }) =>
          courses.find((course) => course.id === id)?.title || '(unknown)'
        }
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            inputRef={ref}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {fetching ? <Spinner size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={
              label ?? translate('course:select_course', { capitalize: false })
            }
            placeholder={
              placeholder ||
              translate('course:search_courses', { capitalize: false })
            }
          />
        )}
        renderOption={(props, option) => (
          <li {...props} style={{ display: 'block' }}>
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Stack>
                <Typography fontWeight={500} variant="body1">
                  {option.title}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  <code>{option.id}</code>
                </Typography>
              </Stack>
              <Typography
                color="textSecondary"
                fontStyle="italic"
                variant="body2"
              >
                {disabledCourses?.includes(option.id) &&
                  translate('course_member:already_enrolled')}
              </Typography>
            </Stack>
          </li>
        )}
        onChange={(event, selected) => onChange?.(selected)}
        {...rest}
      />
    )
  },
)

export default CourseSelect
