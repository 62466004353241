import type { FC } from 'react'
import React from 'react'

import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material'
import { GoogleLogo } from '@phosphor-icons/react'

import { ADD_TO_GOOGLE_CALENDAR_BASE_URL } from '@core/config'
import { useLocales } from '@core/hooks'

export type AddToCalendarDialogProps = {
  mentorSessionId: string
  startsAt: string | Date
  endsAt: string | Date
  eventTitle: string
  isOpen: boolean
  onClose?: VoidFunction
}

export const AddToCalendarDialog: FC<AddToCalendarDialogProps> = ({
  mentorSessionId,
  startsAt,
  endsAt,
  eventTitle,
  isOpen,
  onClose,
}) => {
  const { t } = useLocales('mentor_session')

  // convert startsAt and endsAt to UTC basic format

  const formattedStartsAt = new Date(startsAt)
    .toISOString()
    .replace(/[-:]/g, '')
    .slice(0, -5)
  const formattedEndsAt = new Date(endsAt)
    .toISOString()
    .replace(/[-:]/g, '')
    .slice(0, -5)

  const googleCalendarUrl = `${ADD_TO_GOOGLE_CALENDAR_BASE_URL}?dates=${formattedStartsAt}Z/${formattedEndsAt}Z&details&location=${window.location.origin}/go/MENTOR_SESSION_VIEW?mentorSessionId=${mentorSessionId}&text=${eventTitle}`

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        {t('add_to_calendar_dialog_title', `Add to calendar`)}
      </DialogTitle>
      <DialogContent>
        <Alert severity="info">
          {t(
            'add_to_calendar_dialog_alert',
            `You will be redirect to the calendar page. Event details will be automatically filled except for recurrence. Please add recurrence manually.`,
          )}
        </Alert>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Link color="inherit" href={googleCalendarUrl} target="_blank">
          <Button
            color="primary"
            startIcon={<GoogleLogo />}
            variant="contained"
            onClick={onClose}
          >
            Google Calendar
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  )
}
