export const MENTOR_SESSION_ACTIVITY_COLORS: Record<
  string,
  | 'error'
  | 'inherit'
  | 'grey'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | undefined
> = {
  'mentor_session.booking_request.created': 'info',
  'mentor_session.booking_request.updated': 'info',
  'mentor_session.booking_request.accepted': 'success',
  'mentor_session.booking_request.rejected': 'error',
  'mentor_session.booking_request.expired': 'warning',
  'mentor_session.booking_request.canceled': 'error',
  'mentor_session.created': 'primary',
  'mentor_session.canceled': 'error',
  'mentor_session.event.rescheduled': 'info',
  'mentor_session.event.reschedule_request.accepted': 'info',
  'mentor_session.event.batch.rescheduled': 'info',
  'mentor_session.event.reschedule_request.created': 'info',
}
export const MENTOR_SESSION_ACTIVITY_VARIANT: Record<
  string,
  'filled' | 'outlined' | undefined
> = {
  'mentor_session.event.reschedule_request.created': 'outlined',
}
export const DANGER_TIME_BREAKPOINT = 43200 // 12 hours in seconds
