import type { FC } from 'react'

import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import { X } from '@phosphor-icons/react'

import { useViewMentorSessionRecapQuery } from '@core/graphql'
import { ContentSkeleton, DateFormatText, UrqlError } from '@core/ui/components'

import ViewRecap from './ViewRecap'

export type ViewRecapDialogProps = {
  recapId: string
  open: boolean
  onClose: VoidFunction
}

const ViewRecapDialog: FC<ViewRecapDialogProps> = ({
  onClose,
  open,
  recapId,
}) => {
  const [{ data, fetching, error }] = useViewMentorSessionRecapQuery({
    variables: { recapId },
  })

  return (
    <Dialog
      open={open}
      scroll="paper"
      PaperProps={{
        sx: {
          minHeight: 500,
          minWidth: 640,
        },
      }}
      onClose={onClose}
    >
      <DialogTitle>
        {data?.mentorSessionRecap && (
          <>
            Recaps of Mentor Session on{' '}
            <DateFormatText
              disableTooltip
              format="PP"
              variant="format"
              date={
                data.mentorSessionRecap.mentorSessionEvent.calendarEvent
                  .startsAt
              }
            />
          </>
        )}
        <IconButton
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
          }}
          onClick={onClose}
        >
          <X />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mb: 2 }}>
        {error && <UrqlError error={error} />}
        {fetching && !data && <ContentSkeleton />}
        <ViewRecap mentorSessionRecap={data?.mentorSessionRecap} />
      </DialogContent>
    </Dialog>
  )
}

export default ViewRecapDialog
