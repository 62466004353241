import type { FC } from 'react'
import { useEffect, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Tooltip,
} from '@mui/material'
import { Info } from '@phosphor-icons/react'
import { addHours, addWeeks, startOfMinute } from 'date-fns'
import { max } from 'lodash'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import { useCreateMentorSessionEventRescheduleRequestMutation } from '@core/graphql'
import { useYupForm } from '@core/helpers'
import { useLocales } from '@core/hooks'
import { RescheduleActivityIcon } from '@core/icons'
import type { SchemaOf } from '@core/lib/yup'
import { date, object, ref, string } from '@core/lib/yup'
import type { ANY } from '@core/types'
import { ContentSection, DateFormatText, UrqlError } from '@core/ui/components'
import MenuItemDialog from '@core/ui/components/common/MenuItemDialog'
import {
  FormProvider,
  RHFDateTimePicker,
  RHFTextField,
} from '@core/ui/components/hook-form'

export type RescheduleEventFormValues = {
  proposedStartTime: Date
  proposedEndTime: Date
  reason?: string | null
}

// Prevent default time causing validation error
export const RESCHEDULE_TIME_BUFFER = 1
export const DEFAULT_SESSION_EVENT_DURATION = 1

const useRescheduleFormValidationSchema =
  (): SchemaOf<RescheduleEventFormValues> => {
    return object({
      proposedStartTime: date().label('Proposed Start Time').required(),
      proposedEndTime: date()
        .label('Proposed End Time')
        .required()
        .min(ref('proposedStartTime'), 'Must be after proposed start time'),
      reason: string().label('Notes').required(),
    })
  }

export type RescheduleSessionEventDialogProps = {
  sessionEvent: ANY
  mentorSession: ANY
  isOpen: boolean
  onClose: VoidFunction
  nextEventStartsAt?: Date
  prevEventEndsAt?: Date
}

const RescheduleSessionEventRequestDialog: FC<
  RescheduleSessionEventDialogProps
> = ({ isOpen, onClose, sessionEvent, nextEventStartsAt, prevEventEndsAt }) => {
  const { t } = useLocales('mentor_session')

  const { enqueueSnackbar } = useSnackbar()
  const now = useMemo(() => new Date(), [])

  const [{ fetching, error }, mutateCreateEventRescheduleRequest] =
    useCreateMentorSessionEventRescheduleRequestMutation()

  const minSelectableDateTime = useMemo(
    () =>
      prevEventEndsAt
        ? // Min date is the end of the previous session,
          // but cannot be in the past
          max([now, prevEventEndsAt])
        : // If there is no prev session, min date is one week before the current session,
          // but cannot be in the past
          max([now, addWeeks(sessionEvent.startsAt, -1)]),
    [now, prevEventEndsAt, sessionEvent.startsAt],
  ) as Date

  const maxSelectableDateTime = useMemo(
    () =>
      // Max date is DEFAULT_SESSION_EVENT_DURATION hour(s) before the start of next session
      nextEventStartsAt
        ? addHours(nextEventStartsAt, -DEFAULT_SESSION_EVENT_DURATION)
        : // If there is no next session, max date is one week from the current session
          addWeeks(sessionEvent.startsAt, 1),
    [nextEventStartsAt, sessionEvent.startsAt],
  )

  const validationSchema = useRescheduleFormValidationSchema()

  const { getFieldProps, resolver } = useYupForm(validationSchema)

  const defaultStartTime = max([
    addHours(now, RESCHEDULE_TIME_BUFFER),
    minSelectableDateTime,
  ]) as Date

  const methods = useForm<RescheduleEventFormValues>({
    resolver,
    defaultValues: {
      proposedStartTime: defaultStartTime,
      proposedEndTime: addHours(
        defaultStartTime,
        DEFAULT_SESSION_EVENT_DURATION,
      ),
    },
  })

  const { watch, setValue, setError, clearErrors } = methods

  const proposedStartTime = watch('proposedStartTime')

  const fieldsDisabled = useMemo(
    () =>
      proposedStartTime
        ? startOfMinute(proposedStartTime).getTime() ===
            startOfMinute(sessionEvent.startsAt).getTime() ||
          proposedStartTime < minSelectableDateTime ||
          proposedStartTime > maxSelectableDateTime
        : true,
    [
      maxSelectableDateTime,
      minSelectableDateTime,
      proposedStartTime,
      sessionEvent.startsAt,
    ],
  )

  useEffect(() => {
    setValue(
      'proposedEndTime',
      addHours(proposedStartTime, DEFAULT_SESSION_EVENT_DURATION),
    )
  }, [fieldsDisabled, proposedStartTime, setValue])

  useEffect(() => {
    // Revalidate proposed start time
    // This is needed because MUI's DatePicker can select a date
    // outside of min/max range when changing between AM/PM
    if (
      proposedStartTime < minSelectableDateTime ||
      proposedStartTime > maxSelectableDateTime
    ) {
      setError('proposedStartTime', {
        type: 'custom',
        message:
          'Proposed Start Time must be after the previous session, before the next session and cannot be in the past.',
      })
    } else {
      clearErrors('proposedStartTime')
    }
  }, [
    clearErrors,
    maxSelectableDateTime,
    minSelectableDateTime,
    proposedStartTime,
    setError,
  ])

  const handleCreateRequestReschedule = async (
    values: RescheduleEventFormValues,
  ) => {
    const newStartsAt = startOfMinute(values.proposedStartTime)
    const newEndsAt = startOfMinute(values.proposedEndTime)

    const { error: createEventRescheduleRequestError } =
      await mutateCreateEventRescheduleRequest({
        input: {
          calendarEventId: sessionEvent.id,
          newStartsAt,
          newEndsAt,
          reason: values.reason,
        },
      })

    if (!createEventRescheduleRequestError) {
      enqueueSnackbar(t('mentor_session_event_reschedule_request_created'), {
        variant: 'success',
      })
      onClose()
    }
  }

  return (
    <MenuItemDialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Stack alignItems="center" direction="row" spacing={1}>
          <RescheduleActivityIcon size="1.5rem" />
          <Box>Create reschedule request?</Box>
        </Stack>
      </DialogTitle>
      <FormProvider methods={methods}>
        <form onSubmit={methods.handleSubmit(handleCreateRequestReschedule)}>
          <DialogContent>
            <Stack spacing={2}>
              <ContentSection label="Current Session Date Time">
                <DateFormatText
                  disableTooltip
                  date={sessionEvent.startsAt}
                  format="ccc, MMM dd, yyyy, HH:mm"
                  variant="format"
                />
                {' - '}
                <DateFormatText
                  disableTooltip
                  date={sessionEvent.endsAt}
                  format="HH:mm a"
                  variant="format"
                />
              </ContentSection>
              <Divider />

              <RHFDateTimePicker
                {...getFieldProps('proposedStartTime')}
                defaultOpen
                disabled={fetching}
                inputFormat="ccc, MMM dd, yyyy, HH:mm a"
                maxDateTime={maxSelectableDateTime}
                minDateTime={minSelectableDateTime}
              />
              <RHFDateTimePicker
                {...getFieldProps('proposedEndTime')}
                disabled
                inputFormat="ccc, MMM dd, yyyy, HH:mm a"
                helperText={
                  <>
                    Session duration is currently fixed to{' '}
                    <b>{DEFAULT_SESSION_EVENT_DURATION} hour</b>.
                  </>
                }
              />
              <RHFTextField
                {...getFieldProps('reason')}
                multiline
                disabled={fetching}
                rows={3}
              />

              {error && <UrqlError error={error} />}
            </Stack>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Tooltip title="Proposed Start Time must be after the previous session and before the next session. Session cannot be rescheduled to the past.">
              <Box
                component={Info}
                size="1.5rem"
                sx={{ color: (theme) => theme.palette.info.main }}
                weight="fill"
              />
            </Tooltip>
            <Stack direction="row" spacing={1}>
              <Button
                color="inherit"
                disabled={fetching}
                variant="outlined"
                onClick={onClose}
              >
                {t('common:nevermind')}
              </Button>
              <LoadingButton
                disabled={fieldsDisabled}
                loading={fetching}
                loadingPosition="center"
                sx={{ boxShadow: 0 }}
                variant="contained"
                onClick={methods.handleSubmit(handleCreateRequestReschedule)}
              >
                Create request
              </LoadingButton>
            </Stack>
          </DialogActions>
        </form>
      </FormProvider>
    </MenuItemDialog>
  )
}

export default RescheduleSessionEventRequestDialog
