import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'

export type EventHandler = (event?: KeyboardEvent) => void

/** Handle keydown events
 * @param key - The key to listen for
 * @param handler - The handler to call when the key is pressed
 * @param element - The element to listen for the keydown event on. Defaults to window
 */
export function useKeyDown<T extends HTMLElement | Document | void = void>(
  key: string | string[],
  handler: EventHandler,
  element?: RefObject<T>,
) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    // Get target element from params or default to window
    const targetElement: T | Window = element?.current ?? window

    if (!(targetElement && targetElement.addEventListener)) {
      return undefined
    }

    const listener = ((event: KeyboardEvent) => {
      const keys = Array.isArray(key) ? key : [key]

      if (!keys.some((k) => k.toLowerCase() === event.key.toLowerCase())) {
        return
      }

      // Prevent default browser behavior
      event.preventDefault()

      // Call handler
      handlerRef.current(event)
    }) as EventListener

    // Add event listener to target element
    targetElement.addEventListener('keydown', listener)

    // Cleanup
    return () => {
      targetElement.removeEventListener('keydown', listener)
    }
  }, [element, key])
}
