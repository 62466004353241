import type { FC } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { useMatch } from '@tanstack/react-location'
import { format } from 'date-fns'

import {
  useMentorSessionEventViewQuery,
  useMentorSessionViewQuery,
} from '@core/graphql'
import {
  ContentSection,
  EmptyState,
  ListItemSkeleton,
  UrqlError,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'
import UserEventStatusIcon from 'apps/workplace/modules/calendar/components/UserEventStatusIcon'

export type MentorSessionEventGeneralProps = {}

const MentorSessionEventGeneral: FC<MentorSessionEventGeneralProps> = () => {
  const {
    params: { mentorSessionId, eventId },
  } = useMatch()
  const [{ data: msData, fetching: msFetching, error: msError }] =
    useMentorSessionViewQuery({
      variables: { id: mentorSessionId },
    })
  const [{ data: evData, error: evError, fetching: evFetching }] =
    useMentorSessionEventViewQuery({
      variables: { eventId },
    })

  const mentorSession = msData?.mentorSession
  const event = evData?.mentorSessionEvent.calendarEvent

  const error = msError || evError
  const fetching = msFetching || evFetching

  if (error) {
    return <UrqlError error={error} />
  }

  if (fetching && !mentorSession) {
    return <ListItemSkeleton />
  }

  if (!(mentorSession && event)) {
    return <EmptyState />
  }

  return (
    <Stack spacing={2}>
      <ContentSection label="Course">
        {mentorSession.course?.title ?? 'N/A'}
      </ContentSection>
      <ContentSection label="When">
        <Typography>{format(event.startsAt, 'iii, dd MMM yyyy')}</Typography>
        <Typography>
          {format(event.startsAt, 'HH:mm')} – {format(event.endsAt, 'HH:mm a')}{' '}
        </Typography>
      </ContentSection>
      <ContentSection label="Attendees">
        <Stack mt={1} spacing={0.5}>
          {event.attendees.map((attendee) => (
            <Stack
              key={attendee.id}
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <UserAvatar userId={attendee.userId} />
              <Box>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <UserDisplayName userId={attendee.userId} />{' '}
                  <UserEventStatusIcon status={attendee.status} />
                </Stack>
                <Typography color="textSecondary" variant="body2">
                  {attendee.user.email}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </ContentSection>
    </Stack>
  )
}

export default MentorSessionEventGeneral
