import { useState, type FC, useEffect } from 'react'

import { Box, Fade, keyframes } from '@mui/material'
import { PauseCircle } from '@phosphor-icons/react'
import { PlayCircle } from '@phosphor-icons/react/dist/ssr'

import { Spinner } from '@core/ui/components'

import { useVideoPlayerStore } from '../../VideoPlayerStore'

export type MidContainerProps = { onPlayPause?: VoidFunction }

const BEZEL_ICON_SIZE = 64

const MidContainer: FC<MidContainerProps> = ({ onPlayPause }) => {
  const [isAnimating, setIsAnimating] = useState(false)

  const { playerState } = useVideoPlayerStore()

  const { buffering, playing, showController } = playerState

  useEffect(() => {
    // Start bezel fadeout animation on play/pause
    setIsAnimating(true)
  }, [playing])

  return (
    <Fade in={buffering || showController}>
      <Box
        // Play/pause video when clicking on middle section
        width="100%"
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={onPlayPause}
      >
        {buffering && playing && <Spinner size="large" variant="centered" />}

        {!buffering && (
          <>
            <Box
              sx={getBezelSx('play')}
              className={`${isAnimating ? 'is-bezel-animating' : ''}${
                !playing ? '-play' : ''
              }`}
              onAnimationEnd={() => setIsAnimating(false)}
            >
              <PlayCircle color="white" size={BEZEL_ICON_SIZE} weight="fill" />
            </Box>
            <Box
              sx={getBezelSx('pause')}
              className={`${isAnimating ? 'is-bezel-animating' : ''}${
                playing ? '-pause' : ''
              }`}
              onAnimationEnd={() => setIsAnimating(false)}
            >
              <PauseCircle color="white" size={BEZEL_ICON_SIZE} weight="fill" />
            </Box>
          </>
        )}
      </Box>
    </Fade>
  )
}

const bezelFadeout = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
`

const getBezelSx = (type: 'play' | 'pause') => ({
  opacity: 0,
  transform: 'scale(1)',
  position: 'absolute',
  top: `calc(50% - ${BEZEL_ICON_SIZE / 2}px)`,
  left: `calc(50% - ${BEZEL_ICON_SIZE / 2}px)`,
  [`&.is-bezel-animating-${type}`]: {
    animation: `${bezelFadeout} .4s linear 1 normal forwards`,
  },
})

export default MidContainer
