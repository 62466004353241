import type { FC } from 'react'
import { useCallback } from 'react'

import {
  MENTOR_SESSION_ACTIVITY_COLORS,
  MENTOR_SESSION_ACTIVITY_VARIANT,
} from '@core/config/mentor-session'
import type { BookingRequestViewFragment } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { ANY, ArrayElement } from '@core/types'
import {
  ActivityLogTimeline,
  GoToLink,
  UserDisplayName,
} from '@core/ui/components'

import BatchRescheduleActivity from './components/BatchRescheduleActivity'
import CanceledMentorSessionActivity from './components/CanceledMentorSessionActivity'
import CreatedMentorSessionActivity from './components/CreatedMentorSessionActivity'
import ExtendedMentorSessionActivity from './components/ExtendedMentorSessionActivity'
import MarkedEventAsFinishedActivity from './components/MarkedEventAsFinishedActivity'
import RequestRescheduleActivity from './components/RequestRescheduleActivity'
import RescheduleActivity from './components/RescheduleActivity'

export type ActivityLogItem = ArrayElement<
  BookingRequestViewFragment['activityLogs']
>

export type MentorSessionActivityLogTimelineProps = {
  activityLogs: ActivityLogItem[]
}

const MentorSessionActivityLogTimeline: FC<
  MentorSessionActivityLogTimelineProps
> = ({ activityLogs }) => {
  const { t } = useLocales('activities')
  // filter activity logs with activity === 'mentor_session.event.status.updated' and no userId
  const filteredActivityLogs = activityLogs.filter((activityLog) => {
    if (
      activityLog.activity === 'mentor_session.event.status.updated' &&
      !activityLog.userId
    ) {
      return false
    }
    return true
  })
  const getContent = useCallback(
    (activityLog: ActivityLogItem, data: ANY) => {
      const { activity } = activityLog
      if (activity === 'mentor_session.created') {
        return (
          <>
            <GoToLink
              link="MENTOR_SESSION_VIEW"
              mentorSessionId={data.mentorSessionId}
            >
              Recurring sessions
            </GoToLink>
            {' created'}
            {activityLog.userId && (
              <>
                {' '}
                by{' '}
                <UserDisplayName display="inline" userId={activityLog.userId} />
              </>
            )}
          </>
        )
      }
      if (activity === 'mentor_session.extended') {
        return (
          <ExtendedMentorSessionActivity
            data={data}
            userId={activityLog.userId}
          />
        )
      }
      if (activity === 'mentor_session.booking_request.accepted') {
        return (
          <>
            {activityLog.userId && (
              <UserDisplayName display="inline" userId={activityLog.userId} />
            )}
            {' accepted the '}
            <GoToLink
              link="MENTOR_SESSION_BOOKING_REQUEST_VIEW"
              requestId={data.userRequestId}
            >
              booking request
            </GoToLink>
          </>
        )
      }
      if (activity === 'mentor_session.event.canceled') {
        return (
          <CanceledMentorSessionActivity
            data={data}
            userId={activityLog.userId}
          />
        )
      }
      if (activity === 'mentor_session.event.created') {
        return (
          <CreatedMentorSessionActivity
            data={data}
            userId={activityLog.userId}
          />
        )
      }

      if (activity === 'mentor_session.event.reschedule_request.created') {
        return (
          <RequestRescheduleActivity data={data} userId={activityLog.userId} />
        )
      }

      if (activity === 'mentor_session.event.reschedule_request.accepted') {
        return (
          <>
            {activityLog.userId && (
              <UserDisplayName display="inline" userId={activityLog.userId} />
            )}{' '}
            {`${t(activityLog.activity, {
              upperFirst: !activityLog.userId,
            })}`}
            <RescheduleActivity data={data} />
          </>
        )
      }

      if (
        activity === 'mentor_session.event.status.updated' &&
        activityLog.userId
      ) {
        return (
          <MarkedEventAsFinishedActivity
            data={data}
            userId={activityLog.userId}
          />
        )
      }

      return (
        <>
          {activityLog.userId && (
            <UserDisplayName display="inline" userId={activityLog.userId} />
          )}{' '}
          {`${t(activityLog.activity, {
            upperFirst: !activityLog.userId,
          })}`}
          {activity === 'mentor_session.event.rescheduled' && (
            <RescheduleActivity data={data} />
          )}
          {activity === 'mentor_session.event.batch.rescheduled' && (
            <BatchRescheduleActivity data={data} />
          )}
        </>
      )
    },
    [t],
  )

  return (
    <ActivityLogTimeline
      activityLogs={filteredActivityLogs}
      getContent={getContent}
      getDescription={(activityLog, data: ANY) =>
        data?.reason ? { title: 'Notes', content: data.reason } : null
      }
      getTimelineDotProps={(activityLog: { activity: string }) => ({
        color: MENTOR_SESSION_ACTIVITY_COLORS[activityLog.activity],
        variant: MENTOR_SESSION_ACTIVITY_VARIANT[activityLog.activity],
      })}
    />
  )
}

export default MentorSessionActivityLogTimeline
