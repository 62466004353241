import type { FC } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { ArrowRight } from '@phosphor-icons/react'

import { MentorSessionRecapType } from '@core/graphql'
import { LearnerIcon, MentorIcon } from '@core/icons'

const userTypeColorMap: Record<
  MentorSessionRecapType,
  { from: string; to: string }
> = {
  [MentorSessionRecapType.MENTEE_RECAP]: {
    from: 'learner.main',
    to: 'mentor.main',
  },
  [MentorSessionRecapType.MENTOR_RECAP]: {
    from: 'mentor.main',
    to: 'learner.main',
  },
}

export type RecapFromToProps = {
  from?: string
  to: string
  type: MentorSessionRecapType
}

const RecapFromTo: FC<RecapFromToProps> = ({ from, to, type }) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {type === MentorSessionRecapType.MENTEE_RECAP ? (
          <LearnerIcon />
        ) : (
          <MentorIcon />
        )}
        <Typography
          color={userTypeColorMap[type].from}
          fontWeight={600}
          ml={0.5}
        >
          {from || 'You'}
        </Typography>
      </Box>

      <ArrowRight weight="bold" />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {type === MentorSessionRecapType.MENTEE_RECAP ? (
          <MentorIcon />
        ) : (
          <LearnerIcon />
        )}
        <Typography
          noWrap
          color={userTypeColorMap[type].to}
          fontWeight={600}
          ml={0.5}
          sx={{
            maxWidth: 340,
            overflow: 'hidden',
          }}
        >
          {to}
        </Typography>
      </Box>
    </Stack>
  )
}

export default RecapFromTo
