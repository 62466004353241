import type { FC } from 'react'

import { Box, Container, Fade, Skeleton, Stack } from '@mui/material'

export type RouteSkeletonProps = {}

export const RouteSkeleton: FC<RouteSkeletonProps> = () => {
  return (
    <Fade
      in
      unmountOnExit
      style={{
        transitionDelay: '800ms',
      }}
    >
      <Container sx={{ py: 6 }}>
        <Box>
          <Skeleton width="40%" />
          <Skeleton width="80%" />
          <Stack direction="row" spacing={1}>
            <Skeleton width="10%" />
            <Skeleton width="15%" />
            <Skeleton width="10%" />
          </Stack>

          <Skeleton width="40%" />
          <Skeleton width="55%" />
          <Stack direction="row" spacing={1}>
            <Skeleton width="10%" />
            <Skeleton width="15%" />
            <Skeleton width="10%" />
          </Stack>
        </Box>
      </Container>
    </Fade>
  )
}
