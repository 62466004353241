import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import type { TypographyProps } from '@mui/material'
import { Typography } from '@mui/material'
import type { Variant } from '@mui/material/styles/createTypography'
import type { LinkProps } from '@tanstack/react-location'
import { Link } from '@tanstack/react-location'

import GetFontValue from '../../../utils/getFontValue'

type IProps = TypographyProps & LinkProps

interface Props extends IProps {
  line?: number
  asLink?: boolean
  persistent?: boolean
  children: ReactNode
  variant?: Variant
}

const TextMaxLine = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      asLink,
      variant = 'body1',
      line = 2,
      persistent = false,
      children,
      sx,
      ...other
    },
    ref,
  ) => {
    const { lineHeight } = GetFontValue(variant)

    const style = {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitLineClamp: line,
      WebkitBoxOrient: 'vertical',
      ...(persistent && {
        height: lineHeight * line,
      }),
      ...sx,
    } as const

    if (asLink || other.to) {
      return (
        <Typography
          component={Link}
          ref={ref}
          sx={{ ...style }}
          variant={variant}
          {...other}
        >
          {children}
        </Typography>
      )
    }

    return (
      <Typography ref={ref} sx={{ ...style }} variant={variant} {...other}>
        {children}
      </Typography>
    )
  },
)

export default TextMaxLine
