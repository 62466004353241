import type { FC, ReactNode } from 'react'
import { memo } from 'react'

import type { Permission, UserRole } from '@core/graphql'
import type { ANY } from '@core/types'
import { Forbidden } from '@core/ui/components'

import { useAuth } from './useAuth'

export type AuthGuardProps = {
  permission?: Permission
  permissions?: Permission[]
  role?: UserRole
  roles?: UserRole[]
  children?:
    | ReactNode
    | ((props: { granted: boolean; isLoading: boolean }) => ReactNode)
  render?:
    | ReactNode
    | ((props: { granted: boolean; isLoading: boolean }) => ReactNode)
  fallback?: ReactNode
  loading?: ReactNode
  isPage?: boolean
}

export const AuthGuard: FC<AuthGuardProps> = ({
  permission,
  permissions = [],
  role,
  roles = [],
  children,
  fallback,
  loading,
  render,
  isPage,
}) => {
  const { hasPermission, hasRole, isLoading, user, signOut } = useAuth()

  const isGranted =
    hasPermission(permission, ...permissions) || hasRole(role, ...roles)

  const renderContent = children || render || null

  if (typeof renderContent === 'function') {
    return renderContent({ granted: isGranted, isLoading })
  }

  if (!user && isLoading) {
    return loading as ANY
  }

  if (!user) {
    signOut()
  }

  if (!isGranted) {
    if (fallback) {
      return fallback
    }
    if (isPage) {
      return <Forbidden />
    }
    return null
  }

  return renderContent
}

export default memo(AuthGuard)
