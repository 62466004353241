import type { FC } from 'react'

import { Box, Fade } from '@mui/material'

import { UI } from '@core/config'

import Image from '../data-display/Image'

import Spinner from './Spinner'

export type SplashScreenProps = {}

const LOGO_SIZE = {
  height: 80,
  width: 526,
}

const SplashScreen: FC<SplashScreenProps> = () => {
  return (
    <Fade in unmountOnExit timeout={1000}>
      <Box sx={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
        <Box>
          <Image
            alt="CoderSchool Platform"
            src="/static/img/coderschool-logo.svg"
            title="CoderSchool Platform"
            sx={{
              width: LOGO_SIZE.width,
              height: LOGO_SIZE.height,
              mb: 4,
              filter: UI.LOGO_FILTER,
            }}
          />
          <Fade
            in
            timeout={1000}
            style={{
              transitionDelay: '1000ms',
            }}
          >
            <Box>
              <Spinner variant="centered" />
            </Box>
          </Fade>
        </Box>
      </Box>
    </Fade>
  )
}

export default SplashScreen
