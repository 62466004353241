import type { FC, KeyboardEvent } from 'react'
import { useCallback } from 'react'

import type { InputProps, Theme } from '@mui/material'
import { Input } from '@mui/material'

import type { ANY } from '@core/types'

export type InlineInputProps = InputProps & {
  textVariant?: keyof Theme['typography']
  onDismiss?: VoidFunction
  onSubmit?: VoidFunction
  disableKeyboardSubmit?: boolean
  disableKeyboardDismiss?: boolean
  dismissOnBlur?: boolean
}

export const InlineInput: FC<InlineInputProps> = ({
  textVariant,
  onDismiss,
  onSubmit,
  disableKeyboardDismiss,
  disableKeyboardSubmit,
  dismissOnBlur,
  ...props
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key === 'Enter' && !disableKeyboardSubmit && onSubmit) {
        if (!props.multiline || (props.multiline && event.metaKey)) {
          onSubmit()
        }
      }

      if (event.key === 'Escape' && !disableKeyboardDismiss && onDismiss) {
        onDismiss()
      }
    },
    [
      disableKeyboardDismiss,
      disableKeyboardSubmit,
      onDismiss,
      onSubmit,
      props.multiline,
    ],
  )

  const handleBlur = useCallback(() => {
    if (dismissOnBlur && onDismiss) {
      onDismiss()
    }
  }, [dismissOnBlur, onDismiss])

  return (
    <Input
      autoFocus
      disableUnderline
      fullWidth
      multiline
      sx={{
        '&': textVariant
          ? (theme) => (theme.typography as ANY)[textVariant]
          : {},
        '& > input': { p: 0 },
      }}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...props}
    />
  )
}
