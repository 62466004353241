import { useState, type FC, useEffect, useCallback, useRef } from 'react'

import {
  Box,
  Button,
  Collapse,
  Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Slider,
  Stack,
  Typography,
  sliderClasses,
  styled,
  useTheme,
} from '@mui/material'
import {
  ArrowClockwise,
  ArrowCounterClockwise,
  Check,
  CornersIn,
  CornersOut,
  Pause,
  Play,
  SpeakerHigh,
  SpeakerSlash,
} from '@phosphor-icons/react'
import { isIOS } from 'react-device-detect'

import {
  FORWARD_SECONDS,
  ICON_BUTTON_SIZE,
  ICON_TO_INNER_TEXT_RATIO,
} from '../../VideoPlayer.const'
import { formatVideoTime } from '../../VideoPlayer.util'
import { useVideoPlayerStore } from '../../VideoPlayerStore'

export type BotContainerProps = {
  onVolumeChange?: (value: number) => void
  onPlayPause?: VoidFunction
  onSeek?: (seconds: number) => void
  onPlaybackRateChange?: (value: number) => void
  onFullScreen?: VoidFunction
  onForward?: VoidFunction
  onBackward?: VoidFunction
  onMute?: VoidFunction
}

const BotContainer: FC<BotContainerProps> = ({
  onPlayPause,
  onSeek,
  onFullScreen,
  onForward,
  onBackward,
  onVolumeChange,
  onPlaybackRateChange,
  onMute,
}) => {
  // Predetermine seekTo value to reduce delay
  const [seekTo, setSeekTo] = useState<number | null>(null)

  const { playerState, updatePlayerState } = useVideoPlayerStore()

  const {
    playing,
    totalPlayedSeconds,
    totalDuration,
    volume,
    muted,
    fullscreen,
    playbackRate,
    showController,
  } = playerState

  useEffect(() => {
    // Reset after playedSeconds is updated
    setSeekTo(null)
  }, [totalPlayedSeconds])

  return (
    <Fade in={showController}>
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          pt: 4,
          px: 2,
          pb: 2,
          background:
            'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)',
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <Stack alignItems="center" flexDirection="row">
            {/* Play/Pause button */}
            <MediaControllerIconButton
              size="small"
              sx={{ mr: 1 }}
              onClick={onPlayPause}
            >
              {playing ? (
                <Pause size={ICON_BUTTON_SIZE} weight="fill" />
              ) : (
                <Play size={ICON_BUTTON_SIZE} weight="fill" />
              )}
            </MediaControllerIconButton>
            {/* Volume button */}
            <VolumeButton
              muted={muted}
              volume={volume}
              onMute={onMute}
              onVolumeChange={onVolumeChange}
            />
            {/* Played time - Duration */}
            <Typography color="white" fontWeight={500}>
              {formatVideoTime(seekTo ?? totalPlayedSeconds)} /{' '}
              {formatVideoTime(totalDuration)}
            </Typography>
          </Stack>
          <Stack flexDirection="row">
            {/* Playback rate button */}
            <PlaybackRateButton
              playbackRate={playbackRate}
              showController={showController}
              setSubmenuActive={(value) =>
                updatePlayerState({
                  subMenuActive: value,
                })
              }
              onPlaybackRateChange={onPlaybackRateChange}
            />
            {/* Seek backward button */}
            <MediaControllerIconButton
              disabled={totalPlayedSeconds === 0}
              sx={{
                position: 'relative',
              }}
              onClick={() => {
                setSeekTo(
                  totalPlayedSeconds - FORWARD_SECONDS > 0
                    ? totalPlayedSeconds - FORWARD_SECONDS
                    : 0,
                )
                onBackward?.()
              }}
            >
              <Typography
                fontSize={ICON_BUTTON_SIZE * ICON_TO_INNER_TEXT_RATIO}
                fontWeight="bold"
                sx={{
                  position: 'absolute',
                }}
              >
                {FORWARD_SECONDS}
              </Typography>
              <ArrowCounterClockwise size={ICON_BUTTON_SIZE} />
            </MediaControllerIconButton>
            {/* Seek forward button */}
            <MediaControllerIconButton
              disabled={totalPlayedSeconds === totalDuration}
              sx={{
                position: 'relative',
              }}
              onClick={() => {
                setSeekTo(
                  totalPlayedSeconds + FORWARD_SECONDS < totalDuration
                    ? totalPlayedSeconds + FORWARD_SECONDS
                    : totalDuration,
                )
                onForward?.()
              }}
            >
              <Typography
                fontSize={ICON_BUTTON_SIZE * ICON_TO_INNER_TEXT_RATIO}
                fontWeight="bold"
                sx={{
                  position: 'absolute',
                }}
              >
                {FORWARD_SECONDS}
              </Typography>
              <ArrowClockwise size={ICON_BUTTON_SIZE} />
            </MediaControllerIconButton>
            {/* Fullscreen button */}
            {!isIOS && (
              <MediaControllerIconButton onClick={onFullScreen}>
                {fullscreen ? (
                  <CornersIn size={ICON_BUTTON_SIZE} />
                ) : (
                  <CornersOut size={ICON_BUTTON_SIZE} />
                )}
              </MediaControllerIconButton>
            )}
          </Stack>
        </Stack>
        {/* Seek bar */}
        <SeekBar
          duration={totalDuration}
          playedSeconds={seekTo ?? totalPlayedSeconds}
          onSeek={(seconds: number) => {
            setSeekTo(seconds)
            onSeek?.(seconds)
          }}
        />
      </Stack>
    </Fade>
  )
}

const MediaControllerIconButton = styled(IconButton)(() => ({
  color: 'white',
  ':hover': {
    backgroundColor: 'transparent !important',
  },
}))

const PLAYBACK_RATE_OPTIONS = [0.5, 0.75, 1.0, 1.25, 1.5, 2.0]

const formatPlaybackRate = (rate: number) => {
  return `${rate.toLocaleString('en-US', {
    minimumFractionDigits: 1,
  })}x`
}

const PlaybackRateButton: FC<{
  onPlaybackRateChange?: (value: number) => void
  playbackRate?: number
  showController?: boolean
  setSubmenuActive?: (value: boolean) => void
}> = ({
  onPlaybackRateChange,
  playbackRate,
  showController,
  setSubmenuActive,
}) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const open = !!anchorEl

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (anchorEl !== event.currentTarget) {
        setAnchorEl(event.currentTarget)
      }
    },
    [anchorEl],
  )

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelect = useCallback(
    (value: number) => {
      if (value !== playbackRate) {
        onPlaybackRateChange?.(value)
      }
      handleCloseMenu()
    },
    [handleCloseMenu, onPlaybackRateChange, playbackRate],
  )

  const handleMouseEnterMenu = useCallback(() => {
    setSubmenuActive?.(true)
  }, [setSubmenuActive])

  const handleMouseLeaveMenu = useCallback(() => {
    setSubmenuActive?.(false)
  }, [setSubmenuActive])

  useEffect(() => {
    if (!showController && !!anchorEl) {
      handleCloseMenu()
    }
  }, [anchorEl, handleCloseMenu, showController])

  return (
    <Box
      display="grid"
      ref={containerRef}
      sx={{
        placeItems: 'center',
      }}
    >
      <Button
        aria-controls={open ? 'playback-rate-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        sx={{
          padding: 0,
          color: 'white',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleOpenMenu}
      >
        {playbackRate && playbackRate !== 1.0
          ? formatPlaybackRate(playbackRate)
          : 'Speed'}
      </Button>
      <Menu
        anchorEl={anchorEl}
        container={containerRef.current}
        id="playback-rate-menu"
        open={open}
        anchorOrigin={{
          vertical: -5,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleCloseMenu}
      >
        <MenuList
          dense
          sx={{
            width: 110,
            padding: 0,
          }}
          onMouseEnter={handleMouseEnterMenu}
          onMouseLeave={handleMouseLeaveMenu}
        >
          {PLAYBACK_RATE_OPTIONS.map((rate) => (
            <MenuItem
              key={`playback-rate-${rate}`}
              disableRipple
              sx={{ px: 1 }}
              onClick={() => handleSelect(rate)}
            >
              {playbackRate === rate && (
                <ListItemIcon
                  sx={{
                    minWidth: '20px !important',
                    mr: 1,
                    ml: 1,
                  }}
                >
                  <Check
                    color={theme.palette.primary.main}
                    size="1rem"
                    weight="bold"
                  />
                </ListItemIcon>
              )}
              <ListItemText
                inset={playbackRate !== rate}
                primaryTypographyProps={{
                  sx: {
                    ...(playbackRate === rate
                      ? {
                          color: 'primary.main',
                          fontWeight: '600 !important',
                        }
                      : {}),
                  },
                }}
              >
                {rate === 1 ? 'Normal' : formatPlaybackRate(rate)}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  )
}

const VolumeButton: FC<{
  volume: number
  muted: boolean
  onVolumeChange?: (value: number) => void
  onMute?: VoidFunction
}> = ({ volume, onVolumeChange, onMute, muted }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      justifyContent="flex-end"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <MediaControllerIconButton onClick={onMute}>
        {volume === 0 || muted ? (
          <SpeakerSlash size={ICON_BUTTON_SIZE} />
        ) : (
          <SpeakerHigh size={ICON_BUTTON_SIZE} />
        )}
      </MediaControllerIconButton>
      <Collapse in={hovered && !muted} orientation="horizontal">
        <Box
          width={100}
          sx={{
            display: 'grid',
            placeItems: 'center',
            p: 1,
          }}
        >
          <Slider
            max={100}
            min={0}
            size="medium"
            value={volume * 100}
            sx={{
              height: 6,
              color: 'white',
              [`& .${sliderClasses.thumb}`]: {
                height: 16,
                width: 16,
                boxShadow: 'none !important',
              },
            }}
            onChange={(_, value) => {
              if (typeof value !== 'number') {
                return
              }
              onVolumeChange?.(value / 100)
            }}
          />
        </Box>
      </Collapse>
    </Stack>
  )
}

const SeekBar: FC<{
  duration: number
  playedSeconds: number
  onSeek?: (seconds: number) => void
}> = ({ duration, playedSeconds, onSeek }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 20,
        ml: 1,
        px: 1,
      }}
    >
      <Slider
        max={duration}
        min={0}
        value={playedSeconds}
        sx={{
          color: 'white',
          height: hovered ? 8 : 6,
          transition: 'height 50ms ease-in-out',
          [`& .${sliderClasses.thumb}`]: {
            height: 16,
            width: 16,
            opacity: hovered ? 1 : 0,
            transition: 'opacity 100ms ease-in-out',
            boxShadow: 'none !important',
          },
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onChange={(_, value) => {
          if (typeof value !== 'number') {
            return
          }
          setHovered(true)
          onSeek?.(value)
        }}
      />
    </Box>
  )
}

export default BotContainer
