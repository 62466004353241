import i18n from 'i18next'
import httpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { i18nextPlugin as translationCheck } from 'translation-check'

import namespaces from './namespaces'

i18n
  .use(translationCheck)
  .use(httpBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    ns: namespaces,
    appendNamespaceToMissingKey: true,
    lng: 'en',
    supportedLngs: ['en', 'vi'],
    defaultNS: 'common',
    fallbackLng: 'en',
    fallbackNS: 'common',
    backend: {
      loadPath: '/public/locales/{{lng}}/{{ns}}.json',
      addPath: '/public/locales/add/{{lng}}/{{ns}}',
    },
    // debug: true,
    saveMissing: import.meta.env.DEV,
    // updateMissing: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
