import type { FC } from 'react'

import type { CircularProgressProps } from '@mui/material'
import { Box, CircularProgress, Typography } from '@mui/material'

export type CircularProgressWithLabelProps = CircularProgressProps & {
  value: number
}

const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = (
  props,
) => {
  const { value } = props
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="text.secondary"
          component="div"
          variant="caption"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
