import { useMemo } from 'react'

import { gql } from 'urql'

import { useCoursesFilterListQuery } from '@core/graphql'

export const COURSES_FILTER_QUERY = gql`
  query CoursesFilterList {
    courses {
      id
      title
      description
      totalAllowedTASessions
    }
  }
`

export const useCoursesFilter = () => {
  const [{ error, fetching, data }] = useCoursesFilterListQuery()

  const courses = useMemo(() => data?.courses ?? [], [data?.courses])
  const courseValues = useMemo(
    () =>
      courses.map((course) => ({
        value: course.id,
        label: course.title,
      })),
    [courses],
  )

  return { courses, courseValues, error, fetching, data }
}
