import { useMemo } from 'react'

import type { RequestPolicy } from 'urql'
import { gql } from 'urql'

import type { CourseMemberStatus, CourseMemberType } from '@core/graphql'
import { useCourseMemberListQuery } from '@core/graphql'

export const COURSE_MEMBER_LIST_QUERY = gql`
  fragment CourseMemberItem on CourseMember {
    id
    courseId
    status
    type
    userId
    user {
      id
      email
    }
    learnerId
    learner {
      id
      userId
      lwUser {
        id
        email
      }
    }
    mentorId
    mentor {
      id
      userId
      lwUserId
      status
    }
    createdAt
  }
  query CourseMemberList(
    $courseId: ID
    $status: CourseMemberStatus
    $type: CourseMemberType
  ) {
    courseMembers(courseId: $courseId, status: $status, type: $type) {
      ...CourseMemberItem
    }
  }
`

export const useCourseMembers = (
  variables: {
    courseId?: string
    status?: CourseMemberStatus
    type?: CourseMemberType
  } = {},
  requestPolicy?: RequestPolicy,
) => {
  const [{ error, fetching, data }] = useCourseMemberListQuery({
    variables,
    requestPolicy,
  })

  const courseMembers = useMemo(
    () => data?.courseMembers ?? [],
    [data?.courseMembers],
  )

  return { error, fetching, data, courseMembers }
}
