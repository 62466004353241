import type { FC } from 'react'
import { useMemo } from 'react'

import {
  alpha,
  Box,
  Grid,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { Info, MonitorPlay } from '@phosphor-icons/react'
import { last } from 'lodash'
import numeral from 'numeral'

import { UserAvatar, UserDisplayName } from '@core/ui/components'

import type {
  LearnerMember,
  MemberSessionViewCourse,
} from '../LearnerMemberSessions.type'

export type LearnerMemberHeaderProps = {
  learnerCourseMember: LearnerMember
  course: MemberSessionViewCourse
}

const LearnerMemberHeader: FC<LearnerMemberHeaderProps> = ({
  learnerCourseMember: learnerMember,
  course,
}) => {
  const currentModule = useMemo(() => {
    return learnerMember.latestProgressReport?.currentUnitPath?.section.title
  }, [learnerMember.latestProgressReport?.currentUnitPath?.section.title])

  const nextItem = useMemo(() => {
    const nextUnit =
      last(learnerMember.latestProgressReport?.nextUnitPath?.path) ?? null

    return nextUnit?.title || 'Learner has completed the course'
  }, [learnerMember.latestProgressReport?.nextUnitPath?.path])

  const progress = useMemo(() => {
    return learnerMember.latestProgressReport?.overallProgress ?? 0
  }, [learnerMember.latestProgressReport?.overallProgress])

  const averageScore = useMemo(() => {
    return learnerMember.latestProgressReport?.averageScore ?? 0
  }, [learnerMember.latestProgressReport?.averageScore])

  return (
    <Box
      bgcolor="background.solid"
      borderRadius={3}
      p={5}
      sx={{
        color: (theme) =>
          theme.palette.getContrastText(theme.palette.background.solid),
      }}
    >
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs>
          <Stack spacing={4}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <UserAvatar
                size={72}
                sx={{ '& > * > *': { border: 'solid 3px currentcolor' } }}
                userId={learnerMember.userId}
              />
              <Box>
                <UserDisplayName
                  color="currentcolor"
                  userId={learnerMember.userId}
                  variant="h4"
                />
                <Typography>{course?.title ?? 'N/A'}</Typography>
              </Box>
            </Stack>
            <Stack spacing={1}>
              {currentModule && (
                <Stack alignItems="center" direction="row" spacing={1}>
                  <span style={{ color: '#A1ADB1' }}>Current Module:</span>{' '}
                  <Highlight>{currentModule}</Highlight>
                </Stack>
              )}

              <Stack alignItems="center" direction="row" spacing={1}>
                {progress ? (
                  <>
                    <span style={{ color: '#A1ADB1' }}>Next Item:</span>{' '}
                    <Highlight>
                      <MonitorPlay /> {nextItem}
                    </Highlight>
                  </>
                ) : (
                  <Highlight>
                    <Info size="1.1rem" /> Learner has not started the course
                  </Highlight>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item>
          <Box
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.common.white, 0.08),
              borderRadius: 3,
              padding: 3,
              width: 320,
            })}
          >
            <Stack spacing={5}>
              <ProgressItem
                leftLabel="Overall Progress"
                progressValue={100 * progress}
                rightLabel={numeral(progress).format('0.0%')}
              />
              <ProgressItem
                leftLabel="Average Score"
                progressValue={averageScore}
                rightLabel={`${numeral(averageScore).format('0.0')}/100`}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const ProgressItem: FC<{
  leftLabel: string
  rightLabel: string
  progressValue: number
}> = ({ leftLabel, progressValue, rightLabel }) => {
  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Typography>{leftLabel}</Typography>
        <Typography>{rightLabel}</Typography>
      </Stack>

      <LinearProgress
        sx={{ height: 10 }}
        value={progressValue}
        variant="determinate"
      />
    </Stack>
  )
}

const Highlight = styled('span')(({ theme }) => ({
  backgroundColor: 'rgba(138, 153, 158, 0.12)',
  padding: '2px 6px',
  borderRadius: theme.shape.borderRadius,
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

export default LearnerMemberHeader
