import type { FC } from 'react'

import type { LoadingButtonProps } from '@mui/lab'
import { LoadingButton } from '@mui/lab'
import type { SxProps, Theme } from '@mui/material'

import { LINKS } from '@core/config'
import { JoinEventMeetingIcon } from '@core/icons'
import { buildPath } from '@core/utils/buildPath'
import { fCountDown } from '@core/utils/formatTime'

export type JoinEventMeetingButtonProps = {
  mentorSessionId: string
  eventId: string
  duration?: Duration
  color?: LoadingButtonProps['color']
  sx?: SxProps<Theme>
  zoomMeetingId?: string | null
}

const JoinEventMeetingButton: FC<JoinEventMeetingButtonProps> = ({
  mentorSessionId,
  eventId: _eventId,
  duration,
  color,
  sx,
  zoomMeetingId,
}) => {
  // const { enqueueSnackbar } = useSnackbar()
  // const [{ fetching }, getEventMeetingUrlMutation] =
  //   useGetEventMeetingUrlMutation()

  // const search = useSearch<SearchGenerics<{ meetBy?: string }>>()

  // const meetByGM =
  //   import.meta.env.VITE_USE_GOOGLE_MEET === 'true' || search.meetBy === 'gm'

  // const handleJoinSession = useCallback(async () => {
  //   const { error, data } = await getEventMeetingUrlMutation({ eventId })
  //   if (error) {
  //     enqueueSnackbar(error.message, { variant: 'error' })
  //     return
  //   }
  //   if (data?.getEventMeetingUrl) {
  //     window.open(data?.getEventMeetingUrl, '_blank')
  //   }
  // }, [getEventMeetingUrlMutation, eventId, enqueueSnackbar])

  return (
    <LoadingButton
      color={color}
      // loading={fetching}
      loadingPosition="center"
      startIcon={<JoinEventMeetingIcon />}
      sx={sx}
      variant="contained"
      href={
        zoomMeetingId
          ? undefined
          : buildPath(LINKS.DASKALOS_MEETING_VIEW, {
              mentorSessionId,
            })
      }
      onClick={
        zoomMeetingId
          ? () =>
              window.open(
                buildPath(LINKS.ZOOM_MEETING, {
                  zoomMeetingId,
                }),
                '_blank',
              )
          : undefined
      }
    >
      Join now
      {duration &&
        ` (${fCountDown(duration.minutes)}:${fCountDown(duration.seconds)})`}
    </LoadingButton>
  )
}

export default JoinEventMeetingButton
