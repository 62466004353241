import type { FC } from 'react'

import {
  Box,
  darken,
  FormHelperText,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material'

import type { FieldInputProps } from '../types'

const SPACING = 2
const QUESTION_COL = 5

export type MultiChoiceGridProps = {
  rows: string[]
  columns: string[]
} & FieldInputProps<Record<string, string>>

const MultiChoiceGrid: FC<MultiChoiceGridProps> = ({
  columns,
  onChange,
  rows,
  value,
  disabled,
  error,
}) => {
  const handleCheck = (row: string, col: string) => () => {
    onChange({ ...value, [row]: col })
  }

  return (
    <Stack
      spacing={1}
      sx={{
        width: {
          xs: '30rem',
          md: '100%',
        },
      }}
    >
      <Box p={1}>
        <Grid container alignItems="center" spacing={SPACING}>
          <Grid item xs={QUESTION_COL} />
          {columns.map((col) => (
            <Grid key={col} item sx={{ flex: 1 }}>
              <Typography align="center" variant="body2">
                {col}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      {rows.map((row) => (
        <Box
          key={row}
          sx={{
            background: (theme) => theme.palette.background.neutral,
            p: 1,
            borderRadius: 1,
            '&:hover': {
              background: (theme) =>
                darken(theme.palette.background.neutral, 0.03),
            },
          }}
        >
          <Grid container alignItems="center" spacing={SPACING}>
            <Grid item xs={QUESTION_COL}>
              <Typography variant="body2">{row}</Typography>
            </Grid>
            {columns.map((col) => (
              <Grid key={col} item sx={{ flex: 1, textAlign: 'center' }}>
                <Radio
                  checked={value[row] === col}
                  disabled={disabled}
                  onChange={handleCheck(row, col)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      {error && (
        <FormHelperText error>
          This question requires one response per row
        </FormHelperText>
      )}
    </Stack>
  )
}

export default MultiChoiceGrid
