import type { FC } from 'react'

import type { Theme } from '@mui/material'
import { alpha, Box, Tooltip } from '@mui/material'
import { Check, Question, X } from '@phosphor-icons/react'

import type { UserEventStatus } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { Icon } from '@core/icons'

export type UserEventStatusIconProps = {
  status: UserEventStatus
}

const STATUS_MAP: Partial<
  Record<
    UserEventStatus,
    {
      icon: Icon
      color: (theme: Theme) => string
    }
  >
> = {
  ACCEPTED: {
    icon: Check,
    color: (theme) => theme.palette.success.main,
  },
  DECLINED: {
    icon: X,
    color: (theme) => theme.palette.error.main,
  },
  TENTATIVE: {
    icon: Question,
    color: (theme) => theme.palette.warning.main,
  },
}

const UserEventStatusIcon: FC<UserEventStatusIconProps> = ({ status }) => {
  const { t } = useLocales('calendar')
  const statusProp = STATUS_MAP[status]

  if (!statusProp) {
    return null
  }

  return (
    <Tooltip title={t(`user_event_status.${status}`)}>
      <Box
        sx={{
          height: 24,
          width: 24,
          borderRadius: '100%',
          background: (theme) => alpha(statusProp.color(theme), 0.15),
          color: (theme) => statusProp.color(theme),
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <statusProp.icon weight="bold" />
      </Box>
    </Tooltip>
  )
}

export default UserEventStatusIcon
