import { FormFieldType } from '@core/ui/components/forms/CustomFormInput/types'

export const MENTEE_RECAP_FORM_CONFIG = {
  name: 'MENTEE_RECAP_FORM',
  fields: [
    {
      question: 'What did the session cover? Check all that applied.',
      isRequired: true,
      type: FormFieldType.CHECKBOXES,
      config: {
        options: [
          'Getting to know each other',
          'Sharing industry / job related advices and knowledge',
          'Explaining unclear points in lesson',
          'Reviewing and debugging exercises',
          'Test / Exam',
          'Presenting project',
        ],
      },
    },
    {
      question:
        'Regrading this session, do you agree or disagree with these statements?',
      description:
        '🖐 If there was any misconduct or concern happened, please do not hesitate to contact our Student Service.',
      isRequired: true,
      type: FormFieldType.MULTI_CHOICE_GRID,
      config: {
        columns: ['Agree', 'Disagree', 'Not Applied'],
        rows: [
          `Mentor understood the content.	`,
          `Mentor helped me understand the content.	`,
          `Mentor's explanation was easy to follow.	`,
          `Mentor gave appropriate answers to my course-related questions.	`,
          `Mentor kept track on my study.	`,
          `Mentor gave suggestion to improve my study progress.	`,
          `I felt comfortable.	`,
          `I felt engaged.	`,
          `I felt supported.	`,
          `I felt like making progress after this session.	`,
          `I felt ready to continue to the next lesson.	`,
          `I need more time with my mentor.`,
        ],
      },
    },
    {
      question: 'Any clarification to the above rating?',
      description:
        '🖐 Feel free to elaborate in any language that you prefer, English or Vietnamese.',
      type: FormFieldType.TEXT,
      config: { multiline: true },
    },
    {
      question: 'In total, how many hours did you study last week?',
      description:
        '✍🏼 Your answer helps us design your own study plan and provide you with the resources that best fit to your progress.',
      isRequired: true,
      type: FormFieldType.MULTIPLE_CHOICE,
      config: {
        options: [
          'Less than 5 hours',
          '5-10 hours',
          '10-15 hours',
          '15-20 hours',
          'More than 20 hours',
        ],
      },
    },
  ],
}
