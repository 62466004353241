import { useCallback } from 'react'

import type { MyMentorFragment } from '@core/graphql'
import { useMyMentorQuery } from '@core/graphql'

const useMentorHook = () => {
  const [{ fetching, error, data }, refetch] = useMyMentorQuery({
    requestPolicy: 'cache-first',
  })

  const refetchMentor = useCallback(() => {
    refetch({
      requestPolicy: 'network-only',
    })
  }, [refetch])

  const mentor: MyMentorFragment | null = data?.myMentor ?? null

  return { mentor, $mentor: mentor!, fetching, error, data, refetchMentor }
}

export default useMentorHook
