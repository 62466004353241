import type { FC, ReactNode } from 'react'

import { Box, Fade, Stack, Typography } from '@mui/material'

import type { ANY } from '@core/types'
import { Image } from '@core/ui/components'

export type EmptyStateProps = {
  message?: string
  actions?: ReactNode
  minHeight?: ANY
  imgSrc?: string
  imgWidth?: number
}

const EmptyState: FC<EmptyStateProps> = ({
  message,
  actions,
  minHeight,
  imgSrc,
  imgWidth,
}) => {
  return (
    <Fade in>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          minHeight: minHeight || 'unset',
        }}
      >
        <Stack alignItems="center" spacing={2}>
          <Image
            alt="empty_state"
            src={imgSrc || '/static/img/404.png'}
            sx={{ maxWidth: imgWidth ?? 280, width: '100%' }}
          />
          {message && (
            <Typography align="center" whiteSpace="break-spaces">
              {message}
            </Typography>
          )}

          {actions && (
            <Stack direction="row" spacing={1}>
              {actions}
            </Stack>
          )}
        </Stack>
      </Box>
    </Fade>
  )
}

export default EmptyState
