import type { FC } from 'react'
import { useEffect, useState } from 'react'

import { Box, Button, IconButton, MenuItem, styled } from '@mui/material'
import { DotsThreeVertical } from '@phosphor-icons/react'

import { SettingsTabIcon } from '@core/icons'
import ActionMenu from '@core/ui/components/inputs/ActionMenu'
import { useLearnerMemberSessionsContext } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessionsContext'

import type {
  MentorSessionMenuAction,
  SessionCalendarEvent,
} from '../LearnerMemberSessions.type'

export type SessionMenuActionsProps = {
  actions: MentorSessionMenuAction
  sessionEvent?: SessionCalendarEvent
}

const SessionMenuActions: FC<SessionMenuActionsProps> = ({
  actions: $actions,
  sessionEvent,
}) => {
  const { state, openDialog, closeDialog, refetchMentorSession } =
    useLearnerMemberSessionsContext()
  const actions =
    typeof $actions === 'function'
      ? $actions({
          mentorSession: state.mentorSession,
          sessionEvent,
          dialogIsOpen: state.dialogIsOpen,
          onCloseDialog: closeDialog,
          onOpenDialog: openDialog,
        })
      : $actions

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    refetchMentorSession()
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!state.dialogIsOpen) {
      handleCloseMenu()
    }
  }, [state.dialogIsOpen])

  if (!actions) {
    return null
  }

  return (
    <>
      <IconButton
        sx={{ minWidth: 'auto', padding: 1 / 1.5 }}
        onClick={handleOpenMenu}
      >
        {sessionEvent ? (
          <DotsThreeVertical weight="bold" />
        ) : (
          <SettingsTabIcon />
        )}
      </IconButton>
      <ActionMenu anchorEl={anchorEl} open={openMenu} onClose={handleCloseMenu}>
        {actions.map((action) => (
          <Box key={action.type}>
            <MenuItem disableRipple divider disabled={action.disabled}>
              <ActionMenuButton
                fullWidth
                color="inherit"
                startIcon={action.icon}
                onClick={() => {
                  openDialog({
                    actionType: action.type,
                    dialogTargetId: sessionEvent?.id,
                  })
                }}
              >
                {action.label}
              </ActionMenuButton>
            </MenuItem>
            {state.selectedActionType === action.type &&
              (sessionEvent
                ? state.dialogTargetId === sessionEvent.id
                : true) &&
              state.dialogIsOpen &&
              action.renderActionDialog}
          </Box>
        ))}
      </ActionMenu>
    </>
  )
}

const ActionMenuButton = styled(Button)(() => ({
  borderRadius: 0,
  justifyContent: 'start',
  paddingLeft: 16,
  fontWeight: 500,
  '&:hover': {
    borderRadius: 0,
  },
}))

export default SessionMenuActions
