import type { FC, ReactNode } from 'react'
import { useMemo } from 'react'

import { Box, Tab, Typography } from '@mui/material'

import type { Icon, IconProps } from '@core/icons'
import type { ANY } from '@core/types'

import LinkBehavior from '../LinkBehavior'

export type NavTabItemProps = {
  label: ReactNode
  href: string
  matchRoutes?: string[]
  icon?: Icon
  IconProps?: IconProps
  count?: number | null
  value?: ANY
  replace?: boolean
}

const NavTabItem: FC<NavTabItemProps> = ({
  href,
  icon: TabIcon,
  IconProps: iconProps,
  label: $label,
  count = null,
  value,
  replace,
}) => {
  const label = useMemo(() => {
    if (typeof count !== 'number') {
      return $label
    }

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {$label}{' '}
        <Box
          sx={{
            ml: 1,
            bgcolor: 'background.neutral',
            borderRadius: 1,
            px: 0.75,
          }}
        >
          <Typography color="inherit" fontWeight="bold" variant="caption">
            {count}
          </Typography>
        </Box>
      </Box>
    )
  }, [$label, count])

  return (
    <Tab
      component={LinkBehavior}
      href={href}
      label={label}
      replace={replace}
      value={value}
      icon={
        TabIcon ? <TabIcon size={20} weight="bold" {...iconProps} /> : undefined
      }
    />
  )
}

NavTabItem.displayName = 'Tab'

export default NavTabItem
