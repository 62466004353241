import { lazy } from 'react'

import { Navigate } from '@tanstack/react-location'

import MentorSessionEventView from '@modules/mentor-session/MentorSessionEventView'
import MentorSessionEventGeneral from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventGeneral'
import MentorSessionEventLearners from '@modules/mentor-session/MentorSessionEventView/pages/MentorSessionEventLearners'
import LearnerMembersListPage from 'apps/mentor/LearnerMembersList'
import LearnerMemberSessionsForMentor from 'apps/mentor/mentor-session/LearnerMemberSessionsForMentor'
import MentorSessionBookingRequestListPage from 'apps/mentor/mentor-session/MentorSessionBookingRequestsList'
import UpcomingMentorSessionList from 'apps/mentor/mentor-session/UpcomingMentorSessionList'
import MentorProfileSettings from 'apps/mentor/mentor-settings/profile'
import MentorSchedules from 'apps/mentor/MentorSchedules'
import AccountSettings from 'apps/workplace/modules/user-account/user-settings/components/account-settings/AccountSettings'
import ProfileSettings from 'containers/common/ProfileSettings.container'

import type { GenericsRoute } from '../routes.type'

const NotificationsPage = lazy(
  () => import('@core/ui/components/notification/NotificationsPage'),
)

const MentorRoute: GenericsRoute = {
  path: 'mentor',
  element: <Navigate replace to="/v2/daskalos" />,
  children: [
    // {
    //   path: '/',
    //   element: <MentorDashboard />,
    // },
    { path: '/notifications', element: <NotificationsPage /> },
    {
      path: 'settings',
      element: <ProfileSettings />,
      children: [
        { path: '/', element: <MentorProfileSettings /> },
        { path: '/availability', element: <MentorSchedules /> },
        { path: '/security', element: <AccountSettings /> },
      ],
    },
    {
      path: '/mentor-sessions/:mentorSessionId/events/:eventId',
      element: <MentorSessionEventView viewBy="mentor" />,
      children: [
        { path: '/', element: <MentorSessionEventGeneral /> },
        { path: '/learners', element: <MentorSessionEventLearners /> },
      ],
    },
    {
      path: '/mentor-sessions/:mentorSessionId',
      element: <LearnerMemberSessionsForMentor />,
    },
    {
      path: '/mentor-sessions',
      element: <UpcomingMentorSessionList />,
    },
    {
      path: '/booking-requests',
      element: <MentorSessionBookingRequestListPage />,
    },
    {
      path: '/learners',
      element: <LearnerMembersListPage />,
    },
    { element: <Navigate replace to="mentor-sessions" /> },
  ],
}

export default MentorRoute
