import type { FC, SyntheticEvent } from 'react'
import { useCallback, useDeferredValue, useMemo, useState } from 'react'

import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Stack,
} from '@mui/material'

import type { UserAutocompleteQuery } from '@core/graphql'
import { useUserAutocompleteQuery } from '@core/graphql'
import type { ANY, ArrayElement } from '@core/types'

import UserAvatar from '../../data-display/UserAvatar'
import UserDisplayName from '../../data-display/UserDisplayName'

type UserItem = ArrayElement<
  NonNullable<UserAutocompleteQuery['userConnection']['nodes']>
>

export type UserAutocompleteProps = {
  label?: string
  placeholder?: string
  onSelectUser?: (user: UserItem) => void
  excludedUserIds?: string[]
}

const UserAutocomplete: FC<UserAutocompleteProps> = ({
  label,
  placeholder,
  onSelectUser,
  // excludedUserIds,
}) => {
  const [inputValue, setInputValue] = useState('')
  const searchText = useDeferredValue(inputValue)

  const [{ data, fetching }] = useUserAutocompleteQuery({
    variables: { searchText },
    pause: inputValue !== searchText,
  })

  const users = useMemo(
    () => data?.userConnection.nodes ?? [],
    [data?.userConnection.nodes],
  )

  const handleSelect = useCallback(
    (event: SyntheticEvent, value: UserItem) => {
      onSelectUser?.(value)
      setInputValue('')
    },
    [onSelectUser],
  )

  return (
    <Autocomplete
      clearOnEscape
      disableClearable
      filterOptions={(x) => x}
      getOptionLabel={(user) => user.name}
      inputMode="search"
      inputValue={inputValue}
      isOptionEqualToValue={() => false}
      loading={fetching}
      noOptionsText="No users found"
      options={users}
      value={null as ANY}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: undefined,
            endAdornment: (
              <>
                {fetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, user) => (
        <li {...props} style={{ display: 'block' }}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <UserAvatar userId={user.id} />
            <Box>
              <UserDisplayName userId={user.id} />
              <Typography color="textSecondary" variant="body2">
                {user.email}
              </Typography>
            </Box>
          </Stack>
        </li>
      )}
      onChange={handleSelect}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setInputValue(newInputValue)
        }
      }}
    />
  )
}

export default UserAutocomplete
