import type { FC } from 'react'

import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import type {
  MentorSessionRecapListQuery,
  MentorSessionRecapType,
} from '@core/graphql'
import { useMentorSessionRecapListQuery } from '@core/graphql'
import { useDialogState } from '@core/hooks'
import { MentorSessionRecapIcon } from '@core/icons'
import type { ArrayElement } from '@core/types'
import {
  DateFormatText,
  ListItemSkeleton,
  UrqlError,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'

import ViewRecapDialog from '../ViewRecap/ViewRecapDialog'

export type MentorSessionRecapListProps = {
  mentorSessionId: string
  learnerId?: string
  mentorId?: string
  type: MentorSessionRecapType
}

const MentorSessionRecapList: FC<MentorSessionRecapListProps> = ({
  mentorSessionId,
  type,
  learnerId,
  mentorId,
}) => {
  const [{ fetching, data, error }] = useMentorSessionRecapListQuery({
    variables: {
      mentorSessionIds: [mentorSessionId],
      learnerId,
      mentorId,
      type,
    },
  })

  const recaps = data?.mentorSessionRecaps ?? []

  if (error) {
    return <UrqlError error={error} />
  }

  if (fetching && !data) {
    return <ListItemSkeleton />
  }

  if (isEmpty(recaps)) {
    return <Typography>No recaps submitted yet.</Typography>
  }

  return (
    <Stack spacing={1}>
      {recaps.map((recap) => (
        <RecapItem key={recap.id} recap={recap} />
      ))}
    </Stack>
  )
}

const RecapItem: FC<{
  recap: ArrayElement<MentorSessionRecapListQuery['mentorSessionRecaps']>
}> = ({ recap }) => {
  const { isOpen, openDialog, closeDialog } = useDialogState()

  return (
    <>
      <Box
        sx={{
          pb: 1,
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <UserAvatar userId={recap.createdByUserId} />
          </Grid>
          <Grid item xs>
            <UserDisplayName userId={recap.createdByUserId} />
            <Typography color="textSecondary" variant="body2">
              <DateFormatText
                addSuffix
                date={recap.createdAt}
                variant="distanceToNowStrict"
              />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              color="inherit"
              size="small"
              startIcon={<MentorSessionRecapIcon />}
              onClick={openDialog}
            >
              View
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ViewRecapDialog open={isOpen} recapId={recap.id} onClose={closeDialog} />
    </>
  )
}

export default MentorSessionRecapList
