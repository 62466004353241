import type { MouseEvent, ReactNode } from 'react'
import { useCallback, useState } from 'react'

import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { Check, X } from '@phosphor-icons/react'
import clsx from 'clsx'

import { AuthGuard } from '@core/auth'
import type { Permission, UserRole } from '@core/graphql'

const ACTION_SIZE = 32
const EXIT_DURATION = 0

export type InlineEditableProps<Value extends unknown> = {
  value: Value
  readView: ReactNode
  editView: (props: {
    onDismiss: VoidFunction
    onChange: (value: Value) => void
    onSubmit: VoidFunction
    value: Value
    isUpdating: boolean
  }) => ReactNode
  label?: string | ReactNode
  onSubmit: (value: Value) => void | Promise<void>
  dismissOnSubmit?: boolean
  editable?: boolean
  isUpdating?: boolean
  masked?: boolean
  allowedRoles?: UserRole[]
  allowedPermissions?: Permission[]
  maskVisibility?: boolean
}

const ActionButton = styled(Button)(({ theme }) => ({
  minWidth: 'unset',
  width: ACTION_SIZE,
  height: ACTION_SIZE,
  backgroundColor: `${theme.palette.background.paper} !important`,
  padding: theme.spacing(1 / 2),
  border: `solid 1px`,
}))

const Mask = <Typography>*******</Typography>

const InlineEditable = <Value extends unknown>(
  props: InlineEditableProps<Value>,
) => {
  const {
    readView,
    editView,
    label,
    value: defaultValue,
    onSubmit,
    editable = true,
    dismissOnSubmit = true,
    isUpdating = false,
    masked,
    allowedRoles,
    allowedPermissions,
    maskVisibility,
  } = props
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(defaultValue)

  const handleStartEditing = useCallback(() => {
    if (editable) {
      setIsEditing(true)
    }
  }, [editable])

  const handleDismiss = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation()
    setTimeout(() => setIsEditing(false), EXIT_DURATION)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!editable) {
      return
    }

    await onSubmit(value)

    if (dismissOnSubmit) {
      handleDismiss()
    }
  }, [dismissOnSubmit, editable, handleDismiss, onSubmit, value])

  const readViewWithOptionalMask = useCallback(() => {
    if (masked) {
      return (
        <AuthGuard
          fallback={Mask}
          permissions={allowedPermissions}
          roles={allowedRoles}
        >
          {maskVisibility ? (
            Mask
          ) : (
            <Box
              whiteSpace="break-spaces"
              sx={{
                overflow: 'auto',
              }}
            >
              {readView}
            </Box>
          )}
        </AuthGuard>
      )
    }
    return (
      <Box
        whiteSpace="break-spaces"
        sx={{
          overflow: 'auto',
        }}
      >
        {readView}
      </Box>
    )
  }, [readView, masked, allowedRoles, allowedPermissions, maskVisibility])

  return (
    <Box>
      <Box
        className={clsx({ isEditing, editable })}
        sx={{
          padding: 1,
          margin: -1,
          position: 'relative',
          borderRadius: 1,
          transition: (theme) =>
            theme.transitions.create('background-color', {
              duration: theme.transitions.duration.short,
            }),

          '&.editable:hover, &.isEditing': {
            backgroundColor: (theme) => theme.palette.background.neutral,
            cursor: 'pointer',
          },
        }}
        onClick={handleStartEditing}
      >
        {label && (
          <Typography color="textSecondary" display="block" variant="subtitle2">
            {label}
          </Typography>
        )}

        {isEditing
          ? editView({
              onDismiss: handleDismiss,
              onChange: setValue,
              onSubmit: handleSubmit,
              value,
              isUpdating,
            })
          : readViewWithOptionalMask()}

        {isEditing && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: -ACTION_SIZE - 4,
              zIndex: (theme) => theme.zIndex.fab,
            }}
          >
            <Stack direction="row" spacing={1}>
              <ActionButton
                color="info"
                disabled={isUpdating}
                onClick={handleSubmit}
              >
                <Check size={ACTION_SIZE} weight="bold" />
              </ActionButton>
              <ActionButton
                color="error"
                disabled={isUpdating}
                onClick={handleDismiss}
              >
                <X size={ACTION_SIZE} weight="bold" />
              </ActionButton>
            </Stack>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default InlineEditable
