import type { FC } from 'react'
import { useMemo } from 'react'

import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab'
import { Box, Stack, styled, Tooltip, Typography } from '@mui/material'
import { CircleHalf, X, XCircle } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { some } from 'lodash'

import type { MentorSessionViewFragment } from '@core/graphql'
import {
  CalendarEventOrigin,
  MentorSessionEventStatus,
  UserRequestStatus,
} from '@core/graphql'
import type { ArrayElement } from '@core/types'
import SessionMenuActions from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/components/SessionMenuActions'
import SessionSecondaryAction from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/components/SessionSecondaryAction'
import { useLearnerMemberSessionsContext } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessionsContext'

import ViewRecordingButton from './ViewRecordingButton'

const StyledCircleDashed = styled('div')(({ theme }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `1px dashed ${theme.palette.grey[500]}`,
  boxSizing: 'border-box',
  position: 'relative',
  svg: {
    position: 'absolute',
    color: theme.palette.grey[500],
    top: 1.5,
    left: 1.5,
  },
}))

const StyledCircleHalfFillRotate = styled('div')(({ theme }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.success.main}`,
  boxSizing: 'border-box',
  position: 'relative',
  svg: {
    position: 'absolute',
    color: theme.palette.success.main,
    top: -2,
    left: -2,
    transform: 'rotate(180deg)',
  },
}))

const StyledCircleFill = styled('div')(({ theme }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `2px solid ${theme.palette.success.main}`,
  boxSizing: 'border-box',
  background: `${theme.palette.success.main}`,
}))

const StyledXCircle = styled('div')(() => ({
  width: '12px',
  height: '12px',
  position: 'relative',
  svg: {
    position: 'absolute',
    top: -2,
    left: -1,
    transform: 'rotate(180deg)',
  },
}))

const MentorSessionEventItem: FC<{
  event: ArrayElement<MentorSessionViewFragment['calendarEvents']>
  isLast: boolean
}> = ({ event, isLast }) => {
  const { eventMenuActions, eventSecondaryAction } =
    useLearnerMemberSessionsContext()

  const { mentorSessionEvent, origin, startsAt, endsAt } = event

  const eventDot = useMemo(() => {
    switch (mentorSessionEvent?.status) {
      case MentorSessionEventStatus.ONGOING:
        return (
          <Tooltip title="Ongoing">
            <StyledCircleDashed
              sx={(theme) => ({
                border: `1px dashed ${theme.palette.primary.main}`,
              })}
            />
          </Tooltip>
        )

      case MentorSessionEventStatus.ABSENT:
        return (
          <Tooltip title="Absent">
            <StyledCircleDashed
              sx={(theme) => ({
                border: `1px dashed ${theme.palette.primary.main}`,
                svg: {
                  color: theme.palette.primary.main,
                },
              })}
            >
              <X size={7} weight="bold" />
            </StyledCircleDashed>
          </Tooltip>
        )
      case MentorSessionEventStatus.FINISHED:
        return (
          <Tooltip title="Finished">
            <StyledCircleHalfFillRotate>
              <CircleHalf size={15} weight="fill" />
            </StyledCircleHalfFillRotate>
          </Tooltip>
        )

      case MentorSessionEventStatus.COMPLETED:
        return (
          <Tooltip title="Completed">
            <StyledCircleFill />
          </Tooltip>
        )

      case MentorSessionEventStatus.CANCELED_BY_LEARNER:
        return (
          <Tooltip title="Canceled by learner">
            <StyledXCircle
              sx={{
                color: (theme) => `${theme.palette.error.main}`,
              }}
            >
              <XCircle size={16} weight="fill" />
            </StyledXCircle>
          </Tooltip>
        )
      case MentorSessionEventStatus.CANCELED_BY_MENTOR:
        return (
          <Tooltip title="Canceled by mentor">
            <StyledXCircle
              sx={{
                color: (theme) => `${theme.palette.error.main}`,
              }}
            >
              <XCircle size={16} weight="fill" />
            </StyledXCircle>
          </Tooltip>
        )
      default:
        return <StyledCircleDashed />
    }
  }, [mentorSessionEvent?.status])

  const timelineDot = useMemo(() => {
    if (origin !== CalendarEventOrigin.PLATFORM) {
      return (
        <Tooltip title="This session was conducted outside of CoderSchool Platform">
          <TimelineDot color="warning" variant="outlined" />
        </Tooltip>
      )
    }

    if (
      mentorSessionEvent?.status === MentorSessionEventStatus.SCHEDULED &&
      some(
        mentorSessionEvent?.rescheduleRequests,
        (request) => request.status === UserRequestStatus.PENDING,
      )
    ) {
      return <TimelineDot color="primary" variant="outlined" />
    }

    return (
      <TimelineDot
        sx={{
          bgcolor: 'unset',
          color: 'unset',
          margin: '0.7rem 0',
          padding: 'unset',
          borderWidth: 'unset',
          borderStyle: 'unset',
        }}
      >
        {eventDot}
      </TimelineDot>
    )
  }, [
    origin,
    eventDot,
    mentorSessionEvent?.rescheduleRequests,
    mentorSessionEvent?.status,
  ])
  return (
    <TimelineItem>
      <TimelineSeparator>
        {timelineDot}
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack alignItems="center" direction="row" spacing={2}>
            <Box>
              <Typography variant="subtitle2">
                {format(startsAt, 'iiii, dd MMM, yyyy')}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {format(startsAt, 'HH:mm')} – {format(endsAt, 'HH:mm a')}
              </Typography>
              {/* TODO: Only display when event has recording */}
              <ViewRecordingButton event={event} />
            </Box>
          </Stack>
          <Stack alignItems="center" direction="row" spacing={1}>
            {eventSecondaryAction && (
              <SessionSecondaryAction
                action={eventSecondaryAction}
                sessionEvent={event}
              />
            )}
            {eventMenuActions && (
              <SessionMenuActions
                actions={eventMenuActions}
                sessionEvent={event}
              />
            )}
          </Stack>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  )
}

export default MentorSessionEventItem
