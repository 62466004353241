import type { FC, ReactNode } from 'react'

import { Grid, Typography } from '@mui/material'

import { ContentSection } from '@core/ui/components'

const InfoItem: FC<{
  label: string
  content: ReactNode
  isEmpty?: boolean
  configs: {
    ITEM_MD: number
    ITEM_SM: number
  }
}> = ({ content, label, isEmpty, configs }) => {
  const { ITEM_MD, ITEM_SM } = configs
  return (
    <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
      <ContentSection label={label}>
        {isEmpty || !content ? (
          <Typography color="textSecondary">N/A</Typography>
        ) : (
          content
        )}
      </ContentSection>
    </Grid>
  )
}

export default InfoItem
