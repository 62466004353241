import type { FC } from 'react'
import { useState } from 'react'

import { Button, Divider, Stack, Tooltip, styled } from '@mui/material'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'
import { addWeeks, format, isThisWeek, startOfWeek } from 'date-fns'

export type WeekSelectProps = {
  value?: Date | null
  onChange: (date?: Date | null) => void
}

const WeekSelect: FC<WeekSelectProps> = ({
  value: initialValue = new Date(),
  onChange,
}) => {
  const value = startOfWeek(
    initialValue ? new Date(initialValue) : new Date(),
    { weekStartsOn: 1 },
  )
  const [thisWeekHover, setThisWeekHover] = useState(false)

  return (
    <Stack
      alignItems="center"
      direction="row"
      sx={{
        border: `1px solid`,
        borderColor: (theme) =>
          theme.palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.23)'
            : 'rgba(255, 255, 255, 0.23)',
        borderRadius: 1,
        height: 32,
        overflow: 'hidden',
      }}
    >
      <Tooltip arrow={false} title="Previous week">
        <NavButton
          color="inherit"
          size="small"
          onClick={() =>
            onChange(
              startOfWeek(addWeeks(value || new Date(), -1), {
                weekStartsOn: 1,
              }),
            )
          }
        >
          <CaretLeft color="inherit" weight="bold" />
        </NavButton>
      </Tooltip>
      <Divider flexItem orientation="vertical" />
      <Button
        color="inherit"
        sx={{ minWidth: 110 }}
        onClick={() => onChange(startOfWeek(new Date(), { weekStartsOn: 1 }))}
        onMouseEnter={() => setThisWeekHover(true)}
        onMouseLeave={() => setThisWeekHover(false)}
      >
        {(thisWeekHover && !isThisWeek(value)) || isThisWeek(value)
          ? 'Current week'
          : format(value || new Date(), 'dd MMM yyyy')}
      </Button>
      <Divider orientation="vertical" />
      <Tooltip arrow={false} title="Next week">
        <NavButton
          color="inherit"
          size="small"
          onClick={() =>
            onChange(
              startOfWeek(addWeeks(value || new Date(), 1), {
                weekStartsOn: 1,
              }),
            )
          }
        >
          <CaretRight color="inherit" weight="bold" />
        </NavButton>
      </Tooltip>
    </Stack>
  )
}

const NavButton = styled(Button)({
  minWidth: 'unset',
  width: 32,
  height: '100%',
  borderRadius: 0,
})

export default WeekSelect
