import qs from 'query-string'

type ParseRouteParams<Rte> = Rte extends `${string}/:${infer P}/${infer Rest}`
  ? P | ParseRouteParams<`/${Rest}`>
  : Rte extends `${string}/:${infer P}`
    ? P
    : never

export const buildPath = <Rte extends string = string>(
  path: Rte,
  params?: Record<ParseRouteParams<Rte>, string>,
  search?: Record<string, string | string[]>,
) => {
  let url = path as string

  if (!params) {
    return url
  }

  Object.entries(params as Record<string, string>).forEach(
    ([key, value = '']) => {
      const regex = RegExp(`:${key}`, 'g')
      url = url.replace(regex, value.toString())
    },
  )

  if (search) {
    return `${url}?${qs.stringify(search)}`
  }

  return url
}
