import type { FC } from 'react'
import { memo, useMemo } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'

import type { ANY } from '@core/types'
import { UserDisplayName } from '@core/ui/components'

type CreatedMentorSessionActivityProps = {
  data: ANY
  userId?: string | null
}

const CreatedMentorSessionActivity: FC<CreatedMentorSessionActivityProps> = ({
  data,
  userId,
}) => {
  const { startsAt, endsAt } = useMemo(() => {
    const events = data.mentorSession?.calendarEvents ?? [
      data.mentorSessionEvent?.calendarEvent ??
        data.mentorSession?.calendarEvent ??
        {},
    ]

    const event = events?.find(
      (item: { id: string }) => item.id === data.eventId,
    )

    return {
      startsAt: event?.startsAt,
      endsAt: event?.endsAt,
    }
  }, [
    data.eventId,
    data.mentorSession?.calendarEvent,
    data.mentorSession?.calendarEvents,
    data.mentorSessionEvent?.calendarEvent,
  ])

  return (
    <>
      <UserDisplayName display="inline" userId={userId} /> created a mentor
      session on
      {startsAt && endsAt && (
        <b>
          {` `}
          {format(new Date(startsAt) || new Date(), 'iiii, MMM dd, yyyy')}
          {` `}
          {`${format(new Date(startsAt) || new Date(), 'HH:mm')}`}
          {` - `}
          {`${format(new Date(endsAt) || new Date(), 'HH:mm a')}`}
        </b>
      )}
      <br />
      <Stack direction="column">
        {data.makeUpSessionEventSource && (
          <Box
            sx={{
              px: 2,
              py: 1,
              my: 1,
              borderRadius: 1,
              backgroundColor: ($theme) => $theme.palette.background.neutral,
            }}
          >
            <Typography fontSize="1rem" fontWeight={400}>
              Make-up for session on
            </Typography>
            <Typography color="textSecondary" fontSize="1rem">
              {`${format(
                new Date(
                  new Date(
                    data.makeUpSessionEventSource.calendarEvent.startsAt,
                  ) || '',
                ),
                'EEEE, MMMM dd, yyyy',
              )} `}
              {`${format(
                new Date(data.makeUpSessionEventSource.calendarEvent.startsAt),
                'HH:mm',
              )}`}
              {` - `}
              {`${format(
                new Date(data.makeUpSessionEventSource.calendarEvent.endsAt) ||
                  '',
                'HH:mm a',
              )}`}
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  )
}

export default memo(CreatedMentorSessionActivity)
