import type { FC, ReactNode } from 'react'
import { useCallback, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { CalendarX as CalendarXIcon, Clock } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'

import type { MentorSessionEventRescheduleRequestQuery } from '@core/graphql'
import {
  UserResponseAction,
  useResponseMentorSessionEventRescheduleRequestMutation,
} from '@core/graphql'
import { useYupForm } from '@core/helpers'
import type { Icon } from '@core/icons'
import { CoursesIcon, MakeupMentorSessionIcon } from '@core/icons'
import type { SchemaOf } from '@core/lib/yup'
import { object, string } from '@core/lib/yup'
import { UrqlError } from '@core/ui/components'
import { FormProvider, RHFTextField } from '@core/ui/components/hook-form'

export type RejectReasonFormValues = {
  reason?: string | null
}

export type RejectMentorSessionEventRescheduleRequestDialogProps = {
  open: boolean
  onClose: VoidFunction
  rescheduleRequest: MentorSessionEventRescheduleRequestQuery['mentorSessionEventRescheduleRequest']
  userRequestId: string
}

const RejectMentorSessionEventRescheduleRequestDialog: FC<
  RejectMentorSessionEventRescheduleRequestDialogProps
> = ({ onClose, open, rescheduleRequest, userRequestId }) => {
  const [{ fetching, error }, mutateMentorSessionEventRescheduleRequest] =
    useResponseMentorSessionEventRescheduleRequestMutation()
  const { enqueueSnackbar } = useSnackbar()

  const courseTitle =
    rescheduleRequest?.mentorSessionEvent.mentorSession.course?.title ?? 'N/A'
  const prevStartsAt =
    rescheduleRequest?.mentorSessionEvent.calendarEvent.startsAt
  const prevEndsAt = rescheduleRequest?.mentorSessionEvent.calendarEvent.endsAt

  const startsAt = get(rescheduleRequest, 'startsAt')
  const endsAt = get(rescheduleRequest, 'endsAt')
  const validationSchema = useMemo(
    (): SchemaOf<RejectReasonFormValues> =>
      object({ reason: string().required() }),
    [],
  )

  const { getFieldProps, resolver } = useYupForm(validationSchema)

  const methods = useForm<RejectReasonFormValues>({
    resolver,
    defaultValues: {
      reason: '',
    },
  })

  const handleSubmit = useCallback(
    async (values: RejectReasonFormValues) => {
      const { data } = await mutateMentorSessionEventRescheduleRequest({
        input: {
          userRequestId,
          action: UserResponseAction.REJECT,
          reason: values.reason || '',
        },
      })
      if (data) {
        enqueueSnackbar('The request to reschedule has been rejected', {
          variant: 'success',
        })
      }
      onClose()
    },
    [
      enqueueSnackbar,
      mutateMentorSessionEventRescheduleRequest,
      onClose,
      userRequestId,
    ],
  )

  const getDate = useCallback((date: string) => new Date(date), [])

  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider methods={methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <CalendarXIcon size={24} weight="fill" />
            Reject the rescheduling request?
          </DialogTitle>
          <DialogContent
            sx={{
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DialogContentText>
              Are you sure you want to reject this rescheduling request? <br />
              {error && <UrqlError error={error} sx={{ mt: 2 }} />}
            </DialogContentText>
            <Box
              alignItems="flex-start"
              display="flex"
              flexDirection="column"
              gap={1}
              padding={2}
              sx={{
                bgcolor: (theme) => theme.palette.grey[5008],
                borderRadius: '12px',
              }}
            >
              <InfoItem icon={CoursesIcon}>
                <Typography>{courseTitle}</Typography>
              </InfoItem>
              <InfoItem icon={Clock}>
                <Typography>
                  {` `}
                  {format(
                    getDate(prevStartsAt) || new Date(),
                    'iiii, MMM dd, yyyy',
                  )}
                  {` `}
                  {`${format(getDate(prevStartsAt) || new Date(), 'HH:mm')}`}
                  {` - `}
                  {`${format(getDate(prevEndsAt) || new Date(), 'HH:mm a')}`}
                </Typography>
              </InfoItem>
              <InfoItem icon={MakeupMentorSessionIcon}>
                <Typography
                  sx={{
                    textDecoration: 'line-through',
                  }}
                >
                  {` `}
                  {format(
                    getDate(startsAt) || new Date(),
                    'iiii, MMM dd, yyyy',
                  )}
                  {` `}
                  {`${format(getDate(startsAt) || new Date(), 'HH:mm')}`}
                  {` - `}
                  {`${format(getDate(endsAt) || new Date(), 'HH:mm a')}`}
                </Typography>
              </InfoItem>
            </Box>
            <DialogContentText>Please let us know why:</DialogContentText>
            <RHFTextField
              multiline
              rows={3}
              {...getFieldProps('reason')}
              focused
              label="Your Reason"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              color="inherit"
              loading={fetching}
              variant="outlined"
              onClick={onClose}
            >
              Never mind
            </LoadingButton>
            <LoadingButton
              color="primary"
              loading={fetching}
              variant="contained"
              onClick={methods.handleSubmit(handleSubmit)}
            >
              Reject the request
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

const InfoItem: FC<{ icon: Icon; children: ReactNode }> = ({
  icon: IconComponent,
  children,
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconComponent size={22} />
      <Box ml={2}>{children}</Box>
    </Box>
  )
}

export default RejectMentorSessionEventRescheduleRequestDialog
