const useApp = () => {
  const { location } = window

  const app =
    location.href.match(new RegExp(`^${location.origin}/(.*?)($|/)`))?.[1] ??
    null

  return {
    app,
    isWorkplace: app === 'workplace',
    isMentor: app === 'mentor',
    isLearner: app === 'learner',
  }
}

export default useApp
