import type { FC } from 'react'

import { Box, Grid, Stack, Typography } from '@mui/material'
import { Check } from '@phosphor-icons/react'

const SPACING = 2
const QUESTION_COL = 5

export type MultiChoiceGridAnswerProps = {
  rows: string[]
  columns: string[]
  answered: Record<string, string>
}

const MultiChoiceGridAnswer: FC<MultiChoiceGridAnswerProps> = ({
  answered,
  columns,
  rows,
}) => {
  return (
    <Stack spacing={1}>
      <Box p={1}>
        <Grid container alignItems="center" spacing={SPACING}>
          <Grid item xs={QUESTION_COL} />
          {columns.map((col) => (
            <Grid key={col} item sx={{ flex: 1 }}>
              <Typography align="center" variant="body2">
                {col}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      {rows.map((row) => (
        <Box
          key={row}
          sx={{
            background: (theme) => theme.palette.background.neutral,
            p: 1,
            borderRadius: 1,
            '&:hover': {
              // background: (theme) =>
              //   darken(theme.palette.background.neutral, 0.03),
            },
          }}
        >
          <Grid container alignItems="center" spacing={SPACING}>
            <Grid item xs={QUESTION_COL}>
              <Typography variant="body2">{row}</Typography>
            </Grid>
            {columns.map((col) => (
              <Grid
                key={col}
                item
                sx={{ flex: 1, display: 'grid', placeItems: 'center' }}
              >
                {answered[row] === col ? <Check size={24} /> : <Box />}
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Stack>
  )
}

export default MultiChoiceGridAnswer
