import type { FC } from 'react'

import { Stack } from '@mui/material'

import { useCoursesFilter, useEnrollmentDatesFilter } from '@core/hooks'
import { CoursesIcon, EnrollmentDateIcon } from '@core/icons'
import InlineFilters from '@core/ui/components/feedback/InlineFilters'

import EnrollToLWButton from '../EnrollToLW/EnrollToLWButton'

export type PendingMemberToolbarProps = {}

const PendingMemberToolbar: FC<PendingMemberToolbarProps> = () => {
  const { courseValues } = useCoursesFilter()
  const { enrollmentDateValues } = useEnrollmentDatesFilter()

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      justifyContent="flex-end"
      mt={2}
      spacing={1}
    >
      <InlineFilters
        filterOptions={[
          {
            key: 'courseId',
            label: 'Course',
            icon: CoursesIcon,
            variant: 'single-select',
            values: courseValues,
          },
          {
            key: 'enrollmentDate',
            label: 'Batch',
            icon: EnrollmentDateIcon,
            variant: 'single-select',
            values: enrollmentDateValues,
          },
        ]}
      />
      <EnrollToLWButton />
    </Stack>
  )
}

export default PendingMemberToolbar
