import type { FC } from 'react'
import { useCallback, useMemo } from 'react'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { X } from '@phosphor-icons/react'
import { useSnackbar } from 'notistack'

import { MENTEE_RECAP_FORM_CONFIG } from '@core/config/forms/mentee-recap'
import { MENTOR_RECAP_FORM_CONFIG } from '@core/config/forms/mentor-recap'
import type { MentorSessionViewFragment } from '@core/graphql'
import {
  MentorSessionRecapType,
  useCreateMentorSessionRecapForMentorMutation,
} from '@core/graphql'
import type { CustomFormValues } from '@core/ui/components'
import { CustomFormInput, DateFormatText, UrqlError } from '@core/ui/components'
import type { SessionCalendarEvent } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'

import RecapFromTo from '../RecapFromTo'

export type CreateRecapDialogProps = {
  open: boolean
  onClose?: VoidFunction
  calendarEvent: SessionCalendarEvent
  learnerId: string
  mentorId: string
  mentorSession: MentorSessionViewFragment
  type: MentorSessionRecapType
}

const CreateRecapDialog: FC<CreateRecapDialogProps> = ({
  open,
  onClose,
  calendarEvent,
  learnerId,
  mentorId,
  mentorSession,
  type,
}) => {
  const [{ fetching, error }, mutateCreate] =
    useCreateMentorSessionRecapForMentorMutation()
  const { enqueueSnackbar } = useSnackbar()

  const learnerName = useMemo(
    () =>
      mentorSession.courseMembers.find(
        (member) => member?.learner?.id === learnerId,
      )?.user?.name,
    [learnerId, mentorSession.courseMembers],
  )

  const mentorName = useMemo(
    () =>
      mentorSession.courseMembers.find(
        (member) => member?.mentor?.id === mentorId,
      )?.user?.name,
    [mentorId, mentorSession.courseMembers],
  )

  const handleCreate = useCallback(
    async (formValues: CustomFormValues) => {
      const { data } = await mutateCreate({
        input: {
          calendarEventId: calendarEvent.id,
          learnerId,
          mentorId,
          mentorSessionId: mentorSession.id,
          type,
          content: JSON.stringify(formValues),
        },
      })

      if (data?.createMentorSessionRecap) {
        enqueueSnackbar('Mentor session recap submitted', {
          variant: 'success',
        })
        onClose?.()
      }
    },
    [
      calendarEvent,
      enqueueSnackbar,
      learnerId,
      mentorId,
      mentorSession,
      mutateCreate,
      onClose,
      type,
    ],
  )

  const config =
    type === MentorSessionRecapType.MENTEE_RECAP
      ? MENTEE_RECAP_FORM_CONFIG
      : MENTOR_RECAP_FORM_CONFIG

  return (
    <Dialog
      disableEscapeKeyDown
      PaperProps={{ sx: { position: 'relative' } }}
      open={open}
      onClose={onClose}
    >
      <CustomFormInput config={config} onSubmit={handleCreate}>
        {({ fields }) => (
          <>
            <DialogTitle
              sx={{
                position: 'sticky',
                top: 0,
                marginTop: 0,
                background: (theme) =>
                  `${theme.palette.background.paper} !important`,
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                zIndex: 1,
                pb: 2,
              }}
            >
              <Stack>
                <Box>
                  Recap for Mentor Session on{' '}
                  <DateFormatText
                    disableTooltip
                    date={calendarEvent.startsAt}
                    format="PP"
                    variant="format"
                  />
                </Box>
                {type === MentorSessionRecapType.MENTEE_RECAP
                  ? mentorName && <RecapFromTo to={mentorName} type={type} />
                  : learnerName && <RecapFromTo to={learnerName} type={type} />}
              </Stack>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 16,
                  right: 14,
                }}
                onClick={onClose}
              >
                <X />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              sx={{
                maxHeight: 'calc(100vh - 20rem)',
              }}
            >
              {fields} {error && <UrqlError error={error} sx={{ mt: 2 }} />}
            </DialogContent>
            <DialogActions
              sx={{
                position: 'sticky',
                bottom: 0,
                marginBottom: 0,
                background: (theme) => theme.palette.background.paper,
                borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
                zIndex: 1,
              }}
            >
              <LoadingButton
                loading={fetching}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </CustomFormInput>
    </Dialog>
  )
}

export default CreateRecapDialog
