import type { FC } from 'react'
import { useEffect, useMemo } from 'react'

import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { alpha } from '@mui/system'
import { Link } from '@tanstack/react-location'
import type { Day } from 'date-fns'
import {
  addDays,
  format,
  nextDay,
  set,
  startOfMinute,
  startOfWeek,
} from 'date-fns'
import { capitalize, first, groupBy, orderBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import { rrulestr } from 'rrule'

import type { SuggestionItemFragment } from '@core/graphql'
import { useMentorSessionTimeSuggestionsQuery } from '@core/graphql'
import { SelectiveBox, UrqlError } from '@core/ui/components'
import { convertToDefaultTimeZone } from '@core/utils/date'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import { DAYS_OF_WEEK } from '../../BookMentorSession.util'

import { convertWeekDayIndex } from './TimeSuggestions.util'

export type TimeSuggestionsProps = {
  duration: number
  endsAfterMonths?: number
  defaultRecurrence?: string
  intervalsFrom?: Date
}

const TimeSuggestions: FC<TimeSuggestionsProps> = ({
  duration,
  endsAfterMonths,
  defaultRecurrence,
  intervalsFrom = new Date(),
}) => {
  const { watch, setValue } = useFormContext<BookMentorSessionValues>()
  const {
    selectedLearnerCourseMember,
    selectedMentorCourseMember,
    course,
    time,
    options,
  } = watch()
  const [{ data, fetching, error }] = useMentorSessionTimeSuggestionsQuery({
    variables: {
      courseId: course?.id!,
      durationInMinutes: duration,
      learnerCourseMemberId: selectedLearnerCourseMember?.id!,
      mentorCourseMemberId: selectedMentorCourseMember?.id!,
      endsAfterMonths,
      intervalsFrom,
    },
  })

  const suggestions = useMemo(() => {
    const suggestionData = data?.mentorSessionTimeSuggestions
    return suggestionData
      ? suggestionData?.map((suggestion) => ({
          ...suggestion,
          start: convertToDefaultTimeZone(suggestion.start),
          end: convertToDefaultTimeZone(suggestion.end),
        }))
      : []
  }, [data?.mentorSessionTimeSuggestions])

  const suggestionsByDayOfWeek = useMemo(
    () => groupBy(suggestions, (s) => new Date(s.start).getDay()),
    [suggestions],
  )

  // Auto select the first suggestion
  useEffect(() => {
    let firstSuggestion = first(
      orderBy(suggestions, ['score', 'start'], ['desc', 'asc']),
    )

    // Set first suggestion if a default value is provided
    if (defaultRecurrence) {
      const { byweekday, byhour, byminute } =
        rrulestr(defaultRecurrence).options

      const start = startOfMinute(
        set(nextDay(Date.now(), convertWeekDayIndex(byweekday[0]) as Day), {
          hours: byhour[0],
          minutes: byminute[0],
        }),
      )

      const defaultSuggestion = suggestions.find(
        (suggestion) => suggestion.start.getTime() === start.getTime(),
      )

      if (defaultSuggestion) {
        firstSuggestion = defaultSuggestion
      }
    }

    if (firstSuggestion && options.autoSelect) {
      setValue('time.startsAt', firstSuggestion.start)
    }
  }, [defaultRecurrence, options.autoSelect, setValue, suggestions])

  if (fetching) {
    return <Typography>I am thinking...</Typography>
  }

  if (error) {
    return <UrqlError error={error} />
  }

  if (!suggestions.length) {
    return (
      <Typography>
        Hic. I could not find any suggestions.{' '}
        <Link to="../create">Do it yourself!</Link>
      </Typography>
    )
  }

  return (
    <>
      <Typography color="text.secondary" sx={{ mb: 2 }} variant="caption">
        Please be aware that the time displayed on this page is in GMT+7
        (Indochina Time)
      </Typography>
      <Stack direction="row" spacing={2}>
        {DAYS_OF_WEEK.map(({ value: dayOfWeek, label }) => (
          <Stack key={dayOfWeek} spacing={2} sx={{ flex: 1 }}>
            <Box
              textAlign="center"
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                bgcolor: 'background.paper',
                py: 1,
              }}
            >
              <Typography color="textSecondary" variant="subtitle1">
                {label}
              </Typography>
              <Typography variant="subtitle2">
                {addDays(startOfWeek(intervalsFrom), dayOfWeek).getDate()}
              </Typography>
            </Box>

            {suggestionsByDayOfWeek[dayOfWeek]?.length > 0 && (
              <Stack px="3px" spacing={1.5}>
                {suggestionsByDayOfWeek[dayOfWeek]
                  ?.sort((a, b) => (a.start > b.start ? 1 : -1))
                  .map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.id}
                      suggestion={suggestion}
                      selected={
                        time.startsAt?.getTime() === suggestion.start?.getTime()
                      }
                      onClick={() =>
                        setValue('time.startsAt', suggestion.start)
                      }
                    />
                  ))}
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    </>
  )
}

const SuggestionItem: FC<{
  suggestion: SuggestionItemFragment
  selected: boolean
  onClick: () => void
}> = ({ suggestion, selected, onClick }) => {
  const isBeforeOrAfterAnotherSession = useMemo(
    () => suggestion.notes?.includes('ANOTHER'),
    [suggestion.notes],
  )

  const suggestionEl = useMemo(
    () => (
      <SelectiveBox
        key={suggestion.id}
        selected={selected}
        sx={[
          (theme) => ({
            padding: 1,
            textAlign: 'center',
            outline: `1px solid ${theme.palette.divider}`,
            outlineColor: 'divider',
            bgcolor: isBeforeOrAfterAnotherSession
              ? `${alpha(theme.palette.success.main, 0.1)} !important`
              : 'background.paper',
          }),
          selected && {
            position: 'sticky',
            top: 64,
            bottom: (theme) => theme.spacing(1),
            zIndex: 1,
          },
        ]}
        onClick={onClick}
      >
        <Typography variant="body2">
          {format(suggestion.start, 'HH:mm')}
        </Typography>
      </SelectiveBox>
    ),
    [
      isBeforeOrAfterAnotherSession,
      onClick,
      selected,
      suggestion.id,
      suggestion.start,
    ],
  )

  if (!isBeforeOrAfterAnotherSession) {
    return suggestionEl
  }

  return (
    <Tooltip
      arrow
      title={capitalize(suggestion.notes?.replace(/_/g, ' ') ?? '')}
    >
      <Box>{suggestionEl}</Box>
    </Tooltip>
  )
}

export default TimeSuggestions
