import type { FC } from 'react'

import { useLearner } from '@core/hooks'
import MentorSessionSchedulesUpdate from '@core/ui/components/common/MentorSessionSchedulesUpdate'

export type LearnerSchedulesProps = {}

const LearnerSchedules: FC<LearnerSchedulesProps> = () => {
  const { $learner: learner } = useLearner()
  return <MentorSessionSchedulesUpdate asPage={false} targetId={learner.id} />
}

export default LearnerSchedules
