import type { FC } from 'react'

import { Button, ButtonGroup } from '@mui/material'
import { Warning } from '@phosphor-icons/react'

import { AuthGuard } from '@core/auth'
import type {
  MentorSessionEventViewQuery,
  MentorSessionRecapType,
} from '@core/graphql'
import { Permission } from '@core/graphql'
import { useDialogState, useMentor } from '@core/hooks'
import { MentorSessionRecapIcon } from '@core/icons'
import type { ArrayElement } from '@core/types'

import CreateRecapDialog from '../CreateRecapDialog'
import ViewRecapDialog from '../ViewRecap/ViewRecapDialog'

export type RecapActionsProps = {
  existingRecap?: ArrayElement<
    MentorSessionEventViewQuery['mentorSessionEvent']['mentorSessionRecaps']
  >
  calendarEventId: string
  learnerId: string
  mentorId: string
  mentorSessionId: string
  mentorSessionEventStartsAt: Date
  recapType: MentorSessionRecapType
}

const RecapActions: FC<RecapActionsProps> = ({
  existingRecap,
  calendarEventId,
  learnerId,
  mentorId,
  mentorSessionId,
  mentorSessionEventStartsAt,
  recapType,
}) => {
  const { mentor } = useMentor()
  const {
    isOpen: createOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogState()
  const {
    isOpen: viewOpen,
    openDialog: openViewDialog,
    closeDialog: closeViewDialog,
  } = useDialogState()

  const requiresRecap = mentorSessionEventStartsAt < new Date()

  return (
    <>
      {existingRecap ? (
        <>
          <ButtonGroup color="inherit" variant="outlined">
            <Button
              startIcon={<MentorSessionRecapIcon />}
              onClick={openViewDialog}
            >
              View recap
            </Button>
            {/* <Tooltip title="Update recap">
              <Button disabled>
                <Pencil />
              </Button>
            </Tooltip> */}
          </ButtonGroup>
          <ViewRecapDialog
            open={viewOpen}
            recapId={existingRecap.id}
            onClose={closeViewDialog}
          />
        </>
      ) : (
        <AuthGuard permission={Permission.CREATE_MENTOR_SESSION_RECAP}>
          <Button
            color={requiresRecap ? 'warning' : 'inherit'}
            disabled={!mentor || mentorSessionEventStartsAt >= new Date()}
            sx={{ boxShadow: 'none' }}
            variant={requiresRecap ? 'contained' : 'outlined'}
            startIcon={
              requiresRecap ? (
                <Warning weight="fill" />
              ) : (
                <MentorSessionRecapIcon />
              )
            }
            onClick={openCreateDialog}
          >
            Write recap
          </Button>
        </AuthGuard>
      )}

      <CreateRecapDialog
        calendarEventId={calendarEventId}
        learnerId={learnerId}
        mentorId={mentorId}
        mentorSessionId={mentorSessionId}
        open={createOpen}
        type={recapType}
        onClose={closeCreateDialog}
      />
    </>
  )
}

export default RecapActions
