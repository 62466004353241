import {
  Stack,
  Button,
  Tooltip,
  Typography,
  IconButton,
  ToggleButton,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  CaretLeft,
  CaretRight,
  Columns,
  LineSegments,
  Rows,
  SquaresFour,
} from '@phosphor-icons/react'

import { useLocales, useIsDesktop } from '@core/hooks'

import DateFormatText from '../data-display/DateFormatText'

import type { CalendarView } from './calendar.type'

const VIEW_OPTIONS = [
  { value: 'dayGridMonth', label: 'month', icon: SquaresFour },
  { value: 'timeGridWeek', label: 'week', icon: Columns },
  { value: 'timeGridDay', label: 'day', icon: Rows },
  { value: 'listWeek', label: 'agenda', icon: LineSegments },
] as const

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

// ----------------------------------------------------------------------

type Props = {
  date: Date
  view: CalendarView
  onToday: VoidFunction
  onNextDate: VoidFunction
  onPrevDate: VoidFunction
  onChangeView: (newView: CalendarView) => void
}

export function CalendarToolbar({
  date,
  view,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
}: Props) {
  const { t } = useLocales()
  const isDesktop = useIsDesktop()

  return (
    <RootStyle>
      {isDesktop && (
        <Stack direction="row" spacing={0.5}>
          {VIEW_OPTIONS.map((viewOption) => (
            <Tooltip key={viewOption.value} title={t(viewOption.label)}>
              <ToggleButton
                selected={viewOption.value === view}
                sx={{ width: 32, height: 32, padding: 0, border: 0 }}
                value={view}
                onChange={() => onChangeView(viewOption.value)}
              >
                <viewOption.icon size={20} />
              </ToggleButton>
            </Tooltip>
          ))}
        </Stack>
      )}

      <Stack alignItems="center" direction="row" spacing={2}>
        <IconButton onClick={onPrevDate}>
          <CaretLeft size={20} />
        </IconButton>

        <Typography variant="h5">
          <DateFormatText
            disableTooltip
            date={date}
            format="dd MMMM yyyy"
            variant="format"
          />
        </Typography>

        <IconButton onClick={onNextDate}>
          <CaretRight size={20} />
        </IconButton>
      </Stack>

      {isDesktop && (
        <Button
          color="secondary"
          size="small"
          variant="outlined"
          sx={{
            border: 'none !important',
            backgroundColor: (theme) => theme.palette.action.focus,
          }}
          onClick={onToday}
        >
          {t('today')}
        </Button>
      )}
    </RootStyle>
  )
}
