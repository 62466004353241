import type { FC } from 'react'
import { useMemo } from 'react'

import type { TypographyProps } from '@mui/material'
import { Skeleton, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'

import type { UserRole } from '@core/graphql'
import { UserStatus, useUserDisplayNameQuery } from '@core/graphql'
import type { ANY } from '@core/types'

export type UserDisplayNameProps = {
  userId?: string | null
  component?: ANY
  to?: string
} & Omit<TypographyProps, 'component'>

const roleColorMap: Partial<Record<UserRole, string>> = {
  ADMIN: 'primary',
  DEVELOPER: 'primary',
  MENTOR: 'mentor.main',
  LEARNER: 'learner.main',
  LEARNER_EXP: 'info.main',
  OAUTH_APP: blueGrey[600],
}

const UserDisplayName: FC<UserDisplayNameProps> = ({
  userId,
  ...typographyProps
}) => {
  const [{ fetching, data }] = useUserDisplayNameQuery({
    variables: { id: userId || 'unknown' },
    requestPolicy: 'cache-first',
    pause: !userId,
  })

  const user = useMemo(() => data?.user, [data?.user])
  const color = useMemo(() => {
    if (user?.status !== UserStatus.ACTIVE) {
      return 'textSecondary'
    }

    const highestRole = Object.keys(roleColorMap).find((roleItem) =>
      user?.roles.includes(roleItem as UserRole),
    ) as UserRole | undefined

    return (highestRole && roleColorMap[highestRole]) ?? 'textPrimary'
  }, [user?.roles, user?.status])

  if (!user && fetching) {
    return (
      <Skeleton variant="text">
        <Typography>Dummy name</Typography>
      </Skeleton>
    )
  }

  if (!user) {
    return (
      <Typography color={color} {...typographyProps}>
        [deleted]
      </Typography>
    )
  }

  return (
    <Typography
      color={color}
      fontWeight={500}
      {...typographyProps}
      noWrap
      sx={{
        display: typographyProps.display || 'block',
        maxWidth: typographyProps.maxWidth,
        overflow: 'hidden',
      }}
    >
      {user.name}
    </Typography>
  )
}

export default UserDisplayName
