import { useCallback } from 'react'

import { gql } from 'urql'

import { useAuth } from '@core/auth'
import { useLwSingleSignOnMutation } from '@core/graphql'

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const LW_SSO = gql`
  mutation LwSingleSignOn {
    lwSSO
  }
`

export const useLwSSO = () => {
  const { user } = useAuth()

  const [{ fetching, data, error }, mutateSignOn] = useLwSingleSignOnMutation()

  const url = data?.lwSSO ?? null

  const sso = useCallback(async () => {
    if (!user) {
      return null
    }
    const { data: ssoData } = await mutateSignOn({})

    return ssoData?.lwSSO ?? null
  }, [mutateSignOn, user])

  return { fetching, url, error, sso }
}
