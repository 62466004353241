import type { FC } from 'react'

import { Box, Slider } from '@mui/material'

import type { FieldInputProps } from '../types'

export type LinearScaleProps = {
  from: number
  to: number
  labelMin: string
  labelMax: string
} & FieldInputProps<number>

const LinearScale: FC<LinearScaleProps> = ({
  from,
  labelMax,
  labelMin,
  to,
  value,
  onChange,
  disabled,
}) => {
  return (
    <Box
      sx={{
        pt: 4,
        px: 2,
      }}
    >
      <Slider
        defaultValue={typeof value === 'number' ? value : to}
        disabled={disabled}
        max={to}
        min={from}
        step={1}
        sx={{ flex: 1 }}
        value={value}
        valueLabelDisplay="on"
        marks={[
          { value: from, label: labelMin },
          { value: to, label: labelMax },
        ]}
        onChange={(e, v) => onChange(v as number)}
      />
    </Box>
  )
}

export default LinearScale
