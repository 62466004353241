import type { FC } from 'react'
import { memo } from 'react'

import { format } from 'date-fns'

import type { ANY } from '@core/types'
import { UserDisplayName } from '@core/ui/components'

type CanceledMentorSessionActivityProps = {
  data: ANY
  userId?: string | null
}

const CanceledMentorSessionActivity: FC<CanceledMentorSessionActivityProps> = ({
  data,
  userId,
}) => {
  return (
    <>
      <UserDisplayName display="inline" userId={userId} /> canceled a mentor
      session on
      {data.preStartsAt && data.preEndsAt && (
        <b>
          {` `}
          {format(
            new Date(data.preStartsAt) || new Date(),
            'iiii, MMM dd, yyyy',
          )}
          {` `}
          {`${format(new Date(data.preStartsAt) || new Date(), 'HH:mm')}`}
          {` - `}
          {`${format(new Date(data.preEndsAt) || new Date(), 'HH:mm a')}`}
        </b>
      )}
      <br />
      {userId !== data.canceledByUserId && (
        <>
          {' '}
          on behalf of{' '}
          <UserDisplayName display="inline" userId={data.canceledByUserId} />
        </>
      )}
    </>
  )
}

export default memo(CanceledMentorSessionActivity)
