import { Navigate } from '@tanstack/react-location'

import { AuthGuard } from '@core/auth'
import { Permission as P, Permission } from '@core/graphql'
import CourseList from 'apps/workplace/modules/courses/CourseList'
import CourseView from 'apps/workplace/modules/courses/CourseView'
import CourseContents from 'apps/workplace/modules/courses/CourseView/CourseContents'
import CourseMembers from 'apps/workplace/modules/courses/CourseView/CourseMembers'
import CourseMemberView from 'apps/workplace/modules/courses/CourseView/CourseMembers/CourseMemberView'
import CourseSettings from 'apps/workplace/modules/courses/CourseView/CourseSettings'
import CreateCourse from 'apps/workplace/modules/courses/CreateCourse'
import LearnerEnrollment from 'apps/workplace/modules/learners/LearnerEnrollment'
import DealView from 'apps/workplace/modules/learners/LearnerEnrollment/DealView'
import EnrollmentSettings from 'apps/workplace/modules/learners/LearnerEnrollment/EnrollmentSettings'
import EnrollWizard from 'apps/workplace/modules/learners/LearnerEnrollment/EnrollWizard'
import PendingList from 'apps/workplace/modules/learners/LearnerEnrollment/PendingList'
import WonDeals from 'apps/workplace/modules/learners/LearnerEnrollment/WonDeals'
import LearnerModule from 'apps/workplace/modules/learners/LearnerModule'
import LearnerList from 'apps/workplace/modules/learners/LearnerModule/LearnerList'
import LearnerView from 'apps/workplace/modules/learners/LearnerModule/LearnerView'
import LearnerModuleSettings from 'apps/workplace/modules/learners/LearnerModuleSettings'
import LearnerAvailability from 'apps/workplace/modules/learners/LearnerProfile/components/LearnerAvailability'
import LearnerCoursesPage from 'apps/workplace/modules/learners/LearnerProfile/components/LearnerCoursesPage'
import LearnerProfilePage from 'apps/workplace/modules/learners/LearnerProfile/components/LearnerProfilePage'
import LearnerProfile from 'apps/workplace/modules/learners/LearnerProfile/LearnerProfile'
import MentorManagement from 'apps/workplace/modules/mentors/MentorManagement'
import CreateMentor from 'apps/workplace/modules/mentors/MentorManagement/CreateMentor'
import MentorList from 'apps/workplace/modules/mentors/MentorManagement/MentorList'
import MentorView from 'apps/workplace/modules/mentors/MentorManagement/MentorView'
import MentorProfile from 'apps/workplace/modules/mentors/MentorProfile'
import MentorAvailability from 'apps/workplace/modules/mentors/MentorProfile/components/MentorAvailability'
import MentorCourses from 'apps/workplace/modules/mentors/MentorProfile/components/MentorCourses'
import MentorProfileView from 'apps/workplace/modules/mentors/MentorProfile/components/MentorProfileView'

import type { GenericsRoute } from '../routes.type'
import { authRoute } from '../routes.util'

const AcademicsRoute: GenericsRoute = {
  path: 'academics',
  children: [
    {
      path: 'courses',
      children: [
        { path: '/', element: <CourseList /> },
        {
          path: 'create',
          element: authRoute(<CreateCourse />, P.CREATE_COURSE),
        },
        {
          path: ':courseId',
          element: authRoute(<CourseView />, P.VIEW_COURSE),
          children: [
            {
              path: '/',
              element: <CourseSettings />,
            },
            {
              path: '/contents',
              element: authRoute(<CourseContents />, P.UPDATE_COURSE_CONTENTS),
            },
            {
              path: '/members',
              element: <CourseMembers />,
              children: [
                {
                  path: '/:courseMemberId',
                  element: authRoute(
                    <CourseMemberView />,
                    P.LIST_COURSE_MEMBER,
                  ),
                },
              ],
            },
            {
              path: '/settings',
              element: <CourseSettings />,
            },
          ],
        },
      ],
    },
    {
      path: 'enrollments',
      element: authRoute(<LearnerEnrollment />, Permission.ENROLL_LEARNER),
      children: [
        {
          path: 'enroll',
          element: <EnrollWizard />,
        },
        {
          path: '/deals',
          element: <WonDeals />,
          children: [
            {
              path: ':dealId',
              children: [
                {
                  path: '/',
                  element: <DealView />,
                },
                {
                  path: 'enroll',
                  element: <EnrollWizard />,
                },
              ],
            },
          ],
        },
        { path: '/pending', element: <PendingList /> },
        { path: '/settings', element: <EnrollmentSettings /> },
        { element: <Navigate replace to="./deals" /> },
      ],
    },
    {
      path: 'mentors/create',
      element: (
        <AuthGuard isPage permission={P.CREATE_MENTOR}>
          <CreateMentor />
        </AuthGuard>
      ),
    },
    {
      path: 'mentors/:mentorId/profile',
      element: <MentorProfile />,
      children: [
        {
          path: '/',
          element: <MentorProfileView />,
        },
        {
          path: '/courses',
          element: <MentorCourses />,
        },
        {
          path: '/availability',
          element: <MentorAvailability />,
        },
      ],
    },
    {
      path: 'mentors',
      element: (
        <AuthGuard isPage permission={P.MANAGE_MENTORS}>
          <MentorManagement />
        </AuthGuard>
      ),
      children: [
        { path: 'settings', element: 'empty' },
        {
          element: <MentorList />,
          children: [
            {
              path: ':mentorId',
              element: <MentorView />,
            },
          ],
        },
      ],
    },
    {
      path: 'learners/:learnerId/profile',
      element: <LearnerProfile />,
      children: [
        {
          path: '/',
          element: <LearnerProfilePage />,
        },
        {
          path: 'courses',
          element: <LearnerCoursesPage />,
        },
        {
          path: 'availability',
          element: <LearnerAvailability />,
        },
      ],
    },
    {
      path: 'learners',
      element: authRoute(<LearnerModule />, Permission.VIEW_LEARNER),
      children: [
        {
          path: 'settings',
          element: <LearnerModuleSettings />,
        },
        {
          element: <LearnerList />,
          children: [
            {
              path: ':learnerId',
              element: <LearnerView />,
            },
          ],
        },
      ],
    },
  ],
}

export default AcademicsRoute
