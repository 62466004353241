import type { FC, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Box, IconButton, Stack, Typography } from '@mui/material'
import { X } from '@phosphor-icons/react'
import {
  addMinutes,
  endOfDay,
  setHours,
  startOfDay,
  startOfHour,
} from 'date-fns'
import type { Control } from 'react-hook-form'
import { useFieldArray, useForm, useFormContext } from 'react-hook-form'

import { useYupForm } from '@core/helpers'
import { copyButtonIcon, createButtonIcon } from '@core/icons'
import type { SchemaOf } from '@core/lib/yup'
import { array, date, object } from '@core/lib/yup'
import type { ANY } from '@core/types'
import { fDayOfWeek } from '@core/utils/formatTime'
import { setDateTo2000 } from 'apps/workplace/modules/mentor-session/pages/CreateMentorSession/components/CombinedAvailabilitySchedules/CombinedAvailabilitySchedules.util'

import { FormProvider, RHFTimeSlotPicker } from '../../hook-form'

import CopyAvailabilitySchedulesMenu from './CopyAvailabilitySchedulesMenu'

export type DayIndex = '0' | '1' | '2' | '3' | '4' | '5' | '6'

export type AvailabilitySchedulesValues = {
  '0': { start: Date; end: Date }[]
  '1': { start: Date; end: Date }[]
  '2': { start: Date; end: Date }[]
  '3': { start: Date; end: Date }[]
  '4': { start: Date; end: Date }[]
  '5': { start: Date; end: Date }[]
  '6': { start: Date; end: Date }[]
}

const defaultValues: AvailabilitySchedulesValues = {
  '0': [],
  '1': [],
  '2': [],
  '3': [],
  '4': [],
  '5': [],
  '6': [],
}

export type AvailabilitySchedulesFormProps = {
  initialValues?: AvailabilitySchedulesValues
  onSubmit: (values: AvailabilitySchedulesValues) => void | Promise<void>
  children: (props: { fields: ReactNode }) => ReactNode
}

const dayItemSchema = object({
  start: date().label('Start time').required(),
  end: date()
    .label('End time')
    .required()
    .when(
      'start',
      (start, schema) =>
        start &&
        schema.min(
          addMinutes(start, 1),
          'End time must be later than start time',
        ),
    ),
})

const validationSchema: SchemaOf<AvailabilitySchedulesValues> = object({
  '0': array(dayItemSchema),
  '1': array(dayItemSchema),
  '2': array(dayItemSchema),
  '3': array(dayItemSchema),
  '4': array(dayItemSchema),
  '5': array(dayItemSchema),
  '6': array(dayItemSchema),
})

const AvailabilitySchedulesForm: FC<AvailabilitySchedulesFormProps> = ({
  children,
  onSubmit,
  initialValues,
}) => {
  const { resolver } = useYupForm(validationSchema)
  const methods = useForm<AvailabilitySchedulesValues>({
    resolver,
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? defaultValues,
    mode: 'onChange',
  })

  const { getValues, setValue, reset, trigger } = methods

  const handleCopy = useCallback(
    (sourceDayIndex: DayIndex, copiedToDayIndices: DayIndex[]) => {
      const copyValues = getValues(sourceDayIndex)

      copiedToDayIndices.forEach((copiedTo: DayIndex) => {
        setValue(copiedTo, copyValues)
      })

      // Revalidate when copying
      trigger()
    },
    [getValues, setValue, trigger],
  )

  useEffect(() => {
    reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const fields = useMemo(() => {
    return (
      <Stack spacing={2}>
        {Array.from(new Array(7)).map((_, index) => (
          <DayItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={methods.control}
            dayIndex={index.toString() as ANY}
            onCopy={handleCopy}
          />
        ))}
      </Stack>
    )
  }, [handleCopy, methods.control])

  return (
    <FormProvider methods={methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {children({ fields })}
      </form>
    </FormProvider>
  )
}

export type DayItemProps = {
  dayIndex: DayIndex
  control: Control<AvailabilitySchedulesValues>
  onCopy?: (
    copiedFromDayIndex: DayIndex,
    copiedToDayIndices: DayIndex[],
  ) => void
}

export const DayItem: FC<DayItemProps> = ({ dayIndex, control, onCopy }) => {
  const { append, remove, fields } = useFieldArray({
    control,
    name: dayIndex,
  })
  const { trigger, watch } = useFormContext<AvailabilitySchedulesValues>()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const startOf2000 = useMemo(() => setDateTo2000(new Date()), [])

  const handleOpenCopyMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [],
  )

  const daySchedules = watch(dayIndex)

  const handleAdd = useCallback(() => {
    append({
      start: startOfHour(setHours(startOf2000, 9)),
      end: startOfHour(endOfDay(startOf2000)),
    })
  }, [append, startOf2000])

  const handleRemove = useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  return (
    <Stack
      spacing={2}
      sx={{
        borderBottom: (theme) =>
          +dayIndex < 6 ? `solid 1px ${theme.palette.divider}` : 'none',
        pb: 2,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="button">{fDayOfWeek(+dayIndex)}</Typography>
        <Stack direction="row">
          <IconButton onClick={handleAdd}>{createButtonIcon}</IconButton>
          <IconButton
            disabled={fields?.length === 0}
            onClick={handleOpenCopyMenu}
          >
            {copyButtonIcon}
          </IconButton>
          <CopyAvailabilitySchedulesMenu
            anchorEl={anchorEl}
            copiedFromDayIndex={dayIndex}
            open={!!anchorEl}
            onClose={() => setAnchorEl(null)}
            onSubmit={onCopy}
          />
        </Stack>
      </Stack>

      {fields?.filter((_, idx) => !!daySchedules[idx]?.start)?.length > 0 ? (
        <Box>
          <Stack spacing={2}>
            {fields.map((item, idx) => (
              <Stack
                // eslint-disable-next-line react/no-array-index-key
                key={item.id}
                alignItems="flex-start"
                direction="row"
                spacing={2}
              >
                <RHFTimeSlotPicker
                  control={control}
                  label="Start time"
                  maxTimeSlot={endOfDay(startOf2000)}
                  name={`${dayIndex}.${idx}.start`}
                  // Revalidate when either start or end changes
                  onChange={() => trigger(dayIndex)}
                />
                <Typography pt={2}>–</Typography>
                <RHFTimeSlotPicker
                  control={control}
                  label="End time"
                  minTimeSlot={startOfDay(startOf2000)}
                  name={`${dayIndex}.${idx}.end`}
                  // Revalidate when either start or end changes
                  onChange={() => trigger(dayIndex)}
                />
                <Box pt={2}>
                  <IconButton size="small" onClick={handleRemove(idx)}>
                    <X />
                  </IconButton>
                </Box>
              </Stack>
            ))}
          </Stack>
        </Box>
      ) : (
        <Typography color="textSecondary" variant="body2">
          Unavailable
        </Typography>
      )}
    </Stack>
  )
}

export default AvailabilitySchedulesForm
