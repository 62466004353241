import type { FC } from 'react'
import { useEffect } from 'react'

import { Button, Container } from '@mui/material'
import { Calendar, X } from '@phosphor-icons/react'
import { useMatch } from '@tanstack/react-location'
import { addHours, addMinutes } from 'date-fns'
import { some } from 'lodash'

import { LEARNER_ACTIVE_MENTOR_SESSION_ID } from '@core/config'
import {
  CourseMemberType,
  MentorSessionEventStatus,
  MentorSessionRecapType,
  MentorSessionStatus,
  UserRequestStatus,
  useMentorSessionViewQuery,
} from '@core/graphql'
import { useLearner, useLocalStorage } from '@core/hooks'
import { MentorSessionRecapIcon, RescheduleActivityIcon } from '@core/icons'
import { Countdown, PageSkeleton, UrqlError } from '@core/ui/components'
import JoinEventMeetingButton from '@modules/calendar/JoinEventMeetingButton'
import MentorSessionEventReschedule from '@modules/mentor-session/common/MentorSessionEventReschedule'
import { AddToCalendarDialog } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/components/AddToCalendarDialog'
import LearnerMemberSessionsProvider from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessionProvider'
import LearnerMemberSessions from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions'
import type { MentorSessionSecondaryActionData } from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'
import {
  MentorSessionMenuActionType,
  SessionEventMenuActionType,
  SessionEventSecondaryActionType,
} from '@modules/mentor-session/learner-sessions/LearnerMemberSessions/LearnerMemberSessions.type'
import CreateRecapDialog from '@modules/mentor-session/session-recaps/CreateRecapDialog'
import ViewRecapDialog from '@modules/mentor-session/session-recaps/ViewRecapDialog/ViewRecapDialog'
import { EVENT_ACTION_BTN_SX } from 'apps/mentor/mentor-session/LearnerMemberSessionsForMentor/LearnerMemberSessionsForMentor.const'
import RescheduleSessionEventRequestDialog, {
  DEFAULT_SESSION_EVENT_DURATION,
} from 'apps/workplace/modules/mentor-session/components/MentorSessionEventRescheduleRequestDialog'

import CancelSessionEventForLearnerDialog from './components/CancelSessionEvent/CancelSessionEventForLearnerDialog'

// Hours after an event finishes that it is still retained Need Action section
const FINISHED_EVENT_RETENTION_HOURS = 24

export type LearnerMemberSessionsForLearnerProps = {}

const LearnerMemberSessionsForLearner: FC<
  LearnerMemberSessionsForLearnerProps
> = () => {
  const {
    params: { mentorSessionId },
  } = useMatch()

  const { learner } = useLearner()

  const [, setMentorSessionId] = useLocalStorage<string | null>(
    `${LEARNER_ACTIVE_MENTOR_SESSION_ID}/${learner?.id}`,
    null,
  )

  useEffect(() => {
    setMentorSessionId(mentorSessionId)
  }, [mentorSessionId, setMentorSessionId])

  const [{ fetching, data, error }, refetch] = useMentorSessionViewQuery({
    variables: { id: mentorSessionId },
  })

  if (error) {
    return (
      <Container sx={{ py: 4 }}>
        <UrqlError error={error} />
      </Container>
    )
  }

  if (fetching && !data) {
    return <PageSkeleton />
  }

  return (
    <LearnerMemberSessionsProvider
      mentorSession={data?.mentorSession}
      refetchMentorSession={refetch}
      eventMenuActions={({
        sessionEvent,
        mentorSession,
        dialogIsOpen,
        onCloseDialog,
        onOpenDialog,
      }) => {
        if (!mentorSession || !sessionEvent) {
          return null
        }

        let status = sessionEvent.mentorSessionEvent?.status

        if (
          !some(
            [
              MentorSessionEventStatus.CANCELED_BY_LEARNER,
              MentorSessionEventStatus.CANCELED_BY_MENTOR,
            ],
            ($status) => $status === status,
          )
        ) {
          if (sessionEvent.endsAt < Date.now()) {
            status = MentorSessionEventStatus.FINISHED
          }

          if (
            sessionEvent.startsAt < Date.now() &&
            sessionEvent.endsAt > Date.now()
          ) {
            status = MentorSessionEventStatus.ONGOING
          }
        }

        // Only SCHEDULED or ONGOING events can be canceled
        const cancelActionDisabled = !some(
          [
            MentorSessionEventStatus.SCHEDULED,
            MentorSessionEventStatus.ONGOING,
          ],
          ($status) => $status === status,
        )

        const hasPendingRescheduleRequest = some(
          sessionEvent?.mentorSessionEvent?.rescheduleRequests,
          (request) => request.status === UserRequestStatus.PENDING,
        )
        const calendarEvents = mentorSession.calendarEvents.sort(
          (a, b) => a.startsAt - b.startsAt,
        )
        const eventIndex = calendarEvents.findIndex(
          (event) => event.id === sessionEvent.id,
        )

        const nextEvent =
          eventIndex >= 0 && eventIndex < calendarEvents.length - 1
            ? calendarEvents[eventIndex + 1]
            : undefined

        const previousEvent =
          eventIndex > 0 && eventIndex <= calendarEvents.length - 1
            ? calendarEvents[eventIndex - 1]
            : undefined

        const now = new Date()

        // Whether the event has already passed
        const eventHasPassed = sessionEvent.endsAt < now

        // Whether the max eligible reschedule time has already passed
        const maxEligibleTimeHasPassed =
          addHours(nextEvent?.startsAt, -DEFAULT_SESSION_EVENT_DURATION) < now

        // Disable reschedule action if:
        // a. Event status is ABSENT, FINISHED, COMPLETED OR
        // b. It was canceled by learner AND it has passed OR
        // c. Its max eligible time to reschedule has passed OR
        // d. It already had a pending reschedule request
        const rescheduleActionDisabled =
          mentorSession.status === MentorSessionStatus.CANCELED ||
          status === MentorSessionEventStatus.ABSENT ||
          status === MentorSessionEventStatus.FINISHED ||
          status === MentorSessionEventStatus.COMPLETED ||
          (status === MentorSessionEventStatus.CANCELED_BY_LEARNER &&
            eventHasPassed) ||
          maxEligibleTimeHasPassed ||
          hasPendingRescheduleRequest

        return [
          {
            disabled: rescheduleActionDisabled,
            label: 'Request reschedule',
            type: SessionEventMenuActionType.RESCHEDULE_EVENT,
            icon: <RescheduleActivityIcon />,
            renderActionDialog: (
              <RescheduleSessionEventRequestDialog
                isOpen={dialogIsOpen}
                mentorSession={mentorSession}
                nextEventStartsAt={nextEvent?.startsAt}
                prevEventEndsAt={previousEvent?.endsAt}
                sessionEvent={sessionEvent}
                onClose={() => {
                  onCloseDialog?.()
                  refetch()
                }}
              />
            ),
          },
          {
            disabled: cancelActionDisabled,
            label: 'Cancel session',
            type: SessionEventMenuActionType.CANCEL_EVENT,
            icon: <X />,
            renderActionDialog: (
              <CancelSessionEventForLearnerDialog
                hasPendingRescheduleRequest={hasPendingRescheduleRequest}
                isOpen={dialogIsOpen}
                mentorSession={mentorSession}
                sessionEvent={sessionEvent}
                onClose={onCloseDialog}
                onRedirect={
                  !rescheduleActionDisabled && !!onOpenDialog
                    ? () =>
                        // Redirect to reschedule MSE action if it is available
                        onOpenDialog({
                          actionType:
                            SessionEventMenuActionType.RESCHEDULE_EVENT,
                          dialogTargetId: sessionEvent.id,
                        })
                    : undefined
                }
              />
            ),
          },
        ]
      }}
      eventSecondaryAction={({
        mentorSession,
        sessionEvent,
        onOpenDialog,
        onCloseDialog,
        dialogIsOpen,
      }) => {
        if (!mentorSession || !sessionEvent?.mentorSessionEvent) {
          return null
        }

        let actionData: MentorSessionSecondaryActionData[] = []
        const status = sessionEvent?.mentorSessionEvent?.status
        const now = new Date()

        // Show Join now action when event
        // is within 10 minutes before the session
        // or the event status is ONGOING
        const isWithinTenMinutesBefore =
          status === MentorSessionEventStatus.SCHEDULED &&
          sessionEvent.endsAt >= now &&
          sessionEvent.startsAt < addMinutes(now, 10)

        if (
          isWithinTenMinutesBefore ||
          status === MentorSessionEventStatus.ONGOING
        ) {
          actionData = [
            {
              type: SessionEventSecondaryActionType.JOIN_EVENT,
              renderAction: (
                <Countdown
                  countTo={sessionEvent.startsAt}
                  render={({ secondsLeft, duration }) => (
                    <JoinEventMeetingButton
                      duration={secondsLeft > 0 ? duration : undefined}
                      eventId={sessionEvent.id}
                      mentorSessionId={mentorSession.id}
                      sx={EVENT_ACTION_BTN_SX}
                    />
                  )}
                  onCountdownEnd={() => {
                    refetch()
                  }}
                />
              ),
            },
            ...actionData,
          ]
        }

        const hasPendingRescheduleRequest = some(
          sessionEvent?.mentorSessionEvent?.rescheduleRequests,
          (request) => request.status === UserRequestStatus.PENDING,
        )
        const calendarEvents = mentorSession.calendarEvents.sort(
          (a, b) => a.startsAt - b.startsAt,
        )
        const eventIndex = calendarEvents.findIndex(
          (event) => event.id === sessionEvent.id,
        )

        const nextEvent =
          eventIndex >= 0 && eventIndex < calendarEvents.length - 1
            ? calendarEvents[eventIndex + 1]
            : undefined

        const previousEvent =
          eventIndex > 0 && eventIndex <= calendarEvents.length - 1
            ? calendarEvents[eventIndex - 1]
            : undefined

        // Whether the event has already passed
        const eventHasPassed = sessionEvent.endsAt < now

        // Whether the max eligible reschedule time has already passed
        const maxEligibleTimeHasPassed =
          addHours(nextEvent?.startsAt, -DEFAULT_SESSION_EVENT_DURATION) < now

        // Show reschedule action if:
        // a. Mentor session status is NOT CANCELED
        // b. Event status is CANCELED_BY_LEARNER OR CANCELED_BY_MENTOR
        // If event status is CANCELED_BY_LEARNER, it should also not have passed AND
        // c. Its max eligible time to reschedule has not passed AND
        // d. Event doesn't have a pending reschedule request
        const showRescheduleAction =
          mentorSession.status !== MentorSessionStatus.CANCELED &&
          ((status === MentorSessionEventStatus.CANCELED_BY_LEARNER &&
            !eventHasPassed) ||
            status === MentorSessionEventStatus.CANCELED_BY_MENTOR) &&
          !maxEligibleTimeHasPassed &&
          !hasPendingRescheduleRequest

        if (showRescheduleAction) {
          actionData = [
            {
              type: SessionEventSecondaryActionType.RESCHEDULE_EVENT,
              renderAction: (
                <Button
                  size="small"
                  startIcon={<RescheduleActivityIcon />}
                  sx={EVENT_ACTION_BTN_SX}
                  variant="contained"
                  onClick={() => {
                    onOpenDialog?.({
                      actionType:
                        SessionEventSecondaryActionType.RESCHEDULE_EVENT,
                      dialogTargetId: sessionEvent.id,
                    })
                  }}
                >
                  Reschedule
                </Button>
              ),
              renderActionDialog: (
                <RescheduleSessionEventRequestDialog
                  isOpen={dialogIsOpen}
                  mentorSession={mentorSession}
                  nextEventStartsAt={nextEvent?.startsAt}
                  prevEventEndsAt={previousEvent?.endsAt}
                  sessionEvent={sessionEvent}
                  onClose={() => {
                    onCloseDialog?.()
                    refetch()
                  }}
                />
              ),
            },
            ...actionData,
          ]
        }

        // Write / View recap action can only be displayed for ongoing or past events
        if (sessionEvent.startsAt <= now) {
          const mentorMember = mentorSession.courseMembers.filter(
            (courseMember) => courseMember.type === CourseMemberType.MENTOR,
          )?.[0]

          if (!mentorMember?.mentor || !learner) {
            return null
          }

          const sessionRecapCount =
            sessionEvent.mentorSessionEvent.mentorSessionRecapReport
              .mentorSessionRecapCount
          const sessionRequiredRecapCount =
            sessionEvent.mentorSessionEvent.mentorSessionRecapReport
              .requiredRecapCount

          // If recap is not yet submitted, show Write recap action
          if (sessionRecapCount.mentee < sessionRequiredRecapCount.mentee) {
            actionData = [
              ...actionData,
              {
                type: SessionEventSecondaryActionType.WRITE_RECAP,
                renderAction: (
                  <Button
                    size="small"
                    startIcon={<MentorSessionRecapIcon />}
                    sx={EVENT_ACTION_BTN_SX}
                    variant={
                      sessionEvent.endsAt < now ? 'contained' : 'outlined'
                    }
                    onClick={() => {
                      onOpenDialog?.({
                        actionType: SessionEventSecondaryActionType.WRITE_RECAP,
                        dialogTargetId: sessionEvent.id,
                      })
                    }}
                  >
                    Write recap
                  </Button>
                ),
                renderActionDialog: (
                  <CreateRecapDialog
                    calendarEvent={sessionEvent}
                    learnerId={learner.id}
                    mentorId={mentorMember.mentor.id}
                    mentorSession={mentorSession}
                    open={dialogIsOpen}
                    type={MentorSessionRecapType.MENTEE_RECAP}
                    onClose={onCloseDialog}
                  />
                ),
              },
            ]
          } else {
            // If recap is already submitted, show View recap action
            const mentorRecaps =
              sessionEvent.mentorSessionEvent.mentorSessionRecaps.filter(
                (recap) => recap.createdByUser.id === learner.userId,
              )

            if (mentorRecaps.length) {
              actionData = [
                ...actionData,
                {
                  type: SessionEventSecondaryActionType.VIEW_RECAP,
                  renderAction: (
                    <Button
                      size="small"
                      startIcon={<MentorSessionRecapIcon />}
                      sx={EVENT_ACTION_BTN_SX}
                      variant="outlined"
                      onClick={() =>
                        onOpenDialog?.({
                          actionType:
                            SessionEventSecondaryActionType.VIEW_RECAP,
                          dialogTargetId: sessionEvent.id,
                        })
                      }
                    >
                      View your recap
                    </Button>
                  ),
                  renderActionDialog: (
                    <ViewRecapDialog
                      open={dialogIsOpen}
                      recaps={mentorRecaps}
                      sessionDate={sessionEvent.startsAt}
                      onClose={onCloseDialog}
                    />
                  ),
                },
              ]
            }
          }
        }
        // Display reschedule status
        if (
          sessionEvent.mentorSessionEvent?.rescheduleRequests?.[0]?.userRequest
            ?.status === UserRequestStatus.PENDING
        ) {
          actionData = [
            // Reschedule icon goes first in queue
            {
              type: SessionEventSecondaryActionType.RESCHEDULE_STATUS,
              renderAction: (
                <MentorSessionEventReschedule event={sessionEvent} />
              ),
            },
            ...actionData,
          ]
        }

        // Display action(s), if any
        if (actionData.length) {
          return actionData
        }

        // Otherwise, nothing is displayed
        return null
      }}
      needsAction={({ sessionEvent }) => {
        if (!sessionEvent || !sessionEvent.mentorSessionEvent || !learner) {
          return false
        }

        const now = new Date()

        const learnerRecaps =
          sessionEvent.mentorSessionEvent.mentorSessionRecaps.filter(
            (recap) => recap.createdByUser.id === learner.userId,
          )

        const hasPendingRescheduleRequest = some(
          sessionEvent.mentorSessionEvent.rescheduleRequests,
          (request) => request.status === UserRequestStatus.PENDING,
        )

        // Keep event in Need Action section if:
        // a. It is within FINISHED_EVENT_RETENTION_HOURS after it finishes AND
        // Mentor has not submitted recap OR
        // b. It has pending reschedule request and it has passed
        if (
          (sessionEvent.mentorSessionEvent.status ===
            MentorSessionEventStatus.FINISHED &&
            now <
              addHours(sessionEvent.endsAt, FINISHED_EVENT_RETENTION_HOURS) &&
            learnerRecaps.length === 0) ||
          (hasPendingRescheduleRequest && now > sessionEvent.endsAt)
        ) {
          return true
        }

        return false
      }}
      sessionMenuActions={({ mentorSession, dialogIsOpen, onCloseDialog }) => {
        if (
          !mentorSession ||
          mentorSession.status === MentorSessionStatus.CANCELED
        ) {
          return null
        }

        return [
          {
            disabled: !mentorSession.calendarEvents?.length,
            label: 'Add to calendar',
            type: MentorSessionMenuActionType.ADD_TO_CALENDAR,
            icon: <Calendar />,
            renderActionDialog: (
              <AddToCalendarDialog
                endsAt={mentorSession.calendarEvents[0]?.endsAt}
                isOpen={dialogIsOpen}
                mentorSessionId={mentorSession.id}
                startsAt={mentorSession.calendarEvents[0]?.startsAt}
                eventTitle={`MentorSession: ${mentorSession.courseMembers
                  .map((member) => member.user?.name)
                  .join(', ')}`}
                onClose={onCloseDialog}
              />
            ),
          },
        ]
      }}
    >
      <LearnerMemberSessions />
    </LearnerMemberSessionsProvider>
  )
}

export default LearnerMemberSessionsForLearner
