import { array, number, object, string } from '@core/lib/yup'
import type { ANY } from '@core/types'

import type { FormConfig } from './types'
import { FormFieldType } from './types'

// TODO: Improve typings here

export const getDefaultValues = (config: FormConfig) => {
  const defaultValues: ANY = {}
  config.fields.forEach((field, index) => {
    const fieldConfig: ANY = field.config

    let value: ANY = null

    if (field.type === FormFieldType.TEXT) {
      value = ''
    } else if (field.type === FormFieldType.LINEAR_SCALE) {
      value = fieldConfig.to
    } else if (field.type === FormFieldType.MULTIPLE_CHOICE) {
      value = '-'
    } else if (field.type === FormFieldType.CHECKBOXES) {
      value = []
    } else if (field.type === FormFieldType.MULTI_CHOICE_GRID) {
      value = {}
    }

    defaultValues[index.toString()] = value
  })

  return defaultValues
}

export const getValidationSchema = (config: FormConfig) => {
  const schema: ANY = {}
  const REQUIRED_ERROR = 'This question is required'

  config.fields.forEach((field, index) => {
    const fieldConfig: ANY = field.config

    let item: ANY = null

    if (field.type === FormFieldType.TEXT) {
      item = string()
    } else if (field.type === FormFieldType.LINEAR_SCALE) {
      item = number().integer()
      if (fieldConfig.from) {
        item = item.min(fieldConfig.from)
      }
      if (fieldConfig.to) {
        item = item.max(fieldConfig.to)
      }
    } else if (field.type === FormFieldType.MULTIPLE_CHOICE) {
      item = string().not(['-'], REQUIRED_ERROR)
      if (!fieldConfig.withOther) {
        item = string().oneOf(fieldConfig.options ?? [], REQUIRED_ERROR)
      }
    } else if (field.type === FormFieldType.CHECKBOXES) {
      item = array().of(string())
      if (field.isRequired) {
        item = item.min(1, REQUIRED_ERROR)
      }
    } else if (field.type === FormFieldType.MULTI_CHOICE_GRID) {
      const obj: ANY = {}
      fieldConfig.rows?.forEach((row: string) => {
        obj[row] = string()
          .oneOf(fieldConfig.columns ?? [])
          .required()
      })
      item = object(obj)
    }

    if (field.isRequired) {
      item = item.required(REQUIRED_ERROR)
    }

    item = item.label('This question')

    schema[index.toString()] = item
  })

  return object(schema)
}
