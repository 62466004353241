import type { FC } from 'react'
import { useMemo } from 'react'

import { Stack, Typography, useTheme } from '@mui/material'
import { ArrowRight } from '@phosphor-icons/react'
import { format } from 'date-fns'

import type { ANY } from '@core/types'
import { UserDisplayName } from '@core/ui/components'

export type RequestRescheduleActivityProps = {
  data: ANY
  size?: 'small' | 'medium'
  userId?: string | null
}

const RequestRescheduleActivity: FC<RequestRescheduleActivityProps> = ({
  data,
  userId,
  size = 'medium',
}) => {
  const theme = useTheme()
  const { prevEndsAt, prevStartsAt, newEndsAt, newStartsAt } = data
  const renderUserDisplayName = useMemo(() => {
    if (!userId) {
      const isUserMentor = data.mentorUserIds?.includes(data.userRequestUserId)
      const isUserLearner = data.learnerUserIds?.includes(
        data.userRequestUserId,
      )
      if (isUserMentor) {
        return <>Mentor</>
      }
      if (isUserLearner) {
        return <>Learner</>
      }
    }
    return <UserDisplayName display="inline" userId={userId} />
  }, [userId, data.mentorUserIds, data.userRequestUserId, data.learnerUserIds])

  const isSmall = size === 'small'

  if (!prevEndsAt && !prevStartsAt && !newEndsAt && !newStartsAt) {
    return null
  }

  return (
    <>
      {renderUserDisplayName}
      {` `}
      requested rescheduling
      <Stack
        alignItems="center"
        direction="row"
        maxWidth="100%"
        spacing={isSmall ? 1 : 1.2}
      >
        <DateTimeBox
          endDate={prevEndsAt}
          isSmall={isSmall}
          startDate={prevStartsAt}
        />
        <ArrowRight
          color={theme.palette.text.secondary}
          fontSize={isSmall ? '1.4rem' : '1.6rem'}
        />
        <DateTimeBox
          endDate={newEndsAt}
          isSmall={isSmall}
          startDate={newStartsAt}
        />
      </Stack>
    </>
  )
}

const DateTimeBox: FC<{
  startDate: ANY
  endDate: ANY
  isSmall: boolean
}> = ({ startDate, endDate, isSmall }) => {
  return (
    <Stack
      sx={{
        px: 2,
        py: 1,
        my: 1,
        borderRadius: 1,
        flexGrow: 1,
        backgroundColor: (theme) => theme.palette.background.neutral,
      }}
    >
      <Typography
        color="textSecondary"
        fontSize={isSmall ? '0.875rem' : '1rem'}
        fontWeight={600}
      >
        {format(new Date(startDate), 'ccc, LLL dd, yyyy')}
      </Typography>
      <Typography
        color="textSecondary"
        fontSize={isSmall ? '0.875rem' : '1rem'}
        fontWeight={400}
      >
        {format(new Date(startDate), 'HH:mm a')}
        {' - '}
        {format(new Date(endDate), 'HH:mm a')}
      </Typography>
    </Stack>
  )
}

export default RequestRescheduleActivity
