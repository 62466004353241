import { SuspenseOutlet } from '@core/ui/components'
import CalendarView from 'apps/workplace/modules/calendar/CalendarView'
import CreateEvent from 'apps/workplace/modules/calendar/CreateEvent'
import UpdateEvent from 'apps/workplace/modules/calendar/UpdateEvent'

import type { GenericsRoute } from '../routes.type'

const CalendarRoute: GenericsRoute = {
  path: 'calendar',
  element: <SuspenseOutlet />,
  children: [
    {
      path: '/',
      element: <CalendarView />,
    },
    {
      path: '/events/create',
      element: <CreateEvent />,
    },
    {
      path: '/events/:eventId/update',
      element: <UpdateEvent />,
    },
  ],
}

export default CalendarRoute
