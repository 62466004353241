export default function normalizeSearchText(text: string): string {
  return text
    .replace(/[`~!#$%^&*()|\-=?;:'",<>[\]\\/]/gi, '')
    .replace(/\s\s+/g, ' ')
    .trim()
}

export const removeVietnameseTones = (text: string): string => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}
