import type { FC } from 'react'

import { Box, Grid } from '@mui/material'

import type { MentorSessionViewFragment } from '@core/graphql'
import { useLocales } from '@core/hooks'
import type { ArrayElement } from '@core/types'
import { DateFormatText } from '@core/ui/components'

import InfoItem from '../../InfoItem'

export type LearnerMember = ArrayElement<
  MentorSessionViewFragment['courseMembers']
>

export type LearnerInfoProps = {
  learnerMember: LearnerMember
}

const LearnerInfo: FC<LearnerInfoProps> = ({ learnerMember }) => {
  const { t } = useLocales()

  return (
    <Box>
      <Grid container spacing={2}>
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          label="Started date"
          content={
            learnerMember.enrollmentDate && (
              <DateFormatText
                date={learnerMember.enrollmentDate}
                format="dd/MM/yyyy"
                variant="format"
              />
            )
          }
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.user?.phone}
          label={t('user:phone')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.learner?.codingSkill}
          label={t('learner:coding_skill')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.learner?.expYears}
          label={t('learner:expYears')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.learner?.industry}
          label={t('learner:industry')}
        />
        <InfoItem
          configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.learner?.jobTitle}
          label={t('learner:jobTitle')}
        />
        {/* <InfoItem configs={{ ITEM_SM: 4, ITEM_MD: 4 }}
          content={learnerMember.learner?.lookingForJob}
          label={t('learner:looking_for_job')}
        /> */}
      </Grid>
    </Box>
  )
}

export default LearnerInfo
