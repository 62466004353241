import type { FC } from 'react'

import { Card, Grid, Stack, Typography } from '@mui/material'

import { useLocales } from '@core/hooks'
import type { ANY } from '@core/types'
import {
  ContentSectionGroup,
  InlineEditableText,
  UserAvatar,
  UserDisplayName,
} from '@core/ui/components'

const FIELD_SPACING = 2
const ITEM_SM = 6
const ITEM_MD = 4

const NA = <Typography color="textSecondary">N/A</Typography>

export type LearnerMemberInfoProps = {
  learnerMember: ANY
}

const LearnerMemberInfo: FC<LearnerMemberInfoProps> = ({ learnerMember }) => {
  const { t } = useLocales('learner')
  return (
    <Card sx={{ overflow: 'visible', pr: 1.5, py: 3 }}>
      <Stack px={3} spacing={3}>
        <ContentSectionGroup
          headerDivider
          direction="vertical"
          subtitle="Some ways to communicate and understand  your learner"
          title="About your learner"
        >
          <Grid container spacing={FIELD_SPACING}>
            <Grid item md={8} sm={8} xs={12}>
              <Stack alignItems="center" direction="row" spacing={1}>
                <UserAvatar size={45} userId={learnerMember.userId} />
                <UserDisplayName
                  color="currentcolor"
                  userId={learnerMember.userId}
                  whiteSpace="break-spaces"
                  sx={{
                    overflow: 'auto',
                  }}
                />
              </Stack>
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('user:email')}
                readView={learnerMember.user?.email || NA}
                value={learnerMember.user?.email || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('discord_id')}
                readView={learnerMember.learner?.discordId || NA}
                value={learnerMember.learner?.discordId || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_member:study_time_per_week')}
                readView={learnerMember.studyTimePerWeek || NA}
                value={learnerMember.studyTimePerWeek || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_member:learning_purpose')}
                readView={learnerMember.learningPurpose || NA}
                value={learnerMember.learningPurpose || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_member:month_to_change_job')}
                readView={learnerMember.monthToChangeJob || NA}
                value={learnerMember.monthToChangeJob || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_member:job_expectation')}
                readView={learnerMember.jobExpectation || NA}
                value={learnerMember.jobExpectation || ''}
                onSubmit={() => {}}
              />
            </Grid>
            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_expectation')}
                readView={learnerMember.courseExpectation || NA}
                value={learnerMember.courseExpectation || ''}
                onSubmit={() => {}}
              />
            </Grid>

            <Grid item md={ITEM_MD} sm={ITEM_SM} xs={12}>
              <InlineEditableText
                editable={false}
                label={t('course_member:reason_to_switch_career')}
                readView={learnerMember.reasonToSwitchCareer || NA}
                value={learnerMember.reasonToSwitchCareer || ''}
                onSubmit={() => {}}
              />
            </Grid>
          </Grid>
        </ContentSectionGroup>
      </Stack>
    </Card>
  )
}

export default LearnerMemberInfo
