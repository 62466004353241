import type { FC, ReactNode } from 'react'

import type { Duration, Locale } from 'date-fns'
import { formatDuration } from 'date-fns'

import { useCountdown } from './useCountdown'

export type CountdownProps = {
  countTo?: Date
  countSeconds?: number
  formatDurationOptions?: {
    format?: string[] | undefined
    zero?: boolean | undefined
    delimiter?: string | undefined
    locale?: Locale | undefined
  }
  render?: (props: { secondsLeft: number; duration: Duration }) => ReactNode
  onCountdownEnd?: VoidFunction
}

const Countdown: FC<CountdownProps> = ({
  countSeconds,
  countTo,
  formatDurationOptions,
  render,
  onCountdownEnd,
}) => {
  const { secondsLeft, duration } = useCountdown({
    countSeconds,
    countTo,
    onCountdownEnd,
  })

  if (!render) {
    return <>{formatDuration(duration, formatDurationOptions)}</>
  }

  return <>{render({ secondsLeft, duration })}</>
}

export default Countdown
