import { useCallback } from 'react'

import { get } from 'lodash'
import type { SchemaSpec } from 'yup/lib/schema'

import type { SchemaOf, AnyObjectSchema, ValidationError } from '@core/lib/yup'
import type { ANY } from '@core/types'

const getYupValidationResolver =
  <T extends object>(validationSchema: AnyObjectSchema) =>
  async (data: T) => {
    try {
      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })
      return {
        values,
        errors: {},
      }
    } catch (errors) {
      return {
        values: {},
        errors: (errors as ValidationError).inner.reduce(
          (allErrors: ANY, currentError: ANY) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {},
        ),
      }
    }
  }

export function useYupForm<Values extends Record<ANY, ANY>>(
  schema: SchemaOf<Values>,
) {
  const resolver = getYupValidationResolver(schema)

  const getField = useCallback(
    (name: keyof Values) => {
      const field: ANY = get(schema, ['fields', name])
      const fieldSpec: SchemaSpec<ANY> = get(field, 'spec')

      return { ...field, spec: fieldSpec }
    },
    [schema],
  )

  const getFieldProps = useCallback(
    (name: keyof Values) => {
      const field = getField(name)

      return {
        name,
        label: get(field, 'spec.label'),
        required: get(field, 'exclusiveTests.required'),
      }
    },
    [getField],
  )

  return { getField, getFieldProps, resolver, schema }
}
