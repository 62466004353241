import type { FC } from 'react'
import { useCallback } from 'react'

import { Box, Button, Chip, Stack, alpha } from '@mui/material'

import { useAuth } from '@core/auth'
import { DANGER_TIME_BREAKPOINT } from '@core/config/mentor-session'
import type { MentorSessionEventRescheduleRequestQuery } from '@core/graphql'
import { UserRole } from '@core/graphql'
import { Countdown } from '@core/ui/components'
import { fCountDown } from '@core/utils/formatTime'
import MentorSessionActivityLogTimeline from '@modules/mentor-session/common/MentorSessionActivityLog/MentorSessionActivityLogTimeline'

export type MentorSessionEventRescheduleRequestActionProps = {
  rescheduleRequest: MentorSessionEventRescheduleRequestQuery['mentorSessionEventRescheduleRequest']
  onCancel: VoidFunction
  onReject: VoidFunction
  onAccept: VoidFunction
}

const MentorSessionEventRescheduleRequestAction: FC<
  MentorSessionEventRescheduleRequestActionProps
> = ({ rescheduleRequest, onCancel, onReject, onAccept }) => {
  const { $user: user } = useAuth()

  const renderActionButton = useCallback(() => {
    if (
      user?.id &&
      rescheduleRequest &&
      rescheduleRequest.userRequest.recipientUserIds.includes(user.id) &&
      (user?.roles.indexOf(UserRole.MENTOR) ||
        user?.roles.indexOf(UserRole.LEARNER))
    ) {
      return (
        <>
          <Button
            color="inherit"
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.primary.main,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              fontWeight: 'bold',
            }}
            onClick={onAccept}
          >
            Accept
          </Button>
          <Button
            color="inherit"
            variant="outlined"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              fontWeight: 'bold',
            }}
            onClick={onReject}
          >
            Reject
          </Button>
        </>
      )
    }
    return (
      <Button
        color="inherit"
        variant="outlined"
        sx={{
          color: (theme) => theme.palette.primary.main,
          fontWeight: 'bold',
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
    )
  }, [onAccept, onCancel, onReject, rescheduleRequest, user?.id, user?.roles])

  return (
    <>
      <Box
        component="div"
        sx={{
          maxHeight: 400,
          maxWidth: 640,
          overflow: 'auto',
          pl: 2,
        }}
      >
        <MentorSessionActivityLogTimeline
          activityLogs={rescheduleRequest?.activityLogs || []}
        />
      </Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          pl: 4,
          pr: 4,
          pt: 2,
          pb: 2,
        }}
      >
        <Countdown
          countTo={new Date(rescheduleRequest?.expiresOn)}
          render={({
            secondsLeft,
            duration: { days, hours, minutes, seconds, months, years },
          }) => {
            return (
              <Chip
                variant="outlined"
                label={
                  secondsLeft > 0 ? (
                    <>
                      {!!years && `${years}y`} {!!months && `${months}m`}{' '}
                      {!!days && `${days}d`} {fCountDown(hours)}:
                      {fCountDown(minutes)}:{fCountDown(seconds)}
                    </>
                  ) : (
                    '00:00:00'
                  )
                }
                sx={{
                  fontWeight: 500,
                  fontSize: (theme) => theme.typography.body2.fontSize,
                  borderRadius: 1,
                  borderColor: 'currentcolor',
                  minWidth: 105,
                  ...(secondsLeft <= DANGER_TIME_BREAKPOINT
                    ? {
                        color: 'error.main',
                        backgroundColor: (theme) =>
                          alpha(theme.palette.error.main, 0.05),
                      }
                    : {}),
                }}
              />
            )
          }}
        />
        <Box
          component="div"
          sx={{
            margin: 0,
            left: 0,
          }}
        >
          {renderActionButton()}
        </Box>
      </Stack>
    </>
  )
}

export default MentorSessionEventRescheduleRequestAction
