import { devtoolsExchange } from '@urql/devtools'
import { cacheExchange } from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { refocusExchange } from '@urql/exchange-refocus'
import { createClient, dedupExchange } from 'urql'
import { v4 } from 'uuid'

import {
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER_EMAIL,
  LOCAL_STORAGE_USER_ID,
} from '../config'
import type { ANY } from '../types'

import type { DealEnrollment, UserCalendar } from './generated'

const RELAY_CONNECTIONS = [
  'userConnection',
  'courseConnection',
  'dealConnection',
  'courseMemberConnection',
  'activityLogConnection',
  'mentorConnection',
  'learnerConnection',
  'mentorSessionBookingRequestConnection',
  'mentorSessionConnection',
]

const client = createClient({
  url: '/graphql',
  fetchOptions: () => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    const userEmail = localStorage.getItem(LOCAL_STORAGE_USER_EMAIL)
    const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID)
    const correlation = v4()

    return {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'x-user-email': userEmail || '',
        'x-user-id': userId || '',
        'x-correlation-id': correlation,
        'apollo-require-preflight': 'true',
      },
    }
  },
  requestPolicy: 'cache-and-network',
  exchanges: [
    devtoolsExchange,
    dedupExchange,
    refocusExchange(),
    cacheExchange({
      resolvers: {
        Query: RELAY_CONNECTIONS.reduce(
          (o, key) => ({ ...o, [key]: relayPagination() }),
          {},
        ),
      },
      keys: {
        DealEnrollment: ((data: DealEnrollment) => data.dealId) as ANY,
        SelectableOption: (data) => `${data.group}_${data.value}`,
        UserCalendar: ((data: UserCalendar) =>
          `${data.userId}_${data.calendarId}`) as ANY,
      },
    }),
    multipartFetchExchange,
  ] as ANY,
})

export default client
