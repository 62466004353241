import type { FC } from 'react'

import { Box, Grid, styled, Link, alpha } from '@mui/material'
import { Bug, RocketLaunch } from '@phosphor-icons/react'
import { Helmet } from 'react-helmet-async'

import { useAuth } from '@core/auth'
import { Page, Spinner } from '@core/ui/components'

const FEATURE_REQUEST =
  'https://forms.clickup.com/31544931/f/y2nk3-11665/DY2QL4IK4EII9F3WQI'
const BUG_REPORT =
  'https://forms.clickup.com/31544931/f/y2nk3-11685/CUKE2UK5JE3ZI4ELO0'

export type FeedbackProps = {}

const Feedback: FC<FeedbackProps> = () => {
  const { isLoading, user, signOut } = useAuth()

  if (isLoading) {
    return (
      <Box sx={{ height: '100vh', display: 'grid', placeItems: 'center' }}>
        <Spinner />
      </Box>
    )
  }

  if (!user) {
    signOut?.()
    return <Spinner variant="overlay" />
  }

  return (
    <Page
      goBack={{ label: 'Back to app', to: '/' }}
      maxWidth="sm"
      pageTitle="Submit your feedback"
    >
      <Helmet title="Submit your feedback" />
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <FeedbackItem href={FEATURE_REQUEST} target="_blank">
            <RocketLaunch />
            Request new feature
          </FeedbackItem>
        </Grid>
        <Grid item sm={6} xs={12}>
          <FeedbackItem href={BUG_REPORT} target="_blank">
            <Bug />
            Report a bug
          </FeedbackItem>
        </Grid>
      </Grid>
    </Page>
  )
}

const FeedbackItem = styled(Link)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  border: `solid 3px ${theme.palette.divider}`,
  padding: theme.spacing(2),
  cursor: 'pointer',
  color: 'inherit',
  textDecoration: 'none !important',
  transition: theme.transitions.create('all'),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.lighter, 0.2),
  },
  '& > svg': {
    fontSize: 40,
    marginBottom: theme.spacing(2),
  },
}))

export default Feedback
