import type { FC } from 'react'

import { Alert, Box, Button, Fade, Stack } from '@mui/material'
import { Warning } from '@phosphor-icons/react'
import type { CombinedError } from 'urql'

import { UI } from '@core/config'

import Image from '../data-display/Image'

import UrqlError from './UrqlError'

export type ErrorScreenProps = {
  error: CombinedError
}

const LOGO_SIZE = {
  height: 80,
  width: 526,
}

const ErrorScreen: FC<ErrorScreenProps> = ({ error }) => {
  return (
    <Fade in unmountOnExit timeout={1000}>
      <Box sx={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}>
        <Box>
          <Image
            alt="CoderSchool Platform"
            src="/static/img/coderschool-logo.svg"
            title="CoderSchool Platform"
            sx={{
              width: LOGO_SIZE.width,
              height: LOGO_SIZE.height,
              mb: 4,
              filter: UI.LOGO_FILTER,
            }}
          />
          <Fade
            in
            timeout={1000}
            style={{
              transitionDelay: '1000ms',
            }}
          >
            <Stack spacing={2}>
              {import.meta.env.PROD &&
              import.meta.env.VITE_DEPLOY_ENV === 'production' ? (
                <Alert icon={<Warning />} severity="error">
                  Something went wrong. Please try again or contact us for
                  support.
                </Alert>
              ) : (
                <UrqlError error={error} />
              )}

              <Button
                size="large"
                variant="contained"
                onClick={() => window.location.replace('/')}
              >
                Go back
              </Button>
            </Stack>
          </Fade>
        </Box>
      </Box>
    </Fade>
  )
}

export default ErrorScreen
