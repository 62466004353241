import type { FC } from 'react'
import { useState } from 'react'

import { Box, Stack, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import WeekSelect from '../WeekSelect'

import DurationSelect from './DurationSelect'
import TimeSuggestions from './TimeSuggestions'

export type BookingTimeProps = {
  defaultRecurrence?: string
}

const BookingTime: FC<BookingTimeProps> = ({ defaultRecurrence }) => {
  const [intervalsFrom, setIntervalsFrom] = useState<Date | null | undefined>(
    new Date(),
  )
  const { watch } = useFormContext<BookMentorSessionValues>()

  const { duration, endsAfterMonths } = watch('time')

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography mb={4} variant="h4">
          Find the best time
        </Typography>

        <Stack alignItems="center" direction="row" spacing={2}>
          <DurationSelect />
          <WeekSelect value={intervalsFrom} onChange={setIntervalsFrom} />
        </Stack>
      </Stack>

      <Stack>
        <TimeSuggestions
          defaultRecurrence={defaultRecurrence}
          duration={duration}
          endsAfterMonths={endsAfterMonths}
          intervalsFrom={intervalsFrom!}
        />
      </Stack>
    </Box>
  )
}

export default BookingTime
