import { intervalToDuration } from 'date-fns'

import { fCountDown } from '@core/utils/formatTime'

import { MS_PER_SECOND } from './VideoPlayer.const'

export const formatVideoTime = (seconds: number) => {
  const duration = intervalToDuration({
    start: 0,
    end: seconds * MS_PER_SECOND,
  })
  return `${
    duration.hours && duration.hours > 0 ? `${fCountDown(duration.hours)}:` : ''
  }${fCountDown(duration.minutes)}:${fCountDown(duration.seconds)}`
}
