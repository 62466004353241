import type { FC, ReactNode } from 'react'
import { useEffect, useMemo } from 'react'

import type { ContainerProps } from '@mui/material'
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { CaretLeft } from '@phosphor-icons/react'
import ReactGA from 'react-ga'

import { useHistory, useLocales, useIsDesktop } from '@core/hooks'

export type PageProps = {
  children?: ReactNode
  pageTitle: ReactNode
  subtitle?: ReactNode
  actions?: ReactNode
  maxWidth?: ContainerProps['maxWidth']
  count?: number
  tabs?: ReactNode
  goBack?:
    | {
        label?: string
        to?: string
      }
    | true
  divider?: boolean
}

const Page: FC<PageProps> = ({
  children,
  pageTitle,
  subtitle,
  actions,
  maxWidth = 'md',
  count,
  tabs,
  goBack,
  divider = false,
}) => {
  const { t } = useLocales()
  const isDesktop = useIsDesktop()
  const history = useHistory()

  const renderPageTitle = useMemo(() => {
    if (typeof pageTitle === 'string') {
      return <Typography variant="h3">{pageTitle}</Typography>
    }
    return pageTitle
  }, [pageTitle])

  const renderGoBack = useMemo(() => {
    if (!goBack) {
      return null
    }

    const label = (goBack !== true && goBack.label) || t('go_back', 'Go back')
    const icon = <CaretLeft />

    if (goBack === true || !goBack.to) {
      return (
        <Button
          color="inherit"
          size="small"
          startIcon={icon}
          onClick={() => history.back()}
        >
          {label}
        </Button>
      )
    }

    return (
      <Button color="inherit" href={goBack.to} size="small" startIcon={icon}>
        {label}
      </Button>
    )
  }, [goBack, history, t])

  useEffect(() => {
    if (
      import.meta.env.PROD &&
      import.meta.env.VITE_DEPLOY_ENV === 'production'
    ) {
      ReactGA.pageview(
        window.location.pathname + window.location.search,
        undefined,
        typeof pageTitle === 'string' ? pageTitle : undefined,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box flex={1} pb={isDesktop ? 6 : 4}>
      <Box
        sx={{
          pt: isDesktop ? 6 : 4,
        }}
      >
        <Container maxWidth={maxWidth}>
          <Box pb={4}>
            {goBack && (
              <Box
                sx={{
                  ml: -1,
                  opacity: 0.6,
                  transition: (theme) => theme.transitions.create('opacity'),
                  '&:hover': { opacity: 1 },
                }}
              >
                {renderGoBack}
              </Box>
            )}
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs>
                <Stack direction="row" spacing={4}>
                  {renderPageTitle}
                  {typeof count !== 'undefined' && (
                    <Typography color="primary" variant="h3">
                      {count}
                    </Typography>
                  )}
                </Stack>
                {subtitle && (
                  <Typography color="textSecondary" variant="body1">
                    {subtitle}
                  </Typography>
                )}
              </Grid>

              {!!actions && (
                <Grid item>
                  <Stack direction="row" spacing={1}>
                    {actions}
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      </Box>
      {divider && <Divider />}
      {tabs && (
        <Box>
          <Container maxWidth={maxWidth}>
            <Box>{tabs}</Box>
          </Container>
          <Divider />
        </Box>
      )}
      <Container maxWidth={maxWidth} sx={{ py: 2 }}>
        <Box>{children}</Box>
      </Container>
    </Box>
  )
}

export default Page
