import type { Theme } from '@mui/material/styles'

import { LinkBehavior } from '@core/ui/components'

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      defaultProps: {
        LinkComponent: LinkBehavior,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },

          '&:active': {
            backgroundColor: theme.palette.action.selected,
          },
        },
      },
    },
  }
}
