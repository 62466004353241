import * as Sentry from '@sentry/react'

function initSentry() {
  Sentry.init({
    dsn: 'https://d4cedeff73874fafb6233da28643a99c@o4504077508411392.ingest.sentry.io/4504077521190912',

    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay({
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [
          window.location.origin,
          'https://api.videosdk.live',
        ],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^http:\/\/.*?\.platform\.coderschool\.vn/,
    ],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      'NetworkError',
      'Forbidden resource',
      'NotAllowedError',
      'jwt expired',
      'Large Render Blocking Asset',
      'ResizeObserver loop completed with undelivered notifications.',
      'Importing a module script failed.',
      'Unable to preload CSS',
      'Failed to fetch dynamically imported module',
      'Failed to fetch',
    ],
  })
}

export { initSentry }
