import type { FC } from 'react'
import { useMemo } from 'react'

import { Box, Drawer, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import type { BookMentorSessionValues } from '../../BookMentorSession.util'
import { DRAWER_WIDTH } from '../../BookMentorSession.util'

import LearnerPreview from './LearnerPreview'
import MentorPreview from './MentorPreview'
import SessionPreview from './SessionPreview'

export type RightDrawerProps = {
  onSubmit: VoidFunction
  submitButtonLabel: string
}

const RightDrawer: FC<RightDrawerProps> = ({ onSubmit, submitButtonLabel }) => {
  const { watch } = useFormContext<BookMentorSessionValues>()

  const { previewLearnerCourseMember, previewMentorCourseMember } = watch()

  const content = useMemo(() => {
    if (previewLearnerCourseMember || previewMentorCourseMember) {
      return (
        <Stack spacing={4}>
          {previewLearnerCourseMember && (
            <LearnerPreview courseMember={previewLearnerCourseMember} />
          )}
          {previewMentorCourseMember && (
            <MentorPreview courseMember={previewMentorCourseMember} />
          )}
        </Stack>
      )
    }

    return (
      <SessionPreview
        submitButtonLabel={submitButtonLabel}
        onSubmit={onSubmit}
      />
    )
  }, [
    onSubmit,
    previewLearnerCourseMember,
    previewMentorCourseMember,
    submitButtonLabel,
  ])

  return (
    <Drawer
      anchor="right"
      variant="permanent"
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          zIndex: 0,
        },
      }}
    >
      <Box minHeight="100vh">{content}</Box>
    </Drawer>
  )
}

export default RightDrawer
