import type { FC } from 'react'

import type { SxProps } from '@mui/material'
import { Box, Skeleton, Stack } from '@mui/material'

export type ListItemSkeletonProps = {
  sx?: SxProps
  repeat?: number
  spacing?: number
}

export const ListItemSkeleton: FC<ListItemSkeletonProps> = ({
  sx,
  repeat = 1,
  spacing = 2,
}) => {
  return (
    <Stack spacing={spacing} sx={sx}>
      {Array.from('.'.repeat(repeat)).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index}>
          <Skeleton width="40%" />
          <Skeleton width="55%" />
          <Stack direction="row" spacing={1}>
            <Skeleton width="10%" />
            <Skeleton width="15%" />
            <Skeleton width="10%" />
          </Stack>
        </Box>
      ))}
    </Stack>
  )
}
